// Styles for ModifyUpgradeReqHeader go here.
import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledModifyUpgradeReqHeader = styled.div`
  .modal_loader {
    &__heading {
      width: 60%;
      height: ${toRem(24)};
    }
  }
`;
