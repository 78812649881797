// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AccountPersistentState, useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import {
  KOREA_COUNTRY_CODE,
  COMMUNICATION_PREFERENCE,
  FOOD_AND_BEVERAGE,
  INTERESTS_AND_PASSION,
  AMBASSADOR_ELITE_PREFERENCE_ID,
  EDIT_CHANGE_PASSWORD_ID,
  handleRedirect,
  PHOENIX_SEND_OTP_CHALLENGE_URL,
  PROFILE_REDIRECTION_URL,
  addSubDirectoryPrefix,
  PageContext,
} from '../../modules';
import { useProfileStore } from '../../modules/store/profileStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { ProfileSectionElement } from './ProfileSectionElement/ProfileSectionElement';
import { profileSectionsHelper } from './ProfileUtils/profileHelper';
import { ProfileSectionProps } from './ProfileSection.types';
import { StyledProfileSection } from './ProfileSection.styles';

const VistanaVacationPolicy = dynamic(() =>
  import('../../molecules/VistanaVacationPolicy').then(mod => mod.VistanaVacationPolicy)
);

// Use named rather than default exports.
export const ProfileSection: FC<ProfileSectionProps> = pageProps => {
  const model = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const isStepUpAuthenticated = pageContext?.sessionData?.cacheData?.data?.stepUpAuthenticated;
  const [profileData] = useState(model);
  const { setProfileModalId, setOpenConsentFormProfileModalId, userBaseCountryCode } = useProfileStore(state => state);
  const [url, setUrl] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { memberData } = useAccountPersistentStore(store => store);
  const { setBannerId } = useBannerMessagesStore(state => state);
  const [memberDataState, setMemberDataState] = useState<AccountPersistentState['memberData']>();
  const { levelType, number: memberNumber } = memberDataState?.customer?.loyaltyInformation?.rewards || {};
  const profileSection = useMemo(() => profileSectionsHelper(profileData), []);

  const levelCode = levelType?.code;
  //need to modify the method for different modals
  const handleModalClick = (url: string, isVistanaModal: boolean = false) => {
    if (isVistanaModal) {
      setUrl(url);
      setIsOpen(true);
    } else {
      if (
        userBaseCountryCode === KOREA_COUNTRY_CODE &&
        (url === COMMUNICATION_PREFERENCE ||
          url === FOOD_AND_BEVERAGE ||
          url === INTERESTS_AND_PASSION ||
          url === AMBASSADOR_ELITE_PREFERENCE_ID)
      ) {
        /**
         * open consent form for korean user
         * for few models
         */
        setOpenConsentFormProfileModalId(url);
      } else if (url === EDIT_CHANGE_PASSWORD_ID && !isStepUpAuthenticated) {
        setBannerId(EDIT_CHANGE_PASSWORD_ID);
        const redirectUrl = `${PHOENIX_SEND_OTP_CHALLENGE_URL}?phxRedirect=true&returnTo=${encodeURIComponent(
          PROFILE_REDIRECTION_URL
        )}`;
        handleRedirect(addSubDirectoryPrefix(redirectUrl), false, false);
      } else {
        setProfileModalId(url);
      }
    }
  };

  // set page overflow hidden
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
    } else {
      document.body.style.overflow = 'auto'; // Restore background scrolling
    }
  }, [isOpen]);

  useEffect(() => {
    if (memberData) {
      setMemberDataState(memberData);
    }
  }, [memberData]);

  return (
    <StyledProfileSection data-testid="profilesection" data-component-name="o-account-profilesection">
      <div className="container">
        {isOpen && <VistanaVacationPolicy handleClose={() => setIsOpen(false)} modalURL={url || ''} />}
        <ProfileSectionElement
          profileSection={profileSection}
          memberNumber={`${memberNumber ?? ''}`}
          levelTypeCode={levelCode}
          handleModalClick={handleModalClick}
          sectionClass="profile-section mb-2 mb-md-5 pb-5"
          isAuthorMode={isAuthorMode}
        />
      </div>
    </StyledProfileSection>
  );
};

export const ProfileSectionConfig = {
  emptyLabel: 'profilesection',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/profilesection`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProfileSectionEditable = (props: any) => {
  return (
    <EditableComponent config={ProfileSectionConfig} {...props}>
      <ProfileSection {...props} />
    </EditableComponent>
  );
};
