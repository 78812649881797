import axios from 'axios';
import { Payload } from '../../organisms/UpdateRoomSelection/UpdateRoomSelection.types';

export const nuaUpgradeRequest = async (endPoint: string, payload: Payload) => {
  return await axios.post(endPoint, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
