/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
export interface CreateAccountState {
  formData: {
    firstName: string;
    lastName: string;
    pinyinEnglishSurname: string;
    pinyinEnglishName: string;
    zipcode: string;
    country: { id: string; value: string };
    email: string;
    pwrd: string;
    confirmPwrd: string;
    mobilePhone: string;
    mobilePhoneCountryId: { id: string; name: string; value: string };
    verificationCode: string;
  };
  transactionDetails: string;
  setTransactionDetails: (value: string) => void;
  tripId: string;
  setTripId: (value: string) => void;
  startDate: string;
  setStartDate: (value: string) => void;
  formValidation: boolean;
  setFormValidation: (value: boolean) => void;
  errorKey: string[];
  setErrorKey: (value: string[]) => void;
  consents: { type: string; optin: boolean; ctype: string }[];
  setConsents: (consents: { type: string; optin: boolean; ctype: string }[]) => void;
  joinNowEnabled: boolean;
  setJoinNowEnabled: (value: boolean) => void;
  joinNowBtnClicked: boolean;
  setJoinNowBtnClicked: (value: boolean) => void;
  remembermeCheckBox: boolean;
  setRemembermeCheckBox: (value: boolean) => void;
  errorsList: string[];
  setErrorsList: (errors: string[]) => void;
  setFormData: (data: Partial<CreateAccountState['formData']>) => void;
  checkedItems: {
    key?: string;
    checkboxId: string;
    checked: boolean;
  }[];
  setCheckedItems: (checkedItems: { key?: string; checkboxId: string; checked: boolean }[]) => void;
  initialCheckedItems: {
    key?: string;
    checkboxId: string;
    checked: boolean;
  }[];
  setIntitalCheckedItems: (checkedItems: { key?: string; checkboxId: string; checked: boolean }[]) => void;
  consentErrors: { [key: string]: string };
  setConsentErrors: (errors: { [key: string]: string }) => void;
  validSweepEnrollObject: {
    sweepEnrollmentSourceCode?: string;
    sweepRedirectUrl?: string;
  };
  setvalidSweepEnrollObject: (data?: { sweepEnrollmentSourceCode?: string; sweepRedirectUrl?: string }) => void;
}

const initialState: CreateAccountState = {
  formData: {
    firstName: '',
    lastName: '',
    pinyinEnglishSurname: '',
    pinyinEnglishName: '',
    zipcode: '',
    country: { id: '', value: '' },
    email: '',
    pwrd: '',
    confirmPwrd: '',
    mobilePhone: '',
    mobilePhoneCountryId: { id: '', name: '', value: '' },
    verificationCode: '',
  },
  consents: [
    { type: 'CORE_REQUIRED_CONSENT', optin: false, ctype: 'userConsents' },
    { type: 'PERSONALIZED_MARKETING_CONSENT', optin: false, ctype: 'userConsents' },
    { type: 'subscriptionsOffers', optin: false, ctype: 'communicationPreferences' },
    { type: 'subscriptions3rdParty', optin: false, ctype: 'communicationPreferences' },
  ],
  tripId: '',
  setTripId: () => {},
  startDate: '',
  setStartDate: () => {},
  transactionDetails: '',
  setTransactionDetails: () => {},
  setConsents: () => {},
  joinNowEnabled: false,
  setJoinNowEnabled: () => {},
  formValidation: false, // Initially set to false, this value will be changed to true only after all front-end validations are completed.
  setFormValidation: () => {},
  errorKey: [],
  setErrorKey: () => {},
  joinNowBtnClicked: false, // Initially set to false, this value will be changed to true only after the user clicks the "Join Now" button and the form validation is successful. After the API call, the value will be changed back to false.
  setJoinNowBtnClicked: () => {},
  remembermeCheckBox: true,
  setRemembermeCheckBox: () => {},
  errorsList: [],
  setErrorsList: () => {},
  setFormData: () => {},
  checkedItems: [],
  setCheckedItems: () => {},
  initialCheckedItems: [],
  setIntitalCheckedItems: () => {},
  consentErrors: {},
  setConsentErrors: () => {},
  validSweepEnrollObject: {
    sweepEnrollmentSourceCode: '',
    sweepRedirectUrl: '',
  },
  setvalidSweepEnrollObject: () => {},
};

const createAccountStore: StateCreator<CreateAccountState> = set => ({
  ...initialState,
  setConsents: (consents: { type: string; optin: boolean; ctype: string }[]) => {
    set(() => ({
      consents,
    }));
  },
  setTransactionDetails: (value: string) => {
    set(() => ({
      transactionDetails: value,
    }));
  },
  setTripId: (value: string) => {
    set(() => ({
      tripId: value,
    }));
  },
  setStartDate: (value: string) => {
    set(() => ({
      startDate: value,
    }));
  },
  setJoinNowEnabled: (value: boolean) => {
    set(() => ({
      joinNowEnabled: value,
    }));
  },
  setFormValidation: (value: boolean) => {
    set(() => ({
      formValidation: value,
    }));
  },
  setErrorKey: (value: string[]) => {
    set(() => ({
      errorKey: value,
    }));
  },
  setFormData: (data: Partial<CreateAccountState['formData']>) => {
    set((state: CreateAccountState) => ({
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  },
  setJoinNowBtnClicked: (value: boolean) => {
    set({ joinNowBtnClicked: value });
  },
  setRemembermeCheckBox: (value: boolean) => {
    set({ remembermeCheckBox: value });
  },
  setErrorsList: (errors: string[]) => {
    set(() => ({
      errorsList: errors,
    }));
  },
  setCheckedItems: (checkedItems: { key?: string; checkboxId: string; checked: boolean }[]) => {
    set(() => ({
      checkedItems,
    }));
  },
  setIntitalCheckedItems: (initialCheckedItems: { key?: string; checkboxId: string; checked: boolean }[]) => {
    set(() => ({
      initialCheckedItems,
    }));
  },
  setConsentErrors: (errors: { [key: string]: string }) => {
    set(() => ({
      consentErrors: errors,
    }));
  },
  setvalidSweepEnrollObject: (value?: { sweepEnrollmentSourceCode?: string; sweepRedirectUrl?: string }) => {
    set({ validSweepEnrollObject: value });
  },
});

export const useCreateAccountStore = createAppStore(createAccountStore, {
  usePersistentStore: false,
});
