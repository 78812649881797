// Styles for UpdateRoomSelection go here.
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledUpdateRoomSelection = styled.div`
  ${rtl`
    .upgrade_label a {
      text-decoration: underline;
    }
    .backButton {
      cursor: pointer;
    }
    .wrapper {
      box-shadow: 0 ${toRem(1)} ${toRem(1)} 0 ${baseVariables.shadows.boxShadowTile},
        0 ${toRem(1)} ${toRem(6)} 0 ${baseVariables.shadows.boxShadowTile};
      border-radius: ${toRem(14)};
    }
    .card-footer{
      label:is(${constants.RTL_LANG_LIST}){
        margin-left: ${toRem(24)};
        ::after {
        transform: rotateZ(45deg) !important;
        }
      }
    }
    .glide__arrow--left:is(${constants.RTL_LANG_LIST}){
      right: unset;
    }
    .nua{
      &-back-button:is(${constants.RTL_LANG_LIST}){
        align-self: baseline;
        ::before{
        transform: rotate(180deg); 
        }
      }
      &-footer:is(${constants.RTL_LANG_LIST}){
        margin-left: 0 !important;
        margin-right: auto;
      }
      &-update-btn{
        min-width: max-content;
      }
    }
  `}
`;
