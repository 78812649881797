import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledEarnedRewards = styled.div`
  ${rtl`
    .full-width {
      width: 100%;
    }

    .earned-rewards__heading {
      color: ${baseVariables.color['neutral40']};
    }

    .item-container {
      &__right {
        @media ${baseVariables.mediaQuery.lg} {
          white-space: nowrap;
        }
      }
    }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }

    .item-container__left:is(${constants.RTL_LANG_LIST}) {
      padding: 0 0 0 ${toRem(32)} !important;
    }

    .card-header .card-header__arrowicon:is(${constants.RTL_LANG_LIST}) {
      padding-left: ${toRem(16)} !important;
    }

    .card-header__icon:is(${constants.RTL_LANG_LIST}) {
      margin-right: ${toRem(6)} !important;
    }

    .card-footer:is(${constants.RTL_LANG_LIST}) {
      padding: 0 ${toRem(24)} 0 0 !important;

      .m-link-tertiary-button:is(${constants.RTL_LANG_LIST}) {
        margin: 0 !important;
      }
    }

    .item-container__right:is(${constants.RTL_LANG_LIST}) {
       padding-right : 0 !important;
    }

    @media ${baseVariables.mediaQuery.lg} {
      .item-container__right:is(${constants.RTL_LANG_LIST}) {
        margin: 0 auto 0 0 !important;
        text-align: left !important;
        padding-right : ${toRem(8)} !important;
      }
    }

    .border-line {
      border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral10']};
    }

    .awards-container #reward: first-of-type #awardcard .card{
      border-radius: 0rem 0rem ${toRem(16)} ${toRem(16)};
      box-shadow: none;
    }
  `}
`;
