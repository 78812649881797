/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { useId, FC, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import clsx from 'clsx';
import { inspect } from 'util';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  Button,
  Text,
  Types,
  Heading,
  Link,
  Messages,
  InputTextField,
  RichText,
  useCheckBreakpoint,
} from '@marriott/mi-ui-library';

import { useStore } from '../../modules/store/memberLevelStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import {
  getCurrentUrlParams,
  addSubDirectoryPrefix,
  correctSubDirectoryPathForHeader,
  scrollToClass,
  addSubDirectoryPrefixForNonEaa,
} from '../../modules/utils/helper';
import { apiLogger, getCountryAndLangCode, handleRedirect, validateZipCodeForUSandCanada } from '../../modules/utils';
import {
  forgotPasswordSubmitURL,
  emailPasswordUrl,
  activateAccountUrl,
  EMAIL_DATA_KEY,
  MASKED_PHONE,
  constants,
  userPrefilledDataURL,
} from '../../modules/utils/constants/constants';
import { PageContext } from '../../modules/context/PageContext';
import { Dropdown } from '../Dropdown';
import { ActivateOrForgotFormProps, FormData, FormError, CountryOption } from './ActivateOrForgotForm.types';
import { StyledActivateOrForgotForm } from './ActivateOrForgotForm.styles';
import { useRouter } from 'next/router';

const initialFormData: FormData = {
  memberNum: '',
  firstName: '',
  lastName: '',
  country: {
    value: '',
    id: '',
  },
  zipcode: '',
  prefillQueryParam: '',
};

// Use named rather than default exports.
export const ActivateOrForgotForm: FC<ActivateOrForgotFormProps> = ({
  authorModelData,
  setPageError,
  updatePageErrors,
  setDisablePasswordForm,
  setDisplayTypeOneError,
  setTypeTwoErrorHeader,
  setUserDetails,
  setPhoneDetails,
  model,
  isActivateAccountFlow,
  setSuccessMsg,
  isAuthorMode,
}) => {
  const { isForgotPasswordSuccessEaa, setIsForgotPasswordSuccessEaa } = useStore(state => state);
  const { setCommunicationOption } = useAccountPersistentStore(state => state);
  const [errors, setErrors] = useState<FormError>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [showZipcodeField, setShowZipcodeField] = useState(false);
  const [displayTypeOneErrorInside, setDisplayTypeOneErrorInside] = useState(false);
  const [toggleAccoridon, setToggleAccordion] = useState<boolean>(false);
  const [cancelButtonLink, setCancelButtonLink] = useState<string>('');
  const [isChinaPhoneNumberForChinaLocale, setIsChinaPhoneNumberForChinaLocale] = useState<boolean>(true);
  const [isLHSDisable, setIsLHSDisable] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const isTabletViewPort = useCheckBreakpoint('viewportM');

  const pageContext = useContext(PageContext);
  const serverENV = pageContext?.serverENV ? JSON.parse(pageContext?.serverENV) : {};
  const isMobileAuthFeatureEnabled = pageContext?.isMobileAuthFeatureEnabled;
  const isEAA = pageContext?.isEAASignIn;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const authorModel = model || authorModelData;
  const currentLocale = pageContext?.currentLocale;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode?.toUpperCase() || constants.USA_COUNTRY_CODE;
  const inputId = useId();
  const CustomClickTrackValue = `Activate or forgotpassword Form`;
  const router = useRouter();
  const tokenExpired = router?.query['tokenExpired'];
  const clientId = router?.query['client_id'];
  const prefillData = router?.query['prefillData'];
  const shouldPrefill =
    !isAuthorMode &&
    localeCountryCode === constants.CHINA_COUNTRY_CODE &&
    isEAA &&
    isActivateAccountFlow &&
    !!prefillData &&
    !!clientId;

  const resetForm = (fieldName: string) => {
    const newErrors: any = { ...errors };

    switch (fieldName) {
      case 'memberNum':
        newErrors.memberNumberError = undefined;
        newErrors.emailOrMemberError = undefined;
        break;
      case 'firstName':
        newErrors.firstNameError = undefined;
        break;
      case 'lastName':
        newErrors.lastNameError = undefined;
        break;
      case 'zipcode':
        newErrors.zipPostalCodeError = undefined;
        break;
      default:
    }
    setErrors(newErrors);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    value && resetForm(name);
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const isProhibitedCountry = checkProhibitedCountries();

    if (!isProhibitedCountry) {
      formSubmit();
    }
  };

  const formSubmit = async () => {
    if (!validateForgotPasswordFields()) {
      setIsLoading(false);
      return;
    }
    try {
      const urlParam = getCurrentUrlParams();
      await axios
        .post(
          addSubDirectoryPrefixForNonEaa(activateAccountUrl, isEAA),
          {
            accountId: formData?.memberNum,
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            country: formData?.country?.id,
            postalCode: formData?.zipcode,
            ...(shouldPrefill
              ? { queryParams: formData?.prefillQueryParam }
              : isEAA && urlParam
              ? { queryParams: urlParam }
              : ''),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Cookie: 'sessionID=' + sessionData?.sessionToken,
              isEAA: isEAA ?? '',
            },
          }
        )
        .then(data => {
          setPhoneDetails({
            phoneNumber: data?.data?.phoneNumber,
            transactionId: data?.data?.transactionId,
            isChinaMobileNumber: data?.data?.chinaMobileNumber,
            maskedNumber: data?.data?.maskedNumber,
            emailAddress: data?.data?.emailAddress,
          });
          setIsChinaPhoneNumberForChinaLocale(data?.data?.chinaMobileNumber);
          if (!isTabletViewPort) {
            const element = document.getElementsByClassName('set-password-container')?.[0];
            element?.scrollIntoView();
          }
        });
      setErrors({});
      setErrorsList([]);
      setPageError([]);
      setDisablePasswordForm(false);
      setUserDetails({
        givenName: formData?.firstName || '',
        surName: formData?.lastName || '',
        memberName: formData?.memberNum || '',
        country: formData?.country?.id || '',
        postalCode: formData?.zipcode || '',
        prefillQueryParam: formData?.prefillQueryParam || '',
      });
      setIsLHSDisable(true);
      setIsSubmitted(true);
    } catch (error: any) {
      scrollToClass();
      if (error) {
        const errorArray: any = [];
        const errorObject: any = {};
        const errorBody = error?.response?.data?.phoenixErrorMessages;
        setDisablePasswordForm(true);
        errorBody?.errorMessages?.map((key: string) => {
          const errorString = key === 'uxlFailureErrorMessage' ? pageContext?.uxlErrorMessage : authorModel[key];
          errorArray.push(errorString);
          errorObject[key] = errorString;
        });
        if (
          errorBody?.errorMessages &&
          (errorBody?.errorMessages.includes('errorMessageCorrectInfo') ||
            errorBody?.errorMessages.includes('errorMessageResetPwrd') ||
            errorBody?.errorMessages.includes('uxlFailureErrorMessage'))
        ) {
          setDisplayTypeOneError(true);
        } else {
          setDisplayTypeOneError(false);
          setTypeTwoErrorHeader(authorModel?.errorMessageForgotPwrd);
        }
        setPageError(errorArray);
        setErrors(errorObject);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const updatedLink =
      isEAA && authorModel?.cancelButtonCtaPath
        ? authorModel?.cancelButtonCtaPath + getCurrentUrlParams()
        : authorModel?.cancelButtonCtaPath;
    setCancelButtonLink(updatedLink);

    if (authorModel) {
      isActivateAccountFlow && setSuccessMsg && setSuccessMsg(authorModel?.successfulActivationMessage);
      if (authorModel?.countries && authorModel?.countries.length) {
        const defCountry = localeCountryCode
          ? authorModel?.countries.find(
              (country: CountryOption) => country?.id?.toUpperCase() === localeCountryCode?.toUpperCase()
            )
          : authorModel?.countries[0];
        setFormData(prevData => ({
          ...prevData,
          country: defCountry,
        }));
        if (defCountry?.id === constants.CANADA_COUNTRY_CODE || defCountry?.id === constants.USA_COUNTRY_CODE) {
          setShowZipcodeField(true);
        }
      }
    }
    correctSubDirectoryPathForHeader();

    if (shouldPrefill) {
      handlePrefillData();
    }
  }, []);

  const handleCountryNameChange = (option: any) => {
    if (option.id === constants.CANADA_COUNTRY_CODE || option.id === constants.USA_COUNTRY_CODE) {
      setShowZipcodeField(true);
    } else {
      setShowZipcodeField(false);
    }
    setFormData(prevData => ({ ...prevData, country: { ...option } }));
  };

  const checkProhibitedCountries = () => {
    if (
      serverENV?.PROHIBITED_COUNTRIES_CODES &&
      (serverENV?.PROHIBITED_COUNTRIES_CODES.includes(formData?.country?.id) ||
        serverENV?.PROHIBITED_COUNTRIES_CODES.includes(localeCountryCode))
    ) {
      const newErrors: any = {};
      newErrors.prohibitedCountryErrMsg = authorModel?.prohibitedCountryErrMsg;
      setErrors(newErrors);
      setPageError([]);
      updatePageErrors({
        text: authorModel?.prohibitedCountryErrMsg,
        class: 'error-sev1',
        type: 'alert',
      });
      setIsLoading(false);
      return true;
    } else {
      return false;
    }
  };

  const handleKeyPress = (ev: any) => {
    /**
     * handle key press event on input field that will submit the
     * form
     */
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      isActivateAccountFlow ? handleSubmit(ev) : handleForgotPasswordSubmit(ev);
    }
  };

  const handleForgotPasswordSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateForgotPasswordFields()) {
      setIsLoading(false);
      return;
    }

    const urlParam = getCurrentUrlParams();
    try {
      await axios
        .post(
          addSubDirectoryPrefixForNonEaa(forgotPasswordSubmitURL, isEAA),
          {
            country: formData?.country?.id,
            givenName: formData?.firstName,
            surname: formData?.lastName,
            username: formData?.memberNum,
            postalCode: formData?.zipcode?.length ? formData?.zipcode : null,
            ...(isEAA && urlParam && { queryParams: urlParam }),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Cookie: 'sessionID=' + sessionData?.sessionToken,
              isEAA: isEAA ?? '',
            },
          }
        )
        .then((response: any) => {
          if (response?.data?.status === 'SUCCESS') {
            setCommunicationOption?.(response?.data?.communicationOption);
            if (
              response?.data?.isRedirect &&
              response?.data?.resetToken &&
              response?.data?.validateToken &&
              response?.data?.phoneNumber
            ) {
              sessionStorage.setItem(MASKED_PHONE, response?.data?.phoneNumber);
              handleRedirect(addSubDirectoryPrefixForNonEaa(response?.data?.nextStateURI, isEAA), false, false);
            } else if (response?.data?.emailAddress) {
              sessionStorage.setItem(EMAIL_DATA_KEY, response?.data?.emailAddress);
              if (isEAA) {
                setIsForgotPasswordSuccessEaa(true);
                setIsLoading(false);
              } else {
                handleRedirect(addSubDirectoryPrefix(emailPasswordUrl));
              }
            }
          } else {
            setDisplayTypeOneErrorInside && setDisplayTypeOneErrorInside(true);
            setErrorsList && setErrorsList([authorModel?.errorMessageCorrectInfo]);
            setIsLoading(false);
          }
          apiLogger(
            `[resetCustomerPasswordDetails] resetCustomerPassword - sessionId value: ${
              sessionData?.sessionToken
            }: ${inspect(response?.data)}`
          );
        });
    } catch (error: any) {
      scrollToClass();
      setIsLoading(false);
      if (error) {
        const errorArray: any = [];
        const errorObject: any = {};
        const errorBody = error?.response?.data?.phoenixErrorMessages;
        errorBody?.errorMessages?.map((key: string) => {
          errorArray.push(authorModel[key]);
          errorObject[key] = authorModel[key];
        });
        if (
          errorBody?.errorMessages &&
          errorBody?.errorMessages?.length &&
          errorBody?.errorMessages.includes('zipPostalCodeError')
        ) {
          setDisplayTypeOneError && setDisplayTypeOneError(true);
        } else {
          setDisplayTypeOneErrorInside && setDisplayTypeOneErrorInside(true);
          setErrorsList && setErrorsList([authorModel?.errorMessageCorrectInfo]);
        }
        setPageError && setPageError(errorArray);
        setErrors && setErrors(errorObject);
      }
    }
  };

  const handlePrefillData = () => {
    try {
      setIsLHSDisable(true);
      axios
        .post(
          userPrefilledDataURL,
          {
            clientId,
            prefillData,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              isEAA: isEAA ?? '',
            },
          }
        )
        .then((response: any) => {
          let hasAllFields = false;
          if (response.data?.status === 'SUCCESS') {
            const filteredCountry =
              authorModel?.countries?.find((country: any) => country.id === response?.data?.countryCode) || '';
            if (
              response.data?.countryCode?.id === constants.CANADA_COUNTRY_CODE ||
              response.data?.countryCode?.id === constants.USA_COUNTRY_CODE
            ) {
              setShowZipcodeField(true);
            }
            if (response.data) {
              setFormData(prevData => ({
                ...prevData,
                firstName: response.data?.firstName ?? '',
                lastName: response.data?.lastName ?? '',
                memberNum: response.data?.memberNumber ?? '',
                zipcode: showZipcodeField ? response.data?.postalCode : '',
                ...(filteredCountry && { country: filteredCountry }),
                prefillQueryParam: response.data?.queryParams,
              }));
              if (
                response.data?.firstName &&
                response.data?.lastName &&
                response.data?.memberNumber &&
                filteredCountry &&
                ((showZipcodeField && response.data?.postalCode) || !showZipcodeField)
              ) {
                hasAllFields = true;
              }
            }
          }
          setIsLHSDisable(hasAllFields);
          apiLogger(`[EAA PrefiilData] prefillData:  ${inspect(response?.data)}`);
        });
    } catch (error: any) {
      apiLogger(`[EAA PrefiilData] prefillData api failure error: ${error}`);
      setIsLHSDisable(false);
    }
  };

  const validateForgotPasswordFields = () => {
    const newErrors: any = {};
    let isValid = true;
    if (!formData?.memberNum && !isMobileAuthFeatureEnabled) {
      if (isActivateAccountFlow) {
        newErrors.memberNumberError = authorModel?.memberNumberError;
      } else {
        newErrors.emailOrMemberError = authorModel?.emailOrMemberError;
      }
      isValid = false;
    } else if (isEAA && !formData?.memberNum && isMobileAuthFeatureEnabled) {
      newErrors.emailOrMemberError = authorModel?.credentialsErrorMessage;
      isValid = false;
    }
    if (!formData?.firstName) {
      newErrors.firstNameError = authorModel?.firstNameError;
      isValid = false;
    }
    if (!formData?.lastName) {
      newErrors.lastNameError = authorModel?.lastNameError;
      isValid = false;
    }
    if (showZipcodeField) {
      if (!formData?.zipcode) {
        newErrors.zipPostalCodeError = authorModel?.zipPostalCodeError;
        isValid = false;
      } else if (!validateZipCodeForUSandCanada(formData?.country?.id || '', formData?.zipcode)) {
        newErrors.zipPostalCodeError = authorModel?.zipInlineError || '';
        isValid = false;
      }
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    if (isActivateAccountFlow) {
      setPageError && setPageError(Object.values(newErrors));
      setDisplayTypeOneError && setDisplayTypeOneError(false);
      setTypeTwoErrorHeader && setTypeTwoErrorHeader(authorModel?.errorMessageForgotPwrd);
    }
    setDisplayTypeOneErrorInside && setDisplayTypeOneErrorInside(false);
    !isValid && scrollToClass();
    return isValid;
  };

  const renderFirstNameField = () => (
    <InputTextField
      label={authorModel?.firstNameLabel}
      testId="first-name"
      inputValue={formData?.firstName}
      showErrorMessage={errors?.firstNameError !== undefined}
      messageToShow={errors?.firstNameError}
      setErrorHtml={true}
      messageClass="error-label"
      inputMaxLength={20}
      getInputProps={() => ({
        /** setReadOnly props for remeber case */
        autoComplete: 'off',
        onKeyUp: handleKeyPress,
        id: `firstname`,
      })}
      getLabelProps={() => ({
        htmlFor: `firstname`,
      })}
      className={clsx(
        'm-input-field pb-2 mt-5',
        { 'is-disabled': isLHSDisable },
        errors?.firstNameError ? 'is-error' : formData?.firstName ? 'is-active' : ''
      )}
      getInputValue={(val: string) => {
        val && resetForm('firstName');
        setFormData({ ...formData, firstName: val });
      }}
    />
  );

  const renderLastNameField = () => {
    return (
      <InputTextField
        label={authorModel?.lastNameLabel}
        testId="last-name"
        inputValue={formData?.lastName}
        showErrorMessage={errors?.lastNameError !== undefined}
        messageToShow={errors?.lastNameError}
        setErrorHtml={true}
        messageClass="error-label"
        inputMaxLength={25}
        getInputProps={() => ({
          /** setReadOnly props for remeber case */
          autoComplete: 'off',
          onKeyUp: handleKeyPress,
          id: `${inputId}-lastName`,
        })}
        getLabelProps={() => ({
          htmlFor: `${inputId}-lastName`,
        })}
        className={clsx(
          'm-input-field pb-2 mt-5',
          { 'is-disabled': isLHSDisable },
          errors?.lastNameError ? 'is-error' : formData?.lastName ? 'is-active' : ''
        )} //is-error,is-active
        getInputValue={(val: string) => {
          val && resetForm('lastName');
          setFormData({ ...formData, lastName: val });
        }}
      />
    );
  };

  const isReverseFieldOrder = () => {
    let reverseOrder = false;
    if (
      isEAA ||
      isMobileAuthFeatureEnabled ||
      localeCountryCode === constants.JAPAN_COUNTRY_CODE ||
      localeCountryCode === constants.KOREA_COUNTRY_CODE ||
      localeCountryCode === constants.CHINA_COUNTRY_CODE
    ) {
      reverseOrder = true;
    }
    return reverseOrder;
  };

  const shouldRenderCopyright =
    localeCountryCode === constants.JAPAN_COUNTRY_CODE ||
    localeCountryCode === constants.KOREA_COUNTRY_CODE ||
    localeCountryCode === constants.CHINA_COUNTRY_CODE;

  const renderFields = () => {
    // debugger;
    return (
      <form
        data-component-name="m-account-ActivateOrForgotForm"
        data-testid="account-ActivateOrForgotForm"
        method="post"
        id="submit"
        onSubmit={isActivateAccountFlow ? handleSubmit : handleForgotPasswordSubmit}
      >
        <div className="activate-form">
          {isActivateAccountFlow ? (
            <div className="activate-form__heading t-subtitle-xl mb-4">{authorModel?.subTitle}</div>
          ) : (
            ''
          )}
          {tokenExpired && (
            <Messages messageType="warning" className="mb-5">
              <RichText text={authorModel?.resetPwrdLinkExpired} componentId="reset-token-error" />
            </Messages>
          )}
          <div className="activate-form__body">
            <div className="row">
              <div className={clsx('col-12', isActivateAccountFlow ? 'col-lg-11' : 'col-lg-12')}>
                <div
                  className={clsx(
                    'm-input-field pb-2',
                    { 'is-disabled': isLHSDisable },
                    errors?.memberNumberError || errors?.emailOrMemberError
                      ? 'is-error'
                      : formData?.memberNum
                      ? 'is-active'
                      : ''
                  )}
                >
                  <label htmlFor="memberNum" data-testid="member-number-label">
                    {isActivateAccountFlow
                      ? authorModel?.memberNumberLabel
                      : isMobileAuthFeatureEnabled
                      ? authorModel?.credentialsLabel
                      : authorModel?.emailOrMemberLabel}
                    <span> </span>
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    id="memberNum"
                    maxLength={isActivateAccountFlow ? 9 : 80}
                    data-testid="memnum"
                    value={formData?.memberNum}
                    name="memberNum"
                    onChange={handleChange}
                    disabled={isLHSDisable}
                  />
                  {errors?.memberNumberError && [<span>{errors?.memberNumberError}</span>]}
                  {errors?.emailOrMemberError && [<span>{errors?.emailOrMemberError}</span>]}
                  {!isActivateAccountFlow && !isMobileAuthFeatureEnabled && !errors?.emailOrMemberError && (
                    <span
                      className="t-label-xs"
                      dangerouslySetInnerHTML={{ __html: authorModel?.errorMessageSignIn }}
                    ></span>
                  )}
                  {isActivateAccountFlow && formData?.country?.id === 'CN' && !isChinaPhoneNumberForChinaLocale ? (
                    <div className="mt-1">
                      <RichText
                        text={authorModel?.membershipNumberWarningMessage}
                        customClass="t-label-xs"
                        componentId="membershipwarningmsg"
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {isActivateAccountFlow &&
                  (shouldRenderCopyright || !isEAA) &&
                  !(formData?.country?.id === 'CN' && !isChinaPhoneNumberForChinaLocale) ? (
                    <div className="mt-1">
                      <RichText
                        text={authorModel?.inlineTextMessage}
                        customClass="t-label-xs"
                        componentId="inlinetextmsg"
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {!isActivateAccountFlow && isMobileAuthFeatureEnabled && !errors?.emailOrMemberError && (
                    <div
                      className={clsx(
                        'accordion accordion-container m-accordion-container ps-0 mt-1',
                        !toggleAccoridon ? 'mb-1' : 'mb-3'
                      )}
                      id="accordion-forgotpassword"
                    >
                      {/* TODO:// need confirmation on accordion atoms in mi-ui-lib */}
                      <div className="accordion-item">
                        <div className="p-0 accordion-header px-0 mb-1" id="headingOne-signin">
                          <button
                            className="accordion-button collapsed p-0 ms-0 border-0"
                            type="button"
                            id="accordian_button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne-signin"
                            aria-expanded={toggleAccoridon}
                            aria-controls="collapseOne-signin"
                            onClick={() => setToggleAccordion(!toggleAccoridon)}
                          >
                            <div className="m-accordion-title d-flex align-items-center">
                              <p className="accordian-title t-label-xs mb-0">
                                {authorModel.phoneNumberVerificationMessage}
                              </p>
                              <span
                                className={clsx(
                                  'icon-arrow-down accordion-arrow-icon pl-2 icon-xs arrow-icon',
                                  toggleAccoridon ? 'icon-arrow-up' : 'icon-arrow-down'
                                )}
                              ></span>
                            </div>
                          </button>
                        </div>
                        <div
                          id="collapseOne-signin"
                          className={clsx('accordion-collapse collapse', { show: toggleAccoridon })}
                          aria-labelledby="headingOne-signin"
                          data-bs-parent="#accordionExample-signin"
                        >
                          <div
                            className="accordion-body t-label-xs"
                            dangerouslySetInnerHTML={{ __html: authorModel?.membershipVerificationMessage ?? '' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isActivateAccountFlow &&
                    isMobileAuthFeatureEnabled &&
                    !errors?.emailOrMemberError &&
                    (!isEAA || (isEAA && localeCountryCode === constants.CHINA_COUNTRY_CODE)) && (
                      <RichText
                        text={authorModel?.membershipPwrdResetMessage}
                        customClass="t-label-xs help-text"
                        componentId="membership-reset-message"
                      />
                    )}
                  {/* (isActivateAccountFlow && isMobileAuthFeatureEnabled && !errors?.emailOrMemberError) || */}
                  <span className="t-label-xs help-text">{authorModel?.memberNumberInfoLabel}</span>
                </div>
                {isReverseFieldOrder()
                  ? [renderLastNameField(), renderFirstNameField()]
                  : [renderFirstNameField(), renderLastNameField()]}
                <div
                  className={clsx('m-input-field mt-5 ', { 'is-disabled': isLHSDisable }, { 'pb-2': showZipcodeField })}
                >
                  <label
                    className="country-label"
                    id="dropdown-label-fp-country"
                    htmlFor="dropdown-label-fp-country"
                    data-testid="country-region-label"
                  >
                    {authorModel?.countryRegionLabel}
                  </label>
                  <Dropdown
                    defaultOption={formData?.country?.value || ''}
                    dropdownOptions={authorModel?.countries ?? []}
                    onChange={handleCountryNameChange}
                    customClassName="m-input-field activate_account--countrydropdown"
                    dropdownId="fp-country"
                    isDisabled={isLHSDisable}
                  />
                </div>
                {showZipcodeField && (
                  <InputTextField
                    label={authorModel?.zipPostalCodeLabel}
                    inputValue={formData?.zipcode}
                    showErrorMessage={errors?.zipPostalCodeError !== undefined}
                    messageToShow={errors?.zipPostalCodeError}
                    setErrorHtml={true}
                    messageClass="error-label"
                    inputMaxLength={10}
                    getInputProps={() => ({
                      /** setReadOnly props for remeber case */
                      autoComplete: 'off',
                      onKeyUp: handleKeyPress,
                      id: `${inputId}-zipcode`,
                    })}
                    getLabelProps={() => ({
                      htmlFor: `${inputId}-zipcode`,
                    })}
                    className={clsx(
                      'm-input-field mt-5 ',
                      { 'is-disabled': isLHSDisable },
                      errors?.zipPostalCodeError ? 'is-error' : formData?.zipcode ? 'is-active' : ''
                    )} //is-error,is-active
                    getInputValue={(val: string) => {
                      val && resetForm('zipcode');
                      setFormData({ ...formData, zipcode: val?.trim() });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 d-flex align-items-center flex-column flex-md-row mt-5 px-0 rtl-content">
              {isActivateAccountFlow ? (
                <Button
                  className={clsx('m-button-m m-button-primary custom-btn px-4', {
                    disabled: isLHSDisable && isSubmitted,
                  })}
                  type={Types.ButtonTypeVariation.Submit}
                  isDisabled={isLoading}
                  testId="continue-btn"
                  {...(!isEAA && {
                    custom_click_track_value: `${CustomClickTrackValue}|${authorModel?.submitButtonLabel}|Submit`,
                  })}
                >
                  {isLoading ? (
                    <div className="m-spinner-s" data-testid="activate-spinner"></div>
                  ) : (
                    authorModel?.continueButtonLabel
                  )}
                </Button>
              ) : (
                <>
                  <Button
                    className="m-button-m m-button-primary custom-btn submit_btn"
                    // callback={handleForgotPasswordSubmit}
                    type={Types.ButtonTypeVariation.Submit}
                    isDisabled={isLoading}
                    testId="forgot-btn-submit"
                    {...(!isEAA && {
                      custom_click_track_value: `${CustomClickTrackValue}|${authorModel?.submitButtonLabel}|Submit`,
                    })}
                  >
                    {isLoading ? (
                      <div className="m-spinner-s" data-testid="forgot-spinner"></div>
                    ) : (
                      authorModel?.submitButtonLabel
                    )}
                  </Button>
                  <Link
                    text={authorModel?.cancelButtonLabel}
                    linkClassName="m-link-action mt-4 mt-md-0 ml-md-5"
                    linkHref={cancelButtonLink}
                    target="_self"
                    linkType="internal"
                  />
                </>
              )}
            </div>
            {isEAA ? (
              <Text
                customClass={`t-font-xs pt-2 mt-5 ${isActivateAccountFlow ? 'd-none d-lg-flex' : ''}`}
                copyText={authorModel?.copyright?.replace('{0}', String(moment().year()))}
                fontSize={Types.size.extraSmall}
                element={Types.tags.paragraph}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </form>
    );
  };

  return (
    <div>
      {!isForgotPasswordSuccessEaa && (
        <StyledActivateOrForgotForm>
          {isActivateAccountFlow ? (
            <div className={clsx('row activate-form-container', { 'form-disabled': isLHSDisable && isSubmitted })}>
              <div className="col-12">{renderFields()}</div>
            </div>
          ) : (
            <div className="container column-container my-5 ">
              <div className="row">
                <div className={clsx('col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-5', { 'mx-auto': isEAA })}>
                  <Heading
                    variation={Types.headingType.title}
                    titleText={authorModel?.title}
                    customClass={`t-title-s ${errorsList?.length ? 'my-0' : 'mb-5'}`}
                  />
                  {!!errorsList?.length && (
                    <div>
                      <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
                        {errors?.prohibitedCountryErrMsg ? (
                          <div>
                            <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
                              <RichText text={authorModel?.prohibitedCountryErrMsg} componentId="error-span" />
                            </Messages>
                          </div>
                        ) : displayTypeOneErrorInside ? (
                          <div dangerouslySetInnerHTML={{ __html: errorsList[0] }}></div>
                        ) : (
                          <>
                            <span>{authorModel?.errorMessageForgotPwrd}</span>
                            {errorsList.map(error => (
                              <ul>
                                <li>{error}</li>
                              </ul>
                            ))}
                          </>
                        )}
                      </Messages>
                    </div>
                  )}
                  {renderFields()}
                </div>
              </div>
            </div>
          )}
        </StyledActivateOrForgotForm>
      )}
    </div>
  );
};

export const ActivateOrForgotFormConfig = {
  emptyLabel: 'ActivateOrForgotForm',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/activateorforgotform`,
};
export const ActivateOrForgotFormEditable = (props: any) => {
  return props.cqPath?.includes('datapagecomponent') ? (
    <ActivateOrForgotForm {...props} />
  ) : (
    <EditableComponent config={ActivateOrForgotFormConfig} {...props}>
      <ActivateOrForgotForm {...props} />
    </EditableComponent>
  );
};
