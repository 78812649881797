/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Eyebrow, Heading, Messages, RichText, Text, Types } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  PageContext,
  ARIES_SEND_OTP_CHALLENGE_URL,
  SWEEPSTAKES_ENTER_NOW_URL,
  SWEEPSTAKES_FINAL_URL,
  SweepstakesErrorTypes,
  getSubDirectoryPrefixedUrl,
  generateApolloClientHeaders,
  COMMA_DELIMITER,
  BANNER_MSGS_KEYS,
} from '../../modules';
import { phoenixAccountGetMemberAddressAndCommunicationOptions } from '../../modules/graph/index';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import memberDataMock from './__mock__/SweepstakesEnrollment.model.json';
import {
  MemberAddressAndCommunicationOptions,
  SweepsErrors,
  SweepstakesConsentPayload,
  SweepstakesEnrollmentProps,
  SweepstakesSubmitPayload,
} from './SweepstakesEnrollment.types';
import { StyledSweepstakesEnrollment } from './SweepstakesEnrollment.styles';

const SweepstakesConcertDetails = dynamic(() =>
  import('./SweepstakesConcertDetails/SweepstakesConcertDetails').then(mod => mod.SweepstakesConcertDetails)
);
const SweepstakesConsentForm = dynamic(() =>
  import('./SweepstakesConsentForm/SweepstakesConsentForm').then(mod => mod.SweepstakesConsentForm)
);
const SweepstakesEntryDetails = dynamic(() =>
  import('./SweepstakesEntryDetails/SweepstakesEntryDetails').then(mod => mod.SweepstakesEntryDetails)
);

// Use named rather than default exports.
export const SweepstakesEnrollment: FC<SweepstakesEnrollmentProps> = ({ isAuthorMode, model }) => {
  const { IS_LOCAL_DEV } = process.env;
  const [memberInfo, setMemberInfo] = useState<MemberAddressAndCommunicationOptions>();
  const [formSubmitTriggered, setFormSubmitTriggered] = useState(false);
  const [pageError, setPageError] = useState<{ error: SweepsErrors; id: string }>();
  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const router = useRouter();
  const eventName: string = router.query['index']?.toString() ?? '';
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const sweepstakesPreprocessorResponse = pageContext?.sweepstakesPreprocessorResponse;
  const serverENV = pageContext?.serverENV ? JSON.parse(pageContext?.serverENV) : {};
  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const isNewUser = sessionData?.AriesRewards?.sweepStakeEnrollment === 'Success';
  const skipUxl =
    sweepstakesPreprocessorResponse?.sweepstakeError &&
    sweepstakesPreprocessorResponse?.sweepstakeErrorType?.length > 0;
  const { bannerMsgs } = useBannerMessagesStore(state => state);
  const [pwrdChanged, setPwrdChanged] = useState(false);

  useEffect(() => {
    if (isAuthorMode) {
      setMemberInfo(memberDataMock.data);
    }
    if (bannerMsgs.key === BANNER_MSGS_KEYS.PASSWORD_UPDATED_MSG) {
      setPwrdChanged(true);
      sessionStorage.removeItem('bannerMessagesState');
    }
  }, []);

  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    skipUxl ||
    isAuthorMode;

  const {
    data: memberData,
    loading: memberDataLoading,
    error: memberDataError,
  } = useQuery(phoenixAccountGetMemberAddressAndCommunicationOptions, {
    variables: {
      customerId: sessionData?.consumerID,
      primaryOnly: true,
    },
    skip: skipQuery,
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
  });

  useEffect(() => {
    if (memberData) {
      setMemberInfo(memberData);
    }
  }, [memberData]);

  const isCountryEligible = useMemo(() => {
    const countryCode = memberInfo?.customer?.contactInformation?.addresses?.[0]?.address?.country?.code ?? '';
    const isEligible = model?.eligibleCountries
      ? model?.eligibleCountries.map(country => country.id).includes(countryCode)
      : false;
    if (isEligible) {
      const provinceCode = memberInfo?.customer?.contactInformation?.addresses?.[0]?.address?.stateProvince || '';
      return !(serverENV?.SWEEPSTAKES_RESTRICTED_PROVINCES?.split(COMMA_DELIMITER) ?? []).includes(
        `${countryCode.toUpperCase()}_${provinceCode.toUpperCase()}`
      );
    }
    return isEligible;
  }, [model?.eligibleCountries, memberInfo?.customer]);

  const handleSweepstakesSubmit = async (consentPayload: SweepstakesConsentPayload) => {
    setPageError(undefined);
    const payload: SweepstakesSubmitPayload = {
      ...consentPayload,
      email: {
        body: model?.emailBody,
        subject: model?.emailSubject,
      },
      eventName,
      // TODO - to be uncommented when BE accepts below key, this is required to be passed so that final enrollment API will redirect to below url post enrollment
      //sweepstakeReturnUrl: window.location.pathname,
    };
    setFormSubmitTriggered(true);
    try {
      const response = await axios.post(
        SWEEPSTAKES_ENTER_NOW_URL,
        { ...payload },
        {
          headers: {
            withCredentials: true,
            'Content-Type': 'application/json',
            Cookie: 'sessionID=' + sessionData?.sessionToken,
          },
        }
      );
      if (response.status === 200) {
        const sendOtpUrl = `${getSubDirectoryPrefixedUrl(
          serverENV?.SWEEPSTAKES_OTP_PAGE_URL ?? ARIES_SEND_OTP_CHALLENGE_URL,
          currentLocale
        )}${serverENV?.SWEEPSTAKES_OTP_PAGE_URL.includes('?') ? '&' : '?'}returnTo=${encodeURIComponent(
          getSubDirectoryPrefixedUrl(serverENV?.SWEEPSTAKES_FINAL_API_URL ?? SWEEPSTAKES_FINAL_URL, currentLocale)
        )}${model?.clientId && `&clientId=${model?.clientId}`}`;
        window.location.replace(sendOtpUrl);
      }
    } catch (error: any) {
      if (error) {
        const errorMessages = error?.response?.data?.phoenixErrorMessages?.errorMessages;
        if (Array.isArray(errorMessages) && errorMessages.length > 0) {
          if (
            [SweepstakesErrorTypes.DUPLICATE_ERROR, SweepstakesErrorTypes.INELIGIBLE_ERROR].includes(errorMessages[0])
          ) {
            setPageError({ error: errorMessages[0], id: errorMessages[0] });
          } else {
            setPageError({ error: SweepstakesErrorTypes.GENERIC_ERROR, id: 'generic-error' });
          }
        } else {
          setPageError({ error: SweepstakesErrorTypes.GENERIC_ERROR, id: 'generic-error' });
        }
      }
      setFormSubmitTriggered(false);
    }
  };

  const handleSweepstakesError = (error: string) => {
    switch (error) {
      case SweepstakesErrorTypes.DUPLICATE_ERROR:
        return buildErrorMessage('warning', SweepstakesErrorTypes.DUPLICATE_ERROR, 'duplicate-error');
      case SweepstakesErrorTypes.INELIGIBLE_ERROR:
        return buildErrorMessage('warning', SweepstakesErrorTypes.INELIGIBLE_ERROR, 'ineligible-error');
      case SweepstakesErrorTypes.RECENTLY_ENROLLED:
        return recentlyEnrolledMessage();
      default:
        return buildErrorMessage('warning', SweepstakesErrorTypes.GENERIC_ERROR, 'generic-error');
    }
  };

  const buildErrorMessage = (messageType: string, errorType: SweepsErrors, id: string) => (
    <div className="container" data-testid={id}>
      <Messages messageType={messageType} className="mt-4">
        {model?.[errorType] && (
          <RichText text={model[errorType]} customClass="t-font-m color-scheme1" componentId={`${id}-msg`} />
        )}
      </Messages>
    </div>
  );

  const recentlyEnrolledMessage = () => (
    <div data-testid="recently-enrolled" className="container my-5">
      <div className="d-flex align-items-center justify-content-center flex-column py-lg-5">
        <div className="confirmation-container">
          <div>
            <Eyebrow text={model?.confirmationMessageEyebrow} />
          </div>
          <Heading
            titleText={model?.confirmationMessageTitle}
            fontSize={Types.size.small}
            variation={Types.headingType.title}
            customClass="my-1"
          />
          <RichText
            text={model?.confirmationMessageDescription ?? ''}
            componentId="confirmation-description"
            customClass="t-font-m my-4"
          />
        </div>
      </div>
    </div>
  );

  return (
    <StyledSweepstakesEnrollment
      data-testid="sweepstakesenrollment"
      data-component-name="o-account-sweepstakesenrollment"
    >
      {(isNewUser || pwrdChanged || sessionData?.AriesAuth?.sweepStakeFCPLogin === 'Success') && (
        <div className="container" data-testid="success-msg">
          {isNewUser && (
            <Messages messageType="info" className="mt-4">
              <Text
                copyText={model?.successfulEnrollmentMessage?.replace('{0}', sessionData?.rewardsId || '') || ''}
                fontSize={Types.size.medium}
                element={Types.tags.span}
              />
            </Messages>
          )}
          {(pwrdChanged || sessionData?.AriesAuth?.sweepStakeFCPLogin === 'Success') && (
            <Messages messageType="success" className="mt-4">
              <Text copyText={model?.pwrdChangeMessage} fontSize={Types.size.medium} element={Types.tags.span} />
            </Messages>
          )}
        </div>
      )}
      {sweepstakesPreprocessorResponse?.sweepstakeError &&
      sweepstakesPreprocessorResponse?.sweepstakeErrorType?.length > 0 ? (
        handleSweepstakesError(sweepstakesPreprocessorResponse?.sweepstakeErrorType[0])
      ) : memberDataError ? (
        <div className="container" data-testid="uxl-error">
          <Messages messageType="warning" className="mt-4">
            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" customClass="color-scheme1" />
          </Messages>
        </div>
      ) : memberInfo ? (
        !isCountryEligible ? (
          buildErrorMessage('warning', SweepstakesErrorTypes.INELIGIBLE_ERROR, 'ineligible-error')
        ) : pageError ? (
          buildErrorMessage('warning', pageError.error, pageError.id)
        ) : (
          <>
            <SweepstakesEntryDetails
              offers={model?.offers}
              sweepstakesSubtitle={model?.sweepstakesSubtitle}
              sweepstakesTitle={model?.sweepstakesTitle}
              disclaimerTitle={model?.disclaimerTitle}
            />
            <SweepstakesConsentForm
              model={model}
              communicationOptions={memberInfo?.customer?.communicationOptions}
              handleSubmit={handleSweepstakesSubmit}
              consentOptionsLoading={memberDataLoading}
              disableSubmit={formSubmitTriggered || memberDataLoading}
            />
            <SweepstakesConcertDetails
              concertDatesTitle={model?.concertDatesTitle}
              concertDatesDetails={model?.concertDatesDetails}
            />
          </>
        )
      ) : (
        ''
      )}
    </StyledSweepstakesEnrollment>
  );
};
export const SweepstakesEnrollmentConfig = {
  emptyLabel: 'sweepstakesenrollment',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/sweepstakesenrollment`,
};

export const SweepstakesEnrollmentEditable = (props: any) => (
  <EditableComponent config={SweepstakesEnrollment} {...props}>
    <SweepstakesEnrollment {...props} />
  </EditableComponent>
);
