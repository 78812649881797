// Imports for external libraries go here.
import clsx from 'clsx';
import { LoadScript } from '@react-google-maps/api';
import { MapComponent, MapMarker } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { MapStyle } from './MapView.styles';

const mapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControl: true,
  gestureHandling: 'greedy',
  mapId: 'ec5bb275520a7fc8',
  streetViewControl: false,
};

// Use named rather than default exports.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MapView = (props: any) => {
  const apiKey = process.env['GOOGLE_MAP_API_KEY'] ?? '';
  const mapCenter = { lat: props?.latitude, lng: props?.longitude };
  const markerPosition = { lat: props?.latitude, lng: props?.longitude }; // Marker position based on latitude and longitude
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale } = clientEnvVars;

  const labelVal = {
    text: '.......',
    className: `labels m-map-pin icon-l icon-inverse pb-2 pt-2`,
  };
  const labelVal2 = {
    text: '.',
    className: `labels icon-l icon-status-cobalt icon-inverse pl-2 pr-1`,
  };

  // performs queryselector for role changing of zoom in icon
  const loadHandler = () => {
    const mainDiv = document.getElementsByTagName('main');
    mainDiv[0]?.setAttribute('role', 'navigation');
  };

  return (
    <MapStyle data-component-name="m-account-MapView" data-testid="account-MapView">
      <div data-testid="google-map" className={clsx(props?.isStoryEnabled ? 'map-dimension' : 'g-map-container')}>
        <LoadScript googleMapsApiKey={apiKey} language={currentLocale}>
          <MapComponent
            googleMapsApiKey={apiKey}
            mapId="myUpcomingTripsMapView"
            mapHeight={'100%'}
            onLoad={loadHandler}
            center={mapCenter}
            zoom={10}
            mapContainerStyle={{
              height: '100%',
              width: '100% ',
              borderRadius: '1rem',
            }}
            options={mapOptions}
            children={
              <>
                <MapMarker position={markerPosition} label={labelVal} icon={'.'} />
                <MapMarker position={markerPosition} label={labelVal2} icon={'.'} />
              </>
            }
          />
        </LoadScript>
      </div>
    </MapStyle>
  );
};
