// Styles for PhoneNumberField go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledPhoneNumberField = styled.div`
  .dropdown-field {
    width: 30%;
    position: relative;
  }
  .blurred {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 125%;
    opacity: 0.5;
    background-color: ${baseVariables.color['base20']};
    top: ${toRem(-10)};
  }
  .inputtext-field {
    flex: 1;
    input {
      caret-color: ${baseVariables.color['neutral40']};
    }
  }
  .country-label {
    z-index: 1;
  }
  .country-code-dropdown {
    height: ${toRem(45)};
    width: 100%;
    border-radius: ${toRem(4)} !important;
    border-color: ${baseVariables.color['base10']} !important;
  }
  .country-code-dropdown.is-disabled {
    border-color: ${baseVariables.color['neutral40']} !important;
  }
  .country-code-dropdown .icon-dropdown-down,
  .country-code-dropdown .icon-dropdown-up {
    position: absolute;
    right: ${toRem(8)};
  }
  .numberfieldwidth {
    max-width: 90%;
  }
`;
