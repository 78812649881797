import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { SPA_LOV_TYPE, GOLF_LOV_TYPE, SPA_AND_GOLF } from '../../modules/utils/constants';
import { StyledInterestsAndHobbies } from './InterestsAndHobbies.styles';
import { Section, SectionWithTitle, InterestsAndHobbiesProps } from './InterestsAndHobbies.types';
import { InterestsAndHobbiesModal } from './InterestsAndHobbiesModal/InterestsAndHobbiesModal';

export const InterestsAndHobbies = (props: InterestsAndHobbiesProps) => {
  const filteredArray: SectionWithTitle[] = [];
  const spaAndGolfItems: Section[] = [];

  props.model?.details.forEach(detail => {
    if (detail?.sectionId === SPA_LOV_TYPE || detail?.sectionId === GOLF_LOV_TYPE) {
      spaAndGolfItems.push({
        name: detail.sectionTitle,
        sectionId: detail.sectionId,
        data: detail.items?.map(item => ({ id: item.id, value: item.value })),
      });
    } else {
      filteredArray.push({
        title: detail.title || detail.sectionTitle,
        items: [
          {
            name: detail.sectionTitle.toLowerCase(),
            sectionId: detail.sectionId,
            data: detail.items?.map(item => ({ id: item.id, value: item.value })),
          },
        ],
      });
    }
  });

  if (spaAndGolfItems.length > 0) {
    filteredArray.unshift({
      title: props?.model?.details[0].title,
      items: spaAndGolfItems,
    });
  }

  return (
    <StyledInterestsAndHobbies data-testid="interestsandhobbies" data-component-name="o-account-interestsandhobbies">
      <InterestsAndHobbiesModal
        modalId={props?.model?.modelId}
        filteredArray={filteredArray}
        preferenceModel={props?.model}
      />
    </StyledInterestsAndHobbies>
  );
};

export const InterestsAndHobbiesConfig = {
  emptyLabel: 'InterestsAndHobbies',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/interestsandhobbies`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InterestsAndHobbiesEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <InterestsAndHobbies {...props} />
  ) : (
    <EditableComponent config={InterestsAndHobbiesConfig} {...props}>
      <InterestsAndHobbies {...props} />
    </EditableComponent>
  );
};
