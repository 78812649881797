// Styles for UpgradeRequestCard go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledUpgradeRequestCard = styled.div`
  background-color: ${baseVariables.color.base20};
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 ${baseVariables.shadows.boxShadowTile};
  border-radius: ${toRem(14)};
  overflow: hidden;
  height: auto !important;

  .card__details-container {
    width: 100%;
    .link-header {
      border-bottom: ${toRem(1)} solid ${baseVariables.color.merch30};
      cursor: pointer;
    }
  }

  .upgrade-req-card__img-container {
    width: 100%;
    height: ${toRem(221)};
    position: relative;
    &--img {
      height: ${toRem(221)};
      width: 100%;
    }
  }

  @media ${baseVariables.mediaQuery.md} {
    height: ${toRem(288)};
    .upgrade-req-card__img-container,
    .upgrade-req-card__img-container--img {
      width: ${toRem(320)};
      height: ${toRem(288)};
    }
    .upgrade-req-card__img-container--scrim-component {
      width: ${toRem(344)};
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    height: ${toRem(338)};
    .upgrade-req-card__img-container,
    .upgrade-req-card__img-container--img {
      width: ${toRem(504)};
      height: ${toRem(338)};
    }
    .upgrade-req-card__img-container--scrim-component {
      width: ${toRem(364)};
    }
  }
  height: ${toRem(416)};
  @media ${baseVariables.mediaQuery.xl} {
    height: ${toRem(298)};
    .upgrade-req-card__img-container,
    .upgrade-req-card__img-container--img {
      width: ${toRem(504)};
      height: ${toRem(298)};
    }
  }

  .upgrade-req-card__img-container--scrim-component {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 45%, rgba(0, 0, 0, 0.52) 100%);
    position: absolute;
    bottom: 0px;
    width: inherit;
    cursor: default;
    height: ${toRem(120)};
  }

  .separator {
    border-top: ${toRem(1)} solid ${baseVariables.color['neutral20']};
    @media ${baseVariables.mediaQuery.md} {
      border: none;
    }
  }

  .glide {
    @media ${baseVariables.mediaQuery.mobile}, @media ${baseVariables.mediaQuery.desktop} {
      max-width: 100%;
    }

    @media ${baseVariables.mediaQuery.largeDesktop} {
      max-width: ${toRem(504)};
    }
  }

  .glide-bullet-container {
    position: absolute;
    right: ${toRem(16)};
    bottom: ${toRem(8)};
    height: ${toRem(32)};
    min-width: ${toRem(94)};
    border-radius: ${toRem(22)};
    background-color: rgba(0, 0, 0, 0.33);
    justify-content: space-evenly;

    &.center {
      left: 0;
      right: 0;
      width: fit-content;
      margin: auto;
    }
  }

  .glide__bullet {
    border-radius: 50%;
    background-color: ${baseVariables.color['base20']};
    height: ${toRem(6)};
    width: ${toRem(6)};
    opacity: 0.34;
    outline: none;
    pointer-events: none;
    cursor: default;

    &--active {
      opacity: 1;
      height: ${toRem(8)};
      width: ${toRem(8)};
    }
  }

  .glide__arrow {
    position: absolute;
    top: 45%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.54);
    width: ${toRem(40)};
    height: ${toRem(40)};
    border-radius: 50%;
    border-color: transparent;
    font-size: ${toRem(24)};
    color: ${baseVariables.color['base20']};
    box-shadow: 0 ${toRem(4)} ${toRem(8)} 0 rgba(0, 0, 0, 0.1);
    &:active,
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .glide__arrows > .right-arrow {
    right: ${toRem(16)};
  }

  .glide__arrows > .left-arrow {
    left: ${toRem(16)};
  }

  .glide__arrows > .right-arrow,
  .glide__arrows > .left-arrow {
    .icon-arrow-left:before,
    .icon-arrow-right:before {
      position: absolute;
      top: 20%;
      left: 20%;
      right: 20%;
      bottom: 20%;
    }
  }

  .glide__slides {
    .glide__slide {
      width: 100%;
      list-style: none;
    }
  }

  .glide__track {
    position: relative;
    overflow: hidden;
  }

  .glide__arrow--disabled {
    opacity: unset;
  }
`;
