// Styles for AddCard go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledAddCard = styled.div`
  ${rtl`
  .modal__container {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(584)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(902)} !important;
    }
    &-content {
      :is(${constants.RTL_LANG_LIST}) {
        text-align: right;
      }
      .m-input-field:is(${constants.RTL_LANG_LIST}) {
        label{
          left: unset;
          right:${toRem(16)};
        }
        input{
          padding-right: ${toRem(16)};
        }
      }
    }
    &-footer {
      background-color: ${baseVariables.color.base20};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};

      .footer-button {
        &__cancel {
          background-color: inherit;
        }
        &__delete,
        &__save {
          width: 100%;
          @media ${baseVariables.mediaQuery.md} {
            width: unset;
          }
        }
      }
    }
    &__footer-container {
      gap: ${toRem(16)};
    }

    .add-edit-btn-wrapper {
      gap: ${toRem(16)};
    }
    &_radio:is(${constants.RTL_LANG_LIST}) {
      padding-right: 0 !important;
      margin-right: 0 !important;
      label {
        ::before {
          left: unset;
        }
        ::after {
          left: ${toRem(45)};
          right: ${toRem(6)};
        }
        span {
          margin-right: ${toRem(28)};
        }
      }
    }
  }
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
  }
  .card-number__disable {
    input {
      pointer-events: none;
      background-color: ${baseVariables.color['neutral20']};
      color: ${baseVariables.color['neutral40']} !important;
    }
  }
  .cardExpiry {
    .dropdown__label {
      color: ${baseVariables.color.neutral40};
    }
    .dropdown-labeled {
      width: inherit !important;
      height: ${toRem(52)};

      .icon-dropdown-down:before {
        bottom: ${toRem(2)} !important;
        position: relative;
      }
    }
    .dropdown__label--text {
      width: auto !important;
      top: ${toRem(12)} !important;
    }
  }
  .cardExpiryYear--dropdown .icon-dropdown-down,
  .cardExpiryYear--dropdown .icon-dropdown-up {
    left: ${toRem(8)} !important;
    right: unset !important;
  }
  .cardExpiryYear--dropdown {
    width: 100%;
    height: ${toRem(44.67)};
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    padding-top: ${toRem(12)} !important;
    padding-bottom: ${toRem(12)} !important;

    .dropdown-value {
      color: ${baseVariables.color['neutral40']} !important;
      font-size: ${toRem(16)};
      display: flex;
      justify-content: space-between;
    }
  }
  .cardExpiryYear-label {
    z-index: 1;
  }
  .expiry-date {
    gap: ${toRem(16)};
  }
  .card-form {
    border-top: ${toRem(1)} solid ${baseVariables.color['neutral30']};
  }
  .radio_btn {
    padding-top: ${toRem(2)};
    padding-left: ${toRem(28)};
  }
  .card-type {
    display: flex;
    flex-direction: column;
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: ${toRem(200)};
    }
  }
  .error-msg {
    width: 100%;
  }
  .m-input-field.is-disabled input {
    color: ${baseVariables.color['neutral40']} !important;
  }
  .default-green-label {
    color: ${baseVariables.color['alert30']} !important;
  }

  .checkbox_defaulCard:is(${constants.RTL_LANG_LIST}) {
    padding-right: unset;

    label {
      padding-right: ${toRem(24)};
      ::before {
        left: unset;
        right: 0px;
      }

      ::after {
        left: unset;
        right: ${toRem(6)};
        transform: rotateZ(45deg) !important;
      }
    }
  }
`}
`;
