// Styles for TargetBanner go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledTargetBanner = styled.div`
  ${rtl`

      & .cobrand-banner {
        border-radius: ${toRem(14)};
      }
      .hide-for-target {
        display: none;
      }
      //overriding common component style
      .flexible-card {

        &:is(${constants.RTL_LANG_LIST}) {
          text-align: right !important;
        }
        @media ${baseVariables.mediaQuery.md} {
          padding-right: ${toRem(32)} !important;
        }
        h2 {
          padding-bottom: ${toRem(8)} !important;
        }
      }
      .flexible-click {
        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(154)};
        }
      }
      .target-banner-image {
        img {
          @media ${baseVariables.mediaQuery.md} {
            max-height: ${toRem(80)} !important;
          }
        }
      }
  `}
`;
