// Imports for external libraries go here.
import { FC, useContext, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
import { RichText, Types, Text, Button, Modal } from '@marriott/mi-ui-library';
import { nuaUpgradeRequest } from '../../modules/utils/nuaHelper';
import {
  apiLogger,
  PageContext,
  NUA_UPGRADE_REQUEST,
  addSubDirectoryPrefix,
  handleRedirect,
  CHANGE_CONFIRMATION_ENDPOINT,
  modalScrollHandler,
  BANNER_MSGS_KEYS,
  CANCEL_RQUEST_CLICK_TRACK,
  scrollToClass,
} from '../../modules';

// The closer the import is to the file the lower it should be in this list.
import { CancelUpgradeRequestProps } from './CancelUpgradeRequest.types';
import { StyledCancelUpgradeRequest } from './CancelUpgradeRequest.styles';
import { inspect } from 'util';
import { useRouter } from 'next/router';
import { useNuaStore } from '../../modules/store/nuaStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';

const VistanaVacationPolicy = dynamic(() =>
  import('../../molecules/VistanaVacationPolicy').then(mod => mod.VistanaVacationPolicy)
);
// Use named rather than default exports.
export const CancelUpgradeRequest: FC<CancelUpgradeRequestProps> = ({ model }) => {
  const [isCancelModal, setIsCancelModal] = useState(false);
  const pageContext = useContext(PageContext);
  const router = useRouter();
  const tripID = router && router.query['tripId'];
  const urlParam = router && router.query['nuaUpgrade'];
  const nuaUpgrade = urlParam === 'true' ? true : false;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const tcRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>('');
  const { tripInfo } = useNuaStore(state => state);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);

  const modalOpenHandler = (event: boolean) => {
    // vistana modal handler
    setIsOpen(event);
    if (event === true) {
      modalScrollHandler(true);
    } else {
      modalScrollHandler(false);
    }
  };
  const handleLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const linkHref = (event.currentTarget as HTMLAnchorElement)?.getAttribute('href');
    if (linkHref) {
      setUrl(linkHref);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const cancelUpgradeContainer = tcRef.current;
    if (cancelUpgradeContainer) {
      const anchorTag = cancelUpgradeContainer.querySelector('a[target="_parent"]') as HTMLAnchorElement;
      anchorTag && anchorTag.addEventListener('click', handleLinkClick);
    }
  }, []);

  const handleCancelRequest = async () => {
    setIsLoading(true);
    const reqBody = {
      itemId: tripInfo?.basicInformation?.Id?.split('.')?.[0] ?? '',
      tripId: tripID,
      numberOfRooms: tripInfo?.awardRoomTypes?.edges[0]?.node?.roomTypes?.length,
      checkInDate: tripInfo?.basicInformation?.startDate,
      checkOutDate: tripInfo?.basicInformation?.endDate,
      currency: '',
      locale: '',
      totalPriceForStay: '',
      isTripModifiable: tripInfo?.basicInformation?.isModifiable,
      propertyId: tripInfo?.awardRoomTypes?.edges[0]?.node?.roomTypes[0]?.roomInformation?.id
        ?.split('.')[0]
        .toLowerCase(),
      brandCode: tripInfo?.property?.basicInformation?.marshaBrandCode,
      roomTypes: [],
      cancelFlow: true,
      returnUrl: CHANGE_CONFIRMATION_ENDPOINT,
      errorUrl: '',
    };
    try {
      const response = await nuaUpgradeRequest(addSubDirectoryPrefix(NUA_UPGRADE_REQUEST), reqBody);
      if (response?.status === 200) {
        const { nextStateURI } = response.data;
        handleRedirect(nextStateURI);
      }
    } catch (error) {
      scrollToClass();
      setIsLoading(false);
      apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`);
      setBannerMsgs(BANNER_MSGS_KEYS.NIGHT_UPGRADE_ERROR);
    }
  };

  return (
    <StyledCancelUpgradeRequest data-testid="cancelupgraderequest" data-component-name="o-account-cancelupgraderequest">
      {!nuaUpgrade && (
        <>
          {isOpen && <VistanaVacationPolicy handleClose={() => modalOpenHandler(false)} modalURL={url} />}

          {/* modal details */}
          <Modal
            show={isCancelModal}
            handleBlur={isCancelModal}
            popupOpenState={isCancelModal}
            setPopupOpenState={(): void => {
              setIsCancelModal(false);
            }}
            modalId={'header'}
            labelledBy={'header'}
            onClose={(): void => {
              setIsCancelModal(false);
            }}
            secondaryClassName="modal__container"
            disableScrollOnBody={true}
            aria-modal="true"
          >
            <Modal.Header
              className="t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
              customHeadingClass="t-subtitle-xl"
              labelText={model?.modalHeader}
              popupHeaderOnCLoseFunc={(): void => {
                setIsCancelModal(false);
              }}
              data-testid="cancel-upgrade-request"
            />
            <Modal.Body className="modal__container-content px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 color-scheme1">
              <div className="mr-md-5 pr-md-5">
                <Text
                  copyText={model?.modalSubHeader}
                  fontSize={Types.size.large}
                  customClass="t-font-m mb-1 mr-md-5 pr-md-5"
                  element={Types.tags.paragraph}
                  data-testid="cancel-upgrade-request-heading"
                />
              </div>
            </Modal.Body>
            <Modal.Footer
              className="modal__container-footer m-auto pt-5 pb-md-5 justify-content-end d-flex flex-column-reverse flex-md-row text-center"
              clearButtonLabel={model?.keepUpgradeCTA}
              clearClickHandler={(): void => {
                setIsCancelModal(false);
              }}
              clearBtnClassname="m-link-tertiary-button p-0 mt-4 mt-md-0 text-center"
              applyLabel={model?.cancelUpgradeCTA}
              data-testid="Save"
              applyBtnClickHandler={handleCancelRequest}
            ></Modal.Footer>
          </Modal>
          <div className="container">
            <div className="border-rounded px-4 py-5 wrapper color-scheme7">
              <div
                className="
              d-flex justify-content-sm-between justify-content-start flex-sm-row flex-column align-items-center justify-content-center
            "
              >
                <div className="text-box" ref={tcRef}>
                  <Text
                    copyText={model?.header}
                    fontSize={Types.size.large}
                    customClass="t-subtitle-l mb-3 mb-sm-1 text-sm-left text-center"
                    element={Types.tags.paragraph}
                    data-testid="cancel-upgrade-request-header"
                  />
                  <RichText
                    text={model?.subHeader}
                    componentId="modal-subheading"
                    customClass="subheader text-sm-left text-center"
                    data-testid="cancel-upgrade-request-subheader"
                  />
                </div>

                <div className="text-sm-left text-center">
                  <Button
                    className="m-button-primary mt-5 mt-sm-0 p-2 px-3 cancel-request"
                    callback={() => {
                      setIsCancelModal(true);
                    }}
                    testId="submit_btn"
                    custom_click_track_value={CANCEL_RQUEST_CLICK_TRACK}
                  >
                    {isLoading ? (
                      <div className="m-spinner-s" data-testid="loading-spinner"></div>
                    ) : (
                      model?.cancelRequestCTA
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </StyledCancelUpgradeRequest>
  );
};

const CancelUpgradeRequestConfig = {
  emptyLabel: 'cancelupgraderequest',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelupgraderequest`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CancelUpgradeRequestEditable = (props: any) => {
  return (
    <EditableComponent config={CancelUpgradeRequestConfig} {...props}>
      <CancelUpgradeRequest {...props} />
    </EditableComponent>
  );
};
