/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { PartnerDetails } from '../../organisms/PointsTransfer/TransferPartnerPoints/TransferPartnerPoints.types';

export interface TransferPointsDetails {
  partnerId: string;
  partnerName: string;
  partnerNumber: string;
  pointsToTransfer: string;
  typeOfPointsTransfer: string;
  partnerConfig?: PartnerDetails | undefined;
}

interface PointsTransferState {
  transferPointsDetails: TransferPointsDetails;
  setTransferPointsDetails: (data: TransferPointsDetails) => void;
  isVerificationPointsSuccess: boolean;
  setVerificationPointsSuccess: (data: boolean) => void;
  isVerificationPointsFailed: boolean;
  setVerificationPointsFailed: (data: boolean) => void;
  isResetSate: boolean;
  setIsResetSate: (data: boolean) => void;
  convertPointsUrl: string;
  setErrorKey: (data: string) => void;
  errorKey: string;
}

const initialState: PointsTransferState = {
  transferPointsDetails: {
    partnerId: '',
    partnerName: '',
    partnerNumber: '',
    pointsToTransfer: '',
    typeOfPointsTransfer: '',
    partnerConfig: undefined,
  },
  setTransferPointsDetails: () => {},
  isVerificationPointsSuccess: false,
  setVerificationPointsSuccess: () => {},
  isVerificationPointsFailed: false,
  setVerificationPointsFailed: () => {},
  isResetSate: false,
  setIsResetSate: () => {},
  convertPointsUrl: '',
  setErrorKey: () => {},
  errorKey: '',
};

const pointsTransferStore: StateCreator<PointsTransferState> = set => {
  return {
    ...initialState,
    setTransferPointsDetails: (data: TransferPointsDetails) => {
      set({ transferPointsDetails: data });
    },
    setVerificationPointsSuccess: (data: boolean) => {
      set({ isVerificationPointsSuccess: data });
    },
    setVerificationPointsFailed: (data: boolean) => {
      set({ isVerificationPointsFailed: data });
    },
    setErrorKey: (key: string) => {
      set({ errorKey: key });
    },
    set: (data: boolean) => {
      set({ isResetSate: data });
    },
  };
};

export const usePointsTransferStore = createAppStore(pointsTransferStore, {
  usePersistentStore: true,
  persistentStoreName: 'pointsTransferState',
});
