import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AllLocalesKeysListConstant, BRANDING_QUERY_KEY, LOCALE_QUERY_KEY } from '../../modules';
import { GlobalEAAHeaderProps } from './GlobalEAAHeader.types';
import { StyledGlobalEAAHeader } from './GlobalEAAHeader.styles';

export const GlobalEAAHeader: FC<GlobalEAAHeaderProps> = (props: GlobalEAAHeaderProps) => {
  const [branding, setBranding] = useState<string | null>('');
  const [locale, setLocale] = useState<string | null>('');

  useEffect(() => {
    const currentURL = window?.location?.href;
    const urlParams = new URLSearchParams(currentURL);
    if (urlParams?.has(BRANDING_QUERY_KEY) || props?.mockLocation) {
      const brandingValue = urlParams?.get(BRANDING_QUERY_KEY);
      setBranding(brandingValue);
      const localeValue = urlParams?.get(LOCALE_QUERY_KEY);
      setLocale(localeValue);
    } else {
      setBranding(props?.mockBranding || '');
      setLocale('');
    }
  }, []);

  return (
    <StyledGlobalEAAHeader data-component-name="o-account-eaaglobalheader">
      {branding ? (
        <div
          data-testid="header-container"
          className={clsx('header-container pt-3 pt-md-5', branding === 'right' ? 'text-right' : '')}
        >
          <div className="mt-5 mt-md-3 px-3 px-md-4 px-lg-0">
            <div
              className={clsx(
                'ml-5',
                locale && locale.replace('-', '_').toLowerCase() === AllLocalesKeysListConstant?.cn?.toLowerCase()
                  ? 't-program-logo-alt-cn-color-m'
                  : 't-program-logo-alt-color-m'
              )}
              aria-label="Marriott Bonvoy"
            ></div>
          </div>
        </div>
      ) : (
        ''
      )}
    </StyledGlobalEAAHeader>
  );
};

export const GlobalEAAHeaderConfig = {
  emptyLabel: 'eaaglobalheader',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/eaaglobalheader`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GlobalEAAHeaderEditable = (props: any) => (
  <EditableComponent config={GlobalEAAHeaderConfig} {...props}>
    <GlobalEAAHeader {...props} />
  </EditableComponent>
);
