import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration, constants } from '../../modules';

export const StyledCancelledTrips = styled.div`
  ${rtl`
    .skeleton-style {
      width: '100%';
      height: ${toRem(30)};
      &:after {
        animation-duration: ${SkeletonLoaderDuration};
      }
    }
    .date-range-text:first-letter{
      text-transform:uppercase;
    }
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;

      .m-link-tertiary-button {
        margin: 0 0 0 ${toRem(27)} !important;
      }

      .m-link-tertiary-button::after {
        transform: rotate(-180deg) !important;
      }

      .icon-arrow-right:before {
        content: '\\e921' !important;
      }

      .icon-arrow-left:before {
        content: '\\e922' !important;
      }
    }
  `}
`;
