// Styles for CreateAccountDescription go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCreateAccountDescription = styled.div`
  .create-account-description {
    &-container {
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

      &__icon {
        font-size: ${toRem(36)};
        color: ${baseVariables.color.merch10};
      }
    }
  }
`;
