// Styles for DropdownMolecule go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { containerBoxShadow } from '../../modules/utils/constants';
import { constants } from '../../modules';

export const StyledDropdown = styled.div`
  ${rtl`
    .dropdown {
      position: relative;

      background-color: ${baseVariables?.color?.base20};
      border-radius: ${toRem(8)};

      .dropdown-option-value {
        // adding custom styles here to override the styles of shared/mi-ui-library molecule
        padding-left: ${toRem(16)};
        // TO DO: this code is commented, due to arrow was overlapping in activity filter.
        /* position: absolute;
        right: ${toRem(16)}; */
        &:is(${constants.RTL_LANG_LIST}) {
          padding-right: ${toRem(16)} !important;
          padding-left: ${toRem(0)} !important;
        }
      }
      &__disable{
        border: ${toRem(1)} solid ${baseVariables.color.neutral30} !important;
        pointer-events: none;
      }
      &__enable{
        border: ${toRem(1)} solid ${baseVariables.color.neutral40} !important;
      }

      &__error{
        border: ${toRem(1)} solid ${baseVariables.color.alert50} !important;
      }

      &__container {
        position: absolute;
        width: 100%;
        z-index: 3;
        top: ${toRem(42)};
        left: 0;
        .m-dropdown-container {
          border-radius: ${toRem(12)};
          box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 ${containerBoxShadow};
          .m-dropdown-content {
            border-radius: 0;
            max-height: max-content;
          }
        }
        &--list {
          list-style: none;
          &:focus {
            outline: none;
          }
          &--option {
            background: none;
            cursor: pointer;
            &.selected {
              font-weight: 600;
            }
            &.focused {
              border: ${toRem(2)} solid ${baseVariables.color.neutral00};
            }
            &:hover {
              background-color: ${baseVariables.color.neutral20};
            }
          }
        }
      }
    }
    .option-current {
      border: ${toRem(2)} solid ${baseVariables.color.neutral00};
    }
  
    .dropdown-labeled {
      cursor: pointer;
      width: ${toRem(168)};
      height:  ${toRem(52)};
      border-radius: ${toRem(8)} !important; 
      padding-top: ${toRem(16)} !important;
      padding-bottom: ${toRem(8)} !important;
      .icon-dropdown-down,
      .icon-dropdown-up {
        position: absolute;
        right: ${toRem(8)};
        bottom: ${toRem(8)};
      }
      :is(${constants.RTL_LANG_LIST}) {
        display: inline-flex;

        .icon-dropdown-down,
        .icon-dropdown-up {
          right: unset;
          left: ${toRem(8)};
        }
        .dropdown__container--list--option {
          text-align: right !important;
        }
      }

      .dropdown-value {
        font-size: ${toRem(14)};
        line-height: ${toRem(21)};
        font-weight: ${baseVariables.font['fontWeightRegular']};
      }

      .dropdown__container {
        top: ${toRem(52)};
        color: ${baseVariables.color['neutral40']} !important;
        z-index: 99;
      }
    }
    .dropdown{
      &__label {
        font-weight: ${baseVariables.font['fontWeightRegular']};
        position: absolute;
        z-index: 1;
        top: ${toRem(7)};
        left:  ${toRem(16)};
        
        &--text{
          position: relative;
          display: flex;
          justify-content: space-between;
          width: inherit;
          top:${toRem(8)} !important;
        
          .icon-dropdown-down:before{
            top:unset;
            bottom: ${toRem(8)} !important;
          }
        }
      }
    }
    .option-disabled {
      color: ${baseVariables.color['neutral30']} !important;
    }
    .dropdown-showBothOptions{
      width: max-content;
     }

    .dropdown-value {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: ${toRem(16)};
      
      :is(${constants.RTL_LANG_LIST}) {
        margin-left: ${toRem(16)};
      }
    }
  `}
`;
