import { DateYMDString } from '@marriott/mi-apollo-client-utils';
import { baseVariables } from '@marriott/mi-ui-library';

export const constants = {
  RTL_LANG_LIST: `:lang(he-IL), :lang(ar-AE)`,
  JAPAN_COUNTRY_CODE: 'JP',
  KOREA_COUNTRY_CODE: 'KR',
  CANADA_COUNTRY_CODE: 'CA',
  CHINA_COUNTRY_CODE: 'CN',
  USA_COUNTRY_CODE: 'US',
  MEXICO_COUNTRY_CODE: 'MX',
  DEFAULT_LANG: 'en',
  BRITISH_LANG: 'gb',
  DEFAULT_COUNTRY: 'US',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  BORDER10: 'rgba(0,0,0,0.1)',
  ACTIVE_NAV: 'rgba(28, 28, 28, 0.3)',
  TRANSPARENT: 'transparent',
  STANDARD_DATE_FORMAT: 'YYYY-MM-DD',
  TAB_UPCOMING: 'tabUpcoming',
  TAB_CANCELLED: 'tabCancelled',
  TAB_BUSINESS: 'tabBusiness',
  TAB_LEISURE: 'tabLeisure',
  CONTACT_OPT_PH: 'PH',
  CONTACT_OPT_BOTH: 'BOTH',
  MAX_BRAND_TAGS: 3,
  BOLD_FONT_WEIGHT: 800,
  SESSION_TIME_OUT_URL: '/sessionTimeOut',
  UNAUTH_URL: '/unAuth',
  PROMOTION_PREPROCESSOR_URL: '/promotion',
  NO_DECIMAL_LOCALE: ['ko_KR'],
  KOREAN_CURRENCY: 'KRW',
  //Activity Codes
  STAY: 'STAY',
  RWD_EVENT: 'RWD EVENT',
  BONUS: 'BONUS',
  OTHER: 'OTHER',
  SUITE_NIGHT: 'suite-night',
  MILES_CURRENCY_CODE: 'MI',
  POINTS_CURRENCY_CODE: 'HP',
  //Activity Codes END
  MR_ENROLLED: 'mr_enrolled',
  LOYALTY_PAGE_PATH: '/loyalty',
  FIND_RESERVATION_LIST_PAGE_URL: '/loyalty/findReservationList.mi',
  CREATE_ACCOUNT_PAGE_URL: '/loyalty/createAccount/createAccountPage1.mi',
  STATUS_SUCCESS: 'SUCCESS',
  INVALID_PARTNER_NUMBER: 'INVALID_PARTNER_NUMBER',
  DC_ERROR_FLAG: 'profile.registered.to.dc.program',
  STATUS_FAILURE: 'FAILURE',
  HOME_PHONE_CODE: 'HT',
  BUSINESS_PHONE_CODE: 'BT',
  MOBILE_PHOHE_CODE: 'MT',
  USA_COUNTRY_DIAL_CODE: '+1',
  RESERVATION_LINKED: 'reservationLinkedMsg',
  USA_COUNTRY: 'USA',
  DOB_DATE_PLACEHOLDER: 'mm/dd',
  WEDDING_DATE_PLACEHOLDER: 'mm/dd/yyyy',
  ALLOWED_YEAR_THRESHOLD: 100,
  CONVERSION_POINTS_SYMBOL: '=',
  RAKUTEN_PARTNER: 'RK',
  MGM_PARTNER: 'MM',
  PARTNER_CURRENCY: 'POINTS',
  MILES: 'MILES',
  NEXT_PUBLIC_ENV_KEYS: [
    'PROHIBITED_COUNTRIES_CODES',
    'CREDIT_CARD_EXPIRY_YEAR_LIMIT',
    'SWEEPSTAKES_RESTRICTED_PROVINCES',
    'SWEEPSTAKES_OTP_PAGE_URL',
    'SWEEPSTAKES_FINAL_API_URL',
    'COBRAND_LOCALE',
    'HOMEPAGE_REDIRECT_URL',
    'IS_PASSWORD_ENCRYPT',
    'SKIP_DECODE_URL_FOR_PATH',
    'FETCH_MEMBER_DETAILS_FOR_PATHS',
    'PROMOS_WITHOUT_DEFAULT_EARNED_TEXT',
    'NEXT_PUBLIC_GRAPHQL_URL',
    'NEXT_PUBLIC_PREFIX',
    'NEXT_PUBLIC_DEFAULT_LANG',
    'ADD_CARD_MARSHA_CODE',
    'CHECK_IN_ELIGIBLE_DOMAINS',
  ],
  CHECK_IN_CONFIRMATION: '/reservation/check-in-confirmation.mi',
  FIND_RESERVATION_DETAIL: '/reservation/findReservationDetail.mi',
  LOGOUT_URL: 'logout',
  SYSTEM_MAINTENANCE: 'systemMaintenance',
};

//colors
export const containerBoxShadow = 'rgba(0, 0, 0, 0.1)';
export const memberStatusCardBorder = 'rgba(255, 255, 255, 0.5)';

// Query fallback constants
export const DEFAULT_FALLBACK_END_DATE: DateYMDString = '2023-01-20';
export const clickTrackingLoc = 'HQV AEM';
export const SPA_AND_GOLF = 'Spa & Golf';

// TODO: Please clean up and migrate necessary values to constants (above)
export const accountConstants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  DOWNSIZE_PARAM: '?output-quality=70&interpolation=progressive-bilinear&downsize=',
  IMAGE_SIZE: {
    desktop: '1440:480',
    tablet: '768:*',
    mobile: '*:*',
  },
  VIEWPORT_SIZE: {
    desktop: '1200px',
    largerTablet: '1024px',
    mediumTablet: '992px',
    tablet: '768px',
    mobile: '576px',
  },
  CONTENT_BLOCK_IMAGE_WIDTH: {
    lg: 433,
    md: 345,
    sm: 315,
  },
  BREAKPOINT_TABLET: 768,
  BREAKPOINT_DESKTOP: 992,
  ITEM_PATH_ROOT: 'root/responsivegrid',
  EVENT_DRIVEN_DATALAYER: 'eventdriven',
};

export const JOIN_CONFIRMATION_PROMOTION_BASE_URL = '/loyalty/join/joinConfirmationPromotion.mi';

export const JOIN_PROMOTION_BASE_URL = '/loyalty/join/joinPromotion.mi';

export const PROMOTIONS_CONSTANT = {
  REGISTERED_PROMOTIONS_STATE_CODES: ['RMA', 'RSP', 'RE', 'RNP', 'IP'],
  NEW_PROMOTIONS_STATE_CODES: ['U'],
  PROMOTIONS_MAX_ACHIEVED_STATE_CODES: ['RMA', 'PEMA'],
  PROMO_HURDLE_TYPE_STAYS_CODE: 'stays',
  PROMO_HURDLE_TYPE_NIGHTS_CODE: 'nights',
  POINTS_EARNING_PREF: 'HP',
  FREE_NIGHTS_EARNING_PREF: 'FN',
  ELITE_NIGHTS_EARNING_PREF: 'NT',
  PLATINUM_UPGRADE: 'UP',
  PROMOTION_MAX_GOAL_KEY: 'max',
  PROMOTION_DETAIL_KEYS: ['short', 'details', 'additional', 'tc'],
  PROMOTIONS_URL: '/loyalty/promotion.mi?promotion={promotionCode}',
  PROMOTIONS_CENTRAL_URL: '/loyalty/myAccount/promotionCentral.mi',
  PROMOTION_UNAVAILABLE_URL: '/loyalty/promotionUnavailable.mi',

  JOIN_PROMOTION_URL: `${JOIN_PROMOTION_BASE_URL}?promotion={0}`,
  JOIN_CONFIRMATION_PROMOTION_URL: `${JOIN_CONFIRMATION_PROMOTION_BASE_URL}?promotion={0}`,
  //List of offers Id's that are not available to existing members
  UNAVAILABLE_PROMOTIONAL_OFFERS_LIST_CONFIG_KEY: ['MT'],
  //List of offers Id's that are available to existing members
  AVAILABLE_PROMOTIONAL_OFFERS_LIST_CONFIG_KEY: ['MT004'],
  PROMOTION_IMG_OUTPUT_INTERPOLATION: 'progressive-bilinear',
  HTML_TAG_REGEX: /<\/?[^>]+(>|$)/g, // regex to find html tags
  BANNER_TITLE_CHARACTER_LIMIT: 127,
};

export const OVERVIEW_CONSTANT = {
  ANNUAL_CONSTANTS: ['PFS', 'PFR', 'PPC', 'PL7'],
  AMBASSADOR_CODE: ['PFS', 'PFR', 'PPAE'],
  PLATINUM_CODE: ['PAC', 'PAR', 'PLR'],
  TITANIUM_CODE: ['PL7', 'PPR', 'PPC'],
  TITANIUM_REFERRAL_CODE: 'PPR',
  REFERRAL_CODE: ['GAR', 'SAR', 'PAR', 'PFR', 'PPR'],
  MEMBER_CODE: ['MRD', 'MGD'],
  SILVER_LABEL: 'Silver',
  GOLD_LABEL: 'Gold',
  PLATINUM_LABEL: 'Platinum',
  FILLED_COLOR_NON_MEMBER: baseVariables.color['base20'],
  FILLED_COLOR_MEMBER: baseVariables.color['accent10'],
  TEXT_COLOR_MEMBER: baseVariables.color['base10'],
  MEMBER_BONUS_PROMOTION_LABEL: 'Promotion',
  MEMBER_BONUS_VISA_LABEL: 'Visa',
  MEMBER_BONUS_RWD_LABEL: 'Rwd Event',
  HIDE_LIFETIME_SECTION_CODES: ['PL7', 'PFS', 'PFR', 'PLR', 'PPAE'],
  MEMBER_LEVELS: ['Silver', 'Gold', 'Platinum'],
  SILVER_LIFETIME: 'SLR',
  GOLD_LIFETIME: 'GLR',
  PLATINUM_LIFETIME: 'PLR',
  TITANIUM_LIFETIME: 'PL7',
  UNFILLED_COLOR_MEMBER_NO_NIGHT: baseVariables.color['neutral20'],
  NIGHT_PROGRESS_DATA: {
    innerRadius: 0.8,
    outerRadius: 0.78,
    padAngle: 0.01,
    cornerRadius: 2,
    circle: {
      radius: 0.03,
      stroke: 0.03,
    },
  },

  SAVED_HOTEL_CONSTANTS: {
    croppingRatio: 6,
    outputQuality: 90,
    outputInterpolation: 'progressive-bilinear',
    imageDimensions: {
      height: 221,
      width: 332,
    },
  },

  MEMBER_LEVEL_MSG_ICONS: {
    renew: 'icon-renew',
    successfully_renewed: 'icon-promotion-achieved',
    level_up: 'icon-next-level-genera',
    nights_awards_level: 'icon-next-level-{nightAward}-day',
    titanium_only: 'icon-ambassador',
    ambassador_only: 'icon-ambassador',
  },

  LIFEITME_ACHIEVER: 'LifetimeAchiever',
  LIFEITME_RENEWED: 'LifetimeRenewed',
  AMBASSADOR_REFERRAL: 'AmbassadorReferral',
  AMBASSADOR_ACHIEVER: 'AmbassadorAchiever',
  AMBASSADOR_RENEWED: 'AmbassadorRenewed',
  TITANIUM_REFERRAL: 'TitaniumReferral',
  TITANIUM_ACHIEVER: 'TitaniumAchiever',
  TITANIUM_RENEWED: 'TitaniumRenewed',
  REFERRAL_ACCOUNT: 'ReferralAccount',
  ACHIEVER_ACCOUNT: 'AchieverAccount',
  RENEWED_ACCOUNT: 'RenewedAccount',
  BASIC_MEMBERSHIP: 'BasicMembership',
};

// adding default locale
export enum AllLocalesKeysListConstant {
  en = 'en-US',
  cn = 'zh_CN',
  ja = 'ja_JP',
  ko = 'ko_KR',
}

export enum ValueOrder {
  ENTHU = 1,
  CASUAL = 2,
  NOINTR = 3,
}

export const FOOD_LOV_BEVTYPE = 'food-lov';
export const ALERTS_BEVTYPE = 'alerts';
export const BEVERAGE_NON_ALCOHOLIC_LOV_BEVTYPE = 'beverage-non-alcoholic-lov';
export const BEVERAGE_ALCOHOLIC_LOV_BEVTYPE = 'beverage-alcoholic-lov';
export const SPA_LOV_TYPE = 'spa-lov';
export const GOLF_LOV_TYPE = 'golf-lov';
export const INTERESTS_AND_HOBBIES_LOV_TYPE = 'interests-and-hobbies-lov';
export const FITNESS_LOV_TYPE = 'fitness-lov';
export const ACCESSIBILITY = 'AC';
export const ROOM_OPTIONS = 'RO';
export const FEATHER_FREE_ID = 'B5';
export const EXTRA_FEATHER_PILLOWS_ID = 'V9';
export const ROOM_AMENITIES = 'RA';
export const FoodBevIconsType: { [key: string]: string } = {
  [FOOD_LOV_BEVTYPE]: 'icon-dining-chopsticks',
  [ALERTS_BEVTYPE]: 'icon-requests',
  [BEVERAGE_NON_ALCOHOLIC_LOV_BEVTYPE]: 'icon-grab-n-go',
  [BEVERAGE_ALCOHOLIC_LOV_BEVTYPE]: 'icon-mini-bar',
  [SPA_LOV_TYPE]: 'icon-spa',
  [INTERESTS_AND_HOBBIES_LOV_TYPE]: 'icon-activity',
  [FITNESS_LOV_TYPE]: 'icon-fitness-center',
  [ACCESSIBILITY]: 'icon-accessibility',
  [ROOM_OPTIONS]: 'icon-all-suites',
  [ROOM_AMENITIES]: 'icon-room-ready-alerts',
};

export enum CustomerTripOrderStatus {
  active = 'ACTIVE',
  checkIn = 'checkInEligible',
  modifyCheckIn = 'checkInModifiable',
  inProgressCheckout = 'checkoutEligible',
  isPhysicalCheckedIn = 'PhysicalCheckedIn',
}

// brands that need to redirected to the landing page instead of the reviews page
export const HWSLandingPageRedirectionBrands = ['RZ', 'MV', 'DS', 'BG'];

// link to redirect from reviews section if going to HWS Landing Page
export const viewHotelWebsiteLink = '/hotels/travel/';

// part of link to enable redirection to HWS reviews section
export const hotelsSublink = '/hotels/';

// part of link to enable redirection to HWS reviews section
export const reviewsSubLink = '/reviews/';

// to pass as an argument to redirection hook
export const reviewsRedirectionVarValue = 'reviews';

export const borderColor = '#979797';

// copied from util constants

export const favouriteCount = 20;
export const overviewFavoritesDesktopCount = 3;

export const fallbackImages = {
  Classic: 'https://cache.marriott.com/is/image/marriotts7prod/bonvoy-error-image-10518:Classic-Hor',
  altText: 'Fallback',
};

export const AMBASSADOR_PERSONAL_INFO_CATEGORY = {
  SELF_CATEGORY: 'self',
  SPOUSE_CATEGORY: 'spouse',
  CHILDREN_CATEGORY: 'children',
  PET_CATEGORY: 'pets',
};
export const PERSONAL_INFO_OCCUPATION_DEFAULT = 'NONE';
export const PERSONAL_INFO_PETSTYPE_DEFAULT = 'OTHER';

export const DATE_FORMATE = {
  ENGLISH_DATE_FORMAT: 'MMM DD, YYYY',
  ENGLISH_DATE_FORMAT_NUMBER: 'MM DD, YYYY',
  GERMAN_DATE_FORMAT: 'DD.MM.YYYY',
  ENGLISH_FULLMONTH_FORMAT: 'MMMM Do[st,] YYYY',
};
export const bookNowUrl = '/reservation/availability.mi?propertyCode=';
export const ALLOWED_DAY_THRESHOLD = 365;
export const ALLOWED_DAY_THRESHOLD_FIND_RESERVATION = 1;
export const CONFIRMATION_NUMBER_MAXLENGTH = 11;
export const calendarConstants = {
  SHORT_WEEK_DAYS: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  SHORT_WEEK_DAYS_NAME: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  LONG_WEEK_DAYS_NAME: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  SHORT_MONTH_NAME: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
};
export const ValidMarshCodes = ['BCNRZ', 'DALIL', 'LISRZ', 'RTZ'];
export const ValidBrandIds = ['RZ'];
export const NIGHTS_LABEL = 'nights';
export const NIGHT_LABEL = 'night';

export const hotelReservationUrl = '/reservation/availability.mi?isSearch=true&propertyCode=';
export const ritzCarltonUrl = 'http://www.ritzcarlton.com/?marshaCode={marshaCode}&locale={locale}/';
export const hwshoteLReservation = '/{currentLocale}/hotels/{propertyId}-{propertyUrlName}/overview';
export const ritzCarltonBrandId = 'rz';
export const countryUSCode = 'us';
export const pageSizeLimit = 10;
export const pageSetLimit = '10';
export const defaultValuesetDurationFilter = '3';
export const defaultValuesetActivityFilter = 'all';
export const defaultPageLimitActivity = '10';

export const findReservationDetailUrl = '/reservation/findReservationDetail.mi';
export const CONFIRM_CANCELLATION_URL = '/reservation/confirmReservationCancellation.mi';
export const HYPHEN_SYMBOL = '-';
export const UNDERSCORE_SYMBOL = '_';
export const COLON_SYMBOL = ':';
export const SEMI_COLON_SYMBOL = ';';
export const DATA_LAYER_PROPERTIES = {
  NEXT_PUBLIC_ORIENTATION_LANDSCAPE: 'Landscape',
  NEXT_PUBLIC_ORIENTATION_PORTRAIT: 'Portrait',
  NEXT_PUBLIC_PAGE_BREAKPT: 'Page Loaded on ',
};

export const viewOrModifyBookingUrl =
  '/reservation/findReservationDetail.mi?confirmationNumber={confirmationNumber}&tripId={confirmationNumber}&propertyId={propertyId}';
export const checkinPropertyUrl =
  '/reservation/check-in-details.mi?confirmationNumber={confirmationNumber}&tripId={confirmationNumber}&propertyId={propertyId}';
export const cancelReservationUrl =
  '/reservation/confirmReservationCancellation.mi?confirmationNumbers={confirmationNumber}';
export const reviewCancelReservationUrl = '/reservation/reviewCancellation.mi?confirmationNumbers={confirmationNumber}';
export const tripCancellationUrl = '/mi/phoenix-account-navigation/v1/confirmCancelReservation';

export const forgotPasswordSubmitURL = '/mi/phoenix-account-auth/v1/forgotPasswordSubmit';
export const nextDocumentUploadURL = '/mi/phoenix-account-navigation/v1/uploadDocument';
export const userPrefilledDataURL = '/mi/phoenix-account-navigation/v1/userPrefilledData';
export const nextMissingStayFormSubmitURL = '/mi/phoenix-account-navigation/v1/submitMissingStayForm';
export const combineAccountFormSubmitURL = '/mi/phoenix-account-navigation/v1/accountMergeRequest';
export const oneClickJoinRandDecryptURL = '/mi/phoenix-account-navigation/v1/decryptEnrollmentData';
export const nextMissingStayFormConfirmationURL = '/loyalty/myAccount/missingStayRequestconfirmation.mi';
export const activateAccountUrl = '/mi/phoenix-account-navigation/v1/createOnlineAccount';
export const submitCreateOnlineAccount = '/mi/phoenix-account-navigation/v1/submitCreateOnlineAccount';
export const profileRememberMeURL = '/mi/phoenix-account-auth/v1/updateRememberMe';
export const sendOtpUrl = '/mi/phoenix-account-auth/v1/generateOneTimePassword';
export const otpVerificationUrl = '/mi/phoenix-account-auth/v1/validateOneTimePassword';
export const mfaAfterSignInUrl = '/mi/phoenix-account-auth/v1/otpCommunicationOptions';
export const emailPasswordUrl = '/emailPassword.mi';

export const generateOneTimePwrdForMobileUrl = '/mi/phoenix-account-auth/v1/generateOneTimePasswordForMobile';
export const validateOneTimePwrdForMobileUrl = '/mi/phoenix-account-auth/v1/validateOneTimePasswordForMobile';
export const validateFrequentFlyerProgram = '/mi/phoenix-account-auth/v1/validateAirlineAccountNumber';
export const forgotPasswordPageUrl = '/forgotPassword.mi';
export const verifyPointsUrl = '/mi/phoenix-account-auth/v1/transferPointsToPartners';
export const verifyMemberToMemberUrl = '/mi/phoenix-account-auth/v1/transferPointsToMember';
export const EMAIL_DATA_KEY = 'customer_email';
export const MASKED_PHONE = 'masked_phone';
export const REMEMBER_ME_KEY = 'remember_me';
export const ENCRYPTED_DATA = 'encrypted_data';
export const ENCRYPTED_VERIFY_DATA = 'encrypted_verify_data';

export const BRANDING_QUERY_KEY = 'branding';
export const LOCALE_QUERY_KEY = 'locale';

export const MFA_OPTIONS_KEY = 'mfa_option';
export const savedHotelsImagesOrder = ['hotelView', 'guestRooms', 'suites', 'dining'];
export const savedHotelsMaxCount = 5;
export const imageDomain = 'https://cache.marriott.com';
export const LifetimeTitaniumMemberCode = 'PFS';
export const AriesTitaniumNavBarActivecolor = '#4F4947'; //TODO for navbar color in active and hover state not found
export const CarousalArrowButtonColor = '#ffffff60'; //TODO for carousal button for savedHotel
export const CLICK_TRACK_TURN_ON =
  'Security Preferences Modal|Turn On|internal|event167,evar48=MER-web-securityPreferencesModal-turnOn';
export const CLICK_TRACK_TURN_OFF =
  'Security Preferences Modal|Turn Off|internal|event167,evar48=MER-web-securityPreferencesModal-turnOff';
export const UPGRADE_REQUEST_CLICK_TRACK = 'Upgrade Request Tile|Submit Request Button|internal';
export const MODIFY_REQUEST_CLICK_TRACK = 'Modify Request Tile|Update Request Button|internal';
export const CANCEL_RQUEST_CLICK_TRACK = 'Cancel Request Tile|Cancel Request Button|internal';
export const ROOM_DETAILS_ID = 'showRoomDetailModal';
export const SWEEPSTAKE_ENROLLMENT_CLICK_TRACK = 'Bonvoy Sweepstakes Enrollment|Join|internal|event19';

export const KOREAN_MARKETING_CODES = ['ALL', 'PRTNR', 'HTLDS', 'PRGRM', 'PRGRM_1'];
export const KOREAN_MARKETING_CONSENT_CODES: {
  [key: string]: string[];
} = {
  ALL: ['RSRCH'],
  EML: ['HTLDS', 'PRGRM', 'PRTNR'],
  POST: ['GNRL'],
  SMS: ['MRKT', 'TRNS'],
};

export const KOREAN_CONSENT_FORM_FEATURE_CODE = 'PERSONALIZED_MARKETING_CONSENT';
export const KOREAN_CONSENT_FORM_FEATURE_CODE_GET = 'personalized-marketing-consent';
export const KOREAN_CONSENT_FORM_FEATURE_CODE_VALUE = 'Y';
export const KOREAN_CONSENT_FORM_FEATURE_CODE_DENIED_VALUE = 'N';

export const CORE_REQUIRED_CONSENT = 'CORE_REQUIRED_CONSENT';
export const CORE_REQUIRED_CONSENT_VALUE = 'Y';
export const CORE_TYPES = ['PERSONAL_REQUIRED', 'CROSSBORDER_PERSONAL_REQUIRED'];

export const KOREAN_PERSONAL_CODES = ['TRNS', 'TRNS_1', 'MRKT'];
export const KOREAN_MARKETING_TYPE = 'EML';
export const KOREAN_PERSONAL_TYPE = 'SMS';
export const KOREAN_TYPE_ALL = 'ALL';
export const SNA_Status_REQUESTED = 'requested';
export const SNA_Status_DENIED = 'denied';
export const SNA_Status_GUARANTEED = 'guaranteed';
export const SNA_Status_REMOVED = 'removed';

export const CONSENT_FORM_ADDRESS_TYPE = 'HOME';
export const CONSENT_FORM_LOGIN_COUNTS = ['1', '4', '7'];
export const CONSENT_FORM_AGE_DEFAULT = 0;
export const EMEA_CONSENT_FORM_COUNTRY_CODES = ['AE', 'UK', 'DE', 'GB'];
export const KOREA_COUNTRY_CODE = 'KR';
export const CONSENT_FORM_DECRYPT_ENDPOINT = '/mi/phoenix-encryption/v1/aesDecrypt';
export const EMEA_CONSENT_FORM_TYPE = 'EML';
export const EMEA_AEM_CONSENT_CODE_TYPE = 'EMEA';
export const EMEA_CONSENT_FORM_CODES = ['PRTNR', 'HTLDS', 'PRGRM'];
export const EMEA_CONSENT_FORM_HTLDS_CODE = 'HTLDS';
export const EMEA_CONSENT_FORM_PRGRM_CODE = 'PRGRM';
export const CONSENT_FORM_KOREA_COOKIE_NAME = 'MarketingConsent';
export const CONSENT_FORM_EMEA_COOKIE_NAME = 'EmeaMarketingConsent';
export const SkeletonLoaderDuration = '5100ms';
export const BACKGROUND_COLOR_PAGE_ARRAY = [
  '/loyalty/myAccount/savedHotels',
  '/loyalty/myAccount/activity',
  '/loyalty/myAccount/missingStayRequest',
  '/loyalty/findReservationList',
  '/loyalty/myAccount/promotionCentral',
  '/loyalty/myAccount/default',
  '/loyalty/myAccount/profile',
  '/loyalty/requestNightlyUpgradeAwards',
];
export const OVERVIEW_PAGE_URL = '/loyalty/myAccount/default';
export const ACTIVITY_COUNT = 3;
export const DEFAULT_VALUE_SET_RECENT_DURATION_FILTER = '24';
export const NO_UPCOMING_TRIPS_POLICY_LOCALES = ['en-US', 'en_US', 'en_CN', 'en-CN'];
export const CURRENT_YEAR = new Date().getFullYear();
export const overviewUpcomingTripsCount = 1;

// MOVALBLE COMPONENTS ENUM ORDERING SHOULD NOT BE CHANGED UNLESS ITS PART OF REQUIREMENT
export enum OverviewMovableComponents {
  UPCOMING_TRIPS = 'upcoming-trips',
  RECENT_ACTIVITIES = 'recent-activities',
  EARNED_REWARDS = 'earned-rewards',
  PROMOTIONS = 'promotions',
  NONE = 'none',
}

export const REGISTERED_PROMOTIONS_ID = 'registered-promotions-movable';
export const TWO_STEP_VERIFICATION_ID = 'two-step-verification';
export const COMMUNICATION_PREFERENCE = 'communication-consent';
export const ROOM_PREFERENCE_ID = 'room-preferences';
export const AMBASSADOR_ELITE_PREFERENCE_ID = 'ambassador-elite-preferences';
export const EARNING_AND_REWARDS_MODAL_ID = 'earning-and-rewards';
export const EDIT_CARD_MODAL_ID = 'card-details';
export const EDIT_PERSONAL_INFO_ID = 'personal-info';
export const EDIT_CHANGE_PASSWORD_ID = 'change-password';
export const FORGOT_PWRD_ID = 'forgot-pwrd';
export const FOOD_AND_BEVERAGE = 'food-beverage-preferences';
export const POINTS_TRANSFER_ID = 'points-transfer';
export const INTERESTS_AND_PASSION = 'interests-and-passions';

export const OPTED_OUT_PERSONALIZATION_KEY = 'opted-out-personalization';
export const DEFAULT_VIEW_BILL_FILE_NAME = 'Activity';
export const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

interface LocaleDateFormatObj {
  excludeYear: string;
  onlyDate: string;
  fullDate: string;
  calendarView: string;
  fullMonth: string;
  numericDate: string;
}

export const ROOM_PREF_PRIORITYTYPE = 'PR';

export const ROOM_PREF_AMENTITIES = 'RA';

export const localeDateFormats: Record<string, LocaleDateFormatObj> = {
  'pl-PL': {
    //Polish
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'tr-TR': {
    //Turkish
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, MMM DD',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'th-TH': {
    //Thai
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd[.] D[.] MMM[.]',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'id-ID': {
    //Indonesian
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'el-GR': {
    //Greek
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, DD MMM.',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'nl-NL': {
    //Dutch
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'dd DD MMM',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'sv-SE': {
    //Swedish
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, D MMM[.]',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'it-IT': {
    //Italian
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'fr-FR': {
    //French
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd DD MMM',
    fullMonth: 'Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'he-IL': {
    //Hebrew
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'MMM DD, dd',
    fullMonth: ' Do MMMM',
    numericDate: 'DD/MM/YYYY',
  },
  'zh-TW': {
    //Traditional Chinese
    excludeYear: 'MMM DD[日]',
    onlyDate: 'DD[日]',
    fullDate: 'YYYY[年] M[月] DD[日]',
    calendarView: 'M[月] D[日], ddd',
    fullMonth: 'M[月] D[日]',
    numericDate: 'DD/MM/YYYY',
  },
  'zh-CN': {
    //Simplified Chinese
    excludeYear: 'M[月]  DD [日]',
    onlyDate: 'DD [日]',
    fullDate: 'YYYY [年] M[月]  DD [日]',
    calendarView: 'M[月] D[日], ddd',
    fullMonth: 'M[月] D[日]',
    numericDate: 'YYYY/MM/DD',
  },
  'vi-VN': {
    //Vietnamese
    excludeYear: 'DD [thg] M',
    onlyDate: 'DD',
    fullDate: 'DD [thg] M, YYYY',
    calendarView: 'ddd, DD Tháng M',
    fullMonth: 'DD [Tháng] M',
    numericDate: 'DD/MM/YYYY',
  },
  'da-DK': {
    excludeYear: 'DD. MMM',
    onlyDate: 'DD',
    fullDate: 'DD. MMM YYYY',
    calendarView: 'ddd[.] DD[.] MMM[.]',
    fullMonth: 'D[.] MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //Danish
  'nb-NO': {
    excludeYear: 'DD. MMM',
    onlyDate: 'DD',
    fullDate: 'DD. MMM YYYY',
    calendarView: 'ddd[.] DD[.] MMM',
    fullMonth: 'D[.] MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //Norwegian
  'fi-FI': {
    excludeYear: 'DD.M',
    onlyDate: 'DD',
    fullDate: 'DD.M.YYYY',
    calendarView: 'dd DD[.] MMM',
    fullMonth: 'DD[.]M',
    numericDate: 'DD/MM/YYYY',
  }, //Finnish
  'ar-AE': {
    excludeYear: 'MMM DD',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'DD MMM, ddd',
    fullMonth: 'MMMM DD[,] ddd',
    numericDate: 'DD/MM/YYYY',
  }, //Arabic
  'ko-KR': {
    excludeYear: 'M[월] DD[일]',
    onlyDate: 'DD[일]',
    fullDate: 'YYYY[년] M[월] DD[일]',
    calendarView: 'M[월] D[일], ddd',
    fullMonth: 'MM[월] DD[일]',
    numericDate: 'YYYY/MM/DD',
  }, //Korean
  'es-ES': {
    excludeYear: 'DD [de] MMM',
    onlyDate: 'DD',
    fullDate: 'DD [de] MMM, YYYY',
    calendarView: 'ddd, D MMM',
    fullMonth: 'DD [de] MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //Spanish
  'pt-BR': {
    excludeYear: 'DD [de] MMM',
    onlyDate: 'DD',
    fullDate: 'DD [de] MMM [de] YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'DD [de] MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //Portuguese
  'de-DE': {
    excludeYear: 'DD. MMM',
    onlyDate: 'DD.',
    fullDate: 'DD. MMM YYYY',
    calendarView: 'ddd, DD. MMM',
    fullMonth: 'DD[.] MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //German
  'ja-JP': {
    excludeYear: 'M[月]DD[日]',
    onlyDate: 'DD[日]',
    fullDate: 'YYYY[年]M[月]DD[日]',
    calendarView: 'M[月]D[日](ddd)',
    fullMonth: 'MM[月]DD[日]',
    numericDate: 'YYYY/MM/DD',
  }, //Japanese
  'en-GB': {
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'MMMM Do',
    numericDate: 'DD/MM/YYYY',
  }, // English (United Kingdom)
  'ru-RU': {
    excludeYear: 'DD MMM',
    onlyDate: 'DD',
    fullDate: 'DD MMM YYYY r.',
    calendarView: 'ddd[.], DD MMM',
    fullMonth: 'DD MMMM',
    numericDate: 'DD/MM/YYYY',
  }, //Russian
  'en-US': {
    excludeYear: 'MMM DD',
    onlyDate: 'DD',
    fullDate: 'MMM DD, YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'MMMM Do',
    numericDate: 'MM/DD/YYYY',
  }, //United States
  default: {
    excludeYear: 'MMM DD',
    onlyDate: 'DD',
    fullDate: 'MMM DD, YYYY',
    calendarView: 'ddd, DD MMM',
    fullMonth: 'MMMM Do',
    numericDate: 'MM/DD/YYYY',
  },
};

export const localesWithDateFirst = [
  'pl-PL',
  'tr-TR',
  'th-TH',
  'id-ID',
  'el-GR',
  'nl-NL',
  'sv-SE',
  'it-IT',
  'fr-FR',
  'he-IL',
  'vi-VN',
  'nb-NO',
  'fi-FI',
  'es-ES',
  'pt-BR',
  'de-DE',
  'ru-RU',
  'en-GB',
];

export const localesWithLowerCaseSplCharacters = ['vi-VN', 'es-ES', 'pt-BR'];

export const PLATINUM_CORE_CODE = 'P';

export const PLATINUM_ACHIEVER_LEVELTYPE = 'PAC';
export const PLATINUM_REFERRRAL_LEVELTYPE = 'PAR';
export const PLATINUM_LIFETIME_ELITE_LEVELTYPE = 'PLR';

export const PLATINUM_PREMIER_PFS_LEVELTYPE = 'PFS';
export const PLATINUM_PREMIER_PFR_LEVELTYPE = 'PFR';

export const PLATINUM_PREMIER_ELITE_LEVELTYPE = 'PPE';
export const PLATINUM_PREMIER_ELITE_AMBASSADOR_LEVELTYPES = 'PPAE';
export const changePwrdURL = '/mi/phoenix-account-auth/v1/changePassword';
export const newPwrdAPI = '/mi/phoenix-account-auth/v1/newPasswordSubmit';
export const cancelStatus = ['pending', 'requested', 'cancelled'];
export const cancelStatusGuaranteed = 'guaranteed';
export const cancelStatusForfeited = 'forfeited';
export const STATUS_CANCELLED = 'cancelled';

export const FCP_HURDLE_WITHOUT_ENDPOINT = '/mi/phoenix-account-auth/v1/changePassword';
export const CANCEL_REDIRECTION_URL = '/sign-in.mi';
export const PROFILE_REDIRECTION_URL = '/loyalty/myAccount/profile.mi';
export const RFP_URL = '/meetings-events/rfp/event-profile.mi?isRewardsFlow=true';

export const UPDATE_CUSTOMER_PROFILE = '/mi/phoenix-account-auth/v1/updateCustomerProfile';

export const EDIT_PROFILE_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const CHINESE_MOBILE_CODE = '+86';
export const CHINESE_MOBILE_LENGTH = 11;
export const ALPHABETS_REGEX = /^[a-zA-Z]+$/;
export const NUMBERS_REGEX = /^[0-9]+$/;
export const MOB_NUM_MIN_LENGTH = 7;

// SWEEPSTAKES CONSTANTS

export const CONSENT_METHOD_SMS = 'SMS';
export const CONSENT_METHOD_EMAIL = 'EML';
export const SWEEPSTAKES_ENTER_NOW_URL = '/mi/phoenix-account-navigation/v1/sweepStakeflowEntry';
export const ARIES_SEND_OTP_CHALLENGE_URL = '/loyalty/myAccount/sweepstakes/send-otp-challenge.mi';
export const SWEEPSTAKES_FINAL_URL = '/mi/phoenix-account-navigation/v1/sweepStakeEventEnrollment';
export const SWEEPSTAKES_PREPROCESSOR_PATH_PARAM = '/sweepstakesEvent';
export const SWEEPSTAKES_ENTRY_PAGE_URL = '/loyalty/sweepstakes';

export const SWEEPSTAKES_CONSENT_CODES = {
  [CONSENT_METHOD_SMS]: ['MRKT'],
  [CONSENT_METHOD_EMAIL]: ['PRGRM'],
};

export enum SweepstakesErrorTypes {
  INELIGIBLE_ERROR = 'ineligibleError',
  DUPLICATE_ERROR = 'duplicateError',
  RECENTLY_ENROLLED = 'recentlyEnrolled',
  GENERIC_ERROR = 'genericError',
}

export const CONSENT_TYPE_ONE_COUNTRY_SET = [
  'AU',
  'BD',
  'BT',
  'KH',
  'FJ',
  'PF',
  'GU',
  'HK',
  'IN',
  'ID',
  'JP',
  'MO',
  'MY',
  'MV',
  'NP',
  'NC',
  'NZ',
  'PH',
  'SG',
  'LK',
  'TW',
  'TH',
  'VN',
  'WS',
];
export const UNIQUE_CONSENT_TYPE_COUNTRY_SET = ['CN', 'VN', 'KR', 'US', 'JP'];

export const COUNTRY_SET_CN_VN_KR = ['CN', 'VN', 'KR'];
export const COUNTRY_SET_CN_VN_JP = ['CN', 'VN', 'JP'];

export const IS_DEFAULT_UNCHECKED = [
  'CL',
  'CR',
  'PE',
  'AT',
  'BE',
  'CY',
  'CZ',
  'DK',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'LU',
  'NL',
  'PL',
  'PT',
  'RO',
  'ZA',
  'ES',
  'SE',
  'TR',
  'GB',
  'CN',
  'HK',
  'KO',
  'SG',
  'KZ',
  'RU',
  'CO',
];
export const COUNTRY_CODE_ADD_CARD_ZIPINPUT = ['US', 'CA'];
export const mi_consent = 'mi_consent';
export const tp_consent = 'tp_consent';

export const JOIN_NOW_CANCEL_URL = '/default.mi';
export const SYSTEM_MAINTENANCE_URL = '/profile/systemMaintenance.mi';
export const SERVICE_UNAVIALABLE_PAGE = '/reservation/800Page.mi';
export const OVERVIEW_REGISTERED_PROMO_SORT_ORDER: { [key: string]: number } = { RSP: 1, RE: 2, RNP: 3 };

export const COMMA_DELIMITER = ',';
export const DOT_SYMBOL = '•';

export const PROFILE_CONSTANTS = {
  REMEMBER_ME_FLAG: 'RememberMeFlag',
  COUNTRY_TYPE: 'country',
  USER_TYPE: 'user',
  ALL: 'ALL',
  DYNAMIC_LINE_ITEM_ID: {
    MEMBER_NUMBER: 'member-number',
    LINKED_ACCOUNTS: 'linked-accounts',
    CREDITCARD: 'card-details',
    REMEMBER_ME: 'remember-me',
    ADD_CARD: 'add-card',
  },
  CONDITIONAL_USER_COUNTRY_LINE_IDS: ['two-step-verification'], // add if any other line item has to be hidden/shown based profile country
  PHONE_TYPE: {
    BUSINESS: 'BT',
    HOME: 'HT',
    DEFAULT: 'MT',
  },
  GENDER: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    OTHER: 'OTHER',
  },
  PROFILE_GENDER: {
    MALE: 'M',
    FEMALE: 'F',
  },
};
export const FCP_REDIRECTION_URL = '/loyalty/myAccount/changePasswordChallenge.mi';

export const COMMUNICATION_SUBSCRIBED = [
  {
    method: 'EML',
    type: 'PRGRM',
  },
  {
    method: 'SMS',
    type: 'MRKT',
  },
  {
    method: 'POST',
    type: 'GNRL',
  },
  {
    method: 'SMS',
    type: 'TRNS',
  },
  {
    method: 'ALL',
    type: 'RSRCH',
  },
];
export const SUCCESS_CODE = 'Success';
export const FAILURE_CODE = 'Failure';
export const PROCESS_CREDIT_CARD_ENDPOINT = '/mi/phoenix-payment/v1/processCreditCard';
// error value returned from Backend for Missing Stay Request
export const MSR_ERROR_CONSTANTS = {
  PHONE_NUMBER_WARNING: 'phoneNumberWarning',
  EMAIL_WARNING: 'emailAddressWarning',
};

export const ENROLL_ENDPOINT = '/mi/phoenix-account-auth/v1/enroll';
export const CREATE_ACCOUNT_CLICK_VAL = 'Create Account|Activate Account Link|internal';
export const GEN_OTP_SESSION_EXPIRED = 'userSessionExpired';

export const BANNER_MSGS_ERROR_TYPE = 'error';
export const BANNER_MSGS_ERROR_VALUE = 'error-sev1';

export enum BANNER_MSGS_KEYS {
  ACCOUNT_UPDATED_MSG = 'accountUpdatedMsg',
  TWO_STEP_VERIFICATION_MSG = 'twoStepVerficationMsg',
  TWO_STEP_VERIFICATION_ADD_PHONE_SUCCESS_MSG = 'twoStepVerficationAddPhoneMsg',
  TWO_STEP_VERIFICATION_ERROR_MSG = 'twoStepVerficationErrorMsg',
  PASSWORD_UPDATED_MSG = 'passwordUpdatedMsg',
  PASSWORD_ERROR_MSG = 'passwordErrorMsg',
  PREVIOUSLY_USED_PASSWORD_ERROR_MSG = 'previouslyUsedPasswordErrorMsg',
  NEW_MEMBER_NUMBER_MSG = 'newMemberNumberMsg',
  RESERVATION_NOT_LINKED_ERROR_MSG = 'reservationNotLinkedErrorMsg',
  EMAIL_DUPLICATE_ERROR_MSG = 'emailDuplicateErrorMsg',
  NIGHT_UPGRADE_SUCCESS = 'nightUpgradeSuccess',
  NIGHT_UPGRADE_ERROR = 'nightUpgradeError',
  PREV_USED_PWRD_KEY = 'previouslyUsedPwrdErrMsg',
  UXL_ERROR_MSG = 'showUxlErrorMsg',
}

export enum NOTIFICATION_BANNER_KEYS {
  MISSING_MOBILE_NUMBER = 'missingMobileNumber',
}

export enum EARNING_AND_REWARDS_OPTIONS {
  POINTS = 'HP',
  MILES = 'MI',
}
export const NIGHT_DETAIL_LABEL_SWAP_FOR_LOCALE = ['it_IT', 'de_DE', 'pt_BR'];

export const CREDIT_CARD_CODES: {
  [key: string]: string;
} = {
  V: 'VS',
  M: 'MC',
  A: 'AX',
  R: 'DS',
  D: 'DC',
  J: 'JC',
  UA: 'UA',
  UP: 'UP',
};

export const ADD_MOBILE_NUMBER_PATH = '/loyalty/myAccount/addMobileNumber.mi';
export const PHONE_NUMBER_REGEX = /^[0-9]+$/;
export const LOGIN_URL = '/mi/phoenix-account-auth/v1/loginWithCredentials';
export const LOGIN_LINK_RESERVATION_URL = '/mi/phoenix-account-auth/v1/loginWithCredentialsAndLink';
export const EAA_FCP_WARNING_MESSAGE_KEY = 'eaaFcpWarningMessage';

export enum ENROLLMENT_KOREAN_CONSENT_ENUM {
  KRPRC = 'KRPRC',
  KRCBC = 'KRCBC',
  MRKT = 'MRKT',
  TRNS = 'TRNS',
  TRNS_1 = 'TRNS_1',
  HTLDS = 'HTLDS',
  PRGRM = 'PRGRM',
  PRGRM_1 = 'PRGRM_1',
  PRTNR = 'PRTNR',
}

export enum SMS_TYPE_CODES {
  TRNS = 'TRNS',
  MRKT = 'MRKT',
}

export enum ENROLLMENT_CHINA_CONSENT_ENUM {
  SOCN = 'SOCN',
  SPCN = 'SPCN',
  CNCBC = 'CNCBC',
  CNPRC = 'CNPRC',
}

export enum ENROLLMENT_VIETNAM_CONSENT_ENUM {
  SOVN = 'SOVN',
  SPVN = 'SPVN',
  VNCBC = 'VNCBC',
  VNPRC = 'VNPRC',
}

export enum ENROLLMENT_US_CONSENT_ENUM {
  SO = 'SO',
  SP = 'SP',
}

export enum ENROLLMENT_CONSENT_TYPES {
  CORE_REQUIRED_CONSENT = 'CORE_REQUIRED_CONSENT',
  SUBSCRIPTION_OFFER = 'subscriptionsOffers',
  SUBSCRIPTION_3RD_OFFER = 'subscriptions3rdParty',
  PERSONALIZED_MARKETING_CONSENT = 'PERSONALIZED_MARKETING_CONSENT',
}
export const USER_STATE_AUTHENTICATED = 'Authenticated';

export enum ONE_CLICK_JOIN_ERROR {
  PROHIBITED_ERROR = 'prohibitedCountryErrMsg',
  EMAIL_DUPLICATE_ERROR = 'emailDuplicateError',
  API_ERROR = 'uxlFailureErrorMessage',
}

export const EditPersonalInfoModalErrorList = [
  'uxlFailureErrorMessage',
  'customerProfileUpdateFailure',
  'invalidUserInfo',
];

export const SYSTEM_MAINTENANCE_ERROR_URL = '/profile/systemMaintenance.mi?type={0}';
export const COMPLETE_PROFILE_URL = '/loyalty/myAccount/editPersonalInformation.mi';

export enum CUSTOM_PROFILE_TRACK_Val {
  BOOK_HOTEL_TRACK_VAL = '3 Pack Welcome Tile|Book A Hotel|internal|event167, event168, evar48=MER-web-enrollWelcomeTile-BookHotel',
  VIEW_BENEFITS_TRACK_VAL = '3 Pack Welcome Tile|View Benefits|internal|event167, event168, evar48=MER-web-enrollWelcomeTile-ViewBenefits',
  COMPLETE_PROFILE_TRACK_VAL = '3 Pack Welcome Tile|Complete Profile|internal|event167, event168, evar48=MER-web-enrollWelcomeTile-CompleteProfile',
}

export const LAST_ATTEMPT_WARNING_KEY = 'incorrect.password.last.attempt.remaining';

export const LOCKOUT_ERROR_KEY = 'account.lockedout.dueto.password.failure.attempts';
export const ALREADY_COMBINED_ERROR_KEY = 'transferred.marriott.rewards.account.status';

export const CHECKED_IN_MODAL_ID = 'checked-in';
export const CHINESE_SOURCE_LANG = 'CHINESE';
export const ENGLISH_SOURCE_LANG = 'ENGLISH';
export enum nightlyUpgradeAwardType {
  suiteNight = 'SUITE_NIGHT_AWARD',
}
export const NUA_UPGRADE_REQUEST = '/mi/phoenix-account-navigation/v1/SNAUpgrade';
export const CHANGE_CONFIRMATION_ENDPOINT = '/reservation/changeConfirmation.mi';

export const CLICK_TRACK_DYNAMIC_VALUE_PROFILE_EDIT_BTN: Record<string, string> = {
  'ambassador-elite-preferences': 'Profile Preferences|Ambassador Elite Preferences|internal',
  'interests-passions': ' Profile Preferences|Edit Interests & Passions|internal',
  'food-beverage-preferences': 'Profile Preferences|Edit Food & Beverage Preferences|internal',
  'folio-delivery': 'Payment Preferences|Edit Folio Delivery Method|internal',
};

export const FOOD_BEV_CLICK_TRACK = 'Food & Beverages|Save Food & Beverages|internal';
export const INTERESTS_PASSIONS_CLICK_TRACK = 'Interests and Hobbies|Save Interests & Hobbies|internal';
export const PERSONAL_INFO_CLICK_TRACK = 'Personal Info Modal|Save Your Profile|internal|event200';
export const ROOM_PREFERENCES_CLICK_TRACK = 'Room Options and Stay Preferences|Save|internal';
export const COMMUNICATION_CLICK_TRACK = 'Communication Preferences|Save|internal';
export const EARNING_REWARDS_CLICK_TRACK = 'Earning Preferences|Submit|internal';
export const AMBASSADOR_ELITE_CLICK_TRACK = 'Ambassador Elite Preference|Save|internal';
export const FOLIO_CLICK_TRACK = 'Folio and Delivery Method|Save Folio and Delivery Method|internal';
export const ADD_CARD_CLICK_TRACK = 'Credit-Card-Modal|Save|internal|eVar199=New Card';
export const EDIT_CARD_CLICK_TRACK = 'Credit-Card-Modal|Save|internal|eVar199=Saved Card';
export const LINK_ACCOUNT_CLICK_TRACK = 'Convert Points|Unlink|external';

export enum AMBASSADOR_SERVICE {
  REWARDS_MAIN_DIRECT_AMBASSADOR = 'rewardsMainDirect_ambassador',
  REWARDS_MAIN_TOLL_FREE_AMBASSADOR = 'rewardsMainTollFree_ambassador',
}

export enum ENROLLMENT_CONSTANTS {
  LACA = 'LACA_EF',
  MRMB = 'MRMB',
  USMI = 'USMI',
  SOURCE_CODE_KEY_SUFFIX = '_EF',
}

export const PREV_USED_PWRD_KEY = 'previouslyUsedPwrdErrMsg';

export enum CreditCardType {
  V = 'visa',
  A = 'american-express',
  R = 'discover',
  M = 'mastercard',
  D = 'diners-club',
  J = 'jcb',
  UP = 'unionpay',
}

export enum ONE_CLICK_JOIN_MODAL_TYPE {
  CHECKED_IN = 'checkedIn',
  EARN_WHILE_YOU_SLEEP = 'earnWhileYouSleep',
  UPCOMING = 'joinAndSaveReservation',
  SAVE_RESERVATION = 'saveReservation',
}

export const POINTS_TRANSFER_TYPE = {
  CONVERT_POINTS: 'convert-points',
  FREQUENT_FLYER: 'frequent-flyer',
  MEMBER_TO_MEMBER: 'member-to-member',
};
export const PHOENIX_SEND_OTP_CHALLENGE_URL = '/loyalty/myAccount/send-otp-challenge.mi';

export const EDIT_CHANGE_PWRD_QUERY_PARAM = 'editChangePassword';

export const UNSUBSCRIBE_ACTION = 'UNSUBSCRIBE';
