import clsx from 'clsx';
import { Button, CheckBox, Eyebrow, Image, Label, Link, RichText, Text, Types } from '@marriott/mi-ui-library';
import {
  CREDIT_CARD_CODES,
  PROFILE_CONSTANTS,
  getCountryAndLangCode,
  accountConstants,
  getMaskedDot,
  isCardExpired,
  CLICK_TRACK_DYNAMIC_VALUE_PROFILE_EDIT_BTN,
} from '../../../modules';
import {
  ActionObject,
  ActionObjectDataType,
  AddressType,
  TitleObject,
  CreditCardDetails,
  FeaturedDynamicImage,
} from '../ProfileSectionElement/ProfileSectionElement.types';
import { ImageDetails, ProfileSectionType, Section, SectionList } from '../ProfileSection.types';
import { CustomerPaymentMethod, UserProfile } from '../../../modules/store/profileStore';

const { MEMBER_NUMBER, LINKED_ACCOUNTS, CREDITCARD, REMEMBER_ME } = PROFILE_CONSTANTS.DYNAMIC_LINE_ITEM_ID;

const handleLinkObject = (linkObject: ActionObject) =>
  linkObject?.ctaLink &&
  linkObject?.ctaLabel && (
    <Link
      text={linkObject?.ctaLabel}
      linkClassName="t-font-s text-nowrap border-line"
      linkHref={linkObject?.ctaLink || ''}
      target={linkObject?.openInANewTab ? '_blank' : '_self'}
    />
  );

const handleModalObject = (modalObject: ActionObject, handleModalClick: (link: string) => void) => {
  const clickTrackValue = findClickTrackValue(modalObject?.profileId);
  return (
    modalObject?.ctaLabel && (
      <Button
        buttonCopy={modalObject?.ctaLabel}
        className="t-font-s btn-editLink px-0 border-line"
        linkType="internal"
        callback={() => handleModalClick(modalObject?.profileId || '')}
        ariaLabel="model"
        {...(clickTrackValue && { custom_click_track_value: clickTrackValue })}
      />
    )
  );
};

const findClickTrackValue = (profileId: string) => {
  return CLICK_TRACK_DYNAMIC_VALUE_PROFILE_EDIT_BTN[profileId];
};
const handleModalLinkObject = (
  modalLinkObject: ActionObject,
  handleModalClick: (link: string, isVistanaModal: boolean) => void
) =>
  modalLinkObject?.ctaLabel && (
    <Button
      buttonCopy={modalLinkObject?.ctaLabel}
      className="t-font-s btn-editLink px-0 border-line"
      linkType="internal"
      callback={() => handleModalClick(modalLinkObject?.modalLink || '', true)}
      ariaLabel="model"
      testId={modalLinkObject.profileId}
    />
  );

const handleImageObject = (imgObject: ImageDetails[], linkedAccount?: string[]) =>
  imgObject && (
    <div className="image-wrapper d-flex">
      {imgObject.map((img, index) => {
        const { linkedAccountId } = img;
        const showLinkedAccount = linkedAccountId && linkedAccount?.includes(linkedAccountId);
        return showLinkedAccount && handleImageObjectUXL(img, index);
      })}
    </div>
  );

const handleImageObjectUXL = (imgObject: ImageDetails, index: number) => {
  const { imageRedirectLink, imageAltText, featuredDynamicImage, openImageRedirectLinkInANewTab } = imgObject;
  return imageRedirectLink ? (
    <Link
      linkClassName="ml-3"
      linkHref={imageRedirectLink}
      key={index}
      target={openImageRedirectLinkInANewTab ? '_blank' : '_self'}
      children={
        <Image
          title={imageAltText}
          defaultImageURL={featuredDynamicImage?.assetPath}
          altText={imageAltText}
          customClass="img-fluid"
          loading="lazy"
        />
      }
    />
  ) : (
    <Image
      title={imageAltText}
      defaultImageURL={featuredDynamicImage?.assetPath}
      altText={imageAltText}
      customClass="img-fluid"
      loading="lazy"
    />
  );
};

const handleCheckboxObject = (checkboxObject: ActionObject, isChecked: boolean, handleToggleCheckbox: () => void) =>
  checkboxObject && (
    <CheckBox
      checkboxId="remember-me"
      checkboxName="remember-me"
      className="mb-0"
      checkboxLabel=""
      checked={isChecked}
      onChange={handleToggleCheckbox}
      data-testId="remember-me"
    />
  );

export const handleTitleObject = (titleObject: TitleObject | undefined, profileId: string) => {
  const isCreditcard = profileId === CREDITCARD;
  if (titleObject && titleObject?.text && !isCreditcard) {
    return <Text element={Types.tags.div} fontSize={Types.size.medium} copyText={titleObject?.text} />;
  } else {
    return titleObject?.titleElement;
  }
};

// This is RHS item for each line
export const handleActionObject = (
  actionObject: ActionObject | undefined,
  handleModalClick: (link: string, isVistanaModal?: boolean) => void,
  isChecked: boolean,
  handleToggleCheckbox: () => void,
  memberNumber: string,
  dynamic: boolean | undefined,
  linkedAccount?: string[]
) => {
  if (!dynamic) {
    // Handle non-dynamic cases first
    switch (actionObject?.ctaType) {
      case 'link':
        return handleLinkObject(actionObject);
      case 'modal':
        return handleModalObject(actionObject, link => handleModalClick(link));
      case 'linkModal':
        return handleModalLinkObject(actionObject, (link, isVistanaModal = false) =>
          handleModalClick(link, isVistanaModal)
        );
      case 'image':
        return handleImageObject(actionObject?.imageDetails || [], linkedAccount);
      case 'checkbox':
        return handleCheckboxObject(actionObject, isChecked, handleToggleCheckbox);
      default:
        return '';
    }
  } else {
    // Handle dynamic cases with line item ID checks
    switch (actionObject?.profileId) {
      case MEMBER_NUMBER:
        return (
          <Text
            customClass={clsx(MEMBER_NUMBER)}
            element={Types.tags.div}
            fontSize={Types.size.small}
            copyText={memberNumber}
          />
        );
      case LINKED_ACCOUNTS:
        return handleImageObject(actionObject?.imageDetails || [], linkedAccount);
      case CREDITCARD:
        return handleModalObject(actionObject, link => handleModalClick(link));
      case REMEMBER_ME:
        return handleCheckboxObject(actionObject, isChecked, handleToggleCheckbox);
      default:
        return '';
    }
  }
};

export const sectionTitleHelper = (sectionTitle: string) =>
  sectionTitle && (
    <div className=" mb-3 color-scheme1 section-title text_modal-align">
      <Eyebrow text={sectionTitle} />
    </div>
  );

export const getCreditCardDetail = (
  userPayment: CustomerPaymentMethod[],
  cardImage: FeaturedDynamicImage | undefined,
  isTabletAndAboveViewPort?: boolean
) => {
  if (!userPayment || userPayment?.length === 0) {
    return [];
  }

  const processCard = (card: CustomerPaymentMethod) => {
    const cardCode = card?.issuerType?.code || card?.cobrand?.issuer?.code;
    const isExpiredCard = isCardExpired(card?.expiration);
    const cardDetail = {
      icon: cardImage, // Use empty string if icon is undefined
      label: cardCode || '', // Use issuerType label if lookup label is undefined
      maskedDot: getMaskedDot(!isTabletAndAboveViewPort), // passed true for single dot variation in mobile
      pin: card?.panLast4,
      isPreferred: card?.preferred || false,
      isExpired: isExpiredCard,
      nickname: card?.nickname || '',
    };

    return cardDetail;
  };

  const processedCardDetails = [];

  // Check if there are payment methods
  if (userPayment.length > 0) {
    // Check if there is a preferred card
    const preferredCard = userPayment?.find((card: CustomerPaymentMethod) => card?.preferred);

    if (preferredCard) {
      // Process the preferred card first
      const preferredCardDetail = processCard(preferredCard);
      processedCardDetails.push(preferredCardDetail);
    } else {
      // If no preferred card, process the first 10 non-preferred cards or all available if less than 10
      const nonPreferredCards = userPayment.filter((card: CustomerPaymentMethod) => !card?.preferred).slice(0, 9);
      nonPreferredCards.forEach((card: CustomerPaymentMethod) => {
        const nonPreferredCardDetail = processCard(card);
        processedCardDetails.push(nonPreferredCardDetail);
      });
    }
  }
  return processedCardDetails;
};

export const getCardDetailStructure = (
  cardDetails: CreditCardDetails[],
  defaultLabel?: string,
  expiryLabel?: string
) => {
  const cardStructurehtml = cardDetails.map((cardDetail: CreditCardDetails) => {
    return (
      <div>
        <div className="cardData d-flex align-items-center">
          <Image
            customClass={clsx(`cardData__${CREDIT_CARD_CODES[cardDetail.label] || ''}`)}
            altText={'credit-card-' + cardDetail.label}
            defaultImageURL={(cardDetail?.icon?.assetPath as string) || ''}
          />
          <div className="cardData__cardpin pl-4 ml-5 d-flex t-body-s">
            {cardDetail.maskedDot} {cardDetail.pin}
          </div>
          {cardDetail.isPreferred && (
            <Label
              labelText={defaultLabel ?? ''}
              customClass="cardData__defaultLabel pl-2 pl-md-3  t-label-s"
              size={Types.size.small}
            />
          )}

          {cardDetail.isExpired && (
            <Label
              customClass="cardData__expiryLabel pl-2 pl-md-3 t-label-s"
              size={Types.size.small}
              labelText={expiryLabel ?? ''}
            />
          )}
        </div>
        {cardDetail.nickname && (
          <Label
            customClass="cardData__nickname pt-2 pl-5 ml-4 t-body-xs"
            labelText={cardDetail.nickname}
            size={Types.size.small}
          />
        )}
      </div>
    );
  });
  return cardStructurehtml;
};
export const renderLineItemHelper = (
  actionObjectData: ActionObjectDataType | null,
  rowIndex: number,
  sectionLength: number,
  profileDetails: UserProfile
) => {
  if (actionObjectData === null) {
    return '';
  }
  const profileId = actionObjectData?.actionObject?.profileId || '';
  const { titleObject, actionObject, handleModalClick, isRememberMeChecked, handleToggleCheckbox, memberNumber } =
    actionObjectData;
  const sectionBorderClass = rowIndex + 1 < sectionLength ? 'section-border' : '';
  const linkedAccount = profileDetails?.linkedAccount;
  const paddingClass =
    rowIndex === 0 && rowIndex === sectionLength - 1
      ? '' // This is when we have only one line item
      : profileId === PROFILE_CONSTANTS?.DYNAMIC_LINE_ITEM_ID.CREDITCARD && rowIndex === -1
      ? 'pb-4' // This is for first line item of CREDITCARD
      : profileId === PROFILE_CONSTANTS?.DYNAMIC_LINE_ITEM_ID.CREDITCARD && rowIndex >= 0
      ? 'pt-4 pb-4' // This is for non-first line items of CREDITCARD
      : rowIndex === 0 && profileId !== PROFILE_CONSTANTS?.DYNAMIC_LINE_ITEM_ID.CREDITCARD
      ? 'pb-4' // This is for first line item
      : rowIndex === sectionLength - 1
      ? 'pt-4' // This is for last line item
      : profileId === PROFILE_CONSTANTS?.DYNAMIC_LINE_ITEM_ID.LINKED_ACCOUNTS
      ? 'pb-3 pt-3'
      : 'pb-4 pt-4'; // This is for non-first and non-last line items

  return (
    <div className={clsx(sectionBorderClass, paddingClass, 'row mx-0')}>
      <div className="col-12 d-flex justify-content-between align-items-center px-0 item-gap" data-testid="line-item">
        {titleObject && handleTitleObject(titleObject, profileId)}
        {handleActionObject(
          //image, link, modal object
          actionObject,
          handleModalClick,
          isRememberMeChecked,
          handleToggleCheckbox,
          memberNumber,
          actionObject?.dynamic,
          linkedAccount
        )}
      </div>
    </div>
  );
};

export const profileSectionsHelper = (profilesections: Section) => {
  const profileObject: ProfileSectionType = {
    sectionTitle: profilesections?.sectionTitle,
    showForUsers: profilesections?.showForUsers,
    profileSectionRow: profilesections?.sectionList?.map((ele: SectionList) => {
      return {
        titleObject: {
          text: ele?.profileTitle,
          //need to be updated by the credit card logic here
          titleElement: '', // TO DO going replace with dynamic html based on UXL data,
          defaultLabel: profilesections?.defaultLabel,
          expiryLabel: profilesections?.expiredLabel,
          featuredDynamicImage: profilesections?.featuredDynamicImage,
        },
        actionObject: {
          imageDetails: ele?.imageDetails,
          openInANewTab: ele?.openInANewTab,
          ctaLink: ele?.ctaLink,
          modalLink: ele?.modalLink,
          ctaType: ele?.ctaType,
          profileId: ele?.profileId,
          conditionalForLineItem: ele?.conditionalForLineItem,
          conditionType: ele?.conditionType,
          allowedCountries: ele?.allowedCountries,
          allowedUsers: ele?.allowedUsers,
          dynamic: ele?.dynamic,
          ctaLabel: ele?.ctaLabel,
          openImageRedirectLinkInANewTab: ele?.openImageRedirectLinkInANewTab,
        },
      };
    }),
  };
  return profileObject;
};

export const countryCodeHelper = (profileAddress: AddressType[], profileId: string, currentLocale: string) => {
  const userProfileCountryCode = profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  return PROFILE_CONSTANTS.CONDITIONAL_USER_COUNTRY_LINE_IDS.includes(profileId)
    ? userCountryCode
    : getCountryAndLangCode(currentLocale).countryCode || accountConstants?.DEFAULT_COUNTRY;
};
