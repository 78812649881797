/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';
import { ActivityFooter, FooterMobile } from './linksGroup';
import { ExtendedFooterStyle } from './ExtendedFooter.styles';
import { ExtentedFooterProps } from './ExtendedFooter.types';

export const ExtendedFooter: React.FC<ExtentedFooterProps> = pageProps => {
  const data = pageProps?.model;
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportM'); // check if viewport is Tablet and above

  return (
    <ExtendedFooterStyle data-testid="extendedFooter-comp" data-component-name="o-account-extendedFooter">
      <div className="extended-footer-container" id="extendedFooterContainer">
        <div className="extended-footer d-flex flex-column" data-testid="extended-footer">
          {data?.footerItems?.length && (
            <div>
              {data?.footerItems?.length !== 0 && isTabletAndAboveViewPort ? (
                <div data-testid="desktop-footer">
                  <ActivityFooter data={data} />
                </div>
              ) : (
                <div data-testid="mobile-footer">
                  <FooterMobile data={data} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ExtendedFooterStyle>
  );
};

export const ExtendedFooterConfig = {
  emptyLabel: 'ExtendedFooter',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/extendedFooter`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExtendedFooterEditable = (props: any) => {
  return props.cqPath.includes('datapagecomponent') ? (
    <ExtendedFooter {...props} />
  ) : (
    <EditableComponent config={ExtendedFooterConfig} {...props}>
      <ExtendedFooter {...props} />
    </EditableComponent>
  );
};

export default ExtendedFooterEditable;
