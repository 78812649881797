// Imports for external libraries go here.
import { FC, useContext, useEffect } from 'react';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
// The closer the import is to the file the lower it should be in this list.
import { SetPassword } from '../../molecules';
import { ChangePasswordProps } from './ChangePassword.types';
import { StyledChangePassword } from './ChangePassword.styles';
import { PageContext, correctSubDirectoryPathForHeader } from '../../modules';

// Use named rather than default exports.
export const ChangePassword: FC<ChangePasswordProps> = ({ model }) => {
  const pageContext = useContext(PageContext);
  const isResetTokenValid = pageContext?.isResetTokenValid ?? false;

  useEffect(() => {
    correctSubDirectoryPathForHeader();
  }, []);

  return (
    <StyledChangePassword
      className="container mb-5"
      data-testid="changepassword"
      data-component-name="o-account-changepassword"
    >
      <div className="row">
        <div className="col-12 col-lg-5 col-md-8">
          <SetPassword
            authorModelData={model}
            isChangePwrd={true}
            isFormDisabled={false}
            isResetTokenValid={isResetTokenValid}
          />
        </div>
      </div>
    </StyledChangePassword>
  );
};

export const ChangePasswordConfig = {
  emptyLabel: 'changepassword',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/changepassword`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChangePasswordEditable = (props: any) => {
  return (
    <EditableComponent config={ChangePasswordConfig} {...props}>
      <ChangePassword {...props} />
    </EditableComponent>
  );
};
