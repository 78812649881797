/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { inspect } from 'util';
import { useLazyQuery, useQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { PaginationPanel } from '@marriott/mi-ui-library-shop';
import { Messages, RichText } from '@marriott/mi-ui-library';
import {
  addClassToBlankTargetLinks,
  apiLogger,
  generateApolloClientHeaders,
  getCalednarLocaleKeys,
} from '../../modules/utils';
import { useStore } from '../../modules/store/memberLevelStore';

import { PageContext } from '../../modules/context/PageContext';
import { phoenixAccountGetOrdersByCustomerId } from '../../modules/graph';
import { phoenixAccountCancelledTripsBasicInfo } from '../../modules/graph';
import { CancelledTripsProps } from './CancelledTrips.types';
import { StyledCancelledTrips } from './CancelledTrips.styles';
import cancelledtripsmocks from './__mock__/cancelledtrips.json';
import { createRows } from './utils/CancelledTripsUtils';
import propertiesByIds from './__mock__/basicTripDetailsMock.json';

const ColumnTable = dynamic(() => import('../../molecules/ColumnTable').then(mod => mod.ColumnTable));
const NoCancelledTrips = dynamic(() => import('./NoCancelledTrips').then(mod => mod.NoCancelledTrips));

export const CancelledTrips = (props: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const [cancelledTripsDetails, setCancelledTripsDetails] = useState<CancelledTripsProps>();
  const isUpcomingTripSelected = useStore(state => state.isUpcomingTripSelected);
  const [basicTripDetails, setBasicTripDetails] = useState<Array<any> | null>();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, _setPageSize] = useState(10);
  const pageContext = useContext(PageContext);
  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const dataLoaded = useRef<boolean>(false);
  const [getCancelledTripsBasicInfo, { data: tripsBasicInfoData, error: tripsBasicInfoError }] = useLazyQuery(
    phoenixAccountCancelledTripsBasicInfo
  );

  getCalednarLocaleKeys(props.model); // to initialize moment with the locale date object

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    isAuthorMode ||
    dataLoaded.current ||
    isUpcomingTripSelected;

  useEffect(() => {
    if (isAuthorMode) {
      setCancelledTripsDetails(cancelledtripsmocks?.data);
      setBasicTripDetails(propertiesByIds?.propertiesByIds);
    }
  }, []);

  const {
    loading: _cancelledTripsLoading,
    error: cancelledTripsError,
    data: cancelledTripsData,
    refetch: cancelledTripsRefetch,
  } = useQuery(phoenixAccountGetOrdersByCustomerId, {
    variables: {
      customerId: sessionData?.consumerID,
      status: 'CANCELLED',
      limit: Number(pageSize),
      offset: Number((currentPage - 1) * pageSize),
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
  });

  useEffect(() => {
    if (cancelledTripsData) {
      setCancelledTripsDetails(cancelledTripsData);
      const cancelledTripIds: Array<string> = [];
      cancelledTripsData?.customer?.orders?.edges?.map((res: any) => {
        cancelledTripIds.push(res?.node?.items[0]?.property?.id);
      });
      apiLogger(
        `[CancelledTrips] getSavedPropertiesData - sessionId: ${sessionData?.sessionToken}: ${inspect(
          cancelledTripsData
        )}`
      );
      if (cancelledTripIds?.length > 0) {
        getCancelledTripsBasicInfo({
          variables: {
            ids: cancelledTripIds,
          },
          context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        });
      } else {
        dataLoaded.current = true;
        setBasicTripDetails([]);
      }
    }
  }, [cancelledTripsData]);

  useEffect(() => {
    if (tripsBasicInfoData) {
      dataLoaded.current = true;
      setBasicTripDetails(tripsBasicInfoData?.propertiesByIds);
      apiLogger(
        `[cancelledTrips] cancelledTripsData - sessionId value: ${sessionData?.sessionToken}: ${inspect(
          tripsBasicInfoData
        )}`
      );
    }
  }, [tripsBasicInfoData]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const newVariables = {
      customerId: sessionData?.consumerID,
      status: 'CANCELLED',
      limit: Number(pageSize),
      offset: Number((newPage - 1) * pageSize),
    };
    window?.scrollTo(0, 0);
    dataLoaded.current = false;
    setCancelledTripsDetails(undefined);
    setBasicTripDetails(undefined);
    cancelledTripsRefetch(newVariables);
  };

  const totalCancelledCount = cancelledTripsDetails?.customer?.orders?.total;
  const isCanceledTripsNotEmpty =
    cancelledTripsDetails?.customer?.orders?.edges && cancelledTripsDetails?.customer?.orders?.edges?.length > 0;

  // ************return statement******************

  if (cancelledTripsError) {
    apiLogger(
      `[CancelledTrips] getSavedPropertiesData - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
        cancelledTripsError
      )}`
    );
    return (
      <div>
        {!isUpcomingTripSelected && (
          <Messages messageType="warning" className="my-4">
            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
          </Messages>
        )}
      </div>
    );
  }

  if (tripsBasicInfoError) {
    apiLogger(
      `[cancelledTrips] cancelledTripsData - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
        tripsBasicInfoError
      )}`
    );

    return (
      <div>
        {!isUpcomingTripSelected && (
          <Messages messageType="warning" className="my-4">
            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
          </Messages>
        )}
      </div>
    );
  }
  return (
    <div data-testid="cancelledtrips" data-component-name="o-account-cancelledtrips">
      {!isUpcomingTripSelected ? (
        <div>
          {!basicTripDetails ? (
            <StyledCancelledTrips>
              <div className="container">
                <div className="row">
                  <div className="skeleton-loader col-lg-2 col-12 skeleton-style"></div>
                  <div className="skeleton-loader col-lg-4 col-12"></div>
                  <div className="skeleton-loader col-lg-3 col-12"></div>
                  <div className="skeleton-loader col-lg-3 col-12"></div>
                </div>
                <div className="skeleton-loader my-3 skeleton-style"></div>
                <div className="skeleton-loader my-3 skeleton-style"></div>
                <div className="skeleton-loader my-3 skeleton-style"></div>
                <div className="skeleton-loader my-3 skeleton-style"></div>
              </div>
            </StyledCancelledTrips>
          ) : (
            <StyledCancelledTrips>
              {isCanceledTripsNotEmpty ? (
                <div>
                  <ColumnTable
                    columns={[
                      props?.model?.firstColumnHeader,
                      props?.model?.secondColumnHeader,
                      props?.model?.thirdColumnHeader,
                      props?.model?.fourthColumnHeader,
                    ]}
                    rows={createRows(
                      cancelledTripsDetails,
                      basicTripDetails,
                      props?.model?.bookAgainButtonLabel,
                      props?.model?.confirmationNumberLabel,
                      props?.model?.cancelRoomCtaLabel
                    )}
                    columnClasses={[
                      'col-lg-2 col-12',
                      'col-lg-4 col-12 pt-3',
                      'col-lg-3 col-12 pt-3',
                      'col-lg-3 col-12',
                    ]}
                    showColumnHeader={true}
                  />
                  <PaginationPanel
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalCount={totalCancelledCount ?? 0}
                    classList={{ listParent: 'mb-0' }}
                    className="mt-5"
                  />
                  {props?.model?.footerTextOne && (
                    <div className="container pl-md-0 mt-4">
                      <RichText
                        customClass="container t-font-s text-center text-md-left text-lg-left"
                        text={addClassToBlankTargetLinks(props?.model?.footerTextOne)}
                        componentId={'footerTextOne'}
                      />
                    </div>
                  )}
                  {props?.model?.footerTextTwo && (
                    <div className="container pl-md-0 mt-lg-3 mt-md-4 mt-3">
                      <RichText
                        customClass="container t-font-s mb-5 text-center text-md-left text-lg-left"
                        text={addClassToBlankTargetLinks(props?.model?.footerTextTwo)}
                        componentId={'footerTextTwo'}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <NoCancelledTrips
                    {...props}
                    localeVal={pageContext?.currentLocale ? pageContext?.currentLocale : 'en-US'}
                  />
                </div>
              )}
            </StyledCancelledTrips>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export const CancelledTripsConfig = {
  emptyLabel: 'CancelledTrips',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelledtrips`,
};
export const CancelledTripsEditable = (props: any) => {
  return (
    <EditableComponent config={CancelledTripsConfig} {...props}>
      <CancelledTrips {...props} />
    </EditableComponent>
  );
};
