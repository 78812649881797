// Imports for external libraries go here.
import React, { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Image, Link, Icon } from '@marriott/mi-ui-library'; //import common component
import { SignInAppBannerProps } from './SignInAppBanner.types';
import { StyledSignInAppBannerDiv } from './SignInAppBanner.styles';

// Use named rather than default exports.
export const SignInAppBanner: FC<SignInAppBannerProps> = (props: SignInAppBannerProps) => {
  const pageProps = props.model;
  return (
    <StyledSignInAppBannerDiv
      data-component-name="o-account-signinappbanner"
      data-testid="signinappbanner"
      className="banner d-flex align-items-center justify-content-center mt-3 mt-lg-5"
    >
      <div className="banner__content-wrapper py-3 py-md-2 my-md-0 my-2 mx-sm-0 mx-3">
        <Link
          ariaLabel={pageProps?.contentDescription}
          custom_click_track_value="Marriott App sign in banner"
          linkHref={pageProps?.marriottAppCtaPath || ''}
          linkClassName="banner__link py-5 d-flex align-items-center justify-content-center"
        >
          <Image
            altText={pageProps?.imageAltText ?? pageProps?.featuredDynamicImage?.altText}
            defaultImageURL={pageProps?.image ?? pageProps?.featuredDynamicImage?.assetPath}
            renditions={pageProps?.featuredDynamicImage?.renditions}
            customClass="banner__content-img"
          />
          <div className="t-font-inverse-s mx-3 banner__content-text text-left">{pageProps?.contentDescription}</div>
          <Icon iconClass={`pl-1 icon-forward-arrow-cropped banner__content-icon`}></Icon>
        </Link>
      </div>
    </StyledSignInAppBannerDiv>
  );
};

export const SignInAppBannerConfig = {
  emptyLabel: 'SignInAppBanner',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/signinappbanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignInAppBannerEditable = (props: any) => {
  return (
    <EditableComponent config={SignInAppBannerConfig} {...props}>
      <SignInAppBanner {...props} />
    </EditableComponent>
  );
};
