/* eslint-disable jsx-a11y/label-has-associated-control */
// Imports for external libraries go here.
import { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
import { Modal } from '@marriott/mi-ui-library';

// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { FindReservation } from '../../molecules/FindReservation';
import { useStore } from '../../modules/store/memberLevelStore';
import { FindReservationOverlayProps } from './FindReservationOverlay.types';
import { StyledFindReservationOverlay } from './FindReservationOverlay.styles';

// Use named rather than default exports.
export const FindReservationOverlay: FC<FindReservationOverlayProps> = props => {
  const showFindReservationModal = useStore(state => state.showFindReservationModal);
  const setShowFindReservationModal = useStore(state => state.setShowFindReservationModal);

  return (
    <StyledFindReservationOverlay data-testid="findreservation" data-component-name="o-account-findreservation">
      <Modal
        className="modal-main-container"
        show={showFindReservationModal}
        setPopupOpenState={(): void => {
          setShowFindReservationModal(false);
        }}
        popupOpenState={showFindReservationModal}
        allFilterPopUp={true}
        secondaryClassName="modal-main-container__content"
        role="alertdialog"
        aria-modal="true"
        labelledBy="findReservationOverlay"
        modalId={'findReservationOverlay'}
      >
        <Modal.Header
          className="modal-header p-4 pt-md-5 px-md-5 pb-md-4"
          labelText={props?.model?.title ?? ''}
          popupHeaderOnCLoseFunc={() => setShowFindReservationModal(false)}
        />
        <Modal.Body className="p-0 modal-body">
          <FindReservation labels={props.model} onCancel={() => setShowFindReservationModal(false)} />
        </Modal.Body>
      </Modal>
    </StyledFindReservationOverlay>
  );
};

export const FindReservationOverlayConfig = {
  emptyLabel: 'findReservation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/findreservation`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FindReservationOverlayEditable = (props: any) => {
  return (
    <EditableComponent config={FindReservationOverlayConfig} {...props}>
      <FindReservationOverlay {...props} />
    </EditableComponent>
  );
};
