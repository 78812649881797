// Styles for MapView go here.
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const MapStyle = styled.div`
  height: 100%;
  width: 100%;
  .map-dimension {
    height: ${toRem(500)};
    width: ${toRem(800)};
  }
  .g-map-container {
    height: 100%;
    width: 100%;
  }
  .gm-control-active:focus {
    outline: ${toRem(5)} auto -webkit-focus-ring-color;
  }

  .gmnoprint {
    button[aria-label='Keyboard shortcuts'] {
      &:focus {
        outline: ${toRem(2)} solid ${baseVariables.color['neutral00']} !important;
        border-radius: ${toRem(1)} !important;
      }
    }
    div {
      border-radius: 40% !important;
    }
  }

  .gmnoprint {
    .gmnoprint {
      & > div {
        height: ${toRem(69)} !important;
        width: ${toRem(32)} !important;
        margin-left: ${toRem(8)} !important;
      }
      div {
        margin-top: ${toRem(4)} !important;
        margin-right: ${toRem(4)} !important;
      }
    }
  }

  .g-map-container {
    button.gm-control-active {
      border-radius: 50% !important;
      border: ${toRem(1)} solid ${baseVariables.color['neutral20']} !important;
      height: ${toRem(32)} !important;
      width: ${toRem(32)} !important;
      margin-top: ${toRem(2)} !important;
      background: ${baseVariables.color['merch50']} !important;
    }
  }

  div[data-control-height='81'] > div > div {
    height: 0 !important;
  }
`;
