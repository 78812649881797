/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useId, useState, memo, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';
import { inspect } from 'util';
import { usePageContext, PageContext, REMEMBER_ME_KEY, MFA_OPTIONS_KEY, useCheckBreakpoint } from '../../modules'; // import page context for session/env variable
import {
  clearSessionAndCookie,
  LAST_ATTEMPT_WARNING_KEY,
  LOCKOUT_ERROR_KEY,
  logger,
  signEmailRegex,
  signMobileRegex,
} from '../../modules/utils';
import { useAccountCommonStore } from '../../modules/store/accountCommonStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { capatilizeFirstLetter, encryptPassword } from '../../modules/utils/helper';
import {
  Button,
  Heading,
  InputTextField,
  Link,
  Types,
  CheckBox,
  Text,
  Icon,
  RichText,
  Messages,
  Accordion,
  eventUtil,
} from '@marriott/mi-ui-library'; //import common component
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  userLogin,
  handleRedirect,
  getUserRememberData,
  clearRememberUserDetails,
  correctSubDirectoryPathForHeader,
} from '../../modules';
import {
  addSubDirectoryPrefix,
  checkChinaLocale,
  checkUSLocale,
  checkKOLocale,
  getCurrentUrlParams,
} from '../../modules/utils/helper'; // import helper from utills
import {
  EAA_FCP_WARNING_MESSAGE_KEY,
  CHECKED_IN_MODAL_ID,
  constants,
  ENCRYPTED_DATA,
  ALREADY_COMBINED_ERROR_KEY,
} from '../../modules/utils/constants';
import { SignInComponentProps, SignInDataType } from './SignIn.types';
import { StyledSignInContainerDiv } from './SignIn.styles';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';

const { log } = logger({})('SearchResults/searchResults');

/** return memoized signin form component */
export const SignIn = memo<SignInComponentProps | any>((props: SignInComponentProps | any) => {
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const {
    ACCEPT_LANGUAGE: currentLocale,
    HOMEPAGE_REDIRECT_URL: homePageRedirectUrl,
    IS_PASSWORD_ENCRYPT: isEncryptionEnable,
    SKIP_DECODE_URL_FOR_PATH: skipDecodeForPaths,
  } = clientEnvVars;
  const router = useRouter();
  const overlay = router && router.query['overlay'];
  const isOverlay = overlay === 'true';
  const CustomClickTrackValue = `Login Form`;
  const {
    updatePageErrors,
    resLookupFromHeader,
    mockTestFlag,
    isReservation,
    eventReturnUrl,
    isInsideAccountContainer,
    isAccountModal,
    isCheckedIn,
  } = props;
  const pageContext = useContext(PageContext);
  const [isError, setIsError] = useState(false);
  const isDesktopViewPort = useCheckBreakpoint('viewportL');
  const checkBoxId = useId(); // create uuid for
  const [rememberMe, setRememberMe] = useState(true); // remeber me checkbox
  const [showPassword, setShowPassword] = useState(false); // password toogle
  const { sessionData, sessionTimedOutPage, isMobileAuthFeatureEnabled, isEAASignIn } = usePageContext();
  const [formData, setFormData] = useState<SignInDataType>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<SignInDataType>({});
  const [wasRemeberMeFlag, setWasRemeberMe] = useState<boolean>(false);
  const [toggleAccoridon, setToggleAccordion] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const { rememberedUser, setRememberedUser, rememberDetails, setRememberDetails, randData } = useAccountCommonStore(
    state => state
  );
  const { interPageErrorMsg, setInterPageErrorMsg, setBannerMsgs, setBannerId } = useBannerMessagesStore(
    state => state
  );
  const { setMemberDetailsUxl } = useAccountPersistentStore(state => state);
  const inputId = useId();
  const isChinaLocale = checkChinaLocale(currentLocale);
  const isUSLocale = checkUSLocale(currentLocale);
  const isKOLocale = checkKOLocale(currentLocale);
  const [toggleErrormsgAccoridon, setToggleErrormsgAccordion] = useState<boolean>(false);
  const [forgotPasswordLink, setForgotPasswordLink] = useState<string>('');
  const [isFcpError, setIsFcpError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { auditWarningMessage, lockoutWarningMessage, lockedWarningMessage, alreadyCombinedError } = router.query;
  /** toggle the password view to text view */
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const HOMEPAGE_REDIRECT_URL = homePageRedirectUrl;
  const enableEncryption = isEncryptionEnable;
  const publicKey = pageContext?.rsaPublicKey;

  const isSignInFormValid = (): boolean => {
    let newErrors = {};
    /**validate signing form field */
    if (!isOverlay) {
      if (
        (formData.email === '' || formData.email === undefined) &&
        (formData.pwrd === '' || formData.pwrd === undefined)
      ) {
        newErrors = {
          email: checkForCountry() ? props?.credentialsErrorMessage : props?.emailErrorLabel,
          pwrd: props?.pwrdErrorLabel,
        };
        // if user doesn't have email val
        setFormErrors(newErrors);
        return false;
      }
      if (formData.email === '' || formData.email === undefined) {
        newErrors = {
          email: checkForCountry() ? props?.credentialsErrorMessage : props?.emailErrorLabel,
        };
        // if user doesn't have email val
        setFormErrors(newErrors);
        return false;
      } else if (!wasRemeberMeFlag) {
        const isValidMobile = checkForCountry() && signMobileRegex.test(formData?.email);
        if (!isValidMobile && !signEmailRegex.test(formData?.email)) {
          updatePageErrors({
            text: props?.warningMessage,
            class: 'error-sev1',
            type: 'alert',
          });
          return false;
        }
      }
      if (formData.pwrd === '' || formData.pwrd === undefined) {
        newErrors = {
          pwrd: props?.pwrdErrorLabel,
        };
        // if user doesn't have passsword val
        setFormErrors(newErrors);
        return false;
      }
    }
    setFormErrors({
      pwrd: undefined,
      email: undefined,
    });
    setIsFcpError(false);
    return true;
  };

  const checkForCountry = () => {
    return isMobileAuthFeatureEnabled || isChinaLocale || mockTestFlag;
  };

  const displaySigninError = (key: string[] = []) => {
    /**display error post submit */
    setErrorsList([]);

    if (updatePageErrors && !isEAASignIn && !isAccountModal) {
      setFormErrors({
        email: props?.inlineErrorMessage,
        pwrd: '',
      });
      let warningText = props[key?.[0]] ?? props?.warningMessage;
      if (checkForCountry()) warningText = props?.credentialsWarningMessage;
      updatePageErrors({
        // page level error - to add logic at component level for account modal
        text: warningText,
        class: 'error-sev1',
        type: 'alert',
      });
      setIsError(true);
    } else if (isEAASignIn) {
      if (key?.includes(EAA_FCP_WARNING_MESSAGE_KEY)) {
        setIsFcpError(true);
        setFormErrors({
          email: '',
          pwrd: '',
        });
        setErrorsList([props?.eaaFcpWarningMessage]);
      } else {
        setFormErrors({
          email: checkForCountry() ? props?.credentialsErrorMessage : props?.emailErrorLabel,
          pwrd: props?.pwrdErrorLabel,
        });

        setErrorsList([props?.credentialsWarningMessage]);
      }
      setIsError(true);
    } else if (isAccountModal) {
      setErrorsList([props?.[key?.[0]]]);
      setFormErrors({
        email: '',
        pwrd: '',
      });
      setIsError(true);
    }
  };

  const resetFormFields = () => {
    setFormData({ ...formData, pwrd: '', email: wasRemeberMeFlag ? formData.email : '' });
  };

  // TODO: signin return to url needs to be handled, it will be used if available form event
  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault(); // prevent loading of the page on submit
    setIsLoading(true);
    const urlParams = getCurrentUrlParams(isOverlay);
    if (isSignInFormValid()) {
      /** if form is valid */
      try {
        const sessionToken =
          sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToken;
        log.debug(`[User Login] submit Funtion called sessionID: ${sessionToken}`);
        const payload = wasRemeberMeFlag
          ? {
              pwrd:
                formData?.pwrd &&
                encryptPassword(formData?.pwrd, publicKey || rememberDetails?.pwdPublicKey, enableEncryption),
              email: '',
            }
          : {
              ...formData,
              pwrd:
                formData.pwrd &&
                encryptPassword(formData?.pwrd, publicKey || rememberDetails?.pwdPublicKey, enableEncryption),
            };
        const { data, status } = await userLogin(
          sessionToken,
          payload,
          rememberMe,
          isEAASignIn,
          isOverlay,
          currentLocale,
          eventReturnUrl,
          skipDecodeForPaths,
          randData
        );

        if (data?.customerDetails) {
          clearSessionAndCookie();
          setMemberDetailsUxl?.(data?.customerDetails);
        }

        if (data?.mfaOption && (data?.mfaOption?.emailAddress || data?.mfaOption?.phoneNumbers?.length > 0)) {
          sessionStorage.setItem(MFA_OPTIONS_KEY, JSON.stringify(data?.mfaOption));
        }
        if (randData?.orderId) {
          if (data?.isRedirect && !data?.mfaOption) {
            setBannerMsgs(constants?.RESERVATION_LINKED);
          } else {
            setBannerId(CHECKED_IN_MODAL_ID);
            sessionStorage.setItem(ENCRYPTED_DATA, data?.encodePayload);
          }
        }
        setIsError(false);
        // get the data from the user login api
        log.debug(
          `[User Login] success sessionID: ${
            sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToken
          }, ${inspect(data)}`
        );
        if (status === 200 && props?.successEvent) {
          eventUtil.dispatch(props?.successEvent, { data });
          return;
        }
        if (data?.isRedirect) {
          /** redrict user to next response url */
          handleRedirect(addSubDirectoryPrefix(data?.nextStateURI), isOverlay, !data?.mfaOption);
        }
      } catch (error: any) {
        log.debug(
          `[User Login] error sessionID: ${
            sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToken
          }, ${inspect(error)}`
        );
        // hardcoded check because, for sign in error we can get other error message as well when it is not mfa or fcp flow, and sign in happens but reservation is not linked
        if (randData?.orderId) {
          setBannerMsgs(error?.response?.data?.phoenixErrorMessages?.errorMessages?.[0]);
        }
        if (error?.response?.data?.nextStateURI && error?.response?.data.isRedirect) {
          // redirect to appropriate url from backend
          handleRedirect(addSubDirectoryPrefix(error?.response?.data?.nextStateURI), isOverlay, !isOverlay);
        }
        if (isOverlay) {
          const param = new URLSearchParams(urlParams);
          let returnUrl = param.get('returnTo')
            ? `?returnTo=${param.get('returnTo')}`
            : `?returnTo=${
                window.parent.location.pathname === '/sign-in.mi' ||
                window.parent.location.pathname === '/logout.mi' ||
                window.parent.location.pathname === '/sessionTimedOut.mi'
                  ? encodeURIComponent(addSubDirectoryPrefix(HOMEPAGE_REDIRECT_URL))
                  : encodeURIComponent(
                      addSubDirectoryPrefix(
                        param ? `${window.parent.location.pathname}?${param}` : window.parent.location.pathname
                      )
                    )
              }`;
          const clientId = param.get('clientId') ?? '';
          if (error?.response?.data?.phoenixErrorMessages?.errorMessages?.length) {
            const errMessageType = error?.response?.data?.phoenixErrorMessages?.errorMessages[0];
            returnUrl = `${returnUrl}&${errMessageType}=true`;
          }
          handleRedirect(
            addSubDirectoryPrefix(`/sign-in-error.mi${returnUrl}${clientId && `&clientId=${clientId}`}`),
            isOverlay,
            !isOverlay
          );
        } else {
          // display error on component
          displaySigninError(error?.response?.data?.phoenixErrorMessages?.errorMessages);
          resetFormFields();
          setIsLoading(false);
        }
      }
    } else {
      if (isOverlay) {
        const param = new URLSearchParams(urlParams);
        const returnUrl = param.get('returnTo')
          ? `?returnTo=${param.get('returnTo')}`
          : `?returnTo=${
              window.parent.location.pathname === '/sign-in.mi' ||
              window.parent.location.pathname === '/logout.mi' ||
              window.parent.location.pathname === '/sessionTimedOut.mi'
                ? encodeURIComponent(addSubDirectoryPrefix(HOMEPAGE_REDIRECT_URL))
                : encodeURIComponent(
                    addSubDirectoryPrefix(
                      param ? `${window.parent.location.pathname}?${param}` : window.parent.location.pathname
                    )
                  )
            }`;
        const clientId = param.get('clientId') ?? '';
        handleRedirect(
          addSubDirectoryPrefix(`/sign-in-error.mi${returnUrl}${clientId && `&clientId=${clientId}`}`),
          isOverlay,
          !isOverlay
        );
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleClearRememberUserClick = async () => {
    const sessionToken =
      sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToke;
    try {
      log.debug(`[User Remember] function called sessionID: ${sessionToken}`);
      const response = await clearRememberUserDetails(sessionToken, isEAASignIn, isOverlay);
      if (response) {
        /**if 200 then remove remember flag */
        setWasRemeberMe(false);
        setRememberedUser('');
        setFormData({ email: '' });
        isOverlay ? window.parent.location.reload() : window.location.reload();
      }
    } catch (error) {
      log.debug(`[User Remember] error sessionID: ${sessionToken}, ${inspect(error)}`);
    }
  };

  const getUserRemeberDetails = useCallback(async () => {
    try {
      const sessionToken =
        sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToken;
      log.debug(`[User Remember] function called sessionID: ${sessionToken}`);

      const response = await getUserRememberData(sessionToken, isEAASignIn);

      if (response) {
        const { data } = response;
        setFormData({
          ...formData,
          email: data?.memberNumber,
        });
        const name = capatilizeFirstLetter(data?.headerSubtext?.consumerName?.toLowerCase() || '');
        setRememberedUser(name);
        setRememberDetails(data);
        setWasRemeberMe(data?.rememberedUser);
      }
    } catch (e) {
      log.debug(`[User Remember] error sessionID: ${sessionData?.sessionToken}, ${inspect(e)}`);
    } finally {
      setDisableButton(false);
    }
  }, []);

  const handleKeyPress = (ev: any) => {
    /**
     * handle key press event on input field that will submit the
     * form
     */
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      handleSubmit(ev);
    }
  };

  useEffect(() => {
    if (interPageErrorMsg) {
      updatePageErrors({
        text: props?.[interPageErrorMsg],
        class: 'error-sev1',
        type: 'alert',
      });
      setInterPageErrorMsg('');
    }
    getUserRemeberDetails();
    sessionStorage.setItem(REMEMBER_ME_KEY, JSON.stringify(rememberMe));

    if (isEAASignIn && props?.forgotPwrdCtaPath) {
      const updatedLink = props.forgotPwrdCtaPath + getCurrentUrlParams();
      setForgotPasswordLink(updatedLink);
    } else {
      setForgotPasswordLink(props?.forgotPwrdCtaPath);
    }

    if (window.location.pathname?.includes('/sign-in-error') || mockTestFlag) {
      if (lockoutWarningMessage && lockoutWarningMessage === 'true') {
        displaySigninError(['lockoutWarningMessage']); // to show errors on the sign-in-error page
      } else if (auditWarningMessage && auditWarningMessage === 'true') {
        displaySigninError(['auditWarningMessage']); // to show errors on the sign-in-error page
      } else if (lockedWarningMessage && lockedWarningMessage === 'true') {
        displaySigninError(['auditWarningMessage']); // to show errors on the sign-in-error page
      } else if (alreadyCombinedError && alreadyCombinedError === 'true') {
        displaySigninError(['alreadyCombinedError']); // to show errors on the sign-in-error page
      } else if (sessionData?.cacheData?.data?.AriesAuth?.errorMessages?.apiErrors) {
        const apiError = sessionData?.cacheData?.data?.AriesAuth?.errorMessages?.apiErrors;
        if (apiError[LAST_ATTEMPT_WARNING_KEY]) {
          displaySigninError(['lockoutWarningMessage']);
        } else if (apiError[LOCKOUT_ERROR_KEY]) {
          displaySigninError(['auditWarningMessage']);
        } else if (apiError[ALREADY_COMBINED_ERROR_KEY]) {
          displaySigninError(['alreadyCombinedError']);
        } else {
          displaySigninError();
        }
      } else {
        displaySigninError(); // to show errors on the sign-in-error page
      }
    }
    correctSubDirectoryPathForHeader();
  }, []);

  useEffect(() => {
    /*When error messages are added to the container, the iframe height will be adjusted accordingly. */
    if (isOverlay && isError) setIframeHeight();
  }, [isError]);

  /*setIframeHeight function will updates the height of Iframe */
  const setIframeHeight = () => {
    const iframe: any = window?.parent?.document?.getElementById(
      resLookupFromHeader ? 'resLookupIframe' : 'signInOverlayIframe'
    );
    const iframeContent = iframe?.contentDocument?.scrollingElement?.scrollHeight;
    const paddingAround = 10 * 2;
    if (iframe && iframeContent) {
      iframe.style.height = `${iframeContent + paddingAround}px`;
    }
  };
  return (
    <StyledSignInContainerDiv
      data-component-name="m-account-SignIn"
      data-testid="account-SignIn"
      className={clsx(
        'px-md-0 res-lookup-overlay-book',
        isEAASignIn ? 'mx-auto mr-lg-auto' : !isReservation ? 'mr-lg-auto' : '',
        resLookupFromHeader && 'reslookup-container',
        isReservation ? 'd-flex flex-column justify-content-lg-end px-0' : 'px-1',
        isAccountModal && isReservation
          ? 'ml-0 ml-lg-auto mr-0'
          : isReservation
          ? 'ml-3 ml-md-0 ml-lg-auto mr-3 mr-md-0'
          : '',
        !isInsideAccountContainer && 'mt-5 px-3'
      )}
      isReservation={isReservation}
    >
      {!sessionTimedOutPage ? (
        <div
          className={clsx(
            (isEAASignIn || isAccountModal) && errorsList?.length ? 'mb-3' : 'mb-4 pb-1',
            (resLookupFromHeader || isReservation) && !isCheckedIn && 'mt-4 mt-md-0 mt-lg-2'
          )}
        >
          {props?.oneClickJoinReservationTitle && (
            <Heading
              variation={Types.headingType.title}
              fontSize={Types.size.small}
              element={Types.tags.h3}
              titleText={props?.oneClickJoinReservationTitle}
            />
          )}

          {resLookupFromHeader || isReservation ? (
            isCheckedIn ? (
              <>
                <Heading
                  variation={Types.headingType.subtitle}
                  fontSize={isDesktopViewPort ? Types.size.extraLarge : Types.size.large}
                  element={Types.tags.h3}
                  titleText={props?.signIntoYourAccountTitle}
                  disableCustomClickTrack={true}
                  customClass="mt-5 mt-lg-0 mb-4"
                />
                <Heading
                  variation={Types.headingType.subtitle}
                  fontSize={Types.size.medium}
                  element={Types.tags.h3}
                  titleText={props?.subTitle}
                  disableCustomClickTrack={true}
                  customClass="mb-0"
                />
              </>
            ) : (
              <Heading
                variation={Types.headingType.subtitle}
                fontSize={Types.size.extraLarge}
                element={Types.tags.h3}
                titleText={props?.signIntoYourAccountTitle ? props?.signIntoYourAccountTitle : props?.subTitle}
                disableCustomClickTrack={true}
              />
            )
          ) : (
            <Heading
              variation={isEAASignIn ? Types.headingType.title : Types.headingType.subtitle}
              fontSize={isEAASignIn ? Types.size.small : Types.size.medium}
              /** title is for your account title, subtitle for sign in */
              titleText={wasRemeberMeFlag || isEAASignIn ? props?.signIntoYourAccountTitle : props?.subTitle}
              element={isEAASignIn ? Types.tags.h2 : Types.tags.paragraph}
              customClass={clsx(wasRemeberMeFlag && !isEAASignIn ? 'mb-2' : 'mb-0')}
            />
          )}
          {wasRemeberMeFlag && !isEAASignIn && (
            <Text
              copyText={`${props?.rememberedUserTextLabel?.replace('{0}', rememberedUser || '')}`}
              fontSize={Types.size.small}
              element={Types.tags.paragraph}
              customClass="remember-me__text mb-0"
            />
          )}
        </div>
      ) : (
        <div className="mb-4">
          <Heading
            variation={Types.headingType.subtitle}
            titleText={props?.sectionTitle}
            customClass="t-subtitle-m mb-2"
            element={Types.tags.paragraph}
          />
          <Text
            copyText={`${rememberedUser ? `${rememberedUser}${isKOLocale ? '' : ','} ` : ''}${
              props?.sectionDescription
            }`}
            fontSize={Types.size.small}
            element={Types.tags.paragraph}
            customClass="t-font-s mb-0 remember-me__text"
          />
        </div>
      )}
      {(isEAASignIn || isAccountModal) && !!errorsList?.length && (
        <div className="mb-4">
          <Messages messageType="error-sev1" className="account-page-error-msg px-2">
            {!!errorsList?.length && (
              <RichText customClass={''} text={errorsList[0] ?? ''} componentId="signin-error-msg-title" />
            )}
          </Messages>
        </div>
      )}
      <form method="post" onSubmit={handleSubmit}>
        <div
          className={clsx(
            'form-field-contaioner',
            formErrors.email !== undefined && formErrors.email !== '' ? 'mb-4' : 'mb-5'
          )}
        >
          <InputTextField
            label={checkForCountry() ? props?.credentialsLabel : props?.emailOrMemberLabel}
            inputValue={formData.email}
            testId="email-text-field"
            showErrorMessage={formErrors.email !== undefined || isFcpError}
            messageToShow={isEAASignIn || !checkForCountry() ? formErrors?.email : ''}
            setErrorHtml={true}
            messageClass="error-label t-font-xs m-0"
            {...(!isEAASignIn && {
              custom_click_track_value: `${CustomClickTrackValue} | ${props?.emailOrMemberLabel}`,
            })}
            inputMaxLength={70}
            infoLabel={isCheckedIn ? `${inputId}-email` : props?.emailOrMemberLabel?.toLowerCase() || ''}
            getInputProps={() => ({
              /** setReadOnly props for remeber case */
              disabled: disableButton,
              readOnly: wasRemeberMeFlag,
              autoComplete: 'off',
              onKeyUp: handleKeyPress,
              id: isCheckedIn ? `${inputId}-email-checkedin` : `${inputId}-email`,
            })}
            getLabelProps={() => ({
              htmlFor: `${inputId}-email`,
            })}
            className={clsx(
              'm-input-field',
              formData.email !== undefined && formData.email !== '' && formErrors.email === undefined
                ? 'is-active'
                : '',
              wasRemeberMeFlag || disableButton ? 'disabled' : ''
            )} //is-error,is-active
            getInputValue={(val: string) => {
              if (!wasRemeberMeFlag) {
                setFormData({ ...formData, email: val?.trim() });
              }
            }}
          />
          {!isEAASignIn && formErrors?.email !== undefined && checkForCountry() ? (
            <div
              className="accordion accordion-container m-accordion-container ps-0 mt-1"
              id="accordionExample-errormsg"
            >
              {/* TODO:// need confirmation on accordion atoms in mi-ui-lib */}
              <div className="accordion-item">
                <div className="p-0 accordion-header px-0 mb-1" id="headingOne-errormsg">
                  <button
                    className="accordion-button collapsed p-0 ms-0 border-0"
                    type="button"
                    id="accordian_button_errormsg"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne-errormsg"
                    aria-expanded={toggleErrormsgAccoridon}
                    aria-controls="collapseOne-errormsg"
                    onClick={() => setToggleErrormsgAccordion(!toggleErrormsgAccoridon)}
                  >
                    <div className="m-accordion-title d-flex align-items-center justify-content-center">
                      <Text
                        copyText={props?.phoneNumberVerificationMessage}
                        fontSize={Types.size.extraSmall}
                        customClass={'t-font-xs mb-0 p-0 error-label'}
                        element={Types.tags.paragraph}
                      />
                      <Icon
                        iconClass={clsx(
                          `icon-arrow-down accordion-arrow-icon pl-2 icon-xs error-label ${
                            toggleErrormsgAccoridon ? 'icon-arrow-up' : 'icon-arrow-down'
                          }`
                        )}
                      ></Icon>
                    </div>
                  </button>
                </div>
                <div
                  id="collapseOne-errormsg"
                  className={clsx('accordion-collapse collapse', toggleErrormsgAccoridon ? 'show' : '')}
                  aria-labelledby="headingOne-errormsg"
                  data-bs-parent="#accordionExample-errormsg"
                >
                  <div
                    className="accordion-body t-font-xs error-label"
                    dangerouslySetInnerHTML={{ __html: props?.membershipVerificationMessage ?? '' }}
                  ></div>
                </div>
              </div>
              <RichText
                customClass={clsx(' t-font-xs error-label', toggleErrormsgAccoridon ? 'mt-3' : 'mt-1')}
                text={props?.membershipNumberWarningMessage ?? ''}
                componentId="signin-error-msg"
              />
            </div>
          ) : (
            ''
          )}
          {isEAASignIn && isFcpError && (
            <>
              <Accordion
                id="eaa-fcp-error"
                headerChildren={
                  <Text
                    element={Types.tags.span}
                    fontSize={Types.size.medium}
                    copyText={props?.eaaFcpInlineErrorHeaderMessage}
                    customClass="t-font-xs error-label"
                  />
                }
                customClass="eaa-fcp-error error-label"
              >
                <Text
                  element={Types.tags.span}
                  fontSize={Types.size.medium}
                  copyText={props?.eaaFcpInlineErrorBodyMessage}
                  customClass="t-font-xs error-label"
                />
              </Accordion>
              <Text
                element={Types.tags.span}
                fontSize={Types.size.medium}
                copyText={props?.eaaFcpSecondInlineErrorMessage}
                customClass="t-font-xs error-label"
              />
            </>
          )}
        </div>
        <div className="form-field-contaioner mb-md-5 mb-4">
          <InputTextField
            type={showPassword ? 'text' : 'password'}
            label={props?.pwrdLabel}
            testId="sign-in-pwrd"
            inputValue={formData.pwrd}
            {...(!isEAASignIn && {
              custom_click_track_value: `${CustomClickTrackValue} | ${props?.emailOrMemberLabel}`,
            })}
            inputMaxLength={20}
            getInputProps={() => ({
              disabled: disableButton,
              autoComplete: 'off',
              onKeyUp: handleKeyPress,
              id: `${inputId}-password`,
            })}
            // iconAriaLabel={`toggle ${props?.passwordLabel}`}
            className={clsx(
              'm-input-field',
              formData.pwrd !== undefined && formData.pwrd !== '' && formErrors.pwrd === undefined ? 'is-active' : '',
              disableButton && 'disabled'
            )} //is-error
            showIcon={true}
            messageClass="error-label t-font-xs"
            iconClass={clsx(showPassword ? 'icon-visibility' : 'icon-visibility-off')}
            showErrorMessage={formErrors.pwrd !== undefined}
            messageToShow={formErrors?.pwrd}
            iconOnClick={toggleShowPassword}
            infoLabel={props?.pwrdLabel?.toLowerCase() || ''}
            trailingIconProps={{
              role: 'button',
              'aria-pressed': showPassword,
              'aria-label': `toggle ${props?.pwrdLabel}`,
            }}
            getLabelProps={() => ({
              htmlFor: `${inputId}-password`,
            })}
            getInputValue={(val: string) => {
              setFormData({ ...formData, pwrd: val?.trim() });
            }}
          />
        </div>

        {!isEAASignIn && (
          <div className="form-field-contaioner mb-md-5 mb-4">
            <CheckBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                // handle rember me from session or other value
                setRememberMe(e.target.checked);
                sessionStorage.setItem(REMEMBER_ME_KEY, `${e.target.checked}`);
              }}
              checked={rememberMe}
              data-testid="remember-me"
              checkboxName="remember_me"
              checkboxLabel={props?.rememberMeLabel}
              checkboxId={checkBoxId}
              disabled={disableButton}
              inputClassName={disableButton ? 'disabled' : ''}
            />
          </div>
        )}
        <div className="form-field-contaioner mb-4">
          <Button
            type={Types.ButtonTypeVariation.Submit}
            testId="sign-in-btn-submit"
            isDisabled={disableButton || isLoading}
            className={clsx(
              'signin-btn m-button-m width-mobile-100 d-flex-sm justify-content-center align-items-center py-3 py-sm-2',
              disableButton ? 'disabled' : ''
            )}
            {...(!isEAASignIn && {
              custom_click_track_value: `${CustomClickTrackValue}|${props?.signInLabel}|Submit`,
            })}
          >
            {isLoading ? <div className="m-spinner-s" data-testid="loading-spinner"></div> : props?.signInLabel}
          </Button>
        </div>
      </form>
      <div
        className={clsx(
          'link-container',
          isReservation ? `${wasRemeberMeFlag ? 'd-flex' : 'd-flex d-md-block'} mb-5 mb-lg-0` : ''
        )}
      >
        <div className="link-container__item pb-1">
          <Link
            text={props.forgotPwrdLabel}
            linkClassName="m-link-action"
            linkHref={forgotPasswordLink}
            target={isOverlay ? '_parent' : '_self'}
            linkType="internal"
          />
        </div>
        {!isEAASignIn && !props?.oneClickJoinReservationTitle && (
          <div
            className={clsx('link-container__item', isReservation ? (wasRemeberMeFlag ? 'ml-5' : 'ml-md-0 ml-5') : '')}
          >
            {wasRemeberMeFlag ? (
              <Button
                buttonCopy={props?.clearRememberedAccountLabel}
                className="m-link-action clear-remember-me p-0"
                linkType="internal"
                id="remember_me"
                callback={handleClearRememberUserClick}
              />
            ) : (
              !isCheckedIn && (
                <Link
                  text={props?.activateOnlineAccountLabel}
                  linkClassName="m-link-action"
                  target={isOverlay ? '_parent' : '_self'}
                  linkType="internal"
                  id="remember_me"
                  linkHref={props.activateOnlineAccountCtaPath}
                />
              )
            )}
          </div>
        )}
      </div>

      {isUSLocale && !isEAASignIn && !isReservation && props.accordionDescription && (
        <div className="accordion accordion-container m-accordion-container pt-3 ps-0" id="accordionExample-signin">
          {/* TODO:// need confirmation on accordion atoms in mi-ui-lib */}
          <div className="accordion-item">
            <div className="p-0 accordion-header px-0 mb-2" id="headingOne-signin">
              <button
                className="accordion-button collapsed p-0 ms-0 border-0"
                type="button"
                id="accordian_button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne-signin"
                aria-expanded={toggleAccoridon}
                aria-controls="collapseOne-signin"
                onClick={() => setToggleAccordion(!toggleAccoridon)}
              >
                <div className="m-accordion-title d-flex align-items-center">
                  <p className="t-label-alt-s mb-0"> {props.accordionLabel}</p>
                  <span
                    className={clsx(
                      'icon-arrow-down accordion-arrow-icon pl-2 icon-xs',
                      toggleAccoridon ? 'icon-arrow-up' : 'icon-arrow-down'
                    )}
                  ></span>
                </div>
              </button>
            </div>
            <div
              id="collapseOne-signin"
              className={clsx('accordion-collapse collapse', toggleAccoridon ? 'show' : '')}
              aria-labelledby="headingOne-signin"
              data-bs-parent="#accordionExample-signin"
            >
              <div
                className="accordion-body t-font-s"
                dangerouslySetInnerHTML={{ __html: props?.accordionDescription ?? '' }}
              ></div>
            </div>
          </div>
        </div>
      )}
      {isEAASignIn && (
        <Text
          customClass="t-font-xs pt-2 mt-5"
          copyText={props?.copyrightStatement?.replace('{0}', String(moment().year()))}
          fontSize={Types.size.extraSmall}
          element={Types.tags.paragraph}
        />
      )}
    </StyledSignInContainerDiv>
  );
});
export const SignInConfig = {
  emptyLabel: 'signin',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/signin`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignInEditable = (props: any) => {
  return props.cqPath?.includes('datapagecomponent') ? (
    <SignIn {...props} />
  ) : (
    <EditableComponent config={SignInConfig} {...props}>
      <SignIn {...props} />
    </EditableComponent>
  );
};
