// Imports for external libraries go here.
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';
import { inspect } from 'util';
import { useRouter } from 'next/router';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Button, Heading, Link, Messages, RichText, Types } from '@marriott/mi-ui-library';
import { nuaUpgradeRequest } from '../../modules/utils/nuaHelper';
import { phoenixAccountGetAwardsRequests } from '../../modules/graph';
import { PageContext } from '../../modules/context/PageContext';
import { UpgradeRequestCard } from '../../molecules/UpgradeRequestCard';
import {
  addSubDirectoryPrefix,
  apiLogger,
  generateApolloClientHeaders,
  handleRedirect,
  modalScrollHandler,
  scrollToClass,
} from '../../modules/utils';
import {
  BANNER_MSGS_KEYS,
  CHANGE_CONFIRMATION_ENDPOINT,
  MODIFY_REQUEST_CLICK_TRACK,
  NUA_UPGRADE_REQUEST,
  UPGRADE_REQUEST_CLICK_TRACK,
  imageDomain,
} from '../../modules/utils/constants';
import { useNuaStore } from '../../modules/store/nuaStore';
import { VistanaVacationPolicy } from '../../molecules/VistanaVacationPolicy';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { UpdateRoomSelectionProps, Edge, RoomType, RoomData } from './UpdateRoomSelection.types';
import { StyledUpdateRoomSelection } from './UpdateRoomSelection.styles';

// Use named rather than default exports.
export const UpdateRoomSelection = (props: UpdateRoomSelectionProps) => {
  const {
    roomLabel,
    roomSelectionLabel,
    updateCTA,
    upgradeOptionsLabel,
    submitCTA,
    backButton,
    roomDetailsCta,
    reqCheckbox,
    backToOriginalReservationCTAPath,
  } = props;
  const dataLoaded = useRef<boolean>(false);
  const isAuthorMode = props?.isAuthorMode;
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { tripInfo, setTripInfo } = useNuaStore(state => state);
  const [hasError, setHasError] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>('');
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const urlParam = router && router.query['nuaUpgrade'];
  const nuaUpgrade = urlParam === 'true' ? true : false;
  const tripID = router && router.query['tripId'];
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const tcRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [getCustomerFeaturePreferences] = useLazyQuery(phoenixAccountGetAwardsRequests);

  useEffect(() => {
    if (!isAuthorMode) {
      fetchRoomDetails();
    }
  }, []);

  const roomDetails = useMemo(() => {
    return tripInfo?.awardRoomTypes?.edges?.[0]?.node?.roomTypes;
  }, [tripInfo]);

  const selectedRooms = useMemo(() => {
    return tripInfo?.awardRequests?.[0]?.roomTypes;
  }, [tripInfo]);

  useEffect(() => {
    if (selectedRooms) {
      const ids = selectedRooms?.map((type: RoomType) => type.roomInformation?.id?.split('.')?.[1]?.toLowerCase());
      setCheckedItems(ids);
    }
  }, [selectedRooms]);

  useEffect(() => {
    const cancelUpgradeContainer = tcRef.current;
    if (cancelUpgradeContainer) {
      const anchorTag = cancelUpgradeContainer.querySelector('a[target="_parent"]') as HTMLAnchorElement;
      anchorTag && anchorTag.addEventListener('click', handleLinkClick);
    }
  }, []);

  useEffect(() => {
    if (hasError) {
      scrollToClass();
    }
  }, [hasError]);

  const fetchRoomDetails = () => {
    getCustomerFeaturePreferences({
      variables: {
        orderId: tripID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: (data: RoomData) => {
        dataLoaded.current = true;
        setTripInfo(data?.order?.items[0]);
        setHasError(false);
        apiLogger(
          `[UpdateRoomSelections] getFoodBevPreferencesData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError(true);
      },
    });
  };

  if (hasError) {
    return (
      <div id={'two-step-verification'} data-testId="uxl-error-msg" className="uxl-error-msg">
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }

  const modalOpenHandler = (event: boolean) => {
    // vistana modal handler
    setIsOpen(event);
    if (event === true) {
      modalScrollHandler(true);
    } else {
      modalScrollHandler(false);
    }
  };

  const handleLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const linkHref = (event.currentTarget as HTMLAnchorElement)?.getAttribute('href');
    if (linkHref) {
      setUrl(linkHref);
      setIsOpen(true);
    }
  };

  const handleCheckboxChange = (itemName: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedItems(prevItems => [...prevItems, itemName]);
    } else {
      setCheckedItems(prevItems => prevItems?.filter(item => item !== itemName));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const reqBody = {
      itemId: tripInfo?.basicInformation?.Id?.split('.')?.[0] ?? '',
      tripId: tripID,
      numberOfRooms: tripInfo?.awardRoomTypes?.edges[0]?.node?.roomTypes?.length,
      checkInDate: tripInfo?.basicInformation?.startDate,
      checkOutDate: tripInfo?.basicInformation?.endDate,
      locale: tripInfo?.basicInformation?.bookingLanguage,
      isTripModifiable: tripInfo?.basicInformation?.isModifiable,
      propertyId: tripInfo?.awardRoomTypes?.edges[0]?.node?.roomTypes[0]?.roomInformation?.id
        ?.split('.')[0]
        .toLowerCase(),
      brandCode: tripInfo?.property?.basicInformation?.marshaBrandCode,
      roomTypes: checkedItems,
      cancelFlow: false,
      returnUrl: CHANGE_CONFIRMATION_ENDPOINT,
      errorUrl: '',
    };
    try {
      const response = await nuaUpgradeRequest(addSubDirectoryPrefix(NUA_UPGRADE_REQUEST), reqBody);
      if (response?.status === 200) {
        const { nextStateURI } = response.data;
        handleRedirect(nextStateURI + '?statusCode=success');
      }
    } catch (error) {
      setIsLoading(false);
      apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`);
      setBannerMsgs(BANNER_MSGS_KEYS.NIGHT_UPGRADE_ERROR);
      scrollToClass();
    }
  };

  return (
    <StyledUpdateRoomSelection
      className="container"
      data-testid="updateroomselection"
      data-component-name="o-account-updateroomselection"
    >
      {isOpen && <VistanaVacationPolicy handleClose={() => modalOpenHandler(false)} modalURL={url} />}
      <Heading
        variation={Types.headingType.subtitle}
        fontSize={Types.size.extraLarge}
        titleText={nuaUpgrade ? upgradeOptionsLabel : roomLabel}
      />
      <div ref={tcRef}>
        <RichText componentId="room_selection" text={roomSelectionLabel} customClass="t-subtitle-m upgrade_label" />
      </div>
      <div className="wrapper d-flex flex-column p-4 mt-3 mb-4 mb-md-5 color-scheme7">
        {roomDetails?.map((room: RoomType) => {
          const { roomInformation } = room;
          const imagesArray = roomInformation?.photoGallery?.edges
            ?.filter((edge: Edge) => edge?.node?.imageUrls?.classicHorizontal)
            ?.map((edge: Edge) => ({
              defaultImageUrl: imageDomain + edge.node.imageUrls.classicHorizontal,
            }));
          return (
            <UpgradeRequestCard
              title={roomInformation?.description}
              cardId={roomInformation?.id.split('.')[1].toLowerCase()}
              images={imagesArray}
              addScrimToImage={true}
              carouselDotsPosition="center"
              prevCheckedItems={selectedRooms}
              onCheckboxChange={handleCheckboxChange}
              roomDetailsCta={roomDetailsCta}
              reqCheckbox={reqCheckbox}
            />
          );
        })}

        <div className="d-md-flex d-block justify-content-end ml-md-auto mt-4 nua-footer">
          <Link
            target="_self"
            text={backButton}
            linkClassName="t-font-m m-link-back-tertiary-button d-flex align-items-end px-3 mr-2 pb-4 p-md-2 justify-content-center col-12 col-md-7 nua-back-button"
            linkHref={`${backToOriginalReservationCTAPath}?confirmationNumber=${tripID}&tripId=${tripID}`}
          />
          <Button
            className={clsx(
              't-font-s m-button-primary btn-editLink d-flex p-2 px-3 justify-content-center col-12 col-md-5 nua-update-btn',
              checkedItems.length ? '' : 'disabled'
            )}
            callback={handleSubmit}
            custom_click_track_value={nuaUpgrade ? UPGRADE_REQUEST_CLICK_TRACK : MODIFY_REQUEST_CLICK_TRACK}
          >
            {isLoading ? (
              <div className="m-spinner-s" data-testid="loading-spinner"></div>
            ) : nuaUpgrade ? (
              submitCTA
            ) : (
              updateCTA
            )}
          </Button>
        </div>
      </div>
    </StyledUpdateRoomSelection>
  );
};

export const UpdateRoomSelectionConfig = {
  emptyLabel: 'UpdateRoomSelection',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/updateroomselection`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UpdateRoomSelectionEditable = (props: any) => {
  return (
    <EditableComponent config={UpdateRoomSelectionConfig} {...props}>
      <UpdateRoomSelection {...props} />
    </EditableComponent>
  );
};
