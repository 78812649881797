// Styles for MemberToMember go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledMemberToMember = styled.div`
  ${rtl`
  &:is(${constants.RTL_LANG_LIST}){
    text-align: justify;
    .dropdown-value{
      text-align: justify;
    }
    .icon-dropdown-down,.icon-dropdown-up{
      left: ${toRem(8)} !important;
      right: unset !important;
    } 
  }

  .points-page-error-msg {
    p {
        margin: 0;
     }
    &:is(${constants.RTL_LANG_LIST}){
      .m-message-content-wrap:before{
        margin-left: ${toRem(8)} !important;
        margin-right: unset !important;
      }
    }
  }
 

 .current-balance {
    width: 100%;
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
    border-radius: ${toRem(14)};
    gap: ${toRem(16)};

    &__points{
      width: 100%;
      gap:${toRem(4)}
    }
    &__label{
        position: relative;
        top: ${toRem(1)};
    }
    &__loader{
      width: 20%;
      top: ${toRem(1)};
      height: ${toRem(24)};
    }
  }

  .icon-buy-points {
    align-self: flex-end;
    &::before {
      font-size: ${toRem(30)};
    }

    &__points{
      width: 100%;
      gap: ${toRem(4)}
    }
      
    &__loader{
      width: 20%;
      top: ${toRem(1)};
      height: ${toRem(24)};
    }
  }

  .m-input-text-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
  
      &:is(${constants.RTL_LANG_LIST}){
        text-indent: ${toRem(16)} !important;
        height: ${toRem(45)} !important;
      }
    }
    label:is(${constants.RTL_LANG_LIST}){
      right: ${toRem(11)} !important;
      width: max-content;
    }
  }

  .transfer-details-container {
    &__header {
      border-top-left-radius: ${toRem(14)};
      border-top-right-radius: ${toRem(14)};
      background-color: ${baseVariables.color.neutral10};
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-right: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    }
    &__form {
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom-left-radius: ${toRem(14)};
      border-bottom-right-radius: ${toRem(14)};
      border-right: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
  
      &__partnerlabel {
        z-index: 1;
      }
  
      &__dropdown-container {
        margin-right: 0;
        @media ${baseVariables.mediaQuery.md} {
          margin-right: ${toRem(12)};
  
          &:is(${constants.RTL_LANG_LIST}){
            margin-left:${toRem(12)};
            margin-right:0 !important;
          }
        }
  
        &__dropdown {
          width: 100%;
          height: ${toRem(44)};
          border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
          border-color: ${baseVariables.color['neutral40']};
          padding-top: ${toRem(12)} !important;
          padding-bottom: ${toRem(12)} !important;
          .icon-dropdown-down,
          .icon-dropdown-up {
            position: absolute;
            right: ${toRem(8)};
          }
          .dropdown-value {
            color: ${baseVariables.color['neutral40']};
            font-size: ${toRem(16)};
          }
          
        }
      }
  
      &__validation-msg {
        color: ${baseVariables.color['alert50']};
      }
  
      &__points {
        flex: 1;
      }
  
      &__separator {
        color: ${baseVariables.color.neutral40};
      }
  
      &__converted-points {
        color: ${baseVariables.color.neutral40} !important;
      }
    }
  }

  .partner-instruction {
    gap: ${toRem(8)};
  }

  .input-valid-check {
    position: absolute;
    top: ${toRem(4)};
    right: ${toRem(16)};
    left: initial;
    transform: translateY(50%);
  
    &:is(${constants.RTL_LANG_LIST}){
      left: ${toRem(16)};
      right: initial;
    }
  }

  .user-instruction {
    p {
      margin-bottom: 0;
    }
    a {
      text-wrap: nowrap;
    }  
  }
  
  .continue_btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }

  .cancel{
    :is(${constants.RTL_LANG_LIST}){
      margin-right: ${toRem(32)}
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: auto;
      max-height:${toRem(224)}; 
    }
  }
  .custom-width {
    width: 100%;
  }
  .custom-gap {
    @media ${baseVariables.mediaQuery.md} {
      gap: ${toRem(24)};
    }
  }
 `}
`;
