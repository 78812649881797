// Styles for PaginationWithPageSize go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';
import { toRem } from '@marriott/mi-ui-library';

export const StyledPaginationWithPageSize = styled.div`
  ${rtl`
    .pagination-container {
      /* Overriding the shared component css to mathc the desgin */
      ul {
        margin: 0;
      }
    }

    .pagination-container__entries:is(${constants.RTL_LANG_LIST}) {
      text-align: left !important;
    }

    .pagination-render-main .views:is(${constants.RTL_LANG_LIST}) {
      margin: 0 0 0 ${toRem(16)} !important;
    }

    .icon-dropdown-down:is(${constants.RTL_LANG_LIST}),
    .icon-dropdown-up:is(${constants.RTL_LANG_LIST}) {
      padding: 0 ${toRem(16)} 0 0 !important;
    }

    .dropdown__container:is(${constants.RTL_LANG_LIST}) li {
        text-align: right !important;
    }

    .pagination-container:is(${constants.RTL_LANG_LIST}) {
      .icon-arrow-right:before {
        content: '\\e921' !important;
      }

      .icon-arrow-left:before {
        content: '\\e922' !important;
      }
    }
  `}
`;
