/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useContext, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Image } from '@marriott/mi-ui-library';
import { useRouter } from 'next/router';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { useCreateAccountStore } from '../../modules/store/createAccountStore';
import { CountryList } from './CreateAccountForm/CreateAccountForm.types';
import { CreateAccountProps, SweepStakesEnrollmentListType } from './CreateAccount.types';
import { StyledCreateAccount } from './CreateAccount.styles';
import { CreateAccountDescription } from './CreateAccountDescription';
import { CreateAccountForm } from './CreateAccountForm';
import { fallbackImages } from '../../modules/utils/constants';
import { PageContext, correctSubDirectoryPathForHeader, getCountryAndLangCode } from '../../modules';

// Use named rather than default exports.
export const CreateAccount: FC<CreateAccountProps> = (pageProps: any) => {
  const { setvalidSweepEnrollObject } = useCreateAccountStore();
  const pageContext = useContext(PageContext);
  const { currentLocale } = pageContext;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const router = useRouter();
  const clientIdParam = router.query['clientId'] ?? '';
  const clientId =
    typeof clientIdParam === 'string' ? clientIdParam : Array.isArray(clientIdParam) ? clientIdParam[0] || '' : '';
  // valid sweepstake object
  const sweepStakeObject =
    pageProps?.sweepStakesEnrollmentList &&
    pageProps?.sweepStakesEnrollmentList?.filter((item: SweepStakesEnrollmentListType) => item?.sweepsId === clientId);

  const isValidClientId = sweepStakeObject && sweepStakeObject?.length > 0;

  const sweepStakeEnrollmentCode = pageProps?.sweepStakesSourceCodes?.filter(
    (enrollmentcode: CountryList) => enrollmentcode?.value === clientId + '_' + countryCode
  )?.[0]?.id;

  useEffect(() => {
    correctSubDirectoryPathForHeader();

    !!clientId &&
      pageProps?.sweepStakesEnrollmentList &&
      setvalidSweepEnrollObject({
        sweepEnrollmentSourceCode: isValidClientId ? sweepStakeEnrollmentCode : '',
        sweepRedirectUrl: sweepStakeObject?.[0]?.sweepsRedirectUrl ?? '',
      });
  }, []);
  return (
    <StyledCreateAccount data-component-name="o-account-createaccount">
      <div className="create-account">
        <div className="create-account-image-container">
          <Image
            customClass="create-account__image"
            altText={
              isValidClientId
                ? sweepStakeObject?.[0]?.imageAltText ?? sweepStakeObject?.[0].featuredDynamicImage?.altText
                : pageProps?.imageAltText ?? pageProps?.featuredDynamicImage?.altText
            }
            defaultImageURL={
              isValidClientId
                ? sweepStakeObject?.[0].image ?? sweepStakeObject?.[0].featuredDynamicImage?.assetPath
                : pageProps?.image ?? pageProps?.featuredDynamicImage?.assetPath ?? fallbackImages.Classic
            }
            renditions={
              isValidClientId
                ? sweepStakeObject?.[0].featuredDynamicImage?.renditions
                : pageProps?.featuredDynamicImage?.renditions
            }
          />
        </div>
        <div className="create-account__wrapper container">
          <CreateAccountDescription
            isValidClientId={isValidClientId}
            sweepStakeObject={sweepStakeObject?.[0]}
            {...pageProps}
          />
          <CreateAccountForm isValidClientId={isValidClientId} {...pageProps} />
        </div>
      </div>
    </StyledCreateAccount>
  );
};

export const CreateAccountConfig = {
  emptyLabel: 'createaccount',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/createaccount`,
};

export const CreateAccountEditable = (props: any) => {
  return (
    <EditableComponent config={CreateAccountConfig} {...props}>
      <CreateAccount {...props} />
    </EditableComponent>
  );
};
