/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { RoomType } from '../../organisms/UpdateRoomSelection/UpdateRoomSelection.types';

export type uxlError = {
  component: string;
  showError: boolean;
};
interface nuaState {
  tripInfo: any | null;
  setTripInfo: (data: any) => void;
  uxlError?: uxlError;
  setUxlError: (error: uxlError) => void;
}

const initialState: nuaState = {
  tripInfo: null,
  setTripInfo: () => {},
  uxlError: {
    component: '',
    showError: false,
  },
  setUxlError: () => {},
};

const nuaStore: StateCreator<nuaState> = set => {
  return {
    ...initialState,
    setTripInfo: (data: any) => {
      set({ tripInfo: data });
    },
    setUxlError: (uxlError: uxlError) => {
      set({ uxlError: uxlError });
    },
  };
};

export const useNuaStore = createAppStore(nuaStore, {
  usePersistentStore: false,
});
