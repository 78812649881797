/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useCallback, SyntheticEvent } from 'react';
import clsx from 'clsx';
import Glide from '@glidejs/glide';
import { Heading, Image, Types, Text, Link } from '@marriott/mi-ui-library';
import { useGetBreakpoint as getBreakPoint, getClassForBreakpoint, ROOM_DETAILS_ID } from '../../modules';
import { handleClickRoomDetail } from '../../modules/utils/helper';
import { UpgradeRequestCardProps, ImageProps, RoomType } from './UpgradeRequestCard.types';
import { StyledUpgradeRequestCard } from './UpgradeRequestCard.styles';

export interface StyledOverlayProps {
  iconPath?: boolean;
}

export const UpgradeRequestCard: React.FC<UpgradeRequestCardProps> = ({
  title,
  cardId,
  roomDetailsCta,
  reqCheckbox,
  images = [],
  carouselDotsPosition,
  addScrimToImage,
  styleClass = '',
  prevCheckedItems,
  onCheckboxChange,
}) => {
  const glideRef = useRef<HTMLDivElement | any>(null);
  const [breakpoint, setBreakpoint] = useState<string | undefined>(getBreakPoint());
  const [isChecked, setIsChecked] = useState(false);

  // Reset breakpoint on window resize.
  useEffect(() => {
    prevCheckedItems?.map((value: RoomType) => {
      const roomId = value?.roomInformation?.id?.split('.')[1].toLowerCase();
      if (roomId === cardId) {
        setIsChecked(true);
      }
    });

    const handleResize = () => {
      setBreakpoint(getBreakPoint());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (images?.length > 1 && glideRef.current) {
      const isRtl = document.body.getAttribute('dir') === 'rtl';
      const lang = document.body.getAttribute('lang');
      const isArabic = lang === 'ar-AE' || lang === 'he-IL';
      const direction = isRtl && isArabic ? 'rtl' : 'ltr';

      const glide = new Glide(glideRef.current, {
        type: 'slider',
        gap: 0,
        startAt: 0,
        focusAt: 'center',
        autoplay: false,
        bound: false,
        rewind: true,
        direction: direction,
        dragThreshold: false,
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        keyboard: false,
      });

      const glideOnRun = () => {
        const index = Number(
          glideRef.current?.querySelector('.glide__slide--active')?.getAttribute('data-glide-index')
        );

        if (index !== null && images) {
          const nextIndex = (index + 1) % images.length;
          const prevIndex = (index - 1 + images.length) % images.length;
          const nextImg = glideRef.current?.querySelector(`[data-glide-index='${nextIndex}'] img`);
          const prevImg = glideRef.current?.querySelector(`[data-glide-index='${prevIndex}'] img`);

          if (nextImg) nextImg.setAttribute('loading', 'eager');
          if (prevImg) prevImg.setAttribute('loading', 'eager');
        }
      };

      glide.on('run.after', glideOnRun);
      glide.mount();
    }
  }, [images]);

  const renderImages = useCallback((images: ImageProps[]) => {
    return (
      <div className="img-carousel__container">
        <div className={`glide`} ref={glideRef}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides d-flex m-0 p-0">
              {images?.slice(0, 5)?.map((img: ImageProps, index: number) => (
                <li className="glide__slide" key={`upgrade-req-card-list-item-${index}`} data-glide-index={index}>
                  <Image
                    defaultImageURL={`${img.defaultImageUrl}`}
                    altText={`${cardId} ${index + 1}`}
                    customClass="upgrade-req-card__img-container--img"
                    loading={index === 0 || index === 1 || index === images.length - 1 ? 'eager' : 'lazy'}
                  />
                  {addScrimToImage && <div className="upgrade-req-card__img-container--scrim-component" />}
                </li>
              ))}
            </ul>

            <div className="glide__arrows" data-glide-el="controls">
              <button
                className={`glide__arrow glide__arrow--left left-arrow carousal-arrow`}
                data-glide-dir="<"
                aria-label="Previous"
              >
                <span className="icon-arrow-left"></span>
              </button>
            </div>
            <div
              data-glide-el="controls[nav]"
              className={clsx(
                'glide-bullet-container d-flex align-items-center px-2',
                carouselDotsPosition ? 'center' : ''
              )}
            >
              {images?.slice(0, 5)?.map((_: ImageProps, index: number) => (
                <button
                  className="glide__bullet carousal-bullet p-0"
                  aria-label={`glide__bullet-${index}`}
                  data-glide-dir={`=${index}`}
                  key={`propery-card-bullet-${index}`}
                ></button>
              ))}
            </div>

            <div className="glide__arrows" data-glide-el="controls">
              <button
                className={`glide__arrow glide__arrow--right right-arrow carousal-arrow`}
                data-glide-dir=">"
                aria-label="Next"
              >
                <span className="icon-arrow-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const handleCheckboxToggle = () => {
    const updatedIsChecked = !isChecked;
    setIsChecked(updatedIsChecked);
    onCheckboxChange(cardId, updatedIsChecked);
  };

  const filteredRoomImages = images?.map(item => {
    return {
      offerCardImage: {
        damPath: item.defaultImageUrl,
      },
    };
  });

  return (
    <StyledUpgradeRequestCard
      data-component-name="m-account-UpgradeRequestCard"
      data-testid="account-UpgradeRequestCard"
      className={clsx(
        'upgrade-req-card container d-flex p-0 mt-3 color-scheme7',
        getClassForBreakpoint(breakpoint, 'flex-nowrap', 'flex-nowrap', 'flex-column flex-nowrap'),
        styleClass
      )}
    >
      <div className="upgrade-req-card__img-container">
        {images?.length === 1 ? (
          <Image
            defaultImageURL={`${images[0].defaultImageUrl}`}
            altText={`Slide 1`}
            customClass="upgrade-req-card__img-container--img"
            loading={'eager'}
          />
        ) : (
          renderImages(images)
        )}
      </div>
      <div className={clsx('card__details-container d-flex flex-column justify-content-between')}>
        <div className="p-4">
          <Heading variation={Types.headingType.subtitle} fontSize={Types.size.extraLarge} titleText={title} />
          <Link
            target="_self"
            text={roomDetailsCta}
            linkClassName="link-header t-label-alt-s"
            linkHref={'#'}
            callback={(event: SyntheticEvent): void => {
              event.preventDefault();
              handleClickRoomDetail({
                id: ROOM_DETAILS_ID,
                item: {
                  description: title,
                  roomDetailsList: [
                    {
                      roomPoolCode: cardId,
                    },
                  ],
                  filteredRoomImages: filteredRoomImages,
                },
                key: ROOM_DETAILS_ID,
              });
            }}
          />
        </div>
        <div className="mb-2 separator mt-2"></div>
        <div className="card-footer d-flex justify-content-end mt-md-0 mt-3 pb-md-4 pr-3 mb-3">
          <Text
            copyText={reqCheckbox}
            fontSize={Types.size.small}
            element={Types.tags.paragraph}
            customClass={'pr-2'}
          />
          <input
            type="checkbox"
            id={cardId}
            name={cardId}
            onChange={handleCheckboxToggle}
            checked={isChecked}
            className={'checkbox-input'}
            aria-checked={isChecked}
            aria-label={reqCheckbox + 'for' + title}
          ></input>
          <label htmlFor={cardId}>{}</label>
        </div>
      </div>
    </StyledUpgradeRequestCard>
  );
};
