import React, { FC, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, CheckBox, InputTextField, RichText, Text, Types, Messages, Heading } from '@marriott/mi-ui-library';
import {
  generateApolloClientHeaders,
  SUCCESS_CODE,
  UNSUBSCRIBE_ACTION,
  isValidEmaiFormat,
  scrollToClass,
} from '../../modules';
import { PageContext } from '../../modules/context/PageContext';
import { phoenixAccountUpdateEmailSubscribtion } from '../../modules/graph/index';
import { EmailUnsubscribeProps, ConsentOption } from './EmailUnsubscribe.types';
import { StyledEmailUnsubscribe } from './EmailUnsubscribe.styles';

export const EmailUnsubscribe: FC<EmailUnsubscribeProps> = pageProps => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const [communicationConsents, setCommunicationConsents] = useState<Array<ConsentOption>>([]);
  const [selectAll, setSelectAll] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasEmail, setHasEmail] = useState('');
  const [updateEmailSubscribtion] = useMutation(phoenixAccountUpdateEmailSubscribtion);
  const [hasMessage, setHasMessage] = useState({
    emailEmptyError: false,
    emailUpdatedSuccess: false,
    emailInvalidError: false,
  });

  useEffect(() => {
    //store value  for paylaod checkbox
    const initializedCheckConsents: Array<ConsentOption> = pageProps?.consentDetails
      ?.filter(option => option.type !== '' && option.method !== '')
      ?.map(option => ({
        method: option.method,
        type: option.type,
        subscribed: true,
      }));
    setCommunicationConsents(initializedCheckConsents);
  }, []);

  useEffect(() => {
    if (hasError || hasMessage.emailUpdatedSuccess || hasMessage.emailEmptyError || hasMessage.emailInvalidError) {
      scrollToClass();
    }
  }, [hasError, hasMessage]);

  //check if any of the checkbox is subscribed
  const hasanyCheckboxChecked = communicationConsents.some(consent => consent.subscribed);
  const handleConsentChange = (method: string, type: string, subscribeAll: boolean) => {
    const updatedConsents = [...communicationConsents];
    if (subscribeAll) {
      updatedConsents.forEach(consent => {
        consent.subscribed = !selectAll;
      });
      setSelectAll(prev => !prev);
    } else {
      updatedConsents.forEach(consent => {
        if (consent.method === method && consent.type === type) {
          consent.subscribed = !consent.subscribed;
        }
      });
      setSelectAll(updatedConsents.every(consent => consent.subscribed));
    }
    setCommunicationConsents(updatedConsents);
  };
  // toggle the value of subscribed and append the consents
  const toggleAndAppendConsents = (consents: { subscribed: boolean }[]) => {
    const toggledConsents = consents.map((consent: { subscribed: boolean }) => ({
      ...consent,
      subscribed: !consent.subscribed,
    }));

    const newConsent = {
      method: 'email',
      type: 'PRTNR',
      subscribed: false,
    };
    const payloadConsnet = [...toggledConsents, newConsent];
    return payloadConsnet;
  };

  //mutation
  const updateSubscribeload = () => {
    const payloadConsent = toggleAndAppendConsents(communicationConsents);

    setHasError(false);
    setHasMessage({
      emailEmptyError: false,
      emailUpdatedSuccess: false,
      emailInvalidError: false,
    });

    const checkEmailFormat = isValidEmaiFormat(hasEmail);
    if (!hasEmail) {
      setHasMessage(prevState => ({ ...prevState, emailEmptyError: true }));
    } else if (!checkEmailFormat) {
      setHasMessage(prevState => ({ ...prevState, emailInvalidError: true }));
    } else {
      // Check if there are any changes in consents
      setIsLoading(true);
      updateEmailSubscribtion({
        variables: {
          input: {
            action: UNSUBSCRIBE_ACTION,
            email: hasEmail,
            options: payloadConsent,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        onCompleted: data => {
          setIsLoading(false);
          const responseCode = data?.updateAccountCommunicationOptions?.code;
          if (responseCode === SUCCESS_CODE) {
            setHasMessage(prevState => ({ ...prevState, emailUpdatedSuccess: true }));
            setHasError(false);
          } else {
            setHasError(true);
          }
        },
        onError: () => {
          setIsLoading(false);
          setHasError(true);
        },
      });
    }
  };

  return (
    <StyledEmailUnsubscribe>
      <div className=" unsubscribe__body my-4 spacing-top-bottom">
        <div className="container">
          <Heading
            variation={Types.headingType.title}
            fontSize={Types.size.small}
            titleText={pageProps?.header}
            customClass="after-submitting--header "
          />
          <Text fontSize={Types.size.medium} copyText={pageProps?.title} element={Types.tags.div} customClass="py-2" />
          {(hasError ||
            hasMessage.emailUpdatedSuccess ||
            hasMessage.emailEmptyError ||
            hasMessage.emailInvalidError) && (
            <div id={'credit-card'} data-testId="error-msg" className="error-msg pt-4 pb-2">
              <Messages
                messageType={clsx(hasMessage.emailUpdatedSuccess ? 'success' : 'error-sev1')}
                className="unsubscribe__errormsg px-2"
              >
                {hasMessage.emailUpdatedSuccess && (
                  <RichText text={pageProps?.emailUpdatedMsg} componentId="success-msg" />
                )}
                {hasMessage.emailEmptyError && <RichText text={pageProps?.emailError} componentId="error-msg" />}
                {hasMessage.emailInvalidError && (
                  <RichText text={pageProps?.emailErrorMessage} componentId="error-msg" />
                )}
                {hasError && <RichText text={pageProps?.apiFailureErrMsg} componentId="error-msg" />}
              </Messages>
            </div>
          )}
          <InputTextField
            label={pageProps?.emailLabel}
            infoLabel={`${pageProps?.emailLabel}-label`}
            testId="email-input"
            showErrorMessage={undefined}
            setErrorHtml={true}
            messageClass="error-label"
            inputMaxLength={80}
            className={clsx(
              'unsubscribe__email my-4 m-input-text-field ',
              (hasMessage.emailEmptyError || hasMessage.emailInvalidError) && ' is-error'
            )}
            getInputValue={val => setHasEmail(val)}
            getInputProps={() => ({
              autoComplete: 'off',
              id: pageProps?.emailLabel,
            })}
          />
        </div>
        <div className="unsubscribe__consents">
          <div className="pb-5">
            {pageProps?.consentDetails?.map((options, index) => (
              <div className={`${options.type}-class`} key={index}>
                <div
                  className={clsx(
                    'unsubscribe__consents__container',
                    options?.type === '' ? 'consent-bg px-3 ' : 'container'
                  )}
                >
                  <div
                    className={clsx(
                      'unsubscribe__consents__container__hrLine container px-0  py-4',
                      options?.type === '' && 'px-md-2'
                    )}
                  >
                    <CheckBox
                      key={index}
                      checked={
                        communicationConsents?.find(
                          consent => consent.type === options.type && consent.method === options.method
                        )?.subscribed || selectAll
                      }
                      checkboxId={`checkbox-${index}`}
                      checkboxName={options.type}
                      className="unsubscribe__consents__container__checkbox"
                      onKeyDown={event =>
                        event.key === 'Enter' &&
                        handleConsentChange(
                          options?.method,
                          options?.type,
                          options?.type === '' && options?.method === ''
                        )
                      }
                      onChange={() =>
                        handleConsentChange(
                          options?.method,
                          options?.type,
                          options?.type === '' && options?.method === ''
                        )
                      }
                      children={
                        <RichText
                          customClass="unsubscribe__consents__container__checkbox__labelText m-0 p-0 t-subtitle-m"
                          text={options?.consentTitle}
                          componentId={`checkboxDescription-${index}`}
                        />
                      }
                    />
                    <div className="px-4">
                      <RichText
                        customClass="t-font-s"
                        text={options?.consentDescription}
                        componentId={`${options?.type}-id`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container d-flex flex-md-row flex-column align-items-center">
            <Button
              className={clsx(
                'm-button-m px-3 m-button-primary modal-footer__unsubscribe ml-md-0',
                !hasanyCheckboxChecked && 'disabled'
              )}
              callback={() => updateSubscribeload()}
              isDisabled={!hasanyCheckboxChecked} // Disable the button if no subscribed consent
              testId="unsubscribeBtn"
            >
              {isLoading ? (
                <div className="m-spinner-s" data-testid="loading-spinner"></div>
              ) : (
                pageProps?.unsubscribeLabel
              )}
            </Button>
            <Button
              className={'modal-footer__cancel  m-link-action mx-md-5  mx-3 mt-5 mt-md-0'}
              buttonCopy={pageProps?.cancelButton}
              isLink={true}
              href={pageProps?.cancelCtaPath}
            />
          </div>
        </div>
      </div>
    </StyledEmailUnsubscribe>
  );
};

export const EmailUnsubscribeConfig = {
  emptyLabel: 'emailunsubscribe',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/emailunsubscribe`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmailUnsubscribeEditable = (props: any) => {
  return (
    <EditableComponent config={EmailUnsubscribeConfig} {...props}>
      <EmailUnsubscribe {...props} />
    </EditableComponent>
  );
};
