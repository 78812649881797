/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState, useContext, useEffect, Suspense, LegacyRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {
  Calendar,
  getEventKeyTypes,
  Icon,
  baseVariables,
  Button,
  RichText,
  getLocalizedDate,
} from '@marriott/mi-ui-library';
import { addClassToBlankTargetLinks, apiLogger, getCalednarLocaleKeys } from '../../../modules/utils';
import { ALLOWED_DAY_THRESHOLD, localeDateFormats } from '../../../modules/utils/constants';
import { PageContext } from '../../../modules';
import { phoenixAccountSuggestedPlacesBySearchStringProperty } from '../../../modules/graph';
import { ReportMissingStayStepTwoStyle, GlobalStyles } from './ReportMissingStayStepTwo.styles';
import { SuggestionDataTypesForInput } from './ReportMissingStayStepTwo.types';

export const StepTwo = (props: any) => {
  const pageContext = useContext(PageContext);
  const { currentLocale } = pageContext;
  const [toggleAccordian, setToggleAccordian] = useState(false);
  const requestId = Date.now();
  const [suggestionListData, setSuggestionListData] = useState<SuggestionDataTypesForInput>();
  const [options, setOptions] = useState<any>([]);
  const [isCheckInCalendarOpen, setIsCheckInCalendarOpen] = useState(false);
  const [isCheckOutCalendarOpen, setIsCheckOutCalendarOpen] = useState(false);
  const [shouldCallUXL, setShouldCallUXL] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedCheckInDate, setSelectedCheckInDate] = useState<Date | undefined>();
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState<Date | undefined>();
  const [customCheckoutDate, setCustomCheckoutDate] = useState<Date | undefined>();
  const [selectedCheckOutStartDate, setSelectedCheckOutStartDate] = useState<Date | undefined>();
  const [_filteredOptions, setFilteredOptions] = useState([]); // Options to display
  const [isTyping, setIsTyping] = useState(false);
  const [_isInputFocused, setInputFocused] = useState(false);
  const authorModelData = props?.authorModelData;
  const [accordionMessage, setAccordionMessage] = useState(authorModelData?.accordionDescription);
  const today = new Date();
  const dateFromDaysAgo = moment().subtract(ALLOWED_DAY_THRESHOLD, 'days').toDate();
  const dayBefore = moment().subtract(1, 'days').toDate();
  const errorFlag = false;
  const autoCompleteError = false;
  // Ref for the calendar container
  const calendarContainerRef: LegacyRef<HTMLDivElement> = useRef(null);

  //Filtering the undefined and null values from suggestion List
  useEffect(() => {
    if (suggestionListData && suggestionListData?.suggestedPlacesBySearchString) {
      const newArray = suggestionListData?.suggestedPlacesBySearchString?.edges
        ?.map(data => {
          if (data?.node?.propertyID) {
            return data;
          }
          return null;
        })
        .filter(item => item !== null);
      setOptions(newArray);
    }
    setAccordionMessage(addClassToBlankTargetLinks(authorModelData?.accordionDescription));
  }, [suggestionListData]);

  //fetch suggested places values from uxl
  const [getSuggestedPlacesBySearchString] = useLazyQuery(phoenixAccountSuggestedPlacesBySearchStringProperty);
  useEffect(() => {
    if (inputValue && shouldCallUXL) {
      getSuggestedPlacesBySearchString({
        variables: {
          search: {
            query: inputValue,
          },
        },
        context: {
          headers: {
            'x-request-id': requestId,
            'accept-language': currentLocale,
          },
        },
        onCompleted: (data: any) => {
          if (data && data.suggestedPlacesBySearchString?.edges) {
            setSuggestionListData(data);
          }
        },
        onError: error => {
          apiLogger(`[MissingStayRequestForm-StepTwo] getMemberStatusDetails - error: ${inspect(error)}`);
        },
      });
    }
  }, [inputValue, shouldCallUXL]);

  //handle calendar to close if user clicks outside the calendar anywhere
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (calendarContainerRef?.current && !calendarContainerRef?.current?.contains(event.target as Node)) {
        setIsCheckInCalendarOpen(false);
        setIsCheckOutCalendarOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // Filter options based on the current input value
  useEffect(() => {
    setFilteredOptions(
      options?.filter((option: any) =>
        `${option?.node?.name}, ${option?.node?.country?.code}, ${option?.node?.city}`
          ?.toLowerCase()
          ?.includes(inputValue?.toLowerCase())
      )
    );
  }, [inputValue, options]);

  useEffect(() => {
    // Calculate customFromDate for checkout calendar
    if (selectedCheckInDate) {
      const customDate = new Date(selectedCheckInDate);
      customDate.setDate(customDate.getDate() + 1); // One day after selected check-in date
      setCustomCheckoutDate(customDate);
      setSelectedCheckOutStartDate(customDate);
      props?.setFormFields({
        ...props?.formFields,
        checkOutDate: customDate.toLocaleDateString('fr-CA'),
      });
      setSelectedCheckOutDate(customDate); //if user selected checkindate after checkout date agin updating checkoutdate to null
    }
  }, [selectedCheckInDate]);

  // Function to handle changes when a accordion  is clicked
  const handleAccoridanToggle = () => {
    setToggleAccordian(!toggleAccordian);
  };

  //Call UXL after user enters Input Value
  const handleInputChange = (event: any, newValue: any) => {
    if (event) {
      setInputValue(newValue);
    }
    setOptions([]);
    setTimeout(() => {
      {
        newValue?.length > 2 && setShouldCallUXL(true);
      }
    }, 500);
  };
  // Open the checkin-calendar when the input box is clicked
  const handleCheckInDateClick = () => {
    setIsCheckInCalendarOpen(true);
    setIsCheckOutCalendarOpen(false);
  };
  // Open the checkout-calendar when the input box is clicked
  const handleCheckOutDateClick = () => {
    setIsCheckOutCalendarOpen(true);
    setIsCheckInCalendarOpen(false);
  };

  const handleAccordionKeyDown = (e: any) => {
    if (getEventKeyTypes(e)?.isEnterKey) {
      handleAccoridanToggle();
    }
  };
  //Selecting the value from Autocomplete field
  const handleSelectValue = (event: any, newvalue: any) => {
    if (event) {
      setSelectedValue(newvalue);
    }

    props?.setFormFields({
      ...props?.formFields,
      propertyName: newvalue?.node?.name,
      propertyCode: newvalue?.node?.propertyID,
    });
  };
  const handleInputFocus = () => {
    setInputFocused(true);
  };
  const handleInputBlur = () => {
    setInputFocused(false);
  };
  // Clear the selected Value from typeahead(Autocomplete)
  const handleClearValue = () => {
    setSelectedValue('');
    setInputValue('');
    setOptions([]);
  };

  // Define the onDayClickHandler checkin function
  const handleDayCheckInDateClick = (day: any) => {
    setIsCheckInCalendarOpen(false);
    setSelectedCheckInDate(day);
    //clear selected checkout date if checkin date is undefined
    !day && setSelectedCheckOutDate(day);
    props?.setFormFields({
      ...props?.formFields,
      checkInDate: day?.toLocaleDateString('fr-CA') ?? '',
    });
  };
  // Define the onDayClickHandler checkout function
  const handleDayCheckOutDateClick = (day: any) => {
    setIsCheckOutCalendarOpen(false);
    setSelectedCheckOutDate(day);
    //updated selected checkout start date if checkout date is defined
    //setting the new startdate for checkout to show sleected day in calendar
    day && setSelectedCheckOutStartDate(day);
    props?.setFormFields({
      ...props?.formFields,
      checkOutDate: day?.toLocaleDateString('fr-CA') ?? '',
    });
  };

  //TODO: should check and remove it later
  const handleDateChange = (start: any, end: any) => {
    console.log(start, '', end);
  };
  const handleRadioButtonKeyDown = (e: any, value: string) => {
    if (getEventKeyTypes(e)?.isEnterKey) {
      e.preventDefault(); // Prevent form submission when using Enter on the radio button
      props?.setFormFields({
        ...props?.formFields,
        digitalBillSelection: value === 'digitalCopy',
        paperBillSelection: value === 'paperCopy',
        noBillSelection: value === 'noBill',
        faxOrMailSelection: value === 'faxOrMail',
        documentIds: null,
        scannedCopySelection: value === 'scannedCopy',
      });
    }
  };
  //format the selected date from calendar
  const formatSelectedDate = (date: any) => {
    if (date) {
      return getLocalizedDate(date, localeDateFormats[currentLocale?.replace('_', '-') ?? 'en-US']?.calendarView);
    }
    return '';
  };
  const { shortWeekdays, shortWeekDaysName, longWeekDaysName, longMonthName, shortMonthName } =
    getCalednarLocaleKeys(authorModelData);

  return (
    <ReportMissingStayStepTwoStyle>
      <div className={clsx('step-two', 'mx-4', props?.formFields?.thirdPartyBooking !== false ? 'blur' : '')}>
        <GlobalStyles />
        <div className="step-two__name pb-4">
          <h2 className="t-subtitle-l">{authorModelData?.stepTwoSectionTitle}</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-5 mb-md-0">
            <div
              className={clsx(
                'm-input-field',
                props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                props?.formFields?.confirmationNumber ? 'is-active' : ''
              )}
            >
              <label className="t-label-s" htmlFor="confirmationNumber">
                {authorModelData?.confirmationNumberLabel}
              </label>
              <input
                autoComplete="off"
                type="text"
                id="confirmationNumber"
                data-testid="confirmationNumber"
                maxLength={20}
                style={{ caretColor: baseVariables.color['base10'] }}
                value={props?.formFields?.confirmationNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props?.setFormFields({ ...props?.formFields, confirmationNumber: event.target.value });
                }}
                disabled={props?.formFields?.thirdPartyBooking !== false}
              />
            </div>
            <div className="pt-1 pb-0 pb-md-4">
              <span className="t-font-s">{authorModelData?.textUnderConfirmationNumber}</span>
            </div>
          </div>
          {!props?.formFields?.thirdPartyBooking && !props?.pageLoadFlag && (
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <div
                className={clsx(
                  'm-input-field',
                  'search-input-field',
                  props?.errorStates?.propertyName ? 'is-error' : '',
                  props?.formFields?.propertyName ? 'is-active' : '',
                  props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : ''
                )}
              >
                <label htmlFor="hotelname" className="input-label t-label-s">
                  {authorModelData?.hotelNameLabel}
                </label>
                <Autocomplete
                  id="hotelname"
                  aria-label="property-Name"
                  value={selectedValue}
                  data-testid="hotelName"
                  onChange={handleSelectValue}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={options}
                  open={isTyping && !selectedValue} // Open the dropdown only when typing and no option is selected
                  onOpen={() => {
                    if (inputValue !== '') {
                      setIsTyping(true);
                    }
                  }}
                  onClose={() => {
                    setIsTyping(false); // Reset isTyping when the dropdown closes
                  }}
                  getOptionLabel={(option: any) =>
                    option ? `${option?.node?.name}, ${option?.node?.country?.code}, ${option?.node?.city}` : ''
                  }
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      aria-label="select hotel name"
                      data-testid="autocompleteInput"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedValue && (
                              <IconButton edge="end" onClick={handleClearValue} aria-label="Clear Input">
                                <CloseIcon />
                              </IconButton>
                            )}
                          </>
                        ),
                        className: autoCompleteError ? 'errorField' : 'defaultField',
                      }}
                      inputProps={{ ...params.inputProps, maxLength: 20 }}
                      onClick={e => {
                        if (selectedValue) {
                          e.preventDefault(); // Prevent opening the dropdown when an option is selected
                        }
                      }}
                    />
                  )}
                  disabled={props?.formFields?.thirdPartyBooking !== false}
                  noOptionsText={authorModelData?.noOptionLabel}
                />
              </div>
              {(autoCompleteError || props?.errorStates?.propertyName) && (
                <span className="t-label-s validation-msg">{authorModelData?.hotelNameErrorMessage}</span>
              )}
            </div>
          )}
          {/* //hidden field */}
          {props?.formFields?.thirdPartyBooking === true && (
            <div className="m-input-field is-disabled col-12 col-md-6 mb-5 mb-md-0">
              <label htmlFor="hotelname">{authorModelData?.hotelNameLabel}</label>
              <input type="text" readOnly={true} disabled={true} />
            </div>
          )}
        </div>

        <div className="row d-flex justify-content-start " ref={calendarContainerRef}>
          <div className="d-flex flex-column col-12 col-md-6 col-lg-3">
            <div
              className={clsx(
                'm-input-field mb-4 mb-md-0',
                props?.formFields?.thirdPartyBooking !== false ? 'is-disabled' : '',
                props.errorStates?.checkInEnteredDate ? 'is-error' : '',
                props?.formFields?.checkInDate ? 'is-active' : ''
              )}
            >
              <label className="t-label-s" htmlFor="checkInDate">
                {authorModelData?.checkInDateLabel}
              </label>
              <input
                type="text"
                autoComplete="off"
                className={clsx(`input-box ${errorFlag ? 'error' : ''} cursor-pointer`)}
                id="checkInDate"
                data-testid="checkInDate"
                value={formatSelectedDate(selectedCheckInDate)}
                onClick={handleCheckInDateClick}
                onKeyDown={handleCheckInDateClick}
                readOnly
                disabled={props?.formFields?.thirdPartyBooking !== false}
              />
              <span
                data-testid="checkInCalendarDay"
                className={clsx(
                  'icon-arrow-down',
                  props?.formFields?.thirdPartyBooking !== false ? 'show-disabled' : ''
                )}
                onClick={props?.formFields?.thirdPartyBooking === false ? handleCheckInDateClick : undefined}
              ></span>
              {props.errorStates?.checkInEnteredDate && (
                <span className="t-label-s validation-msg">{authorModelData?.checkInValidation}</span>
              )}
            </div>

            <div className="">
              {isCheckInCalendarOpen && (
                <Suspense fallback={<>loading</>}>
                  <div className="calendar-wrapper mb-3">
                    <Calendar
                      variation={'DESKTOP'}
                      startDate={moment(selectedCheckInDate)}
                      endDate={null}
                      focusedInput={'customDate'}
                      setFocusedInputHandler={(newState): void => {
                        console.log('setFocusedInputHandler', newState);
                      }}
                      customDatesFlag={true}
                      nightsLabel="nights"
                      nightLabel="night"
                      maxAllowedDays={7}
                      weekdays={shortWeekdays}
                      weekdaysLong={longWeekDaysName}
                      monthsShort={shortMonthName}
                      weekdaysShort={shortWeekDaysName}
                      disableHoverToolTip={true}
                      months={longMonthName}
                      defaultMonth={today}
                      disabledDays={[]}
                      onDayClickEvent={handleDayCheckInDateClick}
                      customFromDate={dateFromDaysAgo}
                      customToDate={dayBefore}
                      numberOfMonths={1}
                      showOutsideDays={true}
                      firstDayOfWeek={authorModelData.firstDayOfWeek || '0'}
                      setDateHandler={handleDateChange}
                      displayFooterSection={true}
                      footerItems={[
                        <Button
                          className="color-scheme5 m-link-action link-button clear-action-button px-0"
                          callback={() => handleDayCheckInDateClick(undefined)}
                          testId={authorModelData?.clearButtonLabel}
                          buttonCopy={authorModelData?.clearButtonLabel}
                        ></Button>,
                      ]}
                    />
                  </div>
                </Suspense>
              )}
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-md-6 col-lg-3">
            <div
              className={clsx(
                'm-input-field mb-4 mb-md-0',
                props?.formFields?.thirdPartyBooking !== false || !selectedCheckInDate ? 'is-disabled' : '',
                props.errorStates?.checkOutEnteredDate ? 'is-error' : '',
                props?.formFields?.checkOutDate ? 'is-active' : ''
              )}
            >
              <label className="t-label-s" htmlFor="checkOutDate">
                {authorModelData?.checkOutDateLabel}
              </label>
              <input
                type="text"
                autoComplete="off"
                id="checkOutDate"
                className="cursor-pointer"
                data-testid="checkOutDate"
                value={formatSelectedDate(selectedCheckOutDate)}
                onClick={handleCheckOutDateClick}
                onKeyDown={handleCheckOutDateClick}
                readOnly
                disabled={props?.formFields?.thirdPartyBooking !== false}
              />

              <span
                data-testid="checkOutCalendarDay"
                className={clsx(
                  'icon-arrow-down',
                  props?.formFields?.thirdPartyBooking !== false || !selectedCheckInDate ? 'show-disabled' : ''
                )}
                onClick={
                  props?.formFields?.thirdPartyBooking === true || props?.pageLoadFlag
                    ? undefined
                    : handleCheckOutDateClick
                }
              ></span>
              {props.errorStates?.checkOutEnteredDate && (
                <span className="t-label-s validation-msg">{authorModelData?.checkOutValidation}</span>
              )}
            </div>

            <div className=" ">
              {isCheckOutCalendarOpen && selectedCheckInDate && (
                <Suspense fallback={<>loading</>}>
                  <div className="calendar-wrapper mb-3">
                    <Calendar
                      variation={'DESKTOP'}
                      startDate={moment(selectedCheckOutStartDate)}
                      endDate={null}
                      focusedInput={'customDate'}
                      setFocusedInputHandler={(newState): void => {
                        console.log('setFocusedInputHandler', newState);
                      }}
                      customDatesFlag={true}
                      nightsLabel="nights"
                      nightLabel="night"
                      maxAllowedDays={7}
                      weekdays={shortWeekdays}
                      weekdaysLong={longWeekDaysName}
                      monthsShort={shortMonthName}
                      weekdaysShort={shortWeekDaysName}
                      disableHoverToolTip={true}
                      months={longMonthName}
                      defaultMonth={today}
                      disabledDays={[]}
                      onDayClickEvent={handleDayCheckOutDateClick}
                      customFromDate={customCheckoutDate}
                      customToDate={today}
                      numberOfMonths={1}
                      firstDayOfWeek={authorModelData.firstDayOfWeek || '0'}
                      showOutsideDays={true}
                      setDateHandler={handleDateChange}
                      displayFooterSection={true}
                      footerItems={[
                        <Button
                          className="color-scheme5 m-link-action link-button clear-action-button px-0"
                          callback={() => handleDayCheckOutDateClick(undefined)}
                          testId={authorModelData?.clearButtonLabel}
                          buttonCopy={authorModelData?.clearButtonLabel}
                        ></Button>,
                      ]}
                    />
                  </div>
                </Suspense>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 pt-4 pt-md-2 pb-4 mb-0 mb-md-4 pl-0">
          <span className="t-font-s">{authorModelData?.textUnderCheckInDate}</span>
        </div>

        <div className="step-two__question ">
          <div className="question-text pb-2" data-testid="step2-question">
            <span>{authorModelData?.hotelBillQuestion}</span>
          </div>
          <div
            className="steptwo__question--radiobutton d-flex flex-column flex-md-row pt-4"
            aria-label={authorModelData?.hotelBillQuestion}
            role="radiogroup"
          >
            <div className="m-radio-button-sm mr-4 mb-3 mb-md-0">
              <input
                type="radio"
                id="digitalCopy"
                value="digitalCopy"
                name="billingOption"
                data-testid="digitalCopy"
                checked={props?.formFields?.digitalBillSelection}
                disabled={props?.formFields?.thirdPartyBooking !== false}
                onChange={() => {
                  props?.setFormFields({
                    ...props?.formFields,
                    digitalBillSelection: true,
                    paperBillSelection: false,
                    noBillSelection: false,
                    faxOrMailSelection: false,
                    scannedCopySelection: false,
                    documentIds: null,
                  });
                }}
                onKeyDown={e => handleRadioButtonKeyDown(e, 'digitalCopy')}
                aria-checked={props?.formFields?.digitalBillSelection}
              />
              <label className="t-body-s" htmlFor="digitalCopy">
                {authorModelData?.billOptionRadioOneLabel}
              </label>
            </div>
            <div className="m-radio-button-sm mr-4 mb-3 mb-md-0">
              <input
                type="radio"
                id="paperCopy"
                value="paperCopy"
                name="billingOption"
                data-testid="paperCopy"
                disabled={props?.formFields?.thirdPartyBooking !== false}
                checked={props?.formFields?.paperBillSelection}
                onChange={() => {
                  props?.setFormFields({
                    ...props?.formFields,
                    paperBillSelection: true,
                    digitalBillSelection: false,
                    noBillSelection: false,
                    faxOrMailSelection: false,
                    scannedCopySelection: false,
                    documentIds: null,
                  });
                }}
                onKeyDown={e => handleRadioButtonKeyDown(e, 'paperCopy')}
                aria-checked={props?.formFields?.paperBillSelection}
              />
              <label className="t-body-s" htmlFor="paperCopy">
                {authorModelData?.billOptionRadioTwoLabel}
              </label>
            </div>
            <div className="m-radio-button-sm mr-4 mb-3 mb-md-0">
              <input
                type="radio"
                id="noBill"
                value="noBill"
                name="billingOption"
                data-testid="noBill"
                disabled={props?.formFields?.thirdPartyBooking !== false}
                checked={props?.formFields?.noBillSelection}
                onChange={() => {
                  props?.setFormFields({
                    ...props?.formFields,
                    noBillSelection: true,
                    paperBillSelection: false,
                    digitalBillSelection: false,
                    faxOrMailSelection: false,
                    scannedCopySelection: false,
                    documentIds: null,
                  });
                }}
                onKeyDown={e => handleRadioButtonKeyDown(e, 'noBill')}
                aria-checked={props?.formFields?.noBillSelection}
              />
              <label className="t-body-s" htmlFor="noBill">
                {authorModelData?.billOptionRadioThreeLabel}
              </label>
            </div>
          </div>
        </div>

        <div className="step-two__findbill pt-0 pt-md-3">
          <div
            className={clsx(
              't-body-s step-two__findbill--accordian',
              props?.formFields?.thirdPartyBooking === true || props?.pageLoadFlag ? 'show-disabled' : ''
            )}
            onClick={() => !props?.formFields?.thirdPartyBooking && handleAccoridanToggle()}
            onKeyDown={e => !props?.formFields?.thirdPartyBooking && handleAccordionKeyDown(e)}
            tabIndex={props?.formFields?.thirdPartyBooking !== false ? -1 : 0}
            role="button"
          >
            <span className="findbill-accordian t-font-s">{authorModelData?.accordionTitle}</span>
            <Icon
              iconClass={clsx('pl-2', !toggleAccordian ? 'icon-arrow-down' : 'icon-arrow-up')}
              ariaLabel={!toggleAccordian ? 'icon-arrow-down' : 'icon-arrow-up'}
            />
          </div>
          {toggleAccordian && (
            <RichText
              customClass="t-body-s mt-2 t-font-s accordion--message"
              text={accordionMessage}
              componentId={'missingStayRequestStepTwoAccordion'}
            />
          )}
        </div>
      </div>
    </ReportMissingStayStepTwoStyle>
  );
};
