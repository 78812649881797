/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import moment from 'moment';
import dynamic from 'next/dynamic';
import { inspect } from 'util';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Types, useCheckBreakpoint, Messages, RichText } from '@marriott/mi-ui-library';
import { PageContext } from '../../modules/context/PageContext';
import { generateApolloClientHeaders, OVERVIEW_CONSTANT, formatDate, getCalednarLocaleKeys } from '../../modules';
import { OverviewMovableComponents, apiLogger } from '../../modules/utils';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { StyledEarnedRewards } from './EarnedRewards.styles';
import { loyaltyinformationDetails } from './EarnedRewards.types';
import { phoenixAccountGetMyActivityRewardsEarned } from '../../modules/graph/index';
import cardData from './__mock__/earnedRewardsmock.json';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';

const OverviewSectionHeading = dynamic(() =>
  import('../../molecules/OverviewSectionHeading').then(mod => mod.OverviewSectionHeading)
);
const AwardsCard = dynamic(() => import('./AwardsCard').then(mod => mod.AwardsCard));
const InsideItem = dynamic(() => import('./EarnedRewardsUtils').then(mod => mod.InsideItem));

export const EarnedRewards = (props: any) => {
  const { IS_LOCAL_DEV } = process.env;
  // ***********useState and useEffect section***********
  const [loyaltyDetails, setLoyaltyDetails] = useState<loyaltyinformationDetails>();
  const [totalStayNights, setTotalStayNight] = useState(0);
  const pageContext = useContext(PageContext);
  const isTablemtViewPortAbove = useCheckBreakpoint('viewportM'); // check if viewport is desktop
  const dataLoaded = useRef<boolean>(false);
  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;

  const { memberLevel, memberData } = useAccountPersistentStore(state => state);
  const { componentMoved, setComponentHasData, setComponentIsLoading, setComponentHasError, setAwardsCount } =
    useOverviewStore(state => state);

  const isEarnedRewardsMoved = componentMoved === OverviewMovableComponents.EARNED_REWARDS;

  const { TITANIUM_CODE, PLATINUM_CODE, AMBASSADOR_CODE } = OVERVIEW_CONSTANT;

  getCalednarLocaleKeys(props?.model); // to initialize moment with the locale date object

  useEffect(() => {
    if (isAuthorMode) {
      setLoyaltyDetails(cardData);
      const nightAwardsDetail = cardData?.customer?.loyaltyInformation?.suiteNightAwards?.available;
      setAwardsCount(nightAwardsDetail?.count, nightAwardsDetail?.details);

      isOverViewPage && setComponentHasData(OverviewMovableComponents.EARNED_REWARDS, true);
    }
  }, []);

  useEffect(() => {
    const stayNights = memberData?.customer?.loyaltyInformation?.rewardsSummary?.yearly?.[0]?.totalNights;
    if (stayNights) {
      setTotalStayNight(stayNights);
    }
  }, [memberData]);

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !isAuthorMode && !pageContext.sessionData;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  // ***********UXL***********
  const { loading: rewardsEarnedLoading, error: rewardsEarnedError } = useQuery(
    phoenixAccountGetMyActivityRewardsEarned,
    {
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      skip: skipQuery,
      onCompleted: (data: any) => {
        dataLoaded.current = true;
        setLoyaltyDetails(data);
        const nightAwardsDetail = data?.customer?.loyaltyInformation?.suiteNightAwards?.available;
        setAwardsCount(nightAwardsDetail?.count, nightAwardsDetail?.details);

        apiLogger(`[EarnedRewards] getEarnedRewardsData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
      },
      onError: error => {
        apiLogger(
          `[EarnedRewards] getEarnedRewardsData - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
        );
      },
    }
  );

  const isFreenightPresent =
    loyaltyDetails?.customer?.loyaltyInformation?.certificates &&
    loyaltyDetails?.customer?.loyaltyInformation?.certificates?.total;

  const isSuitnightPresent =
    loyaltyDetails?.customer?.loyaltyInformation?.suiteNightAwards &&
    loyaltyDetails?.customer?.loyaltyInformation?.suiteNightAwards?.available?.count;

  const isAnualChoiceBenefit = !!(
    !!(TITANIUM_CODE.includes(memberLevel) && totalStayNights >= 75) ||
    !!(PLATINUM_CODE.includes(memberLevel) && totalStayNights >= 50) ||
    !!(AMBASSADOR_CODE.includes(memberLevel) && totalStayNights >= 50)
  );
  const isOverViewPage = pageContext?.isOverViewPage;

  const hasEarnedRewards =
    !!isFreenightPresent ||
    !!isSuitnightPresent ||
    (!!isAnualChoiceBenefit && !!isOverViewPage && !props?.model?.hideAnnualChoiceBenefitSection);

  const suiteNightAwardsDetails =
    loyaltyDetails?.customer?.loyaltyInformation?.suiteNightAwards?.available?.details || [];
  let totalSuiteNightAwards = 0;
  for (const detail of suiteNightAwardsDetails) {
    totalSuiteNightAwards += detail?.count ?? 0;
  }

  const certificatesData = loyaltyDetails?.customer?.loyaltyInformation?.certificates?.edges || [];

  // Sort the valid certificates by their expiration dates in ascending order
  const validCertificates = certificatesData.filter(edge => edge?.node?.expirationDate);
  validCertificates.sort((preDate, postDate) => {
    const preDateValue = new Date(preDate?.node?.expirationDate || '').getTime();
    const postDateValue = new Date(postDate?.node?.expirationDate || '').getTime();
    return preDateValue - postDateValue;
  });

  useEffect(() => {
    if (rewardsEarnedLoading) {
      isOverViewPage && setComponentIsLoading(OverviewMovableComponents.EARNED_REWARDS, true);
    }
  }, [rewardsEarnedLoading]);

  useEffect(() => {
    if (rewardsEarnedError) {
      isOverViewPage && setComponentHasError(OverviewMovableComponents.EARNED_REWARDS, true);
    }
  }, [rewardsEarnedError]);

  useEffect(() => {
    if (loyaltyDetails) {
      isOverViewPage && setComponentHasData(OverviewMovableComponents.EARNED_REWARDS, hasEarnedRewards);
    }
  }, [loyaltyDetails]);

  if (rewardsEarnedError) {
    return (
      <div id={OverviewMovableComponents.EARNED_REWARDS}>
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }
  // ************return statement*******************

  return (
    <StyledEarnedRewards
      id={OverviewMovableComponents.EARNED_REWARDS}
      data-testid="earnedRewards"
      data-component-name="o-account-earnedRewards"
    >
      {(!isOverViewPage || (isOverViewPage && componentMoved)) && (
        <>
          <div className="container">
            {(isOverViewPage ? hasEarnedRewards && !isEarnedRewardsMoved : hasEarnedRewards) && (
              <Heading
                variation={Types.headingType.title}
                titleText={`${props?.model?.title}`}
                customClass={clsx(
                  't-overline-normal mb-3 earned-rewards__heading',
                  isOverViewPage ? 'pt-4 pt-md-5 mt-md-2' : 'mt-5'
                )}
              />
            )}
          </div>
          <div className={clsx(isEarnedRewardsMoved && 'mt-md-4 mt-5', 'container')}>
            <div className="m-0 w-100 full-width">
              {hasEarnedRewards && isEarnedRewardsMoved && (
                <>
                  {' '}
                  <OverviewSectionHeading
                    title={props?.model?.title}
                    sectionHeadingClass={'px-5 py-3'}
                    isComponentMoved={isEarnedRewardsMoved}
                  />
                  <div className="border-line" />
                </>
              )}
              <div className={clsx('row', isEarnedRewardsMoved && 'awards-container')}>
                {!!isFreenightPresent && (
                  <div id="reward" className={clsx(isEarnedRewardsMoved ? 'col-12' : 'col-md-6', 'w-100')}>
                    <AwardsCard
                      data={validCertificates.map(edge => (
                        <InsideItem
                          key={edge?.node?.awardType?.label}
                          dataDetails={{
                            label: edge?.node?.awardType?.label,
                            description: edge?.node?.awardType?.description,
                            expiryDate: edge?.node?.expirationDate,
                            separator: edge?.node?.awardType?.description && edge?.node?.awardType?.label ? ':' : '',
                            classIcon: '',
                            expiry: props?.model?.expiresLabel,
                          }}
                          currentLocale={currentLocale}
                        />
                      ))}
                      icon={'icon-free-night-certificates'}
                      header={props?.model?.accordionTitle}
                      footer={props?.model?.ctaLabel}
                      showTag={props?.model?.showDetailsLabel}
                      hideTag={props?.model?.hideDetailsLabel}
                      link={props?.model?.ctaPath}
                      iconNum={loyaltyDetails?.customer?.loyaltyInformation?.certificates?.edges?.length}
                      isEarnedRewardsMoved={isEarnedRewardsMoved}
                    />
                  </div>
                )}
                {!!isSuitnightPresent && (
                  <div
                    id="reward"
                    className={clsx(
                      isEarnedRewardsMoved ? 'col-12' : 'col-md-6',
                      'w-100',
                      !isTablemtViewPortAbove && !isEarnedRewardsMoved && 'mt-1'
                    )}
                  >
                    <AwardsCard
                      data={loyaltyDetails?.customer?.loyaltyInformation?.suiteNightAwards?.available?.details?.map(
                        edge => (
                          <InsideItem
                            dataDetails={{
                              label: props?.model?.nightlyUpgradeAwardsTitleLabel,
                              description: `${edge?.count} ${
                                Number(edge?.count || 0) <= 1 ? props?.model?.nightLabel : props?.model?.nightsLabel
                              }`,
                              expiryDate: edge?.expirationDate,
                              separator: '',
                              classIcon: 'icon-dot',
                              expiry: props?.model?.expiresLabel,
                            }}
                            currentLocale={currentLocale}
                          />
                        )
                      )}
                      icon={'icon-suite-night-awards'}
                      header={props?.model?.nightlyUpgradeAwardsTitleLabel}
                      footer={props?.model?.nightlyUpgradeAwardsCtaLabel}
                      showTag={props?.model?.showDetailsLabel}
                      hideTag={props?.model?.hideDetailsLabel}
                      link={props?.model?.nightlyUpgradeViewAwardsCtaPath}
                      iconNum={totalSuiteNightAwards}
                      isEarnedRewardsMoved={isEarnedRewardsMoved}
                    />
                  </div>
                )}
                {!!isAnualChoiceBenefit && !!isOverViewPage && !props?.model?.hideAnnualChoiceBenefitSection && (
                  <div id="reward" className={clsx(isEarnedRewardsMoved ? 'col-12' : 'col-md-6', 'w-100')}>
                    <AwardsCard
                      icon={'icon-gift'}
                      header={props?.model?.annualChoiceBenefitTitleLabel}
                      footer={props?.model?.annualChoiceBenefitCtaLabel}
                      link={props?.model?.viewAwardsCtaPath}
                      isAnualChoice={isAnualChoiceBenefit}
                      anualChooseLabel={`${props?.model?.annualChoiceBenefitDescription} `}
                      expiryDate={`${formatDate(
                        `${moment().endOf('year').format('YYYY-MM-DD')}`,
                        currentLocale,
                        isOverViewPage
                      )}`}
                      isEarnedRewardsMoved={isEarnedRewardsMoved}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </StyledEarnedRewards>
  );
};

export const EarnedRewardsConfig = {
  emptyLabel: 'EarnedRewards',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/earnedRewards`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EarnedRewardsEditable = (props: any) => (
  <EditableComponent config={EarnedRewardsConfig} {...props}>
    <EarnedRewards {...props} />
  </EditableComponent>
);

// export default EarnedRewardsEditable;
