/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { LocaleUtils } from 'react-day-picker';
import moment from 'moment';
import forge from 'node-forge';
import {
  baseVariables,
  getDateObject,
  getLocalizedDate,
  getDateObjectCurrent,
  eventUtil,
} from '@marriott/mi-ui-library';
import { ConsentType } from '../store/profileStore';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
  BREAKPOINT_MOBILE_TEXT,
  BREAKPOINT_DESKTOP_TEXT,
  BREAKPOINT_TABLET_TEXT,
  DATA_LAYER_PROPERTIES,
  calendarConstants,
  SNA_Status_REQUESTED,
  SNA_Status_DENIED,
  SNA_Status_GUARANTEED,
  constants,
  DATE_FORMATE,
  DEFAULT_VIEW_BILL_FILE_NAME,
  FILENAME_REGEX,
  localesWithDateFirst,
  localeDateFormats,
  PLATINUM_CORE_CODE,
  PLATINUM_ACHIEVER_LEVELTYPE,
  PLATINUM_REFERRRAL_LEVELTYPE,
  PLATINUM_LIFETIME_ELITE_LEVELTYPE,
  PLATINUM_PREMIER_PFS_LEVELTYPE,
  PLATINUM_PREMIER_PFR_LEVELTYPE,
  PLATINUM_PREMIER_ELITE_AMBASSADOR_LEVELTYPES,
  PLATINUM_PREMIER_ELITE_LEVELTYPE,
  MOB_NUM_MIN_LENGTH,
  AllLocalesKeysListConstant,
  DOT_SYMBOL,
  oneClickJoinRandDecryptURL,
  SYSTEM_MAINTENANCE_ERROR_URL,
  accountConstants,
  OPTED_OUT_PERSONALIZATION_KEY,
  ENROLLMENT_US_CONSENT_ENUM,
  ENROLLMENT_CONSTANTS,
  EDIT_CHANGE_PASSWORD_ID,
  BANNER_MSGS_KEYS,
  EDIT_PERSONAL_INFO_ID,
  TWO_STEP_VERIFICATION_ID,
  CreditCardType,
  ONE_CLICK_JOIN_MODAL_TYPE,
  FORGOT_PWRD_ID,
} from './constants';
import {
  canadaZipcodeRegex,
  canadaZipcodeWithSpaceRegex,
  emailRegex,
  lengthValidationRegex,
  usZipcodeRegex,
} from './regexUtils';
import { ACCOUNT_MODAL_VARIATION } from './accountModalEvents';
import { replaceText } from './promotionHelper';

declare global {
  interface String {
    getSymbol(): string;
  }
}

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export function getItemFromPageModel(pageModel: any, itemPath: string) {
  const parts = itemPath.split('/');
  let obj = pageModel;

  for (let i = 0; i < parts.length && obj; i++) {
    obj = (obj[':items'] || {})[parts[i]];
  }

  return obj || {};
}

export const getProcessEnvs = () => {
  if (canUseDOM) {
    const envTag = document.getElementById('__SERVERENV__');
    if (envTag) {
      const envObject = JSON.parse(envTag.innerHTML);
      return envObject;
    }
    return process.env;
  }
  return process.env;
};

//converts ":items" format to "cqItems" format.
export function transformToCQ(propKey: string) {
  const tempKey = propKey.substring(1);

  return 'cq' + tempKey.substring(0, 1).toUpperCase() + tempKey.substring(1);
}

//To format mock model in local dev, in other envs this formatting is taken care by OOTB fetchModel utility
export const respGridUtil = (item: any) => {
  if (!item || !Object.keys(item).length) {
    return { cqPath: '' };
  }

  const keys = Object.getOwnPropertyNames(item);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {};

  keys.forEach((key: string) => {
    const propKey = key.startsWith(':') ? transformToCQ(key) : key;
    props[propKey] = item[key] || '';
  });

  return props;
};

//metthod to check if Object is empty
export const isEmpty = (value: object): boolean => {
  return value && Object.keys(value).length === 0;
};

export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    return window.innerWidth > BREAKPOINT_DESKTOP
      ? BREAKPOINT_DESKTOP_TEXT
      : window.innerWidth > BREAKPOINT_TABLET
      ? BREAKPOINT_TABLET_TEXT
      : BREAKPOINT_MOBILE_TEXT;
  } else {
    return undefined;
  }
};

export const getClassForBreakpoint = (
  breakpoint: string | undefined,
  desktopClass: string,
  tabletClass: string,
  mobileClass: string
): string => {
  if (!breakpoint) return desktopClass;
  if (desktopClass.indexOf(' ') > -1 || tabletClass.indexOf(' ') > -1 || mobileClass.indexOf(' ') > -1) {
    console.error(
      'Improper use of getClassForBreakpoint utility: To avoid mixed-purpose class assignment logic, please pass one class per breakpoint.'
    );
  }
  if (breakpoint === 'tablet') {
    return tabletClass;
  } else if (breakpoint === 'mobile') {
    return mobileClass;
  } else return desktopClass;
};

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }) {
  return `var dataLayer = ${dataLayer?.data ? JSON.stringify(dataLayer?.data[0]) : '{}'}; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };`;
}

export function getScreenCategory() {
  let screenCategory = '';
  if (window.innerWidth < 240) {
    screenCategory = 'XXS';
  } else if (window.innerWidth >= 240 && window.innerWidth < 320) {
    screenCategory = 'XS';
  } else if (window.innerWidth >= 320 && window.innerWidth < 480) {
    screenCategory = 'S';
  } else if (window.innerWidth >= 480 && window.innerWidth < 560) {
    screenCategory = 'SM';
  } else if (window.innerWidth >= 560 && window.innerWidth < 672) {
    screenCategory = 'M';
  } else if (window.innerWidth >= 672 && window.innerWidth < 768) {
    screenCategory = 'MML';
  } else if (window.innerWidth >= 768 && window.innerWidth < 864) {
    screenCategory = 'ML';
  } else if (window.innerWidth >= 864 && window.innerWidth < 960) {
    screenCategory = 'MLL';
  } else if (window.innerWidth >= 960 && window.innerWidth < 1024) {
    screenCategory = 'L';
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
    screenCategory = 'XL';
  } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
    screenCategory = 'XXL';
  } else if (window.innerWidth >= 1440) {
    screenCategory = 'XXXL';
  } else {
    screenCategory = 'Unknown';
  }

  return screenCategory;
}

// updated datalyer props from clientside
export function UpdateDatalayerObj(dataLayer: Record<string, unknown>) {
  dataLayer['page_data_layer_ready'] = true;

  let deviceOrientation = DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
  const screenCategory = getScreenCategory();

  if (window.innerWidth < 576 && window.innerHeight > window.innerWidth) {
    deviceOrientation = DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_ORIENTATION_PORTRAIT;
  }

  dataLayer[
    'page_breakpt_orientation'
  ] = `${DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window.innerWidth}w: ${deviceOrientation}`;

  dataLayer['previous_page'] = window.document?.referrer;
}

// get colorcodes by mebership level
export function getColorByCode(colorCode: string, isBackground: boolean) {
  // adding constant as there is no global reference for this colorcode
  const newMember = '#e6e6e6';

  switch (colorCode) {
    case 'P':
    case 'PAC':
    case 'PAR':
    case 'PLR':
      return `${baseVariables.color['platinumSolid']} !important`;
    case 'PPR':
    case 'PPC':
    case 'PL7':
    case 'PPE':
    case 'T':
      return `${baseVariables.color['titaniumSolid']} !important`;
    case 'G':
      return `${baseVariables.color['goldSolid']} !important`;
    case 'S':
      return `${baseVariables.color['silverSolid']} !important`;
    case 'A':
    case 'PFS':
    case 'PFR':
    case 'PPAE':
      return `${baseVariables.color['ambassadorSolid']} !important`;
    default:
      return isBackground ? `${newMember} !important` : `${baseVariables.color['base10']} !important`;
  }
}

// get colorcodes by mebership level
export function getGradientByCode(colorCode: string | null | undefined, isOverview?: boolean) {
  switch (colorCode) {
    case 'P':
    case 'PAC':
    case 'PAR':
    case 'PLR':
      return `${baseVariables.color['platinumGradient']}`;
    case 'PPR':
    case 'PPC':
    case 'PL7':
    case 'PPE':
    case 'T':
      return `${baseVariables.color['titaniumGradient']}; !important`;
    case 'G':
      return `${baseVariables.color['goldGradient']} !important`;
    case 'S':
      return `${baseVariables.color['silverGradient']} !important`;
    case 'A':
    case 'PFS':
    case 'PFR':
    case 'PPAE':
      return `${baseVariables.color['ambassadorGradient']} !important`;

    default:
      return `${isOverview ? baseVariables.color['base20'] : baseVariables.color['memberGradient']} !important`;
  }
}

// function returns the text color base on member status
export function getTextColorByCode(colorCode: string | undefined) {
  if (colorCode === null || colorCode === undefined || colorCode === 'R') {
    return `${baseVariables.color['base10']}`;
  } else {
    return `${baseVariables.color['base20']}`;
  }
}
//to get range of date
export const formatDateRange = (start: any, end: any, currentLocale: string) => {
  if (start && end) {
    const stratDateObj = getDateObjectCurrent(start);
    const endDateObj = getDateObjectCurrent(end);
    const startYear = stratDateObj.year();
    const endYear = endDateObj.year();
    const startMonth = stratDateObj.month();
    const endMonth = endDateObj.month();
    const locale = currentLocale?.replace('_', '-') ?? 'en-US';
    let localeDateFormat = localeDateFormats[locale]?.fullDate || localeDateFormats['default']?.fullDate;

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        let leftDateFormat;
        let rightDateFormat;
        if (localesWithDateFirst?.includes(locale)) {
          leftDateFormat = localeDateFormats[locale]?.onlyDate;
          rightDateFormat = localeDateFormats[locale]?.excludeYear;
        } else {
          leftDateFormat = localeDateFormats[locale]?.excludeYear;
          rightDateFormat = localeDateFormats[locale]?.onlyDate;
        }

        return `${stratDateObj.format(leftDateFormat)}  -  ${endDateObj.format(rightDateFormat)}`;
      } else {
        localeDateFormat = localeDateFormats[locale]?.excludeYear;
      }
    } else {
      localeDateFormat = localeDateFormats[locale]?.fullDate;
    }
    return `${stratDateObj.format(localeDateFormat)}  -  ${endDateObj.format(localeDateFormat)}`;
  }
  return '';
};

export function formatDate(
  inputDateStr: string | undefined,
  currentLocale: string | undefined,
  isOverViewPage?: boolean
) {
  // Null check for inputDateStr
  if (!inputDateStr) return '';
  const dateObj = getDateObject(inputDateStr);
  if (currentLocale === 'de_DE') {
    // German locale format: dd.mm.yyyy
    return getLocalizedDate(dateObj, DATE_FORMATE?.GERMAN_DATE_FORMAT);
  } else {
    // Default format: Month dd, yyyy
    if (isOverViewPage) {
      return getLocalizedDate(dateObj, DATE_FORMATE?.ENGLISH_FULLMONTH_FORMAT);
    } else {
      return getLocalizedDate(dateObj, DATE_FORMATE?.ENGLISH_DATE_FORMAT);
    }
  }
}

//generate ritz-carlton HWS url.
export const generateRitzCarltonHotelUrl = (propertyId: string, propertyName: any, currentLocale: string) => {
  const propertyUrlName = propertyName
    ?.toLowerCase()
    .replace('-', ' ')
    .replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
    .replace(/^(-)+|(-)+$/g, '');
  if (currentLocale === 'en_US' || currentLocale === 'en_GB') {
    currentLocale = currentLocale.replace('_', '-');
  } else {
    currentLocale = currentLocale?.split('-')?.[0];
  }
  const url = RITZ_CARLTON_URl?.replace(
    '{currentLocale}',
    SUBDIRECTORY_PREFIX?.toLocaleLowerCase() || currentLocale?.toLocaleLowerCase()
  )
    ?.replace('{propertyId}', propertyId?.toLowerCase())
    ?.replace('{propertyUrlName}', propertyUrlName);
  return url;
};
//generate HWS url.
export const generateViewHotelUrl = (propertyId: string, propertyName: any) => {
  const propertyUrlName = propertyName
    ?.toLowerCase()
    .replace('-', ' ')
    .replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
    .replace(/^(-)+|(-)+$/g, '');
  const url = HWS_RESERVATION_URL?.replace('{propertyId}', propertyId?.toLowerCase())?.replace(
    '{propertyUrlName}',
    propertyUrlName?.toLowerCase()
  );
  return addSubDirectoryPrefix(url);
};

// generate masked string
export const maskEmail = (email: string | undefined | null) => {
  if (!email || email?.trim() === '') {
    return ' ';
  }
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = localPart?.slice(0, 2) + '*'?.repeat(localPart?.length - 4) + localPart?.slice(-2);
  return `${maskedLocalPart}@${domain}`;
};

/**
 *
 * @param url as str
 * This is used find the nth occurence of a char in string
 */
const findNthOccurence = function (str: string, nth: number, char: string) {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};

/**
 *
 * @param prefix
 * This is used for setting the prefix value to the variable from Page level attribute in model.json.
 */
let SUBDIRECTORY_PREFIX = '';
export function setSubDirectoryPrefix(prefix = '') {
  SUBDIRECTORY_PREFIX = prefix;
}
/**
 *
 * @param hwsUrl
 * This is used for setting the hwsUrl value to the variable from Page level attribute in model.json.
 */
let HWS_RESERVATION_URL = '';
export function setHWSReservationURL(hwsUrl = '') {
  HWS_RESERVATION_URL = hwsUrl;
}
let RITZ_CARLTON_URl = '';
export function setRitzCarltonUrl(url = '') {
  RITZ_CARLTON_URl = url;
}
/**
 *
 * @param url
 * This is used for prefix the url. This will check for subdirectory prefix
 * if present then it will be prefixed otherwise the url is returned without prefix.
 */
export const addSubDirectoryPrefix = function (url = '') {
  if (
    SUBDIRECTORY_PREFIX &&
    SUBDIRECTORY_PREFIX.toLowerCase() !== 'en-us' &&
    url?.startsWith('/') &&
    url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
  ) {
    return SUBDIRECTORY_PREFIX ? '/' + SUBDIRECTORY_PREFIX + url : url;
  } else return url;
};

export const addLocalePrefix = function (sourceUrl: string, targetUrl: string) {
  const localeString = sourceUrl?.substring(1, findNthOccurence(sourceUrl, 1, '/')) || '';
  if (localeString.length === 2 || (localeString.length === 5 && localeString.charAt(2) === '-')) {
    return '/' + localeString + targetUrl;
  } else return targetUrl;
};

// generate apollo clientHeaders
export const generateApolloClientHeaders = (
  isLocalDev: boolean,
  pageContext: any,
  extraHeaders: { [key: string]: string | boolean } = {}
) => {
  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const accessToken = pageContext?.sessionData?.cacheData?.data?.accessToken;

  const context: any = {
    headers: {
      'accept-language': currentLocale?.replace('_', '-'),
      ...extraHeaders,
    },
  };

  if (isLocalDev && accessToken) {
    context.headers = {
      ...context.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return context;
};

export const checkUSDomainFuntion = (currentLocale: string) => {
  const localeToCheck = currentLocale?.replace('_', '-');
  return localeToCheck === AllLocalesKeysListConstant.en;
};

//suite nigh awards status check
export function getStatusMessage(code: any, requestedMsg: any, deniedMsg: any, upgradedMsg: any) {
  switch (code) {
    case SNA_Status_REQUESTED:
      return requestedMsg;
    case SNA_Status_GUARANTEED:
      return upgradedMsg;
    case SNA_Status_DENIED:
      return deniedMsg;
    default:
      return null;
  }
}

// convert locale string to {countryCode: '', langCode: ''}
// This will take the current locale string as parameter and return a object with [countryCode] and [langCode] as a key
export const getCountryAndLangCode = (input: string) => {
  let currentLocaleArr;
  if (input?.includes('_')) currentLocaleArr = input?.split('_');
  if (input?.includes('-')) currentLocaleArr = input?.split('-');
  return {
    countryCode: currentLocaleArr && currentLocaleArr[1],
    langCode: currentLocaleArr && currentLocaleArr[0],
  };
};

// check if locale is set to china
// This will take the current locale string as parameter and return a boolean based on country code(China=CN) condition check
export const checkChinaLocale = (currLocale: string) => {
  const { countryCode } = getCountryAndLangCode(currLocale);
  return countryCode === constants.CHINA_COUNTRY_CODE;
};

export const checkUSLocale = (currLocale: string) => {
  const { countryCode } = getCountryAndLangCode(currLocale);
  return countryCode === constants.USA_COUNTRY_CODE;
};

export const checkKOLocale = (currLocale: string) => {
  const { countryCode } = getCountryAndLangCode(currLocale);
  return countryCode === constants.KOREA_COUNTRY_CODE;
};

export const getCalednarLocaleKeys = (data: any) => {
  /** required value for calendar
   * these value updates as per locale
   * we will get week days infromation from AEM
   * update AEM value and fallback
   */
  let shortWeekdays: Array<string> = data?.shortWeekDays?.replaceAll(' ', '')?.split(/,|،/);
  // set default weekdays if i18 weekdays value are invalid
  if (shortWeekdays?.length !== 7) {
    shortWeekdays = calendarConstants.SHORT_WEEK_DAYS;
  }

  let shortWeekDaysName: Array<string> = data?.shortWeekDaysName?.replaceAll(' ', '')?.split(/,|،/);
  // set default weekdays if i18 weekdays value are invalid
  if (shortWeekDaysName?.length !== 7) {
    /** fallback value if we are not getting this details from AEM */
    shortWeekDaysName = calendarConstants.SHORT_WEEK_DAYS_NAME;
  }

  let longWeekDaysName: Array<string> = data?.longWeekDaysName?.replaceAll(' ', '')?.split(/,|،/);
  if (longWeekDaysName?.length !== 7) {
    /** fallback value if we are not getting this details from AEM */
    longWeekDaysName = calendarConstants.LONG_WEEK_DAYS_NAME;
  }

  let longMonthName: Array<string> = data?.longMonthName?.replaceAll(' ', '')?.split(/,|،/);
  // set default month if i18 months value are invalid
  if (longMonthName?.length !== 12) {
    longMonthName = LocaleUtils.getMonths();
  }

  let shortMonthName: Array<string> = data?.shortMonthName?.replaceAll(' ', '')?.split(/,|،/);
  // set default month if i18 months value are invalid
  if (shortMonthName?.length !== 12) {
    shortMonthName = calendarConstants.SHORT_MONTH_NAME;
  }

  moment.updateLocale('locale', {
    months: longMonthName,
    monthsShort: shortMonthName,
    weekdays: longWeekDaysName,
    weekdaysShort: shortWeekDaysName,
  });
  return {
    shortWeekdays,
    shortWeekDaysName,
    longWeekDaysName,
    longMonthName,
    shortMonthName,
  };
};

// add arrow to anchor tag in rte label
export function addClassToBlankTargetLinks(inputString: string, inputTarget: string = '_blank'): string {
  if (canUseDOM) {
    //TO DO:- To remove dom parser
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(inputString, 'text/html');

    // Find all <a> tags with target="_blank"
    const targetInput = `a[target='${inputTarget}']${inputTarget !== '_blank' ? `, a[target='_blank']` : ''}`;

    const blankTargetLinks = parsedDocument.querySelectorAll(targetInput);

    blankTargetLinks.forEach(link => {
      if (inputTarget !== '_blank') {
        link.setAttribute('target', '_blank');
      }
      const arrowIcon = parsedDocument.createElement('span');
      arrowIcon.classList.add('icon-external-arrow'); // Add your arrow class here
      // Append the arrow icon to the anchor tag
      link.appendChild(arrowIcon);
    });

    // Serialize the modified DOM back to a string
    const modifiedString = new XMLSerializer().serializeToString(parsedDocument);

    return modifiedString;
  } else {
    return inputString;
  }
}

// function to get the memberStatusDescription based on levelCode
export function getMemberStatusDescription(
  levelCode: string | undefined,
  memberStatusList: Array<{ id: string; label: string }>
) {
  const memberStatusDescription = memberStatusList?.filter(memberStatusData => memberStatusData.id === levelCode);
  return memberStatusDescription?.[0]?.label ?? '';
}

export function getFileNameFromContentDisposition(contentDisposition: string) {
  const filenameRegex = FILENAME_REGEX;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return DEFAULT_VIEW_BILL_FILE_NAME;
}

export function convertDateToLocaleSpecific(date?: string, locale?: string, dateFormat?: string): string {
  if (date) {
    moment.locale('locale');
    const dateObj = moment(date);
    const localeDateFormat = dateFormat ?? localeDateFormats[locale?.replace('_', '-') ?? 'en-US']?.fullDate;
    return dateObj.format(localeDateFormat);
  }
  return '';
}

export function getMemberLevel(levelCode: string | undefined, levelTypeCode: string) {
  if (levelCode?.toUpperCase() === PLATINUM_CORE_CODE && levelTypeCode) {
    switch (levelTypeCode?.toUpperCase()) {
      case PLATINUM_ACHIEVER_LEVELTYPE:
      case PLATINUM_REFERRRAL_LEVELTYPE:
      case PLATINUM_LIFETIME_ELITE_LEVELTYPE:
        return PLATINUM_CORE_CODE;
      case PLATINUM_PREMIER_PFS_LEVELTYPE:
      case PLATINUM_PREMIER_PFR_LEVELTYPE:
        return PLATINUM_PREMIER_ELITE_AMBASSADOR_LEVELTYPES;
      default:
        return PLATINUM_PREMIER_ELITE_LEVELTYPE;
    }
  }
  return levelCode;
}

export const memberLink = (code: string | undefined) => {
  switch (code) {
    case 'P':
      return 'platinum';
    case 'S':
      return 'silver';
    case 'G':
      return 'gold';
    case 'PPAE':
    case 'A':
      return 'ambassador';
    case 'PPE':
    case 'PPR':
    case 'PPC':
    case 'PL7':
    case 'T':
      return 'titanium';
    case 'M':
      return 'member';
    default:
      return 'member';
  }
};

/**
 * add sub directory prefixes to the header urls if they don't have it
 */

export const correctSubDirectoryPathForHeader = () => {
  /**
   * add subdirectory path if path is not there
   */
  const headerChildArr =
    (document.querySelector('header')?.querySelector('nav')?.querySelector('ul')?.children as any) || []; //access header link on page
  Array.from(headerChildArr)?.map((el: any) => {
    const anchor = el.querySelector('a');
    const tripDataVal = anchor?.dataset?.authenticatedUrl;
    const overlayEndpoint = anchor?.dataset?.overlayEndpoint;
    const urlString: string = anchor?.attributes?.href?.value;
    if (urlString !== '#' && !urlString.startsWith('https')) {
      anchor.setAttribute('href', addSubDirectoryPrefix(urlString));
    }
    if (tripDataVal) {
      anchor.dataset.authenticatedUrl = addSubDirectoryPrefix(tripDataVal);
    }
    if (overlayEndpoint) {
      anchor.dataset.overlayEndpoint = addSubDirectoryPrefix(overlayEndpoint);
    }
  });
};

export const getLocaleFromParams = (resolvedUrlString: string) => {
  const resolvedUrlParams = resolvedUrlString?.split('?')[1];
  const paramsArr = resolvedUrlParams?.split('&');
  let paramLocale;
  paramsArr?.forEach(param => {
    if (param.includes('locale=')) {
      paramLocale = param.split('=')[1];
    }
  });
  return paramLocale;
};

/**
 * getCurrentUrlParams() should return search parameters by reading current url from window.location
 */
export const getCurrentUrlParams = (isOverlay?: boolean) => {
  return isOverlay ? window?.parent?.location?.search : window?.location?.search;
};
/**
 * end of the code
 */

export const encryptPassword = (password: string, publicKey: string, enableEncryption: string) => {
  const isEnableEncryption = enableEncryption && JSON.parse(enableEncryption);
  if (isEnableEncryption && publicKey && publicKey !== null) {
    const key = forge.pki.publicKeyFromPem(publicKey);

    const encryptedPass = key.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha256.create(),
      },
    });

    return forge.util.encode64(encryptedPass);
  } else {
    return password;
  }
};

export const getSubDirectoryPrefixedUrl = (url: string, currLocale: string): string => {
  const isChinaLocale = checkChinaLocale(currLocale);
  if (!isChinaLocale) {
    return addSubDirectoryPrefix(url);
  }
  return url;
};

/**
 * truncate text if length exceeds maxLength and append with ellipsis
 */
export const truncateText = (text: string, maxLength: number) => {
  if (!text) {
    return '';
  }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
};

export const getRedirectUrlFromParams = (isEAASignIn: boolean, isOverlay: boolean, skipDecodeForPaths?: string) => {
  const urlParam = getCurrentUrlParams(isOverlay);
  const param = new URLSearchParams(urlParam);
  const returnToUrl = param.get(isEAASignIn ? 'redirect_uri' : 'returnTo');
  const shouldSkipDecode = skipDecode(returnToUrl, skipDecodeForPaths);
  return returnToUrl ? (shouldSkipDecode ? returnToUrl : decodeURIComponent(returnToUrl)) : null;
};

export const skipDecode = (redirectUrl: string | null, skipPaths: string | undefined) => {
  const paths = skipPaths?.split(',') ?? [];
  let decode = false;
  if (paths.length > 0) {
    decode = paths?.some(path => redirectUrl?.includes(path));
  }
  return decode;
};

export const capatilizeFirstLetter = (str: string) => {
  if (str) {
    const arr = str.split('');
    arr.shift();
    return `${str?.charAt(0).toUpperCase()}${arr.join('')}`;
  }
  return str;
};

// This function takes a mobile num as string and checks for mobile number's minimum length to determine its validity
export const checkMobileNumValidity = (mobNum: string | undefined) => {
  let isValid = true;
  if (!mobNum || (mobNum && mobNum.trim().substring(3)?.length < MOB_NUM_MIN_LENGTH)) {
    isValid = false;
  }
  return isValid;
};
// Validates a string value to check if it's null, undefined, or consists of only whitespace characters.
export const validateField = (value: string) => {
  return value === null || (typeof value === 'string' && value.trim().length === 0);
};

export const getMaskedDot = (isMobile?: boolean) => {
  const dots = DOT_SYMBOL.repeat(4);
  if (isMobile) {
    return dots; // for mobile need single set of 4 dots
  } else {
    return dots + ' ' + dots + ' ' + dots;
  }
};

export const isCardExpired = (expiration: { month: number; year: number }): boolean => {
  const month = expiration?.month;
  const year = expiration?.year;
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  return year < currentYear || (year === currentYear && month < currentMonth);
};

export const getNextPeriodnYears = (len: number, defaultLabel: string) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: len }, (_, i) => ({
    id: `${currentYear + i}`,
    value: `${currentYear + i}`,
  }));
  return [{ id: '', value: defaultLabel }, ...years];
};
export const handleClickRte = (handleLinkClick: any, id: string) => {
  const rteLink = document.querySelector(id);
  rteLink?.addEventListener('click', handleLinkClick as EventListener);
};

export const randDecrpytAPI = async (randParameter?: string) => {
  return axios.get(oneClickJoinRandDecryptURL, {
    params: {
      rand: randParameter,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const isReservationModal = (isAccountModal?: boolean, variation?: string) => {
  return (isAccountModal && variation === ACCOUNT_MODAL_VARIATION?.RESERVATION) ?? false;
};

export const isArraysEqual = (arr1: string[], arr2?: string[]) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  return arr1.every(value => arr2?.includes(value));
};
export const getErrorUrl = (errorType: string) => replaceText(SYSTEM_MAINTENANCE_ERROR_URL, [errorType]);

export const checkAndSetConsentChecked = (
  consentFeatures: { feature: { code: string }; value: string | null }[] | undefined,
  CONSENT_CODE: string,
  valueToCheck: string
): boolean => {
  const foundFeature = consentFeatures?.find(preference => preference?.feature.code === CONSENT_CODE);
  return foundFeature ? foundFeature?.value === valueToCheck : false;
};

//Checks if a given string contains Chinese characters.
export const isChinese = (str: string) => {
  return /[\u4E00-\u9FFF]/.test(str);
};
export const modalScrollHandler = (isModalOpen?: boolean) => {
  if (canUseDOM && document.body) {
    isModalOpen
      ? document.body.style.setProperty('overflow', 'hidden', 'important')
      : document.body.style.removeProperty('overflow');
  }
};

export const getContentBlockImageDimensions = (breakpoint: 'lg' | 'md' | 'sm' | 'all', isOverlay = false) => {
  switch (breakpoint) {
    case 'lg':
      return accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.lg;
    case 'md':
      return isOverlay ? accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.lg : accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.md;
    case 'sm':
      return isOverlay ? accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.md : accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.sm;
    default:
      return accountConstants.CONTENT_BLOCK_IMAGE_WIDTH.sm;
  }
};

// util to validate if the LHS and RHS countries are same
export const isCoutriesSame = (countryRHS: string, countryLHS?: string) => {
  return countryLHS && countryRHS && countryLHS === countryRHS;
};

export const checkForSpecialCharacters = (str: string): boolean => {
  const regex = /[^a-zA-Z0-9]/;
  return regex.test(str);
};

export function updateConsentFeatureOptOut(
  consentFeatures: ConsentType[] | undefined,
  consentChecked: boolean | undefined
) {
  const updatedConsentFeatures = consentFeatures?.map(feature => {
    if (feature.feature.code === OPTED_OUT_PERSONALIZATION_KEY) {
      return { ...feature, value: consentChecked ? 'N' : 'Y' };
    }
    return feature;
  });

  return updatedConsentFeatures;
}

// This method is used to fetch enrollment source code from the mapped list of enrollment source code based on locales.
//It accepts locale as input and extracts country code from it to generate enrollment source code key to fetches related enrollment source code
export const getEnrollmentSourceCodeFromCountry = (
  locale: string,
  sourceMap?: {
    id?: string;
    value?: string;
  }[]
) => {
  const localeCountryCode = getCountryAndLangCode(locale)?.countryCode?.toUpperCase();
  return getEnrollmentSourceCodeFromKey(
    localeCountryCode === ENROLLMENT_US_CONSENT_ENUM?.SP
      ? ENROLLMENT_CONSTANTS?.LACA
      : `${localeCountryCode}${ENROLLMENT_CONSTANTS?.SOURCE_CODE_KEY_SUFFIX}`,
    sourceMap
  );
};

// This method is used to fetch enrollment source code from the mapped list of enrollment source code based on locales.
//It accepts enrollment Source Code Key as input and fetches related enrollment source code
export const getEnrollmentSourceCodeFromKey = (
  enrollmentSourceCodeKey?: string,
  sourceMap?: {
    id?: string;
    value?: string;
  }[]
) => {
  return sourceMap?.filter(item => item.id === enrollmentSourceCodeKey)?.[0]?.value;
};

export const handleClickRoomDetail = ({ e, id, item, key }: any) => {
  e?.preventDefault();
  eventUtil?.dispatch(key, {
    id,
    data: {
      roomData: item,
    },
  });
};
//get current timestamp
export function getCurrentTimestamp() {
  const date = new Date();
  // Convert it to an ISO string (e.g., "2024-05-10T10:22:04Z")
  const timestamp = date.toISOString();

  return timestamp;
}

export const getBannerMessages = (bannerId: string) => {
  switch (bannerId) {
    case EDIT_CHANGE_PASSWORD_ID:
    case FORGOT_PWRD_ID:
      return BANNER_MSGS_KEYS.PASSWORD_UPDATED_MSG;
    case EDIT_PERSONAL_INFO_ID:
      return BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG;
    case TWO_STEP_VERIFICATION_ID:
      return BANNER_MSGS_KEYS.TWO_STEP_VERIFICATION_MSG;
    default:
      return '';
  }
};

export const getCardTypeCode = (cardType: string): string | undefined => {
  return Object.keys(CreditCardType).find(key => CreditCardType[key as keyof typeof CreditCardType] === cardType);
};

// This method is used to get redirect url for OCJ modals
// To get error Return url, we need to pass true as second parameter
export const getOCJRedirectUrl = (modalType?: string, getErrorUrl?: boolean) => {
  const isCheckedIn = modalType === ONE_CLICK_JOIN_MODAL_TYPE?.CHECKED_IN;
  const isUpcoming = modalType === ONE_CLICK_JOIN_MODAL_TYPE?.UPCOMING;

  if (getErrorUrl) {
    return isCheckedIn
      ? constants?.CHECK_IN_CONFIRMATION
      : isUpcoming
      ? constants?.FIND_RESERVATION_DETAIL
      : constants.CREATE_ACCOUNT_PAGE_URL;
  } else {
    return isUpcoming ? constants?.FIND_RESERVATION_DETAIL : constants.FIND_RESERVATION_LIST_PAGE_URL;
  }
};

//This method checks for:
// Valid US or Canada zipcode
export const checkForValidZipCode = (zipCode: string, countryCode: string) => {
  const trimmedZipcode = zipCode.trim();
  return (
    lengthValidationRegex.test(trimmedZipcode) &&
    ((countryCode === constants?.CANADA_COUNTRY_CODE &&
      (canadaZipcodeWithSpaceRegex.test(trimmedZipcode) || canadaZipcodeRegex.test(trimmedZipcode))) ||
      (countryCode === constants?.USA_COUNTRY_CODE && usZipcodeRegex.test(trimmedZipcode)))
  );
};

export function getWindowSession(): any {
  if (canUseDOM) {
    return window['sessionDataClient' as any] ?? window['sessionData'] ?? {};
  }
  return {};
}

export function loadLocaleCurrency(currencySymbol: string) {
  const sessionObject = getWindowSession();
  const localeCode = sessionObject?.locale?.split(/_|-/)?.[1];

  const rk_currency = sessionObject?.cacheData?.data?.AriesCommon?.rk_currency;
  const list: { [key: string]: string } = {};
  rk_currency?.split(',')?.forEach((pair: string) => {
    const [rk, currency] = pair.split(':');
    list[rk.trim()] = currency?.trim();
  });
  const localeCurrency = list[localeCode];

  // eslint-disable-next-line no-extend-native
  String.prototype.getSymbol = function () {
    const currency = String(this || '');
    const isLocaleCurrency = localeCurrency?.toUpperCase() === currency?.toUpperCase();
    return currencySymbol && isLocaleCurrency ? currencySymbol : currency;
  };
}
//check email formate function
export function isValidEmaiFormat(email: string): boolean {
  // Check if email matches the regex and has length between 5 and 80
  return emailRegex.test(email) && email.length >= 5 && email.length <= 80;
}

//This method use to scroll top of modal body if any error encountered
export function scrollToClass(className: string = '') {
  if (className) {
    const element = window?.document?.getElementsByClassName(className);
    element && element?.[0]?.scrollTo?.({ top: 0, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

/**
 *
 * @param isEAA
 * @param url
 * This is used to prefix the url with subdirectory for non eaa requests. This will check for subdirectory prefix
 * if present then it will be prefixed otherwise the url is returned without prefix.
 */
export const addSubDirectoryPrefixForNonEaa = function (url = '', isEAA: boolean = false) {
  if (isEAA) {
    return url;
  } else {
    return addSubDirectoryPrefix(url);
  }
};
