import React, { FC, useContext, useEffect, useId, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { inspect } from 'util';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Modal, CheckBox, RadioButton, InputTextField, RichText, Button, Messages } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import clsx from 'clsx';
import creditCardType from 'credit-card-type';
import {
  BANNER_MSGS_KEYS,
  EDIT_CARD_MODAL_ID,
  PROCESS_CREDIT_CARD_ENDPOINT,
  PageContext,
  apiLogger,
  COUNTRY_CODE_ADD_CARD_ZIPINPUT,
  ADD_CARD_CLICK_TRACK,
  EDIT_CARD_CLICK_TRACK,
} from '../../modules';
import {
  phoenixAccountDeleteCustomerPaymentMethod,
  phoenixAccountGetWalletPaymentInfo,
  phoenixAccountUpdateCustomerPaymentMethod,
} from '../../modules/graph/index';
import {
  getCurrentUrlParams,
  validateField,
  getNextPeriodnYears,
  getMaskedDot,
  generateApolloClientHeaders,
  isCardExpired,
  getCurrentTimestamp,
  getCardTypeCode,
  scrollToClass,
} from '../../modules/utils/helper';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useProfileStore } from '../../modules/store/profileStore';
import { Dropdown } from '../../molecules/Dropdown';
import { DropdownOption } from '../../molecules/Dropdown/Dropdown.types';
import { StyledAddCard } from './AddCard.styles';
import { AddCardProps, CustomError } from './AddCard.types';

// Use named rather than default exports.
export const AddCard: FC<AddCardProps> = (props: AddCardProps) => {
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { IS_LOCAL_DEV } = process.env;
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ADD_CARD_MARSHA_CODE: marshaCode } = clientEnvVars;
  const serverENV = pageContext?.serverENV ? JSON.parse(pageContext?.serverENV) : {};
  const defaultYearLimitDropdown = parseInt(serverENV?.CREDIT_CARD_EXPIRY_YEAR_LIMIT);
  const {
    setProfileModalId,
    profileModalId,
    paymentId,
    setProfileDetails,
    setPaymentId,
    profileDetails,
    setRevisionToken,
  } = useProfileStore(state => state);
  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [formData, setFormData] = useState({
    cardName: '',
    cardNumber: '',
    cardBillingZip: '',
  });
  const inputId = useId();
  const cardType = props?.model?.items;
  const modalBodyClass = 'add-edit-card-modal-body';
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const { memberData } = useAccountPersistentStore(store => store);
  const { number: memberNumber } = memberData?.customer?.loyaltyInformation?.rewards || {};
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const isEditCardSection = profileModalId === EDIT_CARD_MODAL_ID;
  const [selectedYear, setSelectedYear] = useState<DropdownOption>({ id: '', value: props?.model?.yearLabel });
  const [selectedMonth, setSelectedMonth] = useState<DropdownOption>({ id: '', value: props?.model?.monthLabel });
  const [deleteCreditCardConsent] = useMutation(phoenixAccountDeleteCustomerPaymentMethod);
  const [getWalletUpdatePayment] = useLazyQuery(phoenixAccountGetWalletPaymentInfo);
  const [updatePaymentMethod] = useMutation(phoenixAccountUpdateCustomerPaymentMethod);
  const [hasError, setHasError] = useState({ isErrortrue: false, isCommonErrorMsg: '' });
  const [isUxlError, setIsUxlError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [errors, setErrors] = useState({
    cardNumberError: false,
    zipCodeError: false,
  });
  const yearObjects = useMemo(
    () => getNextPeriodnYears(defaultYearLimitDropdown || 10, props?.model?.yearLabel),
    [defaultYearLimitDropdown]
  );
  const matchingCard = useMemo(() => {
    return profileDetails?.paymentMethods?.find(paymentMethod => paymentMethod?.id === paymentId);
  }, [profileDetails, paymentId]);
  const [purposeOption, setPurposeOption] = useState('');
  useEffect(() => {
    //set default year
    const defaultYearId = matchingCard?.expiration?.year;
    const defaultYearOption: DropdownOption = yearObjects.find(
      year => year.id === (defaultYearId?.toString() || '')
    ) || {
      id: defaultYearId?.toString() || '',
      value: defaultYearId?.toString() || props?.model?.yearLabel,
    };
    setSelectedYear(defaultYearOption);
    const initialMonthId = matchingCard?.expiration?.month;

    const months = monthList(props?.model?.monthName);
    // Include {id: '', value: 'Month'} in the month list
    months.unshift({ id: '', value: props?.model?.monthLabel });
    const defaultMonth = months.find(month => month.id === initialMonthId?.toString()) || months[0];
    setSelectedMonth(defaultMonth);
    //set default purpose
    setPurposeOption(matchingCard?.purpose?.code || 'B');
    //alternative card expired
    if (matchingCard) {
      const isCardExpiredNow = isCardExpired({
        month: matchingCard?.expiration.month,
        year: matchingCard?.expiration.year,
      });
      if (isCardExpiredNow) {
        setHasError({ isErrortrue: true, isCommonErrorMsg: '' });
        setErrorMessages([
          matchingCard?.preferred ? props?.model?.errMsgPrimCardExp : props?.model?.errMsgAlterCardExp,
        ]);
        scrollToClass(modalBodyClass);
      }
      setFormData({ ...formData, cardName: matchingCard?.nickname ?? '' });
    }
    if (!profileDetails.paymentMethods) {
      setIsDefaultCard(true);
    } else if (isEditCardSection) {
      setIsDefaultCard(matchingCard?.preferred ? true : false);
    }
  }, [matchingCard, yearObjects, props?.model?.monthName, profileDetails.paymentMethods]);
  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    if (
      param.get('editMyCreditCards') &&
      param.get('editMyCreditCards') === 'true' &&
      param.get('paymentId') === paymentId
    ) {
      setProfileModalId(EDIT_CARD_MODAL_ID);
    }
    if (param.get('editMyCreditCards') && param.get('add') && param.get('add') === 'true') {
      setProfileModalId('add-card');
    }
  }, []);

  //validate credit card
  const validateCreditCardNumber = (number: string): boolean => {
    const pattern = /^[a-zA-Z0-9\u0020]{14,20}$/;
    return !pattern.test(number);
  };

  //check for special character and space
  const checkForZipCodeValidation = (str: string): boolean => {
    const inputVal = str?.replaceAll(/ /g, ''); // replace all spaces in the string for validations as per the AREIS
    if (inputVal.length) {
      return (
        inputVal.length === 5 ||
        inputVal.length === 6 ||
        (inputVal.length === 9 && inputVal.indexOf('-') === -1) ||
        (inputVal.length === 10 && inputVal.indexOf('-') !== -1)
      );
    }
    return false;
  };

  //handle expired
  const checkCardExpiredMsg = (selectedMonthId: string, selectedYearId: string) => {
    const cardErrors = [];
    const isCardExpiredNow = isCardExpired({
      month: parseInt(selectedMonthId),
      year: parseInt(selectedYearId),
    });
    let isInvalid = false;
    const updatedErrors = { cardNumberError: false, zipCodeError: false };

    const validCreditCard = validateCreditCardNumber(formData.cardNumber);
    const isCardNumberFound = checkCardNumberInPaymentCards();
    if (isCardNumberFound && !isEditCardSection) {
      cardErrors.push(props?.model?.errorMsgAddDuplicateCredCard);
      isInvalid = true;
    }

    if (!isEditCardSection) {
      if (!formData.cardNumber) {
        isInvalid = true;
        cardErrors.push(isDefaultCard ? props?.model?.errorMsgReqPrefCardNum : props?.model?.errorMsgReqAlterCardNum);
        updatedErrors.cardNumberError = true;
      } else if (validCreditCard) {
        isInvalid = true;
        cardErrors.push(
          isDefaultCard ? props?.model?.errorMsgPrefCardNumInvalid : props?.model?.errorMsgAltCardNumInvalid
        );
      }
    }
    if (selectedMonthId === '' || selectedYearId === '') {
      isInvalid = true;
      cardErrors.push(
        isDefaultCard || matchingCard?.preferred
          ? props?.model?.errorMsgReqPrefExp
          : props?.model?.errorMsgReqAlterExpDate
      );
    }
    if (isCardExpiredNow) {
      isInvalid = true;
      cardErrors.push(
        isDefaultCard || matchingCard?.preferred ? props?.model?.errMsgPrimCardExp : props?.model?.errMsgAlterCardExp
      );
    }
    if (
      (!formData?.cardBillingZip || !checkForZipCodeValidation(formData?.cardBillingZip)) &&
      !isEditCardSection &&
      COUNTRY_CODE_ADD_CARD_ZIPINPUT.includes(userCountryCode)
    ) {
      isInvalid = true;
      cardErrors.push(props?.model?.errorMsgReqZipCode);

      updatedErrors.zipCodeError = true;
    }
    setErrors(updatedErrors);
    setErrorMessages(cardErrors);
    setHasError({ isErrortrue: isInvalid, isCommonErrorMsg: props?.model?.errMsgCommonTitle });
    return isInvalid;
  };

  const checkCardNumberInPaymentCards = (): boolean => {
    const panLast4 = formData?.cardNumber.slice(-4);
    return profileDetails?.paymentMethods?.some(card => card?.panLast4 === panLast4);
  };
  function monthList(monthName: string): { id: string; value: string }[] {
    const monthsArray = monthName.split(',');
    let monthsObjectArray = monthsArray.map((month, index) => ({ id: String(index + 1), value: month.trim() }));
    monthsObjectArray = [{ id: '', value: props?.model?.monthLabel }, ...monthsObjectArray];
    return monthsObjectArray;
  }
  // validate fields
  const validateAddCardsFields = () => {
    let isValid = true;
    if (
      validateField(formData?.cardNumber) ||
      (userCountryCode && userCountryCode === 'US' && validateField(formData?.cardBillingZip || ''))
    ) {
      isValid = false;
    }
    return isValid;
  };

  //error messages
  function processErrorResponse(errorMessageKey: string[], props: AddCardProps) {
    if (errorMessageKey.includes('cc.payment.domain.rejected.error')) {
      setHasError({ isErrortrue: true, isCommonErrorMsg: '' });
      setErrorMessages([props?.model?.errMsgPaymentDomainRejected]);
    } else if (errorMessageKey.includes('cc.payment.domain.system.failure.error')) {
      setHasError({ isErrortrue: true, isCommonErrorMsg: '' });
      setErrorMessages([props?.model?.errMsgPaymentDomainSystemFailure]);
    } else if (errorMessageKey.includes('cc.payment.domain.error')) {
      setHasError({ isErrortrue: true, isCommonErrorMsg: '' });
      setErrorMessages([props?.model?.errMsgPaymentDomain]);
    } else if (errorMessageKey.includes('credit_card_number_invalid')) {
      setHasError({ isErrortrue: true, isCommonErrorMsg: '' });
      setErrorMessages([
        isDefaultCard || matchingCard?.preferred
          ? props?.model?.errorMsgPrefCardNumInvalid
          : props?.model?.errorMsgAltCardNumInvalid,
      ]);
    } else {
      setHasError({ isErrortrue: true, isCommonErrorMsg: hasError.isCommonErrorMsg });
      setErrorMessages([pageContext?.uxlErrorMessage]);
    }
    scrollToClass(modalBodyClass);
  }

  //handle changes
  const handleExpiryYearChange = (selectedValue: DropdownOption) => {
    setSelectedYear(selectedValue);
  };
  const handleExpiryMonthChange = (selectedValue: DropdownOption) => {
    setSelectedMonth(selectedValue);
  };
  const handleCheckboxChange = () => {
    if (!profileDetails.paymentMethods) {
      setIsDefaultCard(true);
    } else {
      setIsDefaultCard(prevIsDefaultCard => !prevIsDefaultCard);
    }
  };

  const resetErrorMessages = () => {
    setHasError({ isErrortrue: false, isCommonErrorMsg: '' });
    setErrorMessages([]);
    setErrors({ cardNumberError: false, zipCodeError: false });
    setIsUxlError(false);
  };
  const handleResetData = () => {
    resetErrorMessages();
    setProfileModalId('');
    setPaymentId('');
    setFormData({ cardName: '', cardNumber: '', cardBillingZip: '' });
    setSelectedYear({ id: '', value: props?.model?.yearLabel });
    setSelectedMonth({ id: '', value: props?.model?.monthLabel });
    setIsDefaultCard(!profileDetails.paymentMethods ? true : false);
    setPurposeOption('B');
    setIsUxlError(false);
  };

  // method to clear form when add card returns anyn error message
  const handleFormClear = () => {
    setFormData({ cardName: '', cardNumber: '', cardBillingZip: '' });
    setSelectedYear({ id: '', value: props?.model?.yearLabel });
    setSelectedMonth({ id: '', value: props?.model?.monthLabel });
    setIsDefaultCard(!profileDetails.paymentMethods ? true : false);
    setPurposeOption('B');
  };

  //profilestore updated again
  const updateCustomerWalletOptions = () => {
    getWalletUpdatePayment({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const revisionToken = data?.customer?.revisionToken;
        setRevisionToken(revisionToken);
        const { wallet } = data?.customer || {};
        setProfileDetails({
          ...profileDetails,
          paymentMethods: wallet?.paymentMethods,
        });
        setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
        handleResetData();
        setIsUxlError(false);
        apiLogger(
          `[CreditCardChange] updateProfileDetails - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setIsUxlError(true);
        scrollToClass(modalBodyClass);
      },
    });
  };

  //click delete
  const handleDelete = () => {
    if (matchingCard?.preferred && profileDetails?.paymentMethods?.length !== 1) {
      setHasError({ isErrortrue: true, isCommonErrorMsg: props?.model?.errMsgCommonTitle });
      setErrorMessages([props?.model?.errorMsgRemovePrimaryCard]);
      scrollToClass(modalBodyClass);
    } else {
      deleteCreditCardConsent({
        variables: {
          input: {
            id: matchingCard?.id,
            customerId: sessionData?.consumerID,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        fetchPolicy: 'no-cache',
        onCompleted: data => {
          setIsUxlError(false);
          apiLogger(
            `[CreditCardChange] deleteCreditCard- sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
          );
          updateCustomerWalletOptions();
        },
        onError: () => {
          setIsUxlError(true);
          scrollToClass(modalBodyClass);
        },
      });
    }
  };

  const handleSubmit = async () => {
    //clear error messages
    resetErrorMessages();

    const invalid = checkCardExpiredMsg(selectedMonth?.id, selectedYear?.id);
    if (!invalid) {
      const creditcardTypeCode = creditCardType(formData?.cardNumber)[0];
      const cardType = creditcardTypeCode?.type || '';
      const cardTypeCode = getCardTypeCode(cardType);
      if (!isEditCardSection) {
        const hasValidateAddCard = validateAddCardsFields();
        if (hasValidateAddCard) {
          const payload = {
            ...(marshaCode && { marshaCode: marshaCode }),
            creditCardInfo: {
              consumerID: sessionData?.consumerID,
              memberId: memberNumber,
              expMonth: selectedMonth?.id,
              expYear: selectedYear?.id,
              creditCardType: cardTypeCode,
              creditCardCVV: '',
              creditCardNumber: formData?.cardNumber,
              cvvApplicable: false,
              saveChanges: true, // to be true for add card
              preferred: isDefaultCard,
              purpose: purposeOption,
              alias: formData.cardName,
              selectedCreditCard: 'selectedCreditCard',
              paymentMethodId: '',
              encodedPaymentMethodData: '',
              tokenizedPanNumber: '',
              inAuthID: `mi_${sessionData?.sessionToken}`, //sessionID
              accertifyTransactionID: getCurrentTimestamp(),
              memberLevelCode: '',
              creditCardInfoLabel: '',
              policyMessage: '',
              prefCreditCard: {
                selectedCreditCard: '',
                creditCardInfoLabel: '',
              },
              otherCreditCard: {
                selectedCreditCard: '',
                creditCardInfoLabel: '',
              },
              ...(formData?.cardBillingZip && { billingZipCode: formData?.cardBillingZip }),
            },
          };
          try {
            const response = await fetch(PROCESS_CREDIT_CARD_ENDPOINT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Cookie: 'sessionID=' + sessionData?.sessionToken,
              },
              body: JSON.stringify(payload),
            });
            if (response && response.status === 200) {
              const responseData = await response.json();
              const errorMessageKey = responseData?.errorMessages?.guestInfoFormErrorMessages?.errorMessageKeys || [];
              const savetoProfileKey = responseData?.creditCardInfo?.savedToProfile;
              setIsUxlError(false);
              //check for error message keys
              if (errorMessageKey.length > 0) {
                processErrorResponse(errorMessageKey, props);
                handleFormClear();
              } else if (!savetoProfileKey) {
                setHasError({ isErrortrue: true, isCommonErrorMsg: pageContext?.uxlErrorMessage });
                handleFormClear();
                scrollToClass(modalBodyClass);
              } else {
                updateCustomerWalletOptions();
              }
            }
            //add any since catch notation have to be any or unknown
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            if ((error as CustomError)?.response?.data) {
              const responseData = await error.response.json();
              const errorMessageKey = responseData?.errorMessages?.guestInfoFormErrorMessages?.errorMessageKeys || [];
              if (errorMessageKey.length > 0) {
                processErrorResponse(errorMessageKey, props);
              }
            } else {
              setIsUxlError(true);
              scrollToClass(modalBodyClass);
            }
            handleFormClear();
          }
        }
      } else {
        updatePaymentMethod({
          variables: {
            input: {
              customerId: sessionData?.consumerID,
              expiration: {
                month: parseInt(selectedMonth.id),
                year: parseInt(selectedYear.id),
              },
              id: paymentId,
              nickname: formData?.cardName,
              preferred: isDefaultCard,
              purpose: {
                code: purposeOption,
              },
              revisionToken: matchingCard?.revisionToken,
            },
          },
          context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
          onCompleted: data => {
            setIsUxlError(false);
            apiLogger(
              `[CreditCardPaymentMethod] updateCreditCardPaymentMethod - sessionId value: ${
                sessionData?.sessionToken
              }: ${inspect(data)}`
            );
            updateCustomerWalletOptions();
          },
          onError: () => {
            setIsUxlError(true);
            scrollToClass(modalBodyClass);
          },
        });
      }
    } else {
      handleFormClear();
      scrollToClass(modalBodyClass);
    }
  };

  return (
    <StyledAddCard data-testid="add-card" data-component-name="o-account-addcard">
      <Modal
        show={profileModalId === 'add-card' || isEditCardSection}
        popupOpenState={profileModalId === 'add-card' || isEditCardSection}
        setPopupOpenState={(): void => {
          handleResetData();
        }}
        modalId={props?.model?.modelId + 'modal'}
        labelledBy={props?.model?.modelId}
        onClose={(): void => {
          handleResetData();
        }}
        secondaryClassName="modal__container edit-add-card"
        disableScrollOnBody={true}
        aria-modal="true"
        role="dialog"
      >
        <Modal.Header
          className="credit-card-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={isEditCardSection ? props?.model?.editCardModelHeader : props?.model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            handleResetData();
          }}
          data-testid="credit-card-header"
        />
        <Modal.Body className={`modal__container-content py-0 px-0 add-edit-card-modal-body ${modalBodyClass}`}>
          <div className="row mx-5">
            {isUxlError && (
              <div data-testId="uxl-error-msg" className="error-msg">
                <Messages messageType="warning" className="mt-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              </div>
            )}
            {hasError.isErrortrue && (
              <div id={'credit-card'} data-testId="error-msg" className="error-msg pt-5">
                <Messages messageType="error-sev1" className="">
                  {hasError.isCommonErrorMsg && <RichText text={hasError.isCommonErrorMsg} componentId="error-msg" />}
                  {errorMessages.map(message => (
                    <RichText text={message} componentId="error-msg" />
                  ))}
                </Messages>
              </div>
            )}
            <RichText
              customClass={'t-font-m mt-5 px-0'}
              text={props.model?.cardDescription}
              componentId="addcard-descriptionone"
            />
            <div className="d-flex flex-column px-0 mb-2 pb-1">
              {cardType?.map(cType => (
                <RadioButton
                  data-testid={cType?.value}
                  radiobuttonId={cType?.value}
                  radiobuttonName={cType?.value}
                  radiobuttonLabel={cType?.value}
                  checked={purposeOption === cType?.id}
                  onChange={() => {
                    setPurposeOption(cType?.id);
                  }}
                  className="mb-2 modal__container_radio "
                  value={cType?.id}
                  setTabIndex={0}
                  labelClassName={'pt-1 pl-4'}
                  aria-label={`confirm-${cType?.value}`}
                  onKeyDown={event => event.key === 'Enter' && setPurposeOption(cType?.id)}
                ></RadioButton>
              ))}
            </div>
            <div className={clsx('col-12 card-form px-0 color-scheme1')}>
              <div className={clsx('t-font-m px-0')}>
                <InputTextField
                  label={props?.model?.cardNicknameLabel}
                  testId="Card-Nickname"
                  inputValue={formData?.cardName}
                  assignInputValue={true}
                  showErrorMessage={undefined}
                  messageToShow={''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-card-nickname`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-card-nickname`,
                  })}
                  className={clsx('card-Nickname m-input-field mt-4', formData?.cardName ? 'is-active' : '')}
                  getInputValue={(val: string) => {
                    setFormData({ ...formData, cardName: val });
                  }}
                />
              </div>
              <div className={clsx('t-font-m px-0 mt-5')} tabIndex={isEditCardSection ? 0 : -1}>
                <InputTextField
                  label={props.model?.cardNumberLabel || 'Card-Number'}
                  testId="Card-Number"
                  inputValue={clsx(
                    isEditCardSection ? getMaskedDot() + ' ' + matchingCard?.panLast4 : formData?.cardNumber
                  )}
                  showErrorMessage={undefined}
                  messageToShow={''}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-card-number`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-card-number`,
                  })}
                  className={clsx(
                    'm-input-field card-Number',
                    errors.cardNumberError ? 'is-error' : formData?.cardNumber ? 'is-active' : ' ',

                    isEditCardSection && 'is-disabled card-number-disabled'
                  )}
                  getInputValue={(val: string) => {
                    setFormData({ ...formData, cardNumber: val });
                  }}
                />
              </div>
              <div className={clsx('t-font-m expiry-date px-0 mt-5 d-flex flex-row')}>
                <div className={clsx('m-input-field t-font-m px-0 flex-fill ')}>
                  <label
                    className="cardExpiryYear-label mb-0"
                    id="dropdown-label-cardExpiryMonth"
                    htmlFor="dropdown-label-cardExpiryMonth"
                    data-testid="cardExpiryMonth-label"
                  >
                    {props.model?.expirationMonth}
                  </label>
                  <Dropdown
                    defaultOption={selectedMonth?.value}
                    dropdownOptions={monthList(props?.model?.monthName)}
                    onChange={handleExpiryMonthChange}
                    customClassName="cardExpiryYear--dropdown is-active"
                    dropdownId="cardExpiryMonth"
                  />
                </div>
                <div className={clsx('m-input-field t-font-m px-0 flex-fill')}>
                  <label
                    className="cardExpiryYear-label mb-0"
                    id="dropdown-label-cardExpiryYear"
                    htmlFor="dropdown-label-cardExpiryYear"
                    data-testid="cardExpiryYear-label"
                  >
                    {props?.model?.expirationYear}
                  </label>
                  <Dropdown
                    defaultOption={selectedYear?.value}
                    dropdownOptions={yearObjects}
                    onChange={handleExpiryYearChange} // Pass the function directly
                    customClassName="cardExpiryYear--dropdown is-active"
                    dropdownId="cardExpiryYear"
                  />
                </div>
              </div>
              {!isEditCardSection && COUNTRY_CODE_ADD_CARD_ZIPINPUT.includes(userCountryCode) && (
                <div className={clsx('t-font-m px-0 mt-5')}>
                  <InputTextField
                    label={props?.model?.zipcodeLabel}
                    testId="Postal-Code"
                    inputValue={formData?.cardBillingZip}
                    showErrorMessage={undefined}
                    messageToShow={''}
                    setErrorHtml={true}
                    messageClass="error-label"
                    inputMaxLength={23}
                    getInputProps={() => ({
                      autoComplete: 'off',
                      id: `${inputId}-cardBillingZip`,
                    })}
                    getLabelProps={() => ({
                      htmlFor: `${inputId}-cardBillingZip`,
                    })}
                    className={clsx(
                      'm-input-field',
                      errors.zipCodeError ? 'is-error' : formData?.cardBillingZip ? 'is-active' : ''
                    )}
                    getInputValue={(val: string) => {
                      setFormData({ ...formData, cardBillingZip: val?.trim() });
                    }}
                  />
                </div>
              )}
              {isEditCardSection && matchingCard?.preferred ? (
                <RichText
                  customClass={'default-label  my-5'}
                  text={
                    profileDetails?.paymentMethods?.length === 1
                      ? props?.model?.singleDefaultCardDescription
                      : props?.model?.defaultCardDescription
                  }
                  componentId="default-label"
                />
              ) : (
                <div className={clsx('t-font-m px-0 my-5')}>
                  <CheckBox
                    checked={isDefaultCard} // Pass the current checked state
                    onChange={handleCheckboxChange} // Callback for checkbox changes
                    data-testId="checkbox_defaulCard"
                    checkboxName="checkbox_checkbox_defaulCard"
                    checkboxId={'checkbox_defaulCard'}
                    className="checkbox_defaulCard t-subtitle-m "
                    checkboxLabel={props?.model?.defaultConsentLabel}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.FooterGeneric
          className="modal__container-footer p-5"
          customClass="flex-column flex-md-row modal__container__footer-container"
          actions={
            <div
              className={clsx(
                ' d-flex justify-content-center justify-content-md-end align-items-center add-edit-btn-wrapper',
                'flex-column flex-md-row'
              )}
            >
              <Button
                className={clsx(
                  ' m-link-action footer-button__cancel px-0 d-none d-md-block',
                  isEditCardSection ? 'editcard-footer__cancel' : 'addcard-footer__cancel'
                )}
                buttonCopy={props?.model?.cancelButton}
                callback={() => handleResetData()}
              />
              {isEditCardSection && (
                <Button
                  className={clsx(
                    'm-button-m footer-button__delete m-button-m m-button-secondary px-3 editcard-footer__delete my-3 my-md-0 d-none d-md-block',
                    profileDetails?.paymentMethods?.length === 1 ? '' : matchingCard?.preferred ? 'disabled' : ''
                  )}
                  buttonCopy={props?.model?.deleteCTALabel}
                  callback={() => handleDelete()}
                  isDisabled={
                    profileDetails?.paymentMethods?.length === 1 ? false : matchingCard?.preferred ? true : false
                  }
                />
              )}
              <Button
                className={clsx(
                  'm-button-m footer-button__save m-button-primary px-3',
                  isEditCardSection ? 'editcard-footer__save' : 'addcard-footer__save'
                )}
                buttonCopy={props?.model?.saveButton}
                callback={() => handleSubmit()}
                custom_click_track_value={isEditCardSection ? EDIT_CARD_CLICK_TRACK : ADD_CARD_CLICK_TRACK}
              />
              {isEditCardSection && (
                <Button
                  className={clsx(
                    'm-button-m footer-button__delete m-button-s m-button-secondary px-3 editcard-footer__delete d-md-none',
                    profileDetails?.paymentMethods?.length === 1 ? '' : matchingCard?.preferred ? 'disabled' : ''
                  )}
                  buttonCopy={props?.model?.deleteCTALabel}
                  callback={() => handleDelete()}
                  isDisabled={
                    profileDetails?.paymentMethods?.length === 1 ? false : matchingCard?.preferred ? true : false
                  }
                />
              )}
              <Button
                className={clsx(
                  ' m-link-action footer-button__cancel p-0 d-md-none',
                  isEditCardSection ? 'editcard-footer__cancel align-self-center' : 'addcard-footer__cancel'
                )}
                buttonCopy={props?.model?.cancelButton}
                callback={() => handleResetData()}
              />
            </div>
          }
        ></Modal.FooterGeneric>
      </Modal>
    </StyledAddCard>
  );
};
export const AddCardConfig = {
  emptyLabel: 'addcard',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/addcard`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AddCardEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <AddCard {...props} />
  ) : (
    <EditableComponent config={AddCardConfig} {...props}>
      <AddCard {...props} />
    </EditableComponent>
  );
};
