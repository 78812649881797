// Styles for LookUpMemberNumber go here.
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledLookUpMemberNumber = styled.div`
  ${rtl` 
    &:is(${constants.RTL_LANG_LIST}) {
      margin-left: auto;
      margin-right: ${toRem(0)} !important;
      text-align: justify;
    }

    .m-input-text-field input:is(${constants.RTL_LANG_LIST}) {
      text-indent: ${toRem(16)} !important;
      height: ${toRem(45)} !important;
    }

    .m-input-text-field label:is(${constants.RTL_LANG_LIST}) {
      right: ${toRem(11)} !important;
      width: max-content;
    }

    .lookup-countrydropdown {
      width: 100%;
    }

    .m-dropdown-container {
      .m-dropdown-children {
        height: auto;
        max-height: ${toRem(224)};
      }
    }

    .disableField * {
      color: ${baseVariables.color['neutral30']} !important;
      border-color: ${baseVariables.color['neutral30']} !important;
    }
    .m-input-field,
    .m-input-text-field {

      input {
        border-color: ${baseVariables.color['neutral40']} !important;
      }
      &.is-error label {
        color: var(--t-form-error-color) !important;
      }
      &.is-error input {
        border-color: var(--t-form-error-color) !important;
      }
      &.is-disabled {
        label {
          z-index: 1;
        }
      }
      caret-color: inherit; // This is to override transparent caret color
    }
    .error-label,
    .error-label * {
      font-size: ${baseVariables.font.fontXs};
      color: ${baseVariables.color.alert50} !important;
    }

    .link-underline {
      display: inline-block;
      width: fit-content;
    }
    .m-badge-inline-neutral {
      background-color: ${baseVariables.color.merch50} !important;
    }

    .account-page-error-msg:is(${constants.RTL_LANG_LIST}) {
      .m-message-inline {
        text-align: right;
      }
    }
    .account-page-error-msg p {
      margin-bottom: 0 !important;
    }
    .link-underline {
      text-decoration: underline;
    }
    .lookup-submit-mobile {
      width: 100%;
    }
    @media ${baseVariables.mediaQuery.md} {
      .lookup-submit-mobile {
        width: auto;
      }
    }
 `}
`;
