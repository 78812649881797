/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { inspect } from 'util';
import { useQuery, useLazyQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Types, Image, Icon, toRem, Messages, RichText } from '@marriott/mi-ui-library';
import {
  apiLogger,
  generateViewHotelUrl,
  generateRitzCarltonHotelUrl,
  CustomerTripOrderStatus,
  formatDateRange,
  localesWithLowerCaseSplCharacters,
  getCalednarLocaleKeys,
  genrateImageRenditionPathForAllMobileAndTablet,
} from '../../modules';
import { PageContext } from '../../modules/context/PageContext';
import { useStore } from '../../modules/store/memberLevelStore';
import { addClassToBlankTargetLinks, generateApolloClientHeaders, useGetBreakpoint } from '../../modules/utils/helper';
import { BREAKPOINT_DESKTOP_TEXT, BREAKPOINT_MOBILE_TEXT } from '../../modules/utils/constants/application-breakpoints';
import { PaginationWithPageSize } from '../../molecules/PaginationWithPageSize';
import { MapView } from '../../molecules/MapView/MapView';
import {
  fallbackImages,
  pageSizeLimit,
  pageSetLimit,
  ValidMarshCodes,
  ValidBrandIds,
  imageDomain,
  overviewUpcomingTripsCount,
  OverviewMovableComponents,
} from '../../modules/utils/constants';
import {
  phoenixAccountGetUpcomingTripsOfCustomer,
  phoenixAccountGetUpcomingTripsBasicInfo,
  phoenixAccountGetSuiteNightAwardsInfo,
} from '../../modules/graph';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { StyledUpcomingTrips } from './UpcomingTrips.styles';
import user from './__mock__/userDetails.json';
import propertiesByIds from './__mock__/upcomingTrips.json';
import { GalleryImageType, TripsSuiteNightAwardsType } from './UpcomingTrips.types';

const OverviewSectionHeading = dynamic(() =>
  import('../../molecules/OverviewSectionHeading').then(mod => mod.OverviewSectionHeading)
);
const TripDetails = dynamic(() => import('./TripDetails').then(mod => mod.TripDetails));
const NoUpComingTrips = dynamic(() => import('./NoUpcomingTrips').then(mod => mod.NoUpComingTrips));

export const UpcomingTrips = (pageProps: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const pageSize = pageSetLimit;
  const dropdownobj = { value: pageSize, id: pageSize };
  const authorModel = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const currentLocale = pageContext?.currentLocale;
  const localeVal = currentLocale ? currentLocale : 'en-US';
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [toggleAccordian, setToggleAccordian] = useState<number | null>(0);
  const [isMobileViewPort, setIsMobileViewPort] = useState(false);
  const [isDesktopViewPort, setIsDesktopViewPort] = useState(false);
  const [tripData, setTripData] = useState<any | null>(null);
  const [basicTripDetails, setBasicTripDetails] = useState<Array<any> | null>(null);
  const [previouBasicTripDetails, setPreviousBasicTripDetails] = useState<Array<any> | null>(null);
  const [prevBasicTripPropertyID, setPrevBasicTripPropertyID] = useState<Array<any> | null>(null);
  const [totalCount, setTotalCount] = useState();
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [tripsSuiteNightAwardsData, setTripsSuiteNightAwardsData] = useState<TripsSuiteNightAwardsType | undefined>();
  const dataLoaded = useRef<boolean>(false);
  const suitNightAwardInfoLoaded = useRef<boolean>(false);
  const [getUpcomingTripsBasicInfo, { data: basicTripsData, error: basicTripsError, loading: basicTripsLoading }] =
    useLazyQuery(phoenixAccountGetUpcomingTripsBasicInfo);
  const isOverviewPage = pageContext?.isOverViewPage;
  const pageLimit = isOverviewPage ? overviewUpcomingTripsCount : pageSizeLimit;
  const isUpcomingTripSelected = useStore(state => state.isUpcomingTripSelected) || isOverviewPage;
  const { setComponentHasData, setComponentIsLoading, setComponentHasError, componentMoved } = useOverviewStore(
    state => state
  );
  const upcomingTripsMoved = componentMoved === OverviewMovableComponents.UPCOMING_TRIPS;
  getCalednarLocaleKeys(pageProps?.model); // to initialize moment with the locale date object

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  useEffect(() => {
    if (isAuthorMode) {
      setComponentIsLoading(OverviewMovableComponents.UPCOMING_TRIPS, true);
      setTripData(user?.data?.customer?.orders?.edges);
      // TO-DO - update the hard coding with dynamic author value
      setCurrentPageNo(1);
      setBasicTripDetails(
        isOverviewPage ? propertiesByIds?.propertiesByIds.slice(0, 1) : propertiesByIds?.propertiesByIds
      );
      setComponentHasData(OverviewMovableComponents.UPCOMING_TRIPS, propertiesByIds?.propertiesByIds?.length > 0);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAccoridanToggle = (i: number) => {
    if (toggleAccordian === i) {
      setToggleAccordian(null);
    } else {
      setToggleAccordian(i);
    }
  };

  const handleResize = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setIsMobileViewPort(useGetBreakpoint() === BREAKPOINT_MOBILE_TEXT);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setIsDesktopViewPort(useGetBreakpoint() === BREAKPOINT_DESKTOP_TEXT);
  };
  const {
    loading: upcomingTripsLoading,
    error: upcomingTripsError,
    data: upcomingTripsData,
    refetch: upcomingTripsRefetch,
  } = useQuery(phoenixAccountGetUpcomingTripsOfCustomer, {
    variables: {
      customerId: sessionData?.consumerID,
      status: CustomerTripOrderStatus.active,
      limit: pageLimit,
      offset: 0,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    skip: skipQuery,
    onError: () => {
      isOverviewPage && setComponentHasError(OverviewMovableComponents.UPCOMING_TRIPS, true);
    },
  });

  /**
   * load user suite night awards for
   */
  useQuery(phoenixAccountGetSuiteNightAwardsInfo, {
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    fetchPolicy: 'no-cache',
    variables: {
      customerId: sessionData?.consumerID,
    },
    skip: suitNightAwardInfoLoaded.current || skipQuery,
    onCompleted: (data: TripsSuiteNightAwardsType) => {
      suitNightAwardInfoLoaded.current = true;
      setTripsSuiteNightAwardsData(data);
    },
  });

  const checkPrevPropBasicInfoId = (arr: Array<string>) => {
    if (prevBasicTripPropertyID) {
      const tripIDArr = prevBasicTripPropertyID?.filter(key => arr.includes(key));
      return tripIDArr.length === arr.length;
    }
    return false;
  };

  useEffect(() => {
    if (upcomingTripsLoading || basicTripsLoading)
      setComponentIsLoading(OverviewMovableComponents.UPCOMING_TRIPS, true);
  }, [upcomingTripsLoading, basicTripsLoading]);

  useEffect(() => {
    if (basicTripsError) isOverviewPage && setComponentHasError(OverviewMovableComponents.UPCOMING_TRIPS, true);
  }, [basicTripsError]);

  useEffect(() => {
    if (basicTripsData) {
      setBasicTripDetails(basicTripsData?.propertiesByIds);
      apiLogger(`[upcomingTrips] upcomingTripsBasicInfo - Success: ${inspect(basicTripsData)}`);
      setComponentHasData(OverviewMovableComponents.UPCOMING_TRIPS, basicTripsData?.propertiesByIds?.length > 0);
    }
  }, [basicTripsData]);

  useEffect(() => {
    if (upcomingTripsData) {
      setTripData(upcomingTripsData?.customer?.orders?.edges);
      setTotalCount(upcomingTripsData?.customer?.orders?.total);
      const upComingTripIDs: Array<string> = [];
      upcomingTripsData?.customer?.orders?.edges?.map((res: any) => {
        if (!upComingTripIDs.includes(res?.node?.items?.[0]?.property?.id)) {
          upComingTripIDs.push(res?.node?.items?.[0]?.property?.id);
        }
      });
      if (checkPrevPropBasicInfoId(upComingTripIDs)) {
        setBasicTripDetails(previouBasicTripDetails); // get property data from prev resposne
      } else {
        setPrevBasicTripPropertyID(upComingTripIDs); // compare the prev id to avoid cache issue
        getUpcomingTripsBasicInfo({
          variables: {
            ids: upComingTripIDs,
          },
          context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        });
      }
    }
  }, [upcomingTripsData]);

  const handleRefetch = (selectedCurrentPage: number) => {
    const newVariables = {
      customerId: sessionData?.consumerID,
      status: 'ACTIVE',
      limit: pageLimit,
      offset: pageLimit * (selectedCurrentPage - 1),
    };
    // dataLoaded.current = false;
    upcomingTripsRefetch(newVariables);
  };

  if (upcomingTripsError) {
    apiLogger(`[upcomingTrips] upcomingTripsData - Error: ${inspect(upcomingTripsError)}`);
    return (
      <div id={OverviewMovableComponents.UPCOMING_TRIPS}>
        {isUpcomingTripSelected && (
          <Messages messageType="warning" className="my-4">
            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
          </Messages>
        )}
      </div>
    );
  }

  if (basicTripsError) {
    apiLogger(`[upcomingTrips] upcomingTripsBasicInfo - Error: ${inspect(basicTripsError)}`);
    return (
      <div id={OverviewMovableComponents.UPCOMING_TRIPS}>
        {isUpcomingTripSelected && (
          <Messages messageType="warning" className="my-4">
            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
          </Messages>
        )}
      </div>
    );
  }

  return (
    <div
      id={OverviewMovableComponents.UPCOMING_TRIPS}
      className="upcoming-trips-main"
      data-component-name="o-account-upcomingtrips"
      data-testid="upcomingtrips"
    >
      {isUpcomingTripSelected &&
        (!isOverviewPage || (isOverviewPage && upcomingTripsMoved && basicTripDetails?.length)) && (
          <div>
            {!basicTripDetails ? (
              <StyledUpcomingTrips isOverviewPage={isOverviewPage} className="container">
                <div className="upcomingtrips mb-5 color-scheme7">
                  <div className="upcomingtrips__top">
                    <div className="upcomingtrips__top--header p-4 p-md-5 d-flex flex-row justify-content-between align-items-start">
                      <div className="header-right d-flex align-items-start flex-column">
                        <div
                          className="skeleton-loader mb-3"
                          style={{
                            width: `${toRem(320)}`,
                            height: `${toRem(16)}`,
                          }}
                        ></div>
                        <div
                          className="skeleton-loader"
                          style={{
                            width: `${toRem(320)}`,
                            height: `${toRem(24)}`,
                          }}
                        ></div>
                      </div>
                      <div className="header-left d-flex flex-row justify-content-between align-items-center">
                        <div
                          className="skeleton-loader"
                          style={{
                            width: `${toRem(320)}`,
                            height: `${toRem(16)}`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledUpcomingTrips>
            ) : (
              <div>
                {tripData?.length ? (
                  <StyledUpcomingTrips
                    isOverviewPage={isOverviewPage}
                    className={clsx(
                      'container',
                      isOverviewPage ? (upcomingTripsMoved ? 'mt-4 mb-3' : 'pt-4 pt-md-5 mt-md-2') : 'my-3'
                    )}
                  >
                    <div>
                      {tripData?.map((ele: any, index: any) => {
                        const tripDataIndex = index; // remove it
                        const tripDataItems = ele?.node?.items?.[0];
                        const tripDataId = tripDataItems?.property?.id;
                        const hotelData = basicTripDetails.filter(key => key?.id === tripDataId)[0];
                        if (hotelData && tripDataId) {
                          const primaryImage =
                            hotelData?.media?.primaryImage?.edges?.[0]?.node?.imageUrls.wideHorizontal;
                          const galleryImages = hotelData?.media?.photoGallery?.hotelView?.edges.filter(
                            (img: GalleryImageType) => img?.node?.imageUrls?.wideHorizontal
                          );

                          // Check if primaryImage is available, if not, use the first image from photoGallery hotelview if available, or fallback image
                          const primaryImageURL = primaryImage ? imageDomain + primaryImage : null;
                          const galleryImageURL =
                            galleryImages && galleryImages.length > 0
                              ? imageDomain + galleryImages[0]?.node?.imageUrls.wideHorizontal
                              : null;
                          const imageSourceURL = primaryImageURL || galleryImageURL || fallbackImages.Classic;
                          const dateRangeFormatter = formatDateRange(
                            tripDataItems?.basicInformation?.startDate,
                            tripDataItems?.basicInformation?.endDate,
                            currentLocale
                          );

                          const hotelHeader = (
                            <div className="header-right d-flex align-items-start flex-column">
                              <Heading
                                variation={Types.headingType.title}
                                titleText={
                                  localesWithLowerCaseSplCharacters?.includes(currentLocale?.replace('_', '-'))
                                    ? dateRangeFormatter
                                    : dateRangeFormatter?.toUpperCase()
                                }
                                customClass="date t-label-xs"
                              />

                              <a
                                href={
                                  ValidMarshCodes?.includes(tripDataId) ||
                                  ValidBrandIds?.includes(hotelData?.basicInformation?.brand?.id)
                                    ? generateRitzCarltonHotelUrl(
                                        tripDataId,
                                        hotelData?.basicInformation?.nameInDefaultLanguage,
                                        currentLocale
                                      )
                                    : generateViewHotelUrl(
                                        tripDataId,
                                        hotelData?.basicInformation?.nameInDefaultLanguage
                                      )
                                }
                                //TO DO: will clean this code later
                                // href={`/hotels/travel/${tripData.edges[
                                //   tripDataIndex
                                // ]?.node?.items?.[0]?.property?.id?.toLowerCase()}-${hotelData?.basicInformation?.name
                                //   ?.toLowerCase()
                                //   .replace(/[, ]+| +/g, '-')}`}
                                target={
                                  ValidMarshCodes?.includes(tripDataId) ||
                                  ValidBrandIds?.includes(hotelData?.basicInformation?.brand?.id)
                                    ? '_blank'
                                    : '_self'
                                }
                                rel="noreferrer"
                              >
                                <Heading
                                  variation={Types.headingType.subtitle}
                                  titleText={hotelData?.basicInformation?.name}
                                  fontSize={
                                    isOverviewPage
                                      ? isDesktopViewPort
                                        ? Types.size.large
                                        : Types.size.medium
                                      : isMobileViewPort
                                      ? Types.size.medium
                                      : Types.size.large
                                  }
                                  customClass={`name ${!isOverviewPage && 'mb-0'}`}
                                />
                              </a>
                            </div>
                          );
                          return (
                            <div key={tripDataIndex}>
                              <div className={clsx('upcomingtrips color-scheme7', !isOverviewPage && 'mb-5')}>
                                <div className="upcomingtrips__top">
                                  {!isOverviewPage ? (
                                    <div className="upcomingtrips__top--header px-4 py-5 p-md-5 d-flex flex-row justify-content-between">
                                      {hotelHeader}
                                      <div className="header-left d-flex flex-row justify-content-between align-items-center">
                                        {!isMobileViewPort && (
                                          <div className="top-brand pr-5 mr-4">
                                            <Icon
                                              iconClass={clsx(
                                                `brand-logo brand-logo-${hotelData?.basicInformation?.brand?.id.toUpperCase()}`
                                              )}
                                            ></Icon>
                                          </div>
                                        )}
                                        <div
                                          className="toggle"
                                          onClick={() => handleAccoridanToggle(tripDataIndex)}
                                          tabIndex={0}
                                          role="button"
                                          aria-label="toggle"
                                          data-testid="toggle"
                                        >
                                          {toggleAccordian === tripDataIndex ? (
                                            <Icon iconClass="icon-arrow-up"></Icon>
                                          ) : (
                                            <Icon iconClass="icon-arrow-down"></Icon>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <OverviewSectionHeading
                                      title={authorModel?.upcomingTripsSectionHeader}
                                      ctaLabel={authorModel?.viewMyTripsCtaLabel}
                                      sectionHeadingClass={'px-5 py-4'}
                                      ctaPath={authorModel?.viewMyTripsCtaPath}
                                    />
                                  )}
                                  {toggleAccordian === tripDataIndex && (
                                    <div className="upcomingtrips__top--image ">
                                      <Image
                                        title=""
                                        defaultImageURL={imageSourceURL}
                                        altText="hotel"
                                        customClass="img-container"
                                        loading="lazy"
                                        renditions={genrateImageRenditionPathForAllMobileAndTablet(imageSourceURL)}
                                      />
                                      <div className="bottom-logo pl-5 pb-3 ">
                                        <Icon
                                          iconClass={clsx(
                                            `brand-logo brand-logo-${hotelData?.basicInformation?.brand?.id.toUpperCase()} icon-inverse`
                                          )}
                                        ></Icon>
                                      </div>
                                      <div className="upcomingtrips__top--scrim-component" />
                                    </div>
                                  )}
                                </div>
                                {toggleAccordian === tripDataIndex && (
                                  <div
                                    className={clsx(
                                      'upcomingtrips__bottom d-flex flex-column',
                                      isOverviewPage
                                        ? 'flex-lg-row pb-4 pt-3 px-4 px-lg-5 pb-lg-5 pt-lg-3'
                                        : 'flex-md-row pb-4 pt-3 px-4 p-md-5'
                                    )}
                                    data-testid="upcomingtrips__data"
                                  >
                                    <div
                                      className={clsx(
                                        'upcomingtrips__bottom--left pb-3 ',
                                        isOverviewPage ? 'pb-lg-0 overview-page-width' : 'pr-md-3 pb-md-0 pr-lg-0',
                                        'mr-lg-5 mr-md-0'
                                      )}
                                    >
                                      <TripDetails
                                        consumerId={sessionData?.consumerID}
                                        locale={localeVal}
                                        authorModelData={authorModel}
                                        hotelData={hotelData}
                                        bookingData={ele}
                                        overviewHeader={hotelHeader}
                                        isOverviewPage={isOverviewPage}
                                        isMobileViewPort={isMobileViewPort}
                                        isDesktopViewPort={isDesktopViewPort}
                                        tripDataId={tripDataId}
                                        suiteNightAwards={
                                          tripsSuiteNightAwardsData?.customer?.loyaltyInformation?.suiteNightAwards
                                            ?.tripItems
                                        }
                                      />
                                    </div>
                                    <div
                                      className={clsx(
                                        'upcomingtrips__bottom--right pl-0 pl-lg-0',
                                        !isOverviewPage && 'pl-md-3'
                                      )}
                                    >
                                      {' '}
                                      <MapView
                                        latitude={hotelData?.basicInformation?.latitude}
                                        longitude={hotelData?.basicInformation?.longitude}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                        return (
                          <Messages messageType="warning" className="my-4">
                            <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                          </Messages>
                        );
                      })}
                    </div>
                    <div>
                      {!isOverviewPage && (
                        <PaginationWithPageSize
                          customPaginationClass="activity-pagination"
                          enteriesLabel={`${totalCount} entries`}
                          currentPage={currentPageNo}
                          totalCount={totalCount ? totalCount : 0}
                          pageSize={dropdownobj}
                          onPageChange={(page: number): void => {
                            setToggleAccordian(0);
                            window?.scrollTo(0, 0);
                            setCurrentPageNo(page);
                            setTripData(null);
                            setPreviousBasicTripDetails(basicTripDetails);
                            setBasicTripDetails(null);
                            handleRefetch(page);
                          }}
                          showViewPerPage={false}
                          showTotalEntries={false}
                        />
                      )}
                    </div>

                    {!isOverviewPage && (
                      <>
                        {authorModel?.footerTextOne && (
                          <div className="container pl-md-0">
                            <RichText
                              customClass="container t-font-s text-center text-md-left text-lg-left"
                              text={addClassToBlankTargetLinks(authorModel?.footerTextOne)}
                              componentId={'footerTextOne'}
                            />
                          </div>
                        )}
                        {authorModel?.footerTextTwo && (
                          <div className="container pl-md-0 mt-md-4 mt-3">
                            <RichText
                              customClass="container t-font-s mb-5 text-center text-md-left text-lg-left"
                              text={addClassToBlankTargetLinks(authorModel?.footerTextTwo)}
                              componentId={'footerTextTwo'}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </StyledUpcomingTrips>
                ) : (
                  <div>
                    <NoUpComingTrips {...pageProps} localeVal={localeVal} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export const UpcomingTripsConfig = {
  emptyLabel: 'upcomingtrips',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/upcomingtrips`,
};

export const UpcomingTripsEditable = (props: any) => (
  <EditableComponent config={UpcomingTripsConfig} {...props}>
    <UpcomingTrips {...props} />
  </EditableComponent>
);
