// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';
import { PaginationPanel } from '@marriott/mi-ui-library-shop';
import { Text, Types } from '@marriott/mi-ui-library';

import { useCheckBreakpoint } from '../../modules/hooks';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { PaginationWithPageSizeProps } from './PaginationWithPageSize.types';
import { StyledPaginationWithPageSize } from './PaginationWithPageSize.styles';
import { Dropdown } from '../Dropdown';

// Use named rather than default exports.
export const PaginationWithPageSize: FC<PaginationWithPageSizeProps> = ({
  customPaginationClass,
  viewPerPageLabel,
  enteriesLabel,
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  showViewPerPage,
  showTotalEntries,
  pageSizeOptions,
  onPageSizeChange,
}) => {
  const { size, tags } = Types;
  const isDesktop = useCheckBreakpoint('viewportM'); // check if viewport is desktop till 768px

  const renderPagination = () => {
    return (
      <div
        data-testid="pagination-with-page-size"
        className="pagination-container col-md-4 col-12 justify-content-center"
      >
        <PaginationPanel
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize?.value?.toLowerCase() === 'all' ? totalCount : Number(pageSize.value)}
          onPageChange={onPageChange}
        />
      </div>
    );
  };
  return (
    <StyledPaginationWithPageSize
      data-component-name="m-account-PaginationWithPageSize"
      data-testid="account-PaginationWithPageSize"
      className={clsx('d-flex flex-column container py-md-5 py-4', customPaginationClass ?? '')}
    >
      {!isDesktop && renderPagination()}
      <div
        className={clsx(
          'pagination-render-main d-flex justify-content-between align-items-center col-12',
          showViewPerPage && viewPerPageLabel && 'pt-5 pt-md-0 p-0'
        )}
      >
        {showViewPerPage && viewPerPageLabel && (
          <div
            className="d-flex align-items-center col-md-4 col-8 p-0"
            aria-label={viewPerPageLabel}
            id="dropdown-label-page-size"
          >
            <Text copyText={viewPerPageLabel} fontSize={size.small} element={tags.span} customClass="mr-3 views" />
            <Dropdown
              defaultOption={pageSize.value}
              dropdownOptions={pageSizeOptions ?? []}
              onChange={onPageSizeChange ?? (() => ({}))}
              customClassName={''}
              dropdownId="page-size"
            />
          </div>
        )}
        {isDesktop && renderPagination()}
        <div className="col-md-4 col-4 p-0 pagination-container__entries text-right">
          {showTotalEntries && enteriesLabel && (
            <Text copyText={enteriesLabel} fontSize={size.medium} element={tags.span} />
          )}
        </div>
      </div>
    </StyledPaginationWithPageSize>
  );
};
