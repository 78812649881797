/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
// Imports for external libraries go here.
import { useState, useEffect, useContext, useRef } from 'react';
import { inspect } from 'util';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';

// Imports for internal (to the monorepo) libraries go here,
import { Modal } from '@marriott/mi-ui-library';
import { phoenixAccountOrderLineItem } from '../../modules/graph/';
import { CancelReservationDetails } from '../../molecules/CancelReservationDetails';
import { CancelReservationDetailsProps } from '../../molecules/CancelReservationDetails/CancelReservationDetails.types';

// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { useStore } from '../../modules/store/memberLevelStore';
import { CancelReservationProps, PointsDetailsState } from './CancelReservation.types';
import { StyledCancelReservation } from './CancelReservation.styles';
import { PageContext } from '../../modules/context/PageContext';
import { apiLogger } from '../../modules/utils';
import { generateApolloClientHeaders } from '../../modules/utils/helper';
import data from './__mock__/CancelReservation.json';

// Use named rather than default exports.
export const CancelReservation: React.FC<CancelReservationProps> = props => {
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const {
    cancelModal: {
      canceledReservations,
      confirmationNumber,
      confirmationId,
      showCancelReservation,
      setShowCancelReservation,
    },
  } = useStore();
  const initialData = canceledReservations?.bookingData?.node?.items;
  const [bookingData, setBookingData] = useState(Array<string | null>);
  const [pointsDetails, setPointsDetails] = useState<PointsDetailsState | null>(null);
  const [pointsMock, setPointsMock] = useState<PointsDetailsState | null>(null);
  const isAuthorMode = props?.isAuthorMode;

  const cardData: CancelReservationDetailsProps = {
    freeNightAwardReturnDescription: props?.model?.freeNightAwardReturnDescription,
    pointsReturnDescription: props?.model?.pointsReturnDescription,
    nightlyUpgradeAwardReturnDescription: props?.model?.nightlyUpgradeAwardReturnDescription,
    bookingData: bookingData,
    cancelButtonOverlayLabel: props?.model?.cancelButtonOverlayLabel,
    keepRoomButtonLabel: props?.model?.keepRoomButtonLabel,
    pointsDetails: pointsDetails,
  };

  const dataAvailable =
    !!confirmationNumber && !!confirmationId && !!bookingData.length && (!!pointsDetails || !!pointsMock);

  const [generatePointsData] = useLazyQuery(phoenixAccountOrderLineItem, {
    variables: {
      orderId: confirmationNumber,
      itemId: confirmationId,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    onCompleted: (data: any) => {
      setPointsDetails(data);
      apiLogger(`[CancelReservation] AccountOrderLineItem - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
    },
    onError: error => {
      apiLogger(
        `[CancelReservation] AccountOrderLineItem - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  useEffect(() => {
    if (confirmationNumber) {
      generatePointsData();
    }
  }, [confirmationNumber]);

  useEffect(() => {
    isAuthorMode ? setPointsMock(data?.data) : setPointsMock(null);
  }, [showCancelReservation, confirmationNumber, confirmationId]);

  useEffect(() => {
    if (initialData && confirmationNumber && showCancelReservation) {
      const filtered = initialData.filter(
        (data: any) => data?.basicInformation?.confirmationNumber === confirmationNumber
      );
      setBookingData(filtered);
    }
  }, [initialData, confirmationNumber, showCancelReservation]);

  useEffect(() => {
    if (showCancelReservation === false) {
      setBookingData([]);
    }
  }, [showCancelReservation]);

  return dataAvailable ? (
    <StyledCancelReservation data-testid="cancelreservation" data-component-name="o-account-cancelreservation">
      <Modal
        className="modal-main-container"
        show={showCancelReservation}
        popupOpenState={showCancelReservation}
        setPopupOpenState={(): void => {
          setShowCancelReservation(false);
        }}
        allFilterPopUp={true}
        secondaryClassName="modal-main-container__content"
        role="alertdialog"
        aria-modal="true"
        labelledBy="CancelReservation"
        modalId={'CancelReservation'}
      >
        <Modal.Header
          className="modal-header p-4 p-md-5 pb-md-4"
          labelText={props?.model?.cancelRoomTitle ?? ''}
          popupHeaderOnCLoseFunc={() => setShowCancelReservation(false)}
          customHeadingClass="t-subtitle-xl"
        />
        <Modal.Body className="p-0 modal-body">
          <CancelReservationDetails {...cardData} />
        </Modal.Body>
      </Modal>
    </StyledCancelReservation>
  ) : null;
};

export const CancelReservationConfig = {
  emptyLabel: 'cancelreservation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelreservation`,
};

export const CancelReservationEditable = (props: any) => {
  return (
    <EditableComponent config={CancelReservationConfig} {...props}>
      <CancelReservation {...props} />
    </EditableComponent>
  );
};
