/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';
import { Link, useCheckBreakpoint } from '@marriott/mi-ui-library';
import {
  addSubDirectoryPrefix,
  ValidMarshCodes,
  bookNowUrl,
  formatDateRange,
  generateViewHotelUrl,
} from '../../../modules';
import { CreatePropertyColumnProps, CreateIdColumnProps, CreateDurationColumnProps } from '../CancelledTrips.types';
import { usePageContext } from '../../../modules';

const CreatePropertyColumn: React.FC<CreatePropertyColumnProps> = ({ data, validIds, basicinfo, links }) => {
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportL');

  const id = data?.property?.id ?? '';
  const propertyName = basicinfo?.basicInformation?.name || '';
  return (
    <div>
      <div>
        <Link
          linkHref={generateViewHotelUrl(id, propertyName)}
          text={propertyName}
          linkClassName={clsx(`m-0 ${isTabletAndAboveViewPort ? 't-subtitle-l' : 't-subtitle-m'}`)}
          target={validIds?.includes(id) ? '_blank' : '_self'}
          data-is-co-brand="false"
        ></Link>
      </div>

      <Link
        linkHref={addSubDirectoryPrefix(`${bookNowUrl}${id}`)}
        text={links}
        linkClassName={clsx(
          `t-font-s ${validIds?.includes(id) ? 'm-link-tertiary-button-external' : 'm-link-tertiary-button'}`
        )}
        target={validIds?.includes(id) ? '_blank' : '_self'}
        data-is-co-brand="false"
      ></Link>
    </div>
  );
};

const CreateIdColumn: React.FC<CreateIdColumnProps> = ({ id, dataLabel }) => {
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportL');
  return (
    <div>
      <div>
        {!isTabletAndAboveViewPort ? (
          <div className="t-label-s t-subtitle-m mb-2">
            {dataLabel}: {id}
          </div>
        ) : (
          <div className="t-font-m">{id}</div>
        )}
      </div>
    </div>
  );
};

const CreateDurationColumn: React.FC<CreateDurationColumnProps> = ({ starttime, endtime }) => {
  const isTabletAndAboveViewPort = useCheckBreakpoint('viewportL');
  const { currentLocale } = usePageContext();
  return (
    <div className={clsx(`py-lg-2 date-range-text ${isTabletAndAboveViewPort ? 't-font-m' : 't-font-s'}`)}>
      {formatDateRange(starttime, endtime, currentLocale)}
    </div>
  );
};

export function createRows(data: any, basicdetails: any, booklabel: string, cancelLabel: string, confirmLabel: string) {
  const rowData: any[] = [];
  data?.customer?.orders?.edges.map((item: any, index: number) => {
    if (item?.node?.items) {
      rowData.push([
        <CreateDurationColumn
          starttime={item?.node?.items[0]?.basicInformation?.startDate ?? ''}
          endtime={item?.node?.items[0]?.basicInformation?.endDate ?? ''}
        />,
        <CreatePropertyColumn
          data={item?.node?.items[0]}
          validIds={ValidMarshCodes}
          basicinfo={basicdetails[index]}
          links={booklabel}
        />,
        <CreateIdColumn id={item?.node?.items[0]?.basicInformation?.confirmationNumber} dataLabel={cancelLabel} />,
        <CreateIdColumn id={item?.node?.items[0]?.basicInformation?.cancellationNumber} dataLabel={confirmLabel} />,
      ]);
    }
  });
  return rowData;
}
