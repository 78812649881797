import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';
import { StyledUpcomingTripsProps } from './UpcomingTrips.types';

export const StyledUpcomingTrips = styled.div<StyledUpcomingTripsProps>`
  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .m-link-tertiary-button{
        margin-left: ${toRem(27)} !important;
        margin-right: 0 !important;
      }
      .m-link-tertiary-button::after {
        transform: rotate(-180deg) !important;
      }
    }
    //giving important since we are overridng the pagination class
    .pagination-render-main {
      justify-content: center !important;
    }
    .upcomingtrips {
      box-shadow: 0 0 ${toRem(16)} ${baseVariables.color['neutral30']};
      border-radius: ${toRem(16)};
      &__top {
        &--header {
          .header-left {
            .top-brand {
              //for all brand logos
              .brand-logo-SH::before {
                font-size: ${toRem(35)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-GE::before {
                font-size: ${toRem(39)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-AR::before {
                font-size: ${toRem(45)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-AL::before {
                font-size: ${toRem(39)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-AK::before {
                font-size: ${toRem(35)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-CY::before {
                font-size: ${toRem(15)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-DE::before {
                font-size: ${toRem(46)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-DS::before {
                font-size: ${toRem(11)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-BG::before {
                font-size: ${toRem(24)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-EB::before {
                font-size: ${toRem(16)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-EL::before {
                font-size: ${toRem(40)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-FI::before {
                font-size: ${toRem(19)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-FP::before {
                font-size: ${toRem(33)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-JW::before {
                font-size: ${toRem(37)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-MD::before {
                font-size: ${toRem(16)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-ER::before {
                font-size: ${toRem(40)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-MI::before {
                font-size: ${toRem(33)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-MV::before {
                font-size: ${toRem(39)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-MC::before {
                font-size: ${toRem(33)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-OX::before {
                font-size: ${toRem(27)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-PR::before {
                font-size: ${toRem(40)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-BR::before {
                font-size: ${toRem(42)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-RI::before {
                font-size: ${toRem(20)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-SI::before {
                font-size: ${toRem(40)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-XR::before {
                font-size: ${toRem(38)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-LC::before {
                font-size: ${toRem(43)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-RZ::before {
                font-size: ${toRem(40)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-TS::before {
                font-size: ${toRem(32)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-TX::before {
                font-size: ${toRem(20)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-WH::before {
                font-size: ${toRem(14)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-WI::before {
                font-size: ${toRem(20)};
                color: ${baseVariables.color['base10']};
              }
              .brand-logo-XE::before {
                font-size: ${toRem(35)};
                margin-top: ${toRem(17)};
                color: ${baseVariables.color['base10']};
              }

              // hover issue for all the brand logos

              .brand-logo {
                &:hover {
                  background-color: ${constants.TRANSPARENT};
                }
              }
            }
            .icon-arrow-up,
            .icon-arrow-down {
              font-size: ${toRem(16)};
              @media ${baseVariables.mediaQuery.md} {
                font-size: ${toRem(24)};
              }
            }
          }
        }
        &--image {
          position: relative;
          img {
            width: 100%;
            height: ${toRem(221)};
            @media ${baseVariables.mediaQuery.md} {
              height: ${(props: StyledUpcomingTripsProps) => (props.isOverviewPage ? toRem(237) : toRem(400))};
            }
          }
        }
        &--scrim-component{
          position: absolute;
          height: ${toRem(168)};
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 45%, rgba(0, 0, 0, 0.52) 100%);
          bottom: 0;
        }
      }

      &__bottom {
        &--left {
          @media ${baseVariables.mediaQuery.md} {
            width: ${toRem(274)};
            &.overview-page-width{
              width: ${toRem(296)};
            }
          }
          @media ${baseVariables.mediaQuery.lg} {
            max-width: 36%;
            width: auto;
            &.overview-page-width{
              max-width: 43%;
            }
          }
        }
        &--right {
          height: ${toRem(200)};
          @media ${(props: StyledUpcomingTripsProps) =>
            props.isOverviewPage ? baseVariables.mediaQuery.lg : baseVariables.mediaQuery.md} {
            flex: 1;
            height: ${(props: StyledUpcomingTripsProps) => (props.isOverviewPage ? toRem(307) : toRem(268))};
          }
        }
      }
    }

    .toggle {
      cursor: pointer;
    }
    .bottom-logo {
      position: absolute;
      bottom: 0;
      z-index: 1;
      //for all brand logos
      .brand-logo-SH::before {
        font-size: ${toRem(35)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-GE::before {
        font-size: ${toRem(39)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-AR::before {
        font-size: ${toRem(45)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-AL::before {
        font-size: ${toRem(39)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-AK::before {
        font-size: ${toRem(35)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-CY::before {
        font-size: ${toRem(15)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-DE::before {
        font-size: ${toRem(46)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-DS::before {
        font-size: ${toRem(11)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-BG::before {
        font-size: ${toRem(24)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-EB::before {
        font-size: ${toRem(16)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-EL::before {
        font-size: ${toRem(40)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-FI::before {
        font-size: ${toRem(19)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-FP::before {
        font-size: ${toRem(33)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-JW::before {
        font-size: ${toRem(37)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-MD::before {
        font-size: ${toRem(16)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-ER::before {
        font-size: ${toRem(40)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-MI::before {
        font-size: ${toRem(33)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-MV::before {
        font-size: ${toRem(39)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-MC::before {
        font-size: ${toRem(33)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-OX::before {
        font-size: ${toRem(27)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-PR::before {
        font-size: ${toRem(40)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-BR::before {
        font-size: ${toRem(42)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-RI::before {
        font-size: ${toRem(20)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-SI::before {
        font-size: ${toRem(40)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-XR::before {
        font-size: ${toRem(38)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-LC::before {
        font-size: ${toRem(43)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-RZ::before {
        font-size: ${toRem(40)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-TS::before {
        font-size: ${toRem(32)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-TX::before {
        font-size: ${toRem(20)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-WH::before {
        font-size: ${toRem(14)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-WI::before {
        font-size: ${toRem(20)};
        color: ${baseVariables.color['base20']};
      }
      .brand-logo-XE::before {
        font-size: ${toRem(35)};
        margin-top: ${toRem(17)};
        color: ${baseVariables.color['base20']};
      }

      // hover for al logos
      .brand-logo {
        &:hover {
          background-color: ${constants.TRANSPARENT};
        }
      }
    }
    /* custom class added for pagination*/
    .pagination-render-main {
      display: inline-grid !important;
      justify-items: center;
      padding: 0 !important;
    }

    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }

    .top-brand:is(${constants.RTL_LANG_LIST}) {
      margin: 0 0 0 ${toRem(24)} !important;
      padding: 0 0 0 ${toRem(32)} !important;
    }

    .icon:is(${constants.RTL_LANG_LIST}) {
      padding: 0 0 0 ${toRem(16)} !important;
    }

    a.t-label-alt-s:is(${constants.RTL_LANG_LIST}) {
      padding: 0 ${toRem(16)} 0 0 !important;
    }

    .trip-details__actions:is(${constants.RTL_LANG_LIST}) {
      .icon-arrow-right:before {
        content: '\\e921';
      }
    }

    .upcomingtrips__bottom--left:is(${constants.RTL_LANG_LIST}) {
      padding: 0 0 0 ${toRem(32)} !important;
    }

    .bottom-logo:is(${constants.RTL_LANG_LIST}) {
      padding-right: ${toRem(32)} !important;
    }

    .trip-details:is(${constants.RTL_LANG_LIST}) {
      .mi-icon {
        padding: 0 0 0 ${toRem(8)} !important;
      }
    }

    .trip-details__cta--viewmodify:is(${constants.RTL_LANG_LIST}) {
      margin: 0 0 0 ${toRem(24)} !important;
    }

    .img-container{
      object-fit:cover;
    }
  `}
`;
