// Imports for external libraries go here.
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { inspect } from 'util';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Heading, Types, RichText, Messages } from '@marriott/mi-ui-library';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { useNuaStore } from '../../modules/store/nuaStore';
import { replaceText } from '../../modules/utils/promotionHelper';
import { ModifyUpgradeReqHeaderProps } from './ModifyUpgradeReqHeader.types';
import { StyledModifyUpgradeReqHeader } from './ModifyUpgradeReqHeader.styles';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { phoenixAccountAwardsRequestsCount } from '../../modules/graph';
import { PageContext, apiLogger, generateApolloClientHeaders } from '../../modules';
import awardReqCountData from './__mock__/ModifyUpgradeReqHeader.model.json';

// Use named rather than default exports.
export const ModifyUpgradeReqHeader = (props: ModifyUpgradeReqHeaderProps) => {
  const { modifyRequestHeader, upgradeRequestHeader, nuaLabel } = props;
  const { tripInfo } = useNuaStore(state => state);
  const { awardsRequestCount } = useBannerMessagesStore(state => state);
  const router = useRouter();
  const urlParam = router && router.query['nuaUpgrade'];
  const nuaUpgrade = urlParam === 'true' ? true : false;
  const dataLoaded = useRef<boolean>(false);
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const isAuthorMode = props?.isAuthorMode;
  const [awardsCount, setAwardsCount] = useState<number | null>(null);

  useEffect(() => {
    if (isAuthorMode) {
      setAwardsCount(awardReqCountData?.customer?.loyaltyInformation?.suiteNightAwards?.available?.count);
    }
    setAwardsCount(awardsRequestCount);
  }, []);

  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode ||
    awardsRequestCount !== null;

  const { error: countError } = useQuery(phoenixAccountAwardsRequestsCount, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    onCompleted: data => {
      dataLoaded.current = true;
      setAwardsCount(data?.customer?.loyaltyInformation?.suiteNightAwards?.available?.count);
      apiLogger(
        `[modifyUpgradeReqHeader] getAwardRequestCount - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      apiLogger(
        `[modifyUpgradeReqHeader] getAwardRequestCount - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  if (countError) {
    return (
      <Messages messageType="warning" className="my-4">
        <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
      </Messages>
    );
  }

  return (
    <StyledModifyUpgradeReqHeader
      className="container mb-4 mt-5"
      data-testid="modifyupgraderequestheader"
      data-component-name="o-account-modifyupgraderequestheader"
    >
      <Heading
        variation={Types.headingType.title}
        fontSize={Types.size.small}
        titleText={nuaUpgrade ? upgradeRequestHeader : modifyRequestHeader}
      />
      {nuaUpgrade &&
        (tripInfo?.basicInformation?.lengthOfStay && awardsCount !== null ? (
          <RichText
            componentId="room_selection"
            text={nuaLabel ? replaceText(nuaLabel, [tripInfo?.basicInformation?.lengthOfStay, `${awardsCount}`]) : ''}
            customClass="t-subtitle-m"
          />
        ) : (
          <div className="skeleton-loader modal_loader__heading" />
        ))}
    </StyledModifyUpgradeReqHeader>
  );
};

export const ModifyUpgradeReqHeaderConfig = {
  emptyLabel: 'modifyupgradereqheader',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/modifyupgradereqheader`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModifyUpgradeReqHeaderEditable = (props: any) => {
  return (
    <EditableComponent config={ModifyUpgradeReqHeaderConfig} {...props}>
      <ModifyUpgradeReqHeader {...props} />
    </EditableComponent>
  );
};
