import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@marriott/mi-ui-library';
import { VistanaVacationPolicyProps } from './VistanaVacationPolicy.types';
import { StyledVistanaVacationPolicy } from './VistanaVacationPolicy.styles';
import DOMPurify from 'isomorphic-dompurify';

export const VistanaVacationPolicy: FC<VistanaVacationPolicyProps> = ({ handleClose, modalURL, modalWrapperClass }) => {
  const [isLoading, setIsLoading] = useState(true);
  // handle closing functionality of cross icon in modal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (handleClose) {
        handleClose();
      }
    }
  };
  //fetches modal-content from the website and add to the div

  function getDivFromEndpoint() {
    modalURL &&
      fetch(modalURL)
        .then(response => response.text())
        .then(html => {
          setIsLoading(false);
          // Create a temporary element to hold the parsed HTML
          // no need to empty the element for insertAdjacentHTML as its newly created
          const tempElement = document.createElement('div');
          tempElement.append(DOMPurify.sanitize(html, { RETURN_DOM_FRAGMENT: true }));
          const modalClass = modalWrapperClass || 'modal-content';

          // Get the desired div element (adjust the selector as needed)
          let divElement = tempElement.querySelector(`.${modalClass}`);
          const bodyElement = tempElement.querySelector(`.m-modal-body`);
          bodyElement?.setAttribute('role', 'article');
          bodyElement?.setAttribute('tabindex', '0');
          if (!divElement) {
            divElement = tempElement.querySelector(`.m-modal`);
          }

          // Display the div element in the result div
          const divModal = document.getElementById('terms');
          if (divElement && divModal) {
            divModal.appendChild(divElement);
          }
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error fetching data:', error);
        });
  }

  useEffect(() => {
    // Call the function to get the div element from the endpoint
    getDivFromEndpoint();
  }, []);

  return (
    <StyledVistanaVacationPolicy
      data-component-name="m-account-VistanaVacationPolicy"
      data-testid="account-VistanaVacationPolicy"
    >
      <div className="modal" data-testid="VistanaVacationPolicy">
        <Modal
          className="modal-container custom-scrollbar modal-main-container"
          show={true}
          handleBlur={true}
          modalId="vistana-modal"
          labelledBy="vistana-modal"
          role="alertdialog"
          aria-modal="true"
          onClose={(): void => handleClose()}
          setPopupOpenState={(): void => handleClose()}
          popupOpenState={true}
        >
          <Modal.Body className="modal-container__body t-font-s pt-lg-5 pt-4 pl-md-5 pl-4 pb-lg-5 pb-4 pr-5 custom-scrollbar">
            <>
              {isLoading && (
                <div className="modal_loader">
                  <div className="d-flex justify-content-between m-5">
                    <div className="skeleton-loader modal_loader__heading"></div>
                  </div>
                  <div className="px-5 py-2">
                    <div className="skeleton-loader my-3 modal_loader__heading"></div>
                    <div className="skeleton-loader my-3 modal_loader__body"></div>
                    <div className="skeleton-loader my-3 modal_loader__body"></div>
                  </div>
                  <div className="px-5 py-2 my">
                    <div className="skeleton-loader my-3 modal_loader__heading"></div>
                    <div className="skeleton-loader my-3 modal_loader__body"></div>
                    <div className="skeleton-loader my-3 modal_loader__body"></div>
                  </div>
                </div>
              )}
              <div className="embed-container" id="terms"></div>
              <div className="close-icon-container">
                <div
                  className="popup-close custom_click_track"
                  tabIndex={0}
                  role="button"
                  onClick={(e): void => {
                    handleClick(e);
                  }}
                  onKeyDown={(e): void => {
                    handleClick(e);
                  }}
                  aria-label="Close pop up"
                >
                  <div className="icon-clear icon-m" data-testId="icon-clear"></div>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </StyledVistanaVacationPolicy>
  );
};
