/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */

import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import Glide from '@glidejs/glide';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useMutation, useQuery } from '@apollo/client';
import { inspect } from 'util';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Messages, RichText } from '@marriott/mi-ui-library';
import {
  apiLogger,
  ValidMarshCodes,
  ValidBrandIds,
  generateApolloClientHeaders,
  favouriteCount,
  overviewFavoritesDesktopCount,
  ritzCarltonBrandId,
  fallbackImages,
  bookNowUrl,
  savedHotelsImagesOrder,
  savedHotelsMaxCount,
  imageDomain,
  generateViewHotelUrl,
  generateRitzCarltonHotelUrl,
  addSubDirectoryPrefix,
  OVERVIEW_CONSTANT,
} from '../../modules/utils';
import { PageContext } from '../../modules/context/PageContext';
import { OverviewSectionHeading } from '../../molecules/OverviewSectionHeading';
import {
  phoenixAccountGetSavedPropertiesByCustomerId,
  phoenixAccountUpdateCustomerSavedProperties,
} from '../../modules/graph';
import properties from './__mock__/savedHotelDetails.json';
import { getDirection } from '../../modules/utils/overviewHelper';
import { FindFavorites } from './FindFavorites';
import { StyledSavedHotel } from './SavedHotelsDetails.styles';
import { Hotel, PhotoGallery, tertiaryLinkObjsTypes } from './SavedHotelsDetails.types';

const CarouselControls = dynamic(() => import('../../molecules/CarouselControls').then(mod => mod.CarouselControls));
const FavouriteCard = dynamic(() => import('../FavouriteCard/FavouriteCard').then(mod => mod.FavouriteCard));
const PropertyCard = dynamic(() => import('@marriott/mi-shop-components/src/molecules').then(mod => mod.PropertyCard));

export const SavedHotelsDetails = (pageProps: any) => {
  const { t } = useTranslation();
  const { croppingRatio, outputQuality, outputInterpolation, imageDimensions } =
    OVERVIEW_CONSTANT?.SAVED_HOTEL_CONSTANTS || {};
  const componentId = 'glide-favourites-card';
  const parentGlide = useRef<any>();
  const innerGlideList = useRef<any>([]);
  const glideRefOutlet = useRef<HTMLDivElement | any>(null);
  const parentGlideInialized = useRef<boolean>(false);

  const { IS_LOCAL_DEV } = process.env;
  const jsonContent = pageProps?.model;
  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const [savedPropertiesIds, setSavedPropertiesIds] = useState<Array<string> | null>();
  const [customerRevisionToken, setCustomerRevisionToken] = useState<Array<string> | null>();
  const [savedHotelData, setSavedHotelData] = useState<Array<any> | null>();
  const isAuthorMode = pageProps?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const currentLocale = pageContext?.currentLocale;

  const [updateCustomerSavedProperties] = useMutation(phoenixAccountUpdateCustomerSavedProperties);
  const [isDesktop, setIsDesktop] = useState<boolean>();
  const [isTablet, setIsTablet] = useState<boolean>();
  const [isMobile, setIsMobile] = useState<boolean>();
  const [overviewFavoritesCount, setOverviewFavoritesCount] = useState(overviewFavoritesDesktopCount);
  const [totalSavedHotels, setTotalSavedHotels] = useState([]);
  const [isUXLSavedError, setIsUXLSavedError] = useState(false);
  const [noOfDots, setNoOfDots] = useState<number>();
  const isOverViewPage = pageContext?.isOverViewPage;
  const customerRevesionTokenRef = useRef(''); // creating var becuase state aren't updaing on time
  const customerSavedListApiCallIsDone = useRef(true); // creating a ref so that we can hold next call till this be updated
  const noImages = [
    {
      defaultImageUrl: fallbackImages?.Classic,
    },
  ];
  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  useEffect(() => {
    if (isAuthorMode) {
      if (isOverViewPage) {
        // for storybook to show 3cards when using author mode
        const overViewFavCards = properties?.savedProperties?.slice(0, overviewFavoritesCount);
        setSavedHotelData(overViewFavCards);
      } else {
        setSavedHotelData(properties?.savedProperties);
      }
    }
  }, []);

  useEffect(() => {
    if (isOverViewPage && totalSavedHotels?.length > 0) {
      setOverviewFavoritesCount(overviewFavoritesDesktopCount);
      setSavedHotelData(totalSavedHotels?.slice(0, overviewFavoritesDesktopCount));
    }
  }, [isOverViewPage, totalSavedHotels]);

  useEffect(() => {
    const handleResize = () => {
      const mobileBreakpoint = window.matchMedia('(max-width:767px)');
      const tabletBreakpoint = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
      const desktopBreakpoint = window.matchMedia('(min-width: 1200px)');
      setIsDesktop(desktopBreakpoint?.matches);
      setIsTablet(tabletBreakpoint?.matches);
      setIsMobile(mobileBreakpoint?.matches);
    };
    handleResize();
    isOverViewPage && window.addEventListener('resize', handleResize);
    return () => isOverViewPage && window.removeEventListener('resize', handleResize);
  }, []);

  const {
    loading: savedPropertiesLoading,
    error: savedPropertiesError,
    data: _savedPropertiesData,
  } = useQuery(phoenixAccountGetSavedPropertiesByCustomerId, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      const tempPropertyIds: Array<string> = [];
      data?.customer?.savedProperties?.map((propertyData: { id: string }) => {
        tempPropertyIds.push(propertyData?.id);
      });
      let updatedArr =
        data?.customer?.savedProperties?.length && data?.customer?.savedProperties?.length > 20
          ? data?.customer?.savedProperties?.slice(0, 20)
          : data?.customer?.savedProperties;
      if (isOverViewPage) {
        updatedArr = updatedArr.length > 3 ? updatedArr?.slice(0, overviewFavoritesCount) : updatedArr;
      }
      setTotalSavedHotels(data?.customer?.savedProperties);
      setSavedHotelData(updatedArr);
      setSavedPropertiesIds(tempPropertyIds);
      setCustomerRevisionToken(data?.customer?.revisionToken);
      customerRevesionTokenRef.current = data?.customer?.revisionToken;
      apiLogger(
        `[SavedHotelsDetails] getSavedPropertiesData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      dataLoaded.current = true;
      apiLogger(
        `[SavedHotelsDetails] getSavedPropertiesData - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  const savedHotelsActions = async (propertyId: string) => {
    if (customerSavedListApiCallIsDone.current) {
      customerSavedListApiCallIsDone.current = false;
      const tempSavedPropertyIds = savedPropertiesIds ? [...savedPropertiesIds] : [];
      const propertyIdAt = tempSavedPropertyIds?.findIndex((savedPropertyID: string) => savedPropertyID === propertyId);
      if (propertyIdAt !== undefined && propertyIdAt !== -1) {
        tempSavedPropertyIds?.splice(propertyIdAt, 1);
      } else {
        tempSavedPropertyIds?.push(propertyId);
      }
      const savedPropertiesPayload = tempSavedPropertyIds?.map((savedPropertyId: string) => {
        return { id: savedPropertyId };
      });
      const { data } = await updateCustomerSavedProperties({
        variables: {
          input: {
            id: sessionData?.consumerID,
            revisionToken: customerRevesionTokenRef.current || customerRevisionToken,
            savedProperties: savedPropertiesPayload,
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        onCompleted: (data: any) => {
          if (data) {
            setSavedPropertiesIds(tempSavedPropertyIds);
          }
          if (data?.updateCustomerSavedProperties?.revisionToken) {
            setIsUXLSavedError(false);
            customerRevesionTokenRef.current = data?.updateCustomerSavedProperties?.revisionToken;
            setCustomerRevisionToken(data?.updateCustomerSavedProperties?.revisionToken);
          } else {
            setIsUXLSavedError(true);
          }
          customerSavedListApiCallIsDone.current = true;
          apiLogger(
            `[SavedHotelsDetails] updateCustomerSavedProperties - sessionId value: ${
              sessionData?.sessionToken
            }: ${inspect(data)}`
          );
        },
        onError: error => {
          customerSavedListApiCallIsDone.current = true;
          setIsUXLSavedError(true);
          apiLogger(
            `[SavedHotelsDetails] updateCustomerSavedProperties - sessionId value: ${
              sessionData?.sessionToken
            } - error: ${inspect(error)}`
          );
        },
      });
      if (data?.updateCustomerSavedProperties?.revisionToken) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const cardsLength = savedHotelData?.length || 0;
    const showFavoriteCard = cardsLength < overviewFavoritesCount;
    let cardsToDisplay;
    if (isDesktop) {
      cardsToDisplay = 3;
      setNoOfDots(0);
    } else if (isTablet) {
      cardsToDisplay = 2;
      if (showFavoriteCard) {
        setNoOfDots(cardsLength < cardsToDisplay ? 0 : cardsLength);
      } else {
        setNoOfDots(cardsLength - 1);
      }
    } else {
      cardsToDisplay = 1;
      if (showFavoriteCard) {
        setNoOfDots(cardsLength + 1);
      } else {
        setNoOfDots(cardsLength);
      }
    }
  }, [isDesktop, isTablet, savedHotelData]);

  useEffect(() => {
    if (savedHotelData && savedHotelData?.length > 0) {
      if (!parentGlide?.current?.type) {
        const direction = getDirection();
        const glideOutletElement = glideRefOutlet?.current;
        parentGlide.current = new Glide(glideOutletElement, {
          type: 'slider',
          gap: 16,
          startAt: 0,
          autoplay: false,
          bound: true,
          direction: direction,
          focusAt: 0,
          dragThreshold: false,
          perView: 3,
          peek: {
            before: 0,
            after: 0,
          },
          keyboard: false,
          breakpoints: {
            1440: {
              perView: 3,
              peek: {
                before: 0,
                after: 0,
              },
            },
            1200: {
              perView: 2,
              peek: {
                before: 0,
                after: 0,
              },
            },
            767: {
              perView: 1,
              peek: {
                before: 0,
                after: 0,
              },
            },
          },
        });
        if (glideOutletElement) {
          parentGlideInialized.current = true;
        }
        const glideOnRun = () => {
          const index = Number(
            glideRefOutlet?.current
              ?.querySelector('.glideOutlet .glide__slide--active')
              ?.getAttribute('data-glide-index')
          );

          if (index !== null && savedHotelData) {
            const nextIndex = (index + 1) % savedHotelData?.length;
            const prevIndex = (index - 1 + savedHotelData?.length) % savedHotelData?.length;
            const nextImg = glideRefOutlet?.current?.querySelector(`[data-glide-index='${nextIndex}'] img`);
            const prevImg = glideRefOutlet?.current?.querySelector(`[data-glide-index='${prevIndex}'] img`);

            if (nextImg) nextImg.setAttribute('loading', 'eager');
            if (prevImg) prevImg.setAttribute('loading', 'eager');
          }
        };

        parentGlide?.current?.on('run.after', glideOnRun);
        parentGlide?.current?.on(['mount.after', 'run', 'run.after'], glideOnRun);
      }
      if (parentGlide?.current) {
        const innerGlideFix = innerGlideList?.current?.filter((instance: any) => instance);
        if (innerGlideFix?.length) {
          innerGlideList?.current?.forEach((childGlide: any) => {
            childGlide.on('move', function () {
              parentGlide?.current?.disable();
            });
            childGlide.on('move.after', function () {
              parentGlide?.current?.enable();
            });
            childGlide.mount();
          });
          // HACK FOR CHILD PIP
          parentGlide?.current?.on('run.after', () => {
            innerGlideList?.current?.forEach((childGlide: any) => {
              document
                ?.querySelector(`${childGlide?.selector} [data-glide-el="controls[nav]"] button.glide__bullet--active`)
                ?.classList.remove('glide__bullet--active');
              document
                ?.querySelector(`${childGlide?.selector} [data-glide-el="controls[nav]"] button`)
                ?.classList.add('glide__bullet--active');
            });
          });
          if (parentGlide?.current?.length === 1) {
            isMobile && parentGlide?.current?.mount();
          } else {
            parentGlide?.current?.mount();
          }
        } else if (
          parentGlide?.current?.length > 1 &&
          isMobile &&
          parentGlideInialized.current &&
          innerGlideList?.current?.length === 0
        ) {
          parentGlide?.current?.mount();
        }
      }
    }
  }, [savedHotelData, isMobile]);

  const tertiaryLinkObj = (isRitzCarlton: boolean, propertyId: string, propertyName: string, isExternal: boolean) => {
    return {
      isLink: true,
      href: isRitzCarlton
        ? generateRitzCarltonHotelUrl(propertyId, propertyName, currentLocale)
        : generateViewHotelUrl(propertyId, propertyName),

      className: clsx(isExternal ? 'm-link-tertiary-button-external' : 'm-link-tertiary-button', 'analytics-click'),
      linkAriaLabelOpenNewWindow: 'opens in new window',
      buttonCopy: jsonContent?.viewHotelDetailsLabel,
      target: isExternal ? '_blank' : '_self',
      placeLinkOnTopSection: true,
    };
  };

  const getImgProcQueryString = useMemo(() => {
    return (
      `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&crop=${
        imageDimensions.width * croppingRatio
      }:${imageDimensions.height * croppingRatio};*,*&downsize=` +
      imageDimensions.width +
      'px:*'
    );
  }, []);
  const updatePropertyImage = (
    propertyImages: PhotoGallery | undefined,
    primaryImages: any
  ): { defaultImageUrl: string }[] => {
    if (propertyImages) {
      const updatedArray: { defaultImageUrl: string }[] = [];
      savedHotelsImagesOrder?.forEach(order => {
        propertyImages[order]?.edges?.forEach(item => {
          if (updatedArray?.length < savedHotelsMaxCount && item?.node?.imageUrls?.square) {
            updatedArray?.push({
              defaultImageUrl: imageDomain + item?.node?.imageUrls?.square + getImgProcQueryString,
            });
          }
        });
      });
      if (updatedArray.length) {
        return updatedArray;
      } else if (primaryImages?.length) {
        const primaryImageArr = primaryImages?.map((item: any) => {
          return {
            defaultImageUrl: imageDomain + item?.node?.imageUrls?.square + getImgProcQueryString,
          };
        });
        return primaryImageArr;
      }
      return noImages;
    }
    return noImages;
  };

  const isSavedHotel = (propertyId: string) => {
    return savedPropertiesIds?.findIndex((id: string) => id === propertyId) !== -1 ? true : false;
  };

  const updatedSavedHotelsActions = isAuthorMode
    ? (propertyId: string) => {
        apiLogger(
          `[SavedHotelsDetails] Authormode click - sessionId: ${sessionData?.sessionToken} - proprtyID: ${inspect(
            propertyId
          )}`
        );
      }
    : savedHotelsActions;

  const favObject = (propertyId: string, isSavedHotel: boolean) => {
    return {
      isFavorite: isSavedHotel,
      favCallback: () => {
        isSavedHotel = !isSavedHotel;
        updatedSavedHotelsActions(propertyId);
      },
      label: isSavedHotel ? t('unMarkFavorite') : t('markFavorite'),
    };
  };
  const footerObject = (propertyId: string) => {
    return {
      className: 'm-button-s m-button-secondary',
      href: addSubDirectoryPrefix(`${bookNowUrl}${propertyId}`),
      text: jsonContent?.bookNowLabel,
    };
  };

  const titleObject = (propertyName: string, tertiaryLinkObjs: tertiaryLinkObjsTypes, isExternal: boolean) => {
    return {
      title: propertyName,
      isTitleLink: true,
      titleAriaLabel: propertyName,
      titleClassNames: ['t-subtitle-m px-4 mb-0', 't-subtitle-m px-4 mb-0', 't-subtitle-m px-4 mb-0'],
      titleClickCallback: () => window.open(tertiaryLinkObjs?.href, isExternal ? '_blank' : '_self'),
      titleButtonChildNode: <span className="m-ellipsis-3lines t-subtitle-m">{propertyName}</span>,
    };
  };

  if (savedPropertiesError) {
    return (
      <div data-testid="uxl-error-msg">
        <Messages messageType="warning" className="my-4">
          <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
        </Messages>
      </div>
    );
  }

  return (
    <StyledSavedHotel
      data-component-name="o-account-savedHotelsDetails"
      data-testid="savedHotelsDetails"
      className={clsx(
        'saved-container',
        isOverViewPage ? savedHotelData?.length && 'pt-4 pt-md-5 mt-md-2' : 'pt-4 container-lg'
      )}
    >
      <div className={isOverViewPage && 'container'}>
        {!!(isOverViewPage && savedHotelData?.length) && (
          <OverviewSectionHeading
            title={pageProps?.favouritesLabel}
            ctaLabel={pageProps?.viewMyFavoritesLabel}
            ctaPath={pageProps?.viewMyFavouritesCtaPath}
            sectionHeadingClass="mb-0"
          />
        )}
        {isUXLSavedError && (
          <div data-testid="uxl-error-msg-warning">
            <Messages messageType="warning" className={clsx('my-4', isOverViewPage ? '' : 'px-2')}>
              <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
            </Messages>
          </div>
        )}
        <div
          className={clsx(
            'row saved-container__row',
            !isOverViewPage && savedHotelData && savedHotelData?.length < favouriteCount
              ? ' justify-content-center justify-content-xl-start'
              : ''
          )}
        >
          {savedPropertiesLoading ? (
            <div
              className={clsx(
                'row',
                !isOverViewPage && savedHotelData && savedHotelData?.length < favouriteCount
                  ? ' justify-content-center justify-content-xl-start'
                  : ''
              )}
              key="saved-hotel-row"
              data-testid="property-loading"
            >
              {Array(isDesktop ? 3 : isTablet ? 2 : 1)
                .fill('')
                .map((_, index: number) => {
                  return (
                    <div className="col-md-6 col-lg-4" key={`saved-hotel-loader-${index}`}>
                      <PropertyCard
                        titleDetails={{
                          title: '',
                          titleAriaLabel: undefined,
                          titleClickCallback: undefined,
                          titleClickTrackVal: undefined,
                          isTitleLink: undefined,
                        }}
                        isVerticalCard={true}
                        loader={true}
                      />
                    </div>
                  );
                })}
            </div>
          ) : isOverViewPage && savedHotelData?.length ? (
            <div className="container" data-testid="overview-data">
              <div id={componentId} className="glideOutlet property-card-rtl" ref={glideRefOutlet}>
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides d-flex p-0 pb-3 mb-0 list-unstyled">
                    {savedHotelData?.map((res: any, index: number) => {
                      const basicInfo = res?.basicInformation;
                      const propertyId = res?.id;
                      const brandObj = basicInfo?.brand;
                      const brandId = brandObj?.id;
                      const propertyImages = res?.media?.photoGallery;
                      const primaryImages = res?.media?.primaryImage?.edges;
                      const propertyName = basicInfo?.name;
                      const isExternal =
                        ValidMarshCodes?.includes(propertyId) || ValidBrandIds?.includes(brandId) ? true : false;
                      const isRitzCarlton = brandId?.toLowerCase() === ritzCarltonBrandId;
                      const isSavedHotelUpdated = isSavedHotel(propertyId);
                      const tertiaryLinkObjs = tertiaryLinkObj(
                        isRitzCarlton,
                        propertyId,
                        // nameInDefaultLanguage is used instead of name for url formation as it is not translated causing
                        basicInfo?.nameInDefaultLanguage,
                        isExternal
                      );
                      const titleObj = titleObject(propertyName, tertiaryLinkObjs, isExternal);
                      const imageData = updatePropertyImage(propertyImages, primaryImages);
                      const favObj = favObject(propertyId, isSavedHotelUpdated);
                      const footerObj = footerObject(propertyId);
                      const brandData = {
                        brandId: brandId,
                        label: brandObj?.name,
                      };
                      return (
                        <li className="glide__slide" key={`child-glide-outlet-li-${index}`}>
                          <PropertyCard
                            {...res}
                            titleDetails={titleObj}
                            parentGlide={parentGlide?.current}
                            innerGlideList={innerGlideList}
                            brandDetails={brandData}
                            images={imageData}
                            favIconDetails={favObj}
                            tertiaryLinkDetails={tertiaryLinkObjs}
                            footerLinkDetails={footerObj}
                            isVerticalCard={true}
                            addScrimToImage={true}
                            glideClass={`child-glide-outlet-${index}`}
                            key={`child-glide-outlet-${index}`}
                            slideIndex={index + 1}
                          />
                        </li>
                      );
                    })}
                    {savedHotelData && savedHotelData?.length < overviewFavoritesCount && (
                      <li className="glide__slide">
                        <FindFavorites model={pageProps} customClass="glide-find-favorites" />
                      </li>
                    )}
                  </ul>
                  {noOfDots && savedHotelData?.length ? <CarouselControls dots={noOfDots} /> : ''}
                </div>
              </div>
            </div>
          ) : (
            savedHotelData?.map((res: Hotel, index: number) => (
              <FavouriteCard
                props={res}
                {...jsonContent}
                currentLocale={currentLocale}
                isExternal={
                  ValidMarshCodes?.includes(res?.id) || ValidBrandIds?.includes(res?.basicInformation?.brand?.id)
                    ? true
                    : false
                }
                isRitzCarlton={res?.basicInformation?.brand?.id?.toLowerCase() === ritzCarltonBrandId}
                isSavedHotel={
                  savedPropertiesIds?.findIndex((propertyId: string) => propertyId === res.id) !== -1 ? true : false
                }
                savedHotelsActions={
                  isAuthorMode
                    ? (propertyId: string) => {
                        apiLogger(
                          `[SavedHotelsDetails] Authormode click - sessionId: ${
                            sessionData?.sessionToken
                          } - proprtyID: ${inspect(propertyId)}`
                        );
                      }
                    : savedHotelsActions
                }
                key={`saved-hotel-${index}`}
              />
            ))
          )}
          {!isOverViewPage && savedHotelData && savedHotelData?.length < favouriteCount && (
            <FindFavorites {...pageProps} />
          )}
        </div>
      </div>
    </StyledSavedHotel>
  );
};

export const SavedHotelsDetailsConfig = {
  emptyLabel: 'savedHotelsDetails',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/savedHotelsDetails`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SavedHotelsDetailsEditable = (props: any) => (
  <EditableComponent config={SavedHotelsDetailsConfig} {...props}>
    <SavedHotelsDetails {...props} />
  </EditableComponent>
);

// export default SavedHotelsDetailsEditable;
