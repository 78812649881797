/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect, useContext, useRef, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { inspect } from 'util';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client'; // Import useQuery from Apollo Client
import { Messages, RichText } from '@marriott/mi-ui-library';

import { constants } from '../../modules/utils/constants/constants';
import { PageContext } from '../../modules/context/PageContext';
import { useStore } from '../../modules/store/memberLevelStore';
import { useOverviewStore } from '../../modules/store/overviewStore';
import { apiLogger, generateApolloClientHeaders } from '../../modules/utils';
import { phoenixAccountGetMyActivityRewardsEarned } from '../../modules/graph';
import { TripsNavigationProps, bannerInformationDetails } from './TripsNavigation.types';
import { StyledTripsNavigation } from './TripsNavigation.styles';
import { ToggleSwitch } from '../../molecules/ToggleSwitch';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import bannerData from './__mock__/suiteBanner.json';

const SuiteNightAwardBanner = dynamic(() => import('./SuiteNightAwardBanner').then(mod => mod.SuiteNightAwardBanner));

export const TripsNavigation: FC<TripsNavigationProps> = (props: any) => {
  const { IS_LOCAL_DEV } = process.env;

  const [_bannerDetails, setBannerDetails] = useState<bannerInformationDetails | null>(null);
  const [selectedTab, setSelectedTab] = useState(constants.TAB_UPCOMING);

  const { awardsCount, setAwardsCount } = useOverviewStore(state => state);

  const upcomingTitle = props?.upcomingTripsCTALabel;
  const cancelledTitle = props?.cancelledTripsCTALabel;
  const lookUpbtn = props?.lookUpReservationCTALabel;
  const tabList = [
    {
      tabTitle: upcomingTitle,
      tabSubtitle: '',
      tabValue: constants.TAB_UPCOMING,
    },
    {
      tabTitle: cancelledTitle,
      tabSubtitle: '',
      tabValue: constants.TAB_CANCELLED,
    },
  ];

  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;

  const setSelectedTripData = useStore(state => state.setTripData);
  const { setAwardsRequestCount } = useBannerMessagesStore(state => state);

  useEffect(() => {
    if (isAuthorMode) {
      setBannerDetails(bannerData);

      setAwardsCount(
        bannerData?.customer?.loyaltyInformation?.suiteNightAwards?.available?.count,
        bannerData?.customer?.loyaltyInformation?.suiteNightAwards?.available?.details
      );
      setAwardsRequestCount(bannerData?.customer?.loyaltyInformation?.suiteNightAwards?.available?.count);
    }
  }, []);

  useEffect(() => {
    setSelectedTripData(selectedTab === constants.TAB_UPCOMING);
  }, [setSelectedTripData, selectedTab]);

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  // Define the Apollo Client query using useQuery
  const { loading: _tripsNavigationLoading, error: tripsNavigationError } = useQuery(
    phoenixAccountGetMyActivityRewardsEarned,
    {
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      skip: skipQuery,
      onCompleted: data => {
        dataLoaded.current = true;
        setBannerDetails(data);
        const details = data?.customer?.loyaltyInformation?.suiteNightAwards?.available;
        setAwardsCount(details?.count, details.details);
        setAwardsRequestCount(details?.count);
        apiLogger(
          `[TripsNavigation] getTripsNavigationData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: error => {
        apiLogger(
          `[TripsNavigation] getTripsNavigationData - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
            error
          )}`
        );
      },
    }
  );

  if (tripsNavigationError) {
    return (
      <Messages messageType="warning" className="my-4">
        <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
      </Messages>
    );
  }

  const handleTabSelect = (selectedTabValue: string) => {
    setSelectedTab(selectedTabValue);
  };

  let tabContent = null;
  if (selectedTab === constants.TAB_UPCOMING) {
    tabContent = (
      <div data-testid="suite-night-banner">
        <SuiteNightAwardBanner suiteNightCount={awardsCount} {...props} />
      </div>
    );
  } else if (selectedTab === constants.TAB_CANCELLED) {
    tabContent = '';
  }

  return (
    <StyledTripsNavigation data-testid="tripsnavigation" data-component-name="o-account-tripsnavigation">
      <ToggleSwitch tabList={tabList} lookUpbtn={lookUpbtn} onTabSelect={handleTabSelect} />
      {tabContent}
    </StyledTripsNavigation>
  );
};

export const TripsNavigationConfig = {
  emptyLabel: 'tripsNavigation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/tripsnavigation`,
};

export const TripsNavigationEditable = (props: any) => {
  return (
    <EditableComponent config={TripsNavigationConfig} {...props}>
      <TripsNavigation {...props} />
    </EditableComponent>
  );
};
