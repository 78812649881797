// Styles for CancelUpgradeRequest go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
const { lg, md } = { ...baseVariables.mediaQuery };

export const StyledCancelUpgradeRequest = styled.div`
  .border-rounded {
    border-radius: ${toRem(16)};
  }
  .wrapper {
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 ${baseVariables.shadows.boxShadowTile};
  }
  .subheader {
    color: ${baseVariables.color['neutral40']};
    & a {
      text-decoration: underline;
    }
  }
  .apply-button {
    min-width: ${toRem(217)};
  }
  p {
    padding: 0;
    margin: 0;
  }
  a {
    color: ${baseVariables.color['neutral40']}!important;
  }
  .cancel-request {
    min-width: max-content;
  }

  .text-box {
    @media ${md} {
      max-width: ${toRem(492)};
    }
    @media ${lg} {
      max-width: ${toRem(910)};
    }
  }

  .modal__container {
    &-footer {
      background-color: ${baseVariables.color['merch50']};
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      .apply-button {
        height: ${toRem(40)};
        padding: 0;
        width: ${toRem(216)};
        font-size: ${toRem(16)};
        margin: auto;
      }
    }
  }
`;
