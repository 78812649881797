// Styles for VistanaVacationPolicy go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules';

export const StyledVistanaVacationPolicy = styled.div`
  ${rtl`
  .modal-container {
      .popup-close {
        width: ${toRem(32)};
        height: ${toRem(32)};
        border-radius: ${toRem(4)};
        background-color: ${baseVariables.color.neutral20};
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
        &:focus {
          cursor: pointer;
        }
      }
    }
    //TO DO: to check if embed classess are accessible in environment
    .modal-content {
      section {
        padding-left: 0;
      }
    }
  }

  .embed-container {
    width: 100%;
    height: 100%;

    & > .m-modal {
      height: 100%; 
    }
  }

  .modal-main-container {
    #vistana-modal {
      max-height: calc(100% - ${toRem(112)});
      overflow-y: auto;
    }

    .modal-container__body {
      overflow: hidden;
      padding: 0 !important;
      height: 100%;
      max-height:none;
    }

    .close-icon-container {
      position: absolute;
      top: ${toRem(32)};
    }

    .m-modal-header {
      padding: ${toRem(32)};
    }

    .custom-btn {
      min-width: ${toRem(128)};
    }

    .m-modal-body {
      overflow-x: hidden;
      padding-top: 0;
      max-height: calc(90vh - ${toRem(190)});
        @media ${baseVariables.mediaQuery.md} {
          max-height: calc(70vh - ${toRem(190)});
        }

      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-radius: ${toRem(400)};
        background: ${baseVariables.color['neutral30']};
      }
      ::-webkit-scrollbar {
        width: ${toRem(10)};
        height: ${toRem(40)};
      }
    }
  }
  &:is(${constants.RTL_LANG_LIST}) {
    .m-modal-header {
      padding-left: ${toRem(95)}; 
    }
    .close-icon-container {
      left: ${toRem(32)};
    }
  }
  &:not(${constants.RTL_LANG_LIST}) {
    .m-modal-header {
      padding-right: ${toRem(95)};
    }
    .close-icon-container {
      right: ${toRem(32)};
    }
  }

  .modal_loader {
    width:100%;
    &__heading {
      width: 40%;
      height: ${toRem(24)};
    }
    &__body {
      width: 80%;
      height: ${toRem(24)};
    }
  }
`}
`;
