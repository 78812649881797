// Styles for SignInContentBlock go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants, getContentBlockImageDimensions } from '../../modules';

export const StyledSignInContentBlock = styled.div<{ overlay: boolean }>`
  ${rtl`
    padding-left: ${toRem(24)};
    padding-right: ${toRem(24)};
    max-width: ${toRem(458)};
    &.respath-wrapper {
      padding-left: ${toRem(16)} !important;
      padding-right: ${toRem(16)} !important;
      border-top: 0;
        @media ${baseVariables.mediaQuery.lg} {
          padding-left: ${toRem(48)} !important;
          border-left: 0;
          border-top: none;
          border-bottom: 0;
        }
      &:is(${constants.RTL_LANG_LIST}) {
          padding-right: ${toRem(8)} !important;
          @media ${baseVariables.mediaQuery.lg} {
            padding-right: ${toRem(48)} !important;
            padding-left: ${toRem(8)} !important;
          }
        }
    }
    @media ${baseVariables.mediaQuery['sm']} {
      padding-left: ${toRem(48)};
      padding-right: 0;
    }
    &:is(${constants.RTL_LANG_LIST}) {
      @media ${baseVariables.mediaQuery.lg} {
        margin-right: auto;
        margin-left: ${toRem(0)} !important;
        text-align: justify;
      }
    }
    .text-content-container {
      &:is(${constants.RTL_LANG_LIST}){
        text-align: justify;
      }
    }
    .respath-heading {
      line-height: ${toRem(26)};
    }
    .width-mobile-100 {
      width: 100%;
      height: auto;
      @media ${baseVariables.mediaQuery['sm']} {
        width: auto;
      }
    }
    .signin-btn {
      @media ${baseVariables.mediaQuery['md']} {
        padding: ${toRem(8)} ${toRem(40)} !important;
      }
      @media ${baseVariables.mediaQuery['lg']} {
        padding: ${toRem(8)} ${toRem(32)} !important;
      }
    }
    .point-list {
      line-height: ${toRem(18)};
      ul {
        padding-left: ${toRem(16)}; // to override RTE styles
        &:is(${constants.RTL_LANG_LIST}){
          padding-right: ${toRem(16)}; 
        }
      }
      li {
        padding-bottom: ${toRem(8)}; // to override RTE styles
        p {
          margin: 0; // to override RTE styles
        }
        &:last-child {
          padding-bottom: 0 !important;
        }
      }
      &:is(${constants.RTL_LANG_LIST}){
        text-align: justify;
      }
    }
    &.signin-img-block {
      .logo-container {
        margin-bottom: ${toRem(36)};
        .logo {
          font-size: ${toRem(58)};
        }
      }
      img {
        width: 100%;
        min-height: ${toRem(210)} !important;
        max-width: ${toRem(getContentBlockImageDimensions('all'))} !important;
        @media ${baseVariables.mediaQuery.sm} {
          min-height: ${(props: { overlay: boolean }) => (props?.overlay ? toRem(229) : toRem(210))} !important;
          max-width: ${(props: { overlay: boolean }) =>
            toRem(getContentBlockImageDimensions('sm', props?.overlay))} !important;
        }
        @media ${baseVariables.mediaQuery.md} {
          min-height: ${(props: { overlay: boolean }) => (props?.overlay ? toRem(288) : toRem(229))} !important;
          max-width: ${(props: { overlay: boolean }) =>
            toRem(getContentBlockImageDimensions('md', props?.overlay))} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          min-height: ${toRem(288)} !important;
          max-width: ${toRem(getContentBlockImageDimensions('lg'))} !important;
        }
      &:is(${constants.RTL_LANG_LIST}){
        margin-right: 0px !important;
        margin-left: auto;
        @media ${baseVariables.mediaQuery.lg} {
          margin-right: auto !important;
          margin-left: 0px !important;
        }
      }
    }
  `}
`;

export const StyledSignInContentBlockFooter = styled.div`
  margin-right: ${toRem(-8)};
  .footer-container {
    border-top: ${toRem(2)} solid ${baseVariables.color['neutral30']};
    padding-left: ${toRem(24)};
    padding-right: ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      padding-left: ${toRem(48)} !important;
    }
  }
`;

export const StyledSignInContentBlockContainer = styled.div<{ isResLookUpVariation: boolean }>`
  @media ${baseVariables.mediaQuery.md} {
    max-width: ${(props: { isResLookUpVariation: boolean }) => (props?.isResLookUpVariation ? toRem(507) : 'auto')};
  }
  @media ${baseVariables.mediaQuery.lg} {
    max-width: ${(props: { isResLookUpVariation: boolean }) => (props?.isResLookUpVariation ? toRem(490) : 'auto')};
  }
  height: 100%;
  .signin-btn {
    width: fit-content;
  }
  .res-block {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(507)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(490)};
    }
  }
`;
